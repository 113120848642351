/* eslint-disable typescript-sort-keys/interface */

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  DateShort: { input: string; output: string };
  IsoNaiveDatetime: { input: string; output: string };
  Map: { input: unknown; output: unknown };
  NaiveDateTime: { input: string; output: string };
  Upload: { input: File; output: File };
};

export type ActiveDistributionFlows = {
  __typename?: 'ActiveDistributionFlows';
  announcements: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  newShareholderWelcome: Scalars['Boolean']['output'];
  updates: Scalars['Boolean']['output'];
};

export type ActivityStats = {
  __typename?: 'ActivityStats';
  announcementsViewed: Scalars['Int']['output'];
  followers: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  likes: Scalars['Int']['output'];
  questionsAsked: Scalars['Int']['output'];
  surveyResponses: Scalars['Int']['output'];
  updatesViewed: Scalars['Int']['output'];
};

export type AmplifyInvestor = {
  __typename?: 'AmplifyInvestor';
  contact?: Maybe<Contact>;
  id: Scalars['ID']['output'];
  shareholding?: Maybe<Shareholding>;
  type: Scalars['String']['output'];
};

export type AmplifyInvestorConnection = {
  __typename?: 'AmplifyInvestorConnection';
  edges?: Maybe<Array<Maybe<AmplifyInvestorEdge>>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type AmplifyInvestorConnectionTotalArgs = {
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  searchTags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AmplifyInvestorEdge = {
  __typename?: 'AmplifyInvestorEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<AmplifyInvestor>;
};

export type Announcement = {
  __typename?: 'Announcement';
  announcementId: Scalars['ID']['output'];
  companyProfileId: Scalars['ID']['output'];
  date: Scalars['Date']['output'];
  header: Scalars['String']['output'];
};

export type AnnouncementAnalyticsStats = {
  __typename?: 'AnnouncementAnalyticsStats';
  totalLikes: Scalars['Int']['output'];
  totalLikesDifference: Scalars['Int']['output'];
  totalQuestions: Scalars['Int']['output'];
  totalQuestionsDifference: Scalars['Int']['output'];
  totalSurveyResponses: Scalars['Int']['output'];
  totalSurveyResponsesDifference: Scalars['Int']['output'];
  totalViews: Scalars['Int']['output'];
  totalViewsDifference: Scalars['Int']['output'];
};

export type AnnouncementEmailDistributionStatistics = {
  __typename?: 'AnnouncementEmailDistributionStatistics';
  announcements: Array<MediaAnnouncement>;
  clicks: Scalars['Int']['output'];
  ctr: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
  openRate: Scalars['Float']['output'];
  opens: Scalars['Int']['output'];
  sends: Scalars['Int']['output'];
};

export type AnnouncementEngagement = {
  __typename?: 'AnnouncementEngagement';
  date: Scalars['Date']['output'];
  totalUniqueVisitors: Scalars['Int']['output'];
  totalViews: Scalars['Int']['output'];
};

export type AnnouncementList = {
  __typename?: 'AnnouncementList';
  mediaAnnouncement?: Maybe<MediaAnnouncement>;
  preparedAnnouncement?: Maybe<PreparedAnnouncement>;
};

export type AnnouncementListConnection = {
  __typename?: 'AnnouncementListConnection';
  edges?: Maybe<Array<Maybe<AnnouncementListEdge>>>;
  options?: Maybe<Options>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type AnnouncementListConnectionTotalArgs = {
  options?: InputMaybe<OptionsInput>;
};

export type AnnouncementListEdge = {
  __typename?: 'AnnouncementListEdge';
  announcementList: AnnouncementList;
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<AnnouncementList>;
};

export type AnnouncementReachAndEngagement = {
  __typename?: 'AnnouncementReachAndEngagement';
  announcements: Array<MediaAnnouncement>;
  date: Scalars['Date']['output'];
  totalLikes: Scalars['Int']['output'];
  totalQuestions: Scalars['Int']['output'];
  totalSurveyResponses: Scalars['Int']['output'];
  totalViews: Scalars['Int']['output'];
};

export type AnnouncementSubtype = {
  __typename?: 'AnnouncementSubtype';
  isMarketSensitive: Scalars['Boolean']['output'];
  isRecommended: Scalars['Boolean']['output'];
  subtype: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AnnouncementType = {
  __typename?: 'AnnouncementType';
  rectype: Scalars['String']['output'];
  subtypes: Array<AnnouncementSubtype>;
  value: Scalars['String']['output'];
};

export type AnnouncementTypes = {
  __typename?: 'AnnouncementTypes';
  allValues: Array<Scalars['String']['output']>;
  list: Array<AnnouncementType>;
  recommendedValues: Array<Scalars['String']['output']>;
  rectypeValues: Array<Scalars['String']['output']>;
  subtypeValues: Array<Scalars['String']['output']>;
};

export type AnnouncementViewerStats = {
  __typename?: 'AnnouncementViewerStats';
  campaignViews?: Maybe<Scalars['Int']['output']>;
  linkedinViews?: Maybe<Scalars['Int']['output']>;
  other?: Maybe<Scalars['Int']['output']>;
  preparedLinkViews?: Maybe<Scalars['Int']['output']>;
  twitterViews?: Maybe<Scalars['Int']['output']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  description?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  heading?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  orderId: Scalars['Int']['output'];
  subheading?: Maybe<Scalars['String']['output']>;
};

export type AttachmentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  heading?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['Int']['input'];
  subheading?: InputMaybe<Scalars['String']['input']>;
};

export type Audience = {
  __typename?: 'Audience';
  count: Scalars['Int']['output'];
  lastUpdatedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
};

export type AudienceBreakdown = {
  __typename?: 'AudienceBreakdown';
  announcements: Array<Announcement>;
  campaigns: Array<EngagementCampaign>;
  conversionRateSma: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
  totalConvertedShareholders: Scalars['Int']['output'];
  totalLeads: Scalars['Int']['output'];
  totalNominatedShareholders: Scalars['Int']['output'];
  totalUniqueVisitors: Scalars['Int']['output'];
  updates: Array<Update>;
};

export type AudienceList = {
  __typename?: 'AudienceList';
  hub?: Maybe<Audience>;
  imported?: Maybe<Audience>;
  shareholder?: Maybe<Audience>;
  unsubscribed: UnsubscribedAudience;
};

export type AutomationStats = {
  __typename?: 'AutomationStats';
  id: Scalars['ID']['output'];
  shareholderWelcomeEmailSentCount?: Maybe<Scalars['Int']['output']>;
};

export type BaseEmailTemplate = {
  __typename?: 'BaseEmailTemplate';
  emailHtml?: Maybe<Scalars['String']['output']>;
  emailJson: Scalars['String']['output'];
  lastEditedProfileUser?: Maybe<CompanyProfileUser>;
  templateType: BaseEmailTemplateType;
  updatedAt: Scalars['IsoNaiveDatetime']['output'];
};

export enum BaseEmailTemplateType {
  AutomatedDistribution = 'AUTOMATED_DISTRIBUTION',
  CustomCampaign = 'CUSTOM_CAMPAIGN',
  ManualDistribution = 'MANUAL_DISTRIBUTION',
  NewShareholderWelcome = 'NEW_SHAREHOLDER_WELCOME',
}

export type BenchmarkAnalytics = {
  __typename?: 'BenchmarkAnalytics';
  currentCompanyStats: BenchmarkStats;
  id: Scalars['ID']['output'];
  peerCompaniesStats: BenchmarkStats;
};

export type BenchmarkStats = {
  __typename?: 'BenchmarkStats';
  /** Total number of questions, likes and survey responses */
  hubActionsCount: Scalars['Int']['output'];
  hubActionsTopFive: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  likesCount: Scalars['Int']['output'];
  nominatedShareholdersCount: Scalars['Int']['output'];
  questionsCount: Scalars['Int']['output'];
  signupsCount: Scalars['Int']['output'];
  signupsTopFive: Scalars['Boolean']['output'];
  uniqueVisitorsCount: Scalars['Int']['output'];
  viewsCount: Scalars['Int']['output'];
  viewsTopFive: Scalars['Boolean']['output'];
};

export type Block = {
  __typename?: 'Block';
  content?: Maybe<Scalars['Map']['output']>;
  id: Scalars['ID']['output'];
  insertedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  lastEditedByCompanyUser?: Maybe<CompanyUser>;
  position?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  websitePage?: Maybe<Page>;
  websitePageId?: Maybe<Scalars['ID']['output']>;
};

export type BoardReport = {
  __typename?: 'BoardReport';
  endDate: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  pdfUrl?: Maybe<Scalars['String']['output']>;
};

export type BrandColor = {
  __typename?: 'BrandColor';
  hsl: Scalars['String']['output'];
  shades: ShadeMap;
  tints: TintMap;
};

export type Broker = {
  __typename?: 'Broker';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nameShort: Scalars['String']['output'];
  pids: Array<Maybe<Scalars['String']['output']>>;
  /** Number of shareholdings who are with this broker */
  shareholdingsCount?: Maybe<Scalars['Int']['output']>;
};

export type BrokerBreakdown = {
  __typename?: 'BrokerBreakdown';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nameShort: Scalars['String']['output'];
  netMovements: Scalars['Int']['output'];
  pids: Array<Scalars['String']['output']>;
  shareholdersCount: Scalars['Int']['output'];
  totalShares: Scalars['Int']['output'];
};

export type BulkImport = {
  __typename?: 'BulkImport';
  contactsCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  uploaderProfileUser: CompanyProfileUser;
};

export type BuyerField = {
  __typename?: 'BuyerField';
  accountName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  movement: Scalars['Int']['output'];
  movementType: MovementType;
};

export type CampaignDistributionGraphData = {
  __typename?: 'CampaignDistributionGraphData';
  campaignName: Scalars['String']['output'];
  clickThroughRate: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
  openRate: Scalars['Int']['output'];
  totalSent: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type CampaignEmailRecipientTrackingEvents = {
  __typename?: 'CampaignEmailRecipientTrackingEvents';
  eventType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type CertificateById = {
  __typename?: 'CertificateById';
  certificate: InvestorCertificate;
  nextPendingId?: Maybe<Scalars['ID']['output']>;
  totalPending: Scalars['Int']['output'];
};

export type CheckDynamicListSafeToDeleteResponse = {
  __typename?: 'CheckDynamicListSafeToDeleteResponse';
  distributionSettings: Array<DistributionSettings>;
  draftEmails: Array<Email>;
  id: Scalars['ID']['output'];
};

export enum CommentSource {
  Email = 'EMAIL',
  Forum = 'FORUM',
  Linkedin = 'LINKEDIN',
  Other = 'OTHER',
  Twitter = 'TWITTER',
}

export enum CommsCustomEmailType {
  Marketing = 'MARKETING',
  Transactional = 'TRANSACTIONAL',
}

export type CompanyColorPalette = {
  __typename?: 'CompanyColorPalette';
  primary: BrandColor;
};

export type CompanyOverview = {
  __typename?: 'CompanyOverview';
  announcements: Array<Maybe<MediaAnnouncement>>;
  /** Could be null if no data for this date */
  close?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  date: Scalars['Date']['output'];
  shareholdingsCount: Scalars['Int']['output'];
  /** Could be null if no data for this date */
  volume?: Maybe<Scalars['Float']['output']>;
};

export type CompanyProfile = {
  __typename?: 'CompanyProfile';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  aiSummariesEnabled: Scalars['Boolean']['output'];
  announcementTypes: AnnouncementTypes;
  city?: Maybe<Scalars['String']['output']>;
  colorPalette?: Maybe<CompanyColorPalette>;
  country?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  customDomain?: Maybe<CustomDomain>;
  defaultPublicInteractionsEnabled: Scalars['Boolean']['output'];
  dnsIntegrationPdfUrl?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  featuresEnabled: Array<Maybe<Scalars['String']['output']>>;
  hasCompanyShareholderOfferPermission: Scalars['Boolean']['output'];
  hasHadPlacement: Scalars['Boolean']['output'];
  hasHadSpp: Scalars['Boolean']['output'];
  hidePrivateQuestionsFromCount: Scalars['Boolean']['output'];
  hub: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  informationConfirmedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  insertedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  investorCentreEnabled?: Maybe<Scalars['Boolean']['output']>;
  investorHub: InvestorHub;
  isDemo: Scalars['Boolean']['output'];
  isPremium: Scalars['Boolean']['output'];
  isTrial: Scalars['Boolean']['output'];
  isUnlisted: Scalars['Boolean']['output'];
  latestMarketCap: Scalars['Float']['output'];
  liveHubBuilderWebsite?: Maybe<Website>;
  liveShareholderOfferId?: Maybe<Scalars['ID']['output']>;
  logoUrl: Scalars['String']['output'];
  mobileNumber?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  primaryBrandColor?: Maybe<Scalars['String']['output']>;
  registry?: Maybe<Registries>;
  registryDataStatus?: Maybe<RegistryDataStatus>;
  sectors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  socialConnection?: Maybe<SocialConnection>;
  state?: Maybe<Scalars['String']['output']>;
  ticker: Ticker;
  totalShareholderOffers: Scalars['Int']['output'];
  tradingName: Scalars['String']['output'];
};

export type CompanyProfileUpdateInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  aiSummariesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  customDomain?: InputMaybe<CustomDomainInput>;
  defaultPublicInteractionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  hidePrivateQuestionsFromCount?: InputMaybe<Scalars['Boolean']['input']>;
  investorCentreEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  investorHub?: InputMaybe<InvestorHubInput>;
  logo?: InputMaybe<Scalars['Upload']['input']>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  primaryBrandColor?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyProfileUser = {
  __typename?: 'CompanyProfileUser';
  enabledProductTour: Scalars['Boolean']['output'];
  expired?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  profile: CompanyProfile;
  role: CompanyProfileUserRole;
  simulatingAdminUserId?: Maybe<Scalars['String']['output']>;
  status: CompanyProfileUserStatus;
  title?: Maybe<CompanyTitle>;
  user: CompanyUser;
};

export enum CompanyProfileUserRole {
  Admin = 'ADMIN',
  Viewer = 'VIEWER',
}

export enum CompanyProfileUserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
}

export type CompanyStats = {
  __typename?: 'CompanyStats';
  asOfDate?: Maybe<Scalars['DateShort']['output']>;
  /** Number in days */
  averageHoldingTime: Scalars['Float']['output'];
  companyProfileId: Scalars['ID']['output'];
  geographicalBreakdownChart: GeographicalBreakdownChart;
  /** Growth ratio for the past 90 days until today and not as_of_date */
  growthRatioPastNinetyDays: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  profitLoss: ProfitLoss;
  raisingPotential: RaisingPotential;
  shareholderInsights: ShareholderInsights;
};

export type CompanyTitle = {
  __typename?: 'CompanyTitle';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CompanyUser = {
  __typename?: 'CompanyUser';
  companyProfileUsers: Array<CompanyProfileUser>;
  confirmedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  hasPassword: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  informationConfirmedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mobileNumber?: Maybe<Scalars['String']['output']>;
  /** System notification preferences stored in DB + Marketing notification preferences stored on Mailchimp */
  notificationPreferences: Array<NotificationPreference>;
  passwordLastUpdatedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
};

export enum CompanyUserNotificationChannel {
  Email = 'EMAIL',
}

export enum CompanyUserNotificationScope {
  Distribution = 'DISTRIBUTION',
  System = 'SYSTEM',
  Top_50ShareholderMovements = 'TOP_50_SHAREHOLDER_MOVEMENTS',
  WelcomePage = 'WELCOME_PAGE',
}

export type ComputershareRegistryCredentialInput = {
  password?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type Contact = {
  __typename?: 'Contact';
  addressCity?: Maybe<Scalars['String']['output']>;
  addressCountry?: Maybe<Scalars['String']['output']>;
  addressLineOne?: Maybe<Scalars['String']['output']>;
  addressLineTwo?: Maybe<Scalars['String']['output']>;
  addressPostcode?: Maybe<Scalars['String']['output']>;
  addressState?: Maybe<Scalars['String']['output']>;
  commsUnsubscribes: Array<ContactUnsubscribe>;
  companyProfile: CompanyProfile;
  creatorName?: Maybe<Scalars['String']['output']>;
  creatorUser?: Maybe<CompanyUser>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  globalUnsubscribe?: Maybe<ContactGlobalUnsubscribe>;
  hasEmailRecipient?: Maybe<Scalars['Boolean']['output']>;
  hnwIdentifiedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  hnwStatus?: Maybe<ContactHnwStatuses>;
  id: Scalars['ID']['output'];
  importedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  invalidated: Scalars['Boolean']['output'];
  investor?: Maybe<InvestorUser>;
  isNominatedShareholder?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  nominatedShareholderIdentifiedAt?: Maybe<
    Scalars['IsoNaiveDatetime']['output']
  >;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  shareholderStatus?: Maybe<ShareholderStatus>;
  shareholdings: Array<Shareholding>;
  shareholdingsWithoutPreloads: Array<Shareholding>;
  suppression?: Maybe<ContactSuppression>;
  tags: Array<Tag>;
};

export type ContactHasEmailRecipientArgs = {
  emailId?: InputMaybe<Scalars['ID']['input']>;
};

export type ContactActivity = {
  __typename?: 'ContactActivity';
  companyUser?: Maybe<Scalars['Map']['output']>;
  id: Scalars['ID']['output'];
  metadata: Scalars['Map']['output'];
  timestamp: Scalars['IsoNaiveDatetime']['output'];
  type: Scalars['String']['output'];
};

export type ContactActivityConnection = {
  __typename?: 'ContactActivityConnection';
  edges?: Maybe<Array<Maybe<ContactActivityEdge>>>;
  options?: Maybe<Options>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type ContactActivityConnectionTotalArgs = {
  contactId: Scalars['ID']['input'];
  options?: InputMaybe<OptionsInput>;
};

export type ContactActivityEdge = {
  __typename?: 'ContactActivityEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<ContactActivity>;
};

export type ContactConnection = {
  __typename?: 'ContactConnection';
  edges?: Maybe<Array<Maybe<ContactEdge>>>;
  options?: Maybe<Options>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type ContactConnectionTotalArgs = {
  options?: InputMaybe<OptionsInput>;
};

export type ContactEdge = {
  __typename?: 'ContactEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Contact>;
};

export type ContactGlobalUnsubscribe = {
  __typename?: 'ContactGlobalUnsubscribe';
  id: Scalars['ID']['output'];
};

export enum ContactHnwStatuses {
  IdentifiedViaBehaviour = 'IDENTIFIED_VIA_BEHAVIOUR',
  NominatedCertPending = 'NOMINATED_CERT_PENDING',
  NominatedCertVerified = 'NOMINATED_CERT_VERIFIED',
  NominatedWithoutCert = 'NOMINATED_WITHOUT_CERT',
}

export type ContactInput = {
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressCountry?: InputMaybe<Scalars['String']['input']>;
  addressLineOne?: InputMaybe<Scalars['String']['input']>;
  addressLineTwo?: InputMaybe<Scalars['String']['input']>;
  addressPostcode?: InputMaybe<Scalars['String']['input']>;
  addressState?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  invalidated?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ContactNote = {
  __typename?: 'ContactNote';
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invalidated: Scalars['Boolean']['output'];
  occuredAt: Scalars['IsoNaiveDatetime']['output'];
  title: Scalars['String']['output'];
  type: ContactNoteType;
};

export type ContactNoteInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  invalidated?: InputMaybe<Scalars['Boolean']['input']>;
  occuredAt?: InputMaybe<Scalars['IsoNaiveDatetime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ContactNoteType>;
};

export enum ContactNoteType {
  Call = 'CALL',
  Email = 'EMAIL',
  Meeting = 'MEETING',
  Note = 'NOTE',
}

export type ContactShareholdingSummary = {
  __typename?: 'ContactShareholdingSummary';
  announcements: Array<Maybe<MediaAnnouncement>>;
  dailyHoldings: Array<Maybe<DailyHolding>>;
  id: Scalars['ID']['output'];
  shareMovements: Array<Maybe<ShareMovement>>;
  timeseries: Array<Maybe<Timeseries>>;
};

export type ContactSuppression = {
  __typename?: 'ContactSuppression';
  id: Scalars['ID']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  reason: ContactSuppressionReasons;
  source: ContactSuppressionSources;
};

export enum ContactSuppressionReasons {
  Bounce = 'BOUNCE',
  Complaint = 'COMPLAINT',
}

export enum ContactSuppressionSources {
  Marketing = 'MARKETING',
  Transactional = 'TRANSACTIONAL',
}

export type ContactUnsubscribe = {
  __typename?: 'ContactUnsubscribe';
  id: Scalars['ID']['output'];
  scope: ContactUnsubscribeScopeType;
};

export enum ContactUnsubscribeScopeType {
  ActivityFollow = 'ACTIVITY_FOLLOW',
  ActivityUpdate = 'ACTIVITY_UPDATE',
  Announcement = 'ANNOUNCEMENT',
  General = 'GENERAL',
  NewFollower = 'NEW_FOLLOWER',
  Qa = 'QA',
  Raises = 'RAISES',
  SmsRaises = 'SMS_RAISES',
}

export type ContactsGraphData = {
  __typename?: 'ContactsGraphData';
  date: Scalars['Date']['output'];
  hubEmails: Scalars['Int']['output'];
  totalEmails: Scalars['Int']['output'];
};

export type CorporatePage = {
  __typename?: 'CorporatePage';
  id: Scalars['String']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  isLive: Scalars['Boolean']['output'];
  lastEditedByUser: CompanyUser;
  sections: Array<Section>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: CorporatePageType;
  updatedAt: Scalars['IsoNaiveDatetime']['output'];
};

export type CorporatePageInput = {
  isLive: Scalars['Boolean']['input'];
  sections: Array<SectionInput>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: CorporatePageType;
};

export enum CorporatePageType {
  Governance = 'GOVERNANCE',
  Team = 'TEAM',
}

export type CurrentCompanyDistributionSettings = {
  __typename?: 'CurrentCompanyDistributionSettings';
  email?: Maybe<DistributionSettings>;
  id: Scalars['ID']['output'];
  linkedin?: Maybe<DistributionSettings>;
  twitter?: Maybe<DistributionSettings>;
  updatedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
};

export type CurrentCompanyInvestorHubAnalysisStats = {
  __typename?: 'CurrentCompanyInvestorHubAnalysisStats';
  companyProfileId: Scalars['ID']['output'];
  currentMonthInvestorHubStats: InvestorHubAnalysisStats;
  overallInvestorHubStats: InvestorHubAnalysisStats;
  previousMonthInvestorHubStats: InvestorHubAnalysisStats;
};

export type CurrentHoldingDistributions = {
  __typename?: 'CurrentHoldingDistributions';
  date: Scalars['Date']['output'];
  holdingDistributions: Array<HoldingDistribution>;
  id: Scalars['ID']['output'];
};

export type CurrentHoldingLengths = {
  __typename?: 'CurrentHoldingLengths';
  date: Scalars['Date']['output'];
  holdingLengths: Array<HoldingLength>;
  id: Scalars['ID']['output'];
};

export type CurrentHoldingSizes = {
  __typename?: 'CurrentHoldingSizes';
  date: Scalars['Date']['output'];
  holdingSizes: Array<HoldingSize>;
  id: Scalars['ID']['output'];
};

export type CurrentShareholderContactabilities = {
  __typename?: 'CurrentShareholderContactabilities';
  date: Scalars['Date']['output'];
  emailsCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  phonesCount: Scalars['Int']['output'];
  totalShareholders: Scalars['Int']['output'];
};

export type CurrentShareholderLocations = {
  __typename?: 'CurrentShareholderLocations';
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  shareholderLocations: Array<ShareholderLocation>;
};

export type CurrentShareholderProfits = {
  __typename?: 'CurrentShareholderProfits';
  averageShareholdersProfit: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  shareholderProfits: Array<ShareholderProfit>;
  shareholdersInLoss: Scalars['Int']['output'];
  shareholdersInProfit: Scalars['Int']['output'];
  totalUnrealisedGain: Scalars['Float']['output'];
  totalUnrealisedLoss: Scalars['Float']['output'];
};

export type CustomDomain = {
  __typename?: 'CustomDomain';
  canSendEmails: Scalars['Boolean']['output'];
  customDomain: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDkimVerified: Scalars['Boolean']['output'];
  isDmarcVerified: Scalars['Boolean']['output'];
  isMailfromVerified: Scalars['Boolean']['output'];
  isVercelDomainVerified: Scalars['Boolean']['output'];
  rootDomain: Scalars['String']['output'];
  sesDkimConfigurations?: Maybe<Array<Maybe<CustomDomainConfiguration>>>;
  sesDmarcConfigurations?: Maybe<Array<Maybe<CustomDomainConfiguration>>>;
  sesMailFromConfigurations?: Maybe<Array<Maybe<CustomDomainConfiguration>>>;
  vercelConfiguration?: Maybe<CustomDomainConfiguration>;
};

export type CustomDomainConfiguration = {
  __typename?: 'CustomDomainConfiguration';
  configured?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CustomDomainInput = {
  customDomain: Scalars['String']['input'];
};

export type CustomDomainVerification = {
  __typename?: 'CustomDomainVerification';
  isDkimVerified: Scalars['Boolean']['output'];
  isDmarcVerified: Scalars['Boolean']['output'];
  isMailfromVerified: Scalars['Boolean']['output'];
  isVercelDomainVerified: Scalars['Boolean']['output'];
};

export type CustomEmail = {
  __typename?: 'CustomEmail';
  replyToEmail: Scalars['String']['output'];
  sendFromEmail: Scalars['String']['output'];
  sendFromName: Scalars['String']['output'];
  type: CommsCustomEmailType;
};

export type CustomEmailInput = {
  replyToEmail: Scalars['String']['input'];
  sendFromEmail: Scalars['String']['input'];
  sendFromName: Scalars['String']['input'];
  type: CommsCustomEmailType;
};

export type CustomEmails = {
  __typename?: 'CustomEmails';
  automatedDistributionTemplate: BaseEmailTemplate;
  customCampaignTemplate: BaseEmailTemplate;
  isTransactionalEmailSameAsMarketingEmail?: Maybe<
    Scalars['Boolean']['output']
  >;
  manualDistributionTemplate: BaseEmailTemplate;
  marketingEmail?: Maybe<CustomEmail>;
  newShareholderWelcomeTemplate: BaseEmailTemplate;
  transactionalEmail?: Maybe<CustomEmail>;
};

export type DailyHits = {
  __typename?: 'DailyHits';
  date: Scalars['Date']['output'];
  totalHits: Scalars['Int']['output'];
};

export type DailyHolding = {
  __typename?: 'DailyHolding';
  balance: Scalars['Int']['output'];
  date: Scalars['DateShort']['output'];
  id: Scalars['ID']['output'];
};

export type DailyUniqueHits = {
  __typename?: 'DailyUniqueHits';
  date: Scalars['Date']['output'];
  uniqueHits: Scalars['Int']['output'];
};

export type DailyUserSignups = {
  __typename?: 'DailyUserSignups';
  date: Scalars['Date']['output'];
  totalSignups: Scalars['Int']['output'];
};

export type DistributedSocial = {
  __typename?: 'DistributedSocial';
  id: Scalars['ID']['output'];
  linkedinPostId?: Maybe<Scalars['String']['output']>;
  linkedinPostUrl?: Maybe<Scalars['String']['output']>;
  linkedinPostedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  twitterPostId?: Maybe<Scalars['String']['output']>;
  twitterPostUrl?: Maybe<Scalars['String']['output']>;
  twitterPostedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
};

export enum DistributionChannelType {
  Email = 'EMAIL',
  Linkedin = 'LINKEDIN',
  Twitter = 'TWITTER',
}

export enum DistributionRecipientListType {
  All = 'ALL',
  AllShareholders = 'ALL_SHAREHOLDERS',
  Hub = 'HUB',
  Imported = 'IMPORTED',
  NewShareholders = 'NEW_SHAREHOLDERS',
}

export type DistributionSettings = {
  __typename?: 'DistributionSettings';
  channel?: Maybe<DistributionChannelType>;
  emailSettings?: Maybe<DistributionSettingsEmail>;
  excludedContacts?: Maybe<Array<Scalars['ID']['output']>>;
  id?: Maybe<Scalars['ID']['output']>;
  includedAnnouncementTypes?: Maybe<Array<Scalars['String']['output']>>;
  includedUpdateTypes?: Maybe<Array<MediaUpdateType>>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  recipientListType?: Maybe<Array<DistributionRecipientListType>>;
  shareholderWelcomeEnabled?: Maybe<Scalars['Boolean']['output']>;
  socialPostTemplate?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  usedDefaultTypes?: Maybe<Scalars['Boolean']['output']>;
};

export type DistributionSettingsCreateInput = {
  channel: DistributionChannelType;
};

export type DistributionSettingsEmail = {
  __typename?: 'DistributionSettingsEmail';
  emailHtml: Scalars['String']['output'];
  emailJson: Scalars['String']['output'];
  fromName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  subject?: Maybe<Scalars['String']['output']>;
};

export type DistributionSettingsEmailInput = {
  emailHtml?: InputMaybe<Scalars['String']['input']>;
  emailJson?: InputMaybe<Scalars['String']['input']>;
  fromName?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type DistributionSettingsUpdateInput = {
  /** List of contact id's that should be excluded from this distribution */
  excludedContacts?: InputMaybe<Array<Scalars['ID']['input']>>;
  includedAnnouncementTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  includedUpdateTypes?: InputMaybe<Array<MediaUpdateType>>;
  recipientListType?: InputMaybe<Array<DistributionRecipientListType>>;
  shareholderWelcomeEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  socialPostTemplate?: InputMaybe<Scalars['String']['input']>;
  /** Boolean value of whether the default suggested types were used */
  usedDefaultTypes?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DynamicList = {
  __typename?: 'DynamicList';
  companyProfile: CompanyProfile;
  description?: Maybe<Scalars['String']['output']>;
  estimatedContactsSize?: Maybe<Scalars['Int']['output']>;
  filters: Array<Filter>;
  id: Scalars['ID']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  lastUpdatedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  lastUpdatedByProfileUser?: Maybe<CompanyProfileUser>;
  lastUsedOnEmail?: Maybe<Email>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['IsoNaiveDatetime']['output'];
};

export type DynamicListConnection = {
  __typename?: 'DynamicListConnection';
  edges?: Maybe<Array<Maybe<DynamicListEdge>>>;
  options?: Maybe<Options>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type DynamicListConnectionTotalArgs = {
  options?: InputMaybe<OptionsInput>;
};

export type DynamicListEdge = {
  __typename?: 'DynamicListEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<DynamicList>;
};

export type DynamicListInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<FilterInput>>;
  name: Scalars['String']['input'];
};

export type Email = {
  __typename?: 'Email';
  campaignName: Scalars['String']['output'];
  doNotSendToContactIds: Array<Scalars['ID']['output']>;
  doNotSendToContacts: Array<Contact>;
  doNotSendToDynamicListIds: Array<Scalars['ID']['output']>;
  doNotSendToDynamicLists: Array<DynamicList>;
  emailHtml?: Maybe<Scalars['String']['output']>;
  emailJson?: Maybe<Scalars['String']['output']>;
  emailRecipients: Array<Maybe<EmailRecipient>>;
  fromEmail?: Maybe<Scalars['String']['output']>;
  fromName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  isDraft: Scalars['Boolean']['output'];
  isWelcomeEmail: Scalars['Boolean']['output'];
  lastUpdatedUser?: Maybe<CompanyProfileUser>;
  media?: Maybe<Media>;
  previewEmail?: Maybe<Scalars['String']['output']>;
  previewEmailSubject?: Maybe<Scalars['String']['output']>;
  recipients?: Maybe<EmailRecipientConnection>;
  scheduledAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  sendToAllContacts: Scalars['Boolean']['output'];
  sendToContactIds: Array<Scalars['ID']['output']>;
  sendToContacts: Array<Contact>;
  sendToDynamicListIds: Array<Scalars['ID']['output']>;
  sendToDynamicLists: Array<DynamicList>;
  sentAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  stats: Scalars['Int']['output'];
  subject?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['IsoNaiveDatetime']['output'];
};

export type EmailRecipientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  emailId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
};

export type EmailStatsArgs = {
  type: EmailStatsType;
};

export type EmailConnection = {
  __typename?: 'EmailConnection';
  edges?: Maybe<Array<Maybe<EmailEdge>>>;
  options?: Maybe<Options>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type EmailConnectionTotalArgs = {
  options?: InputMaybe<OptionsInput>;
};

export enum EmailDistributionMethod {
  Automated = 'AUTOMATED',
  Manual = 'MANUAL',
}

export type EmailEdge = {
  __typename?: 'EmailEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  email: Email;
  node?: Maybe<Email>;
};

export type EmailInput = {
  campaignName: Scalars['String']['input'];
  contactIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  doNotSendToContactIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  doNotSendToDynamicListIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  emailHtml?: InputMaybe<Scalars['String']['input']>;
  emailJson?: InputMaybe<Scalars['String']['input']>;
  fromEmail?: InputMaybe<Scalars['String']['input']>;
  fromName?: InputMaybe<Scalars['String']['input']>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  mediaId?: InputMaybe<Scalars['ID']['input']>;
  scheduledAt?: InputMaybe<Scalars['IsoNaiveDatetime']['input']>;
  sendToAllContacts?: InputMaybe<Scalars['Boolean']['input']>;
  sendToContactIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sendToDynamicListIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type EmailRecipient = {
  __typename?: 'EmailRecipient';
  contact?: Maybe<Contact>;
  id: Scalars['ID']['output'];
  sentAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  shareholder?: Maybe<Shareholding>;
  trackingEmail?: Maybe<TrackingEmail>;
};

export type EmailRecipientConnection = {
  __typename?: 'EmailRecipientConnection';
  edges?: Maybe<Array<Maybe<EmailRecipientEdge>>>;
  emailId: Scalars['ID']['output'];
  options?: Maybe<Options>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type EmailRecipientConnectionTotalArgs = {
  emailId: Scalars['ID']['input'];
  options?: InputMaybe<OptionsInput>;
};

export type EmailRecipientEdge = {
  __typename?: 'EmailRecipientEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: EmailRecipient;
};

export type EmailReputation = {
  __typename?: 'EmailReputation';
  reputation: Scalars['Int']['output'];
};

export type EmailReputationData = {
  __typename?: 'EmailReputationData';
  bounces: Scalars['Int']['output'];
  clicks: Scalars['Int']['output'];
  complaints: Scalars['Int']['output'];
  opens: Scalars['Int']['output'];
  reputation: Scalars['Int']['output'];
  sends: Scalars['Int']['output'];
  unsubscribes: Scalars['Int']['output'];
};

export type EmailReputationDataPoint = {
  __typename?: 'EmailReputationDataPoint';
  date: Scalars['String']['output'];
  emails: Array<Maybe<SentEmail>>;
  reputation: Scalars['Int']['output'];
  sends: Scalars['Int']['output'];
};

export type EmailStats = {
  __typename?: 'EmailStats';
  bounces: Scalars['Int']['output'];
  clicks: Scalars['Int']['output'];
  complaints: Scalars['Int']['output'];
  identifier?: Maybe<Scalars['Int']['output']>;
  insertedAt: Scalars['Date']['output'];
  method: Scalars['String']['output'];
  opens: Scalars['Int']['output'];
  sends: Scalars['Int']['output'];
  sentAt: Scalars['Date']['output'];
  subject?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  unsubscribes: Scalars['Int']['output'];
};

export enum EmailStatsType {
  Bounce = 'BOUNCE',
  Click = 'CLICK',
  Complaint = 'COMPLAINT',
  Delivery = 'DELIVERY',
  Open = 'OPEN',
  Total = 'TOTAL',
  Unsubscribed = 'UNSUBSCRIBED',
}

export type EngagementAnalyticsOverview = {
  __typename?: 'EngagementAnalyticsOverview';
  audiencesBreakdown: Array<AudienceBreakdown>;
  convertedShareholdersDifference: Scalars['Int']['output'];
  leadsDifference: Scalars['Int']['output'];
  totalConvertedShareholders: Scalars['Int']['output'];
  totalLeads: Scalars['Int']['output'];
  totalNominatedShareholders: Scalars['Int']['output'];
  totalUniqueVisitors: Scalars['Int']['output'];
  uniqueVisitorsDifference: Scalars['Int']['output'];
};

export type EngagementCampaign = {
  __typename?: 'EngagementCampaign';
  campaignId: Scalars['ID']['output'];
  date: Scalars['Date']['output'];
  name: Scalars['String']['output'];
};

export type EngagementGraphData = {
  __typename?: 'EngagementGraphData';
  date: Scalars['Date']['output'];
  engagedUniqueVisitors: Scalars['Int']['output'];
  returningVisitors: Scalars['Int']['output'];
  uniqueVisitors: Scalars['Int']['output'];
};

export type Faq = {
  __typename?: 'Faq';
  answer: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  lastEditedByUser: CompanyUser;
  orderId: Scalars['Int']['output'];
  question: Scalars['String']['output'];
  shareholderOfferPageId: Scalars['ID']['output'];
};

export type FaqInput = {
  answer: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  orderId: Scalars['Int']['input'];
  question: Scalars['String']['input'];
};

export type FeatureOnboarding = {
  __typename?: 'FeatureOnboarding';
  featureName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  onboardedAt: Scalars['IsoNaiveDatetime']['output'];
  userId: Scalars['ID']['output'];
};

export type Filter = {
  __typename?: 'Filter';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type FilterInput = {
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum FlowType {
  Announcement = 'ANNOUNCEMENT',
  NewShareholderWelcome = 'NEW_SHAREHOLDER_WELCOME',
  Update = 'UPDATE',
}

export type GeographicalBreakdownChart = {
  __typename?: 'GeographicalBreakdownChart';
  data: Array<Maybe<GeographicalBreakdownChartData>>;
};

export type GeographicalBreakdownChartData = {
  __typename?: 'GeographicalBreakdownChartData';
  eligible: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type GrowthRatioTimeseries = {
  __typename?: 'GrowthRatioTimeseries';
  churned: Scalars['Int']['output'];
  date: Scalars['DateShort']['output'];
  growthRatio: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  new: Scalars['Int']['output'];
  returning: Scalars['Int']['output'];
};

export enum HnwBehaviours {
  HoldingSizeThresholdMet = 'HOLDING_SIZE_THRESHOLD_MET',
  PropertyValueThresholdMet = 'PROPERTY_VALUE_THRESHOLD_MET',
  SharesMovedThroughWholesaleBroker = 'SHARES_MOVED_THROUGH_WHOLESALE_BROKER',
  SingleTradeVolumeThresholdMet = 'SINGLE_TRADE_VOLUME_THRESHOLD_MET',
  SophInFreshEquities = 'SOPH_IN_FRESH_EQUITIES',
}

export type HnwIdentifiedStats = {
  __typename?: 'HnwIdentifiedStats';
  id: Scalars['ID']['output'];
  lastSevenDays?: Maybe<Scalars['Int']['output']>;
  sevenToFourteenDaysAgo?: Maybe<Scalars['Int']['output']>;
};

export type HnwInvestor = {
  __typename?: 'HnwInvestor';
  accountNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  email?: Maybe<Scalars['String']['output']>;
  hnwBehaviour?: Maybe<HnwBehaviours>;
  hnwIdentifiedAt: Scalars['IsoNaiveDatetime']['output'];
  hnwStatus?: Maybe<ContactHnwStatuses>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  shareCount?: Maybe<Scalars['Int']['output']>;
  type: HnwInvestorType;
};

/** A HNW Investor is either a contact or shareholding, identified by `hnw_status` or `hnw_behaviour` */
export type HnwInvestorConnection = {
  __typename?: 'HnwInvestorConnection';
  edges?: Maybe<Array<Maybe<HnwInvestorEdge>>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
  totals: HnwTotals;
};

/** A HNW Investor is either a contact or shareholding, identified by `hnw_status` or `hnw_behaviour` */
export type HnwInvestorConnectionTotalArgs = {
  hnwType?: InputMaybe<HnwQueryType>;
  includePastInvestors?: InputMaybe<Scalars['Boolean']['input']>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
};

/** A HNW Investor is either a contact or shareholding, identified by `hnw_status` or `hnw_behaviour` */
export type HnwInvestorConnectionTotalsArgs = {
  includePastInvestors?: InputMaybe<Scalars['Boolean']['input']>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
};

export type HnwInvestorEdge = {
  __typename?: 'HnwInvestorEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<HnwInvestor>;
};

export enum HnwInvestorType {
  Contact = 'CONTACT',
  Shareholding = 'SHAREHOLDING',
}

export enum HnwQueryType {
  IdentifiedViaBehaviour = 'IDENTIFIED_VIA_BEHAVIOUR',
  NominatedCertPending = 'NOMINATED_CERT_PENDING',
  NominatedCertVerified = 'NOMINATED_CERT_VERIFIED',
  NominatedWithoutCert = 'NOMINATED_WITHOUT_CERT',
}

export type HnwTotals = {
  __typename?: 'HnwTotals';
  all: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  identifiedViaBehaviour: Scalars['Int']['output'];
  nominatedCertPending: Scalars['Int']['output'];
  nominatedCertVerified: Scalars['Int']['output'];
  nominatedWithoutCert: Scalars['Int']['output'];
};

export type HoldingDistribution = {
  __typename?: 'HoldingDistribution';
  id: Scalars['ID']['output'];
  /** $ value, inclusive */
  lowerBound?: Maybe<Scalars['Int']['output']>;
  /** $ value, exclusive */
  upperBound?: Maybe<Scalars['Int']['output']>;
  /** # of shareholders */
  value: Scalars['Int']['output'];
};

export type HoldingInsights = {
  __typename?: 'HoldingInsights';
  averageHoldingSize: Scalars['Float']['output'];
  averageHoldingValue: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
  holdingTimeseries: Array<HoldingTimeseries>;
  id: Scalars['ID']['output'];
};

export type HoldingLength = {
  __typename?: 'HoldingLength';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  shareholdersCount: Scalars['Int']['output'];
  totalShares: Scalars['Int']['output'];
};

export type HoldingSize = {
  __typename?: 'HoldingSize';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
  value: Scalars['Int']['output'];
};

export type HoldingTimeseries = {
  __typename?: 'HoldingTimeseries';
  /** Number in days */
  averageHoldingTime: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  shareholdingsCount: Scalars['Int']['output'];
};

export type HubStats = {
  __typename?: 'HubStats';
  pendingQualifiedInvestors?: Maybe<Scalars['Int']['output']>;
  signUpsLastThirtyDays?: Maybe<Scalars['Int']['output']>;
  uniqueVisitorsLastThirtyDays?: Maybe<Scalars['Int']['output']>;
};

export enum IncludedMediaUpdateType {
  Image = 'IMAGE',
  Multi = 'MULTI',
  None = 'NONE',
  Pdf = 'PDF',
  Url = 'URL',
  Video = 'VIDEO',
}

export type Instruction = {
  __typename?: 'Instruction';
  buttonText?: Maybe<Scalars['String']['output']>;
  buttonUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastEditedByUser: CompanyUser;
  message: Scalars['String']['output'];
  orderId: Scalars['Int']['output'];
  shareholderOfferPageId: Scalars['ID']['output'];
  subheading: Scalars['String']['output'];
};

export type InstructionInput = {
  buttonText?: InputMaybe<Scalars['String']['input']>;
  buttonUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  message: Scalars['String']['input'];
  orderId: Scalars['Int']['input'];
  subheading: Scalars['String']['input'];
};

export type InteractiveMediaStats = {
  __typename?: 'InteractiveMediaStats';
  totalActiveQuestions: Scalars['Int']['output'];
  totalAnnouncements: Scalars['Int']['output'];
  totalAnnouncementsLastMonth: Scalars['Int']['output'];
  totalAnnouncementsLastWeek: Scalars['Int']['output'];
  totalAnnouncementsThisMonth: Scalars['Int']['output'];
  totalAnnouncementsThisWeek: Scalars['Int']['output'];
  totalAnnouncementsThisYear: Scalars['Int']['output'];
  totalLikes: Scalars['Int']['output'];
  totalLikesLastWeek: Scalars['Int']['output'];
  totalLikesThisWeek: Scalars['Int']['output'];
  totalQuestions: Scalars['Int']['output'];
  totalQuestionsLastWeek: Scalars['Int']['output'];
  totalQuestionsThisWeek: Scalars['Int']['output'];
  totalSurveyResponses: Scalars['Int']['output'];
  totalSurveyResponsesLastWeek: Scalars['Int']['output'];
  totalSurveyResponsesThisWeek: Scalars['Int']['output'];
  totalUpdates: Scalars['Int']['output'];
  totalUpdatesLastMonth: Scalars['Int']['output'];
  totalUpdatesLastWeek: Scalars['Int']['output'];
  totalUpdatesThisMonth: Scalars['Int']['output'];
  totalUpdatesThisWeek: Scalars['Int']['output'];
  totalUpdatesThisYear: Scalars['Int']['output'];
};

export type InvestorCertificate = {
  __typename?: 'InvestorCertificate';
  certificateUrl: Scalars['String']['output'];
  expiresAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  id: Scalars['ID']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  investorUser: InvestorUser;
  isExpired: Scalars['Boolean']['output'];
  status: InvestorCertificateStatus;
  type?: Maybe<InvestorCertificateType>;
};

export enum InvestorCertificateStatus {
  PendingReview = 'PENDING_REVIEW',
  Rejected = 'REJECTED',
  Verified = 'VERIFIED',
}

export enum InvestorCertificateType {
  Afsl = 'AFSL',
  S708 = 'S708',
  Uk = 'UK',
}

export enum InvestorHnwStatuses {
  NominatedCertPending = 'NOMINATED_CERT_PENDING',
  NominatedCertVerified = 'NOMINATED_CERT_VERIFIED',
  NominatedWithoutCert = 'NOMINATED_WITHOUT_CERT',
  NotHnw = 'NOT_HNW',
}

export type InvestorHub = {
  __typename?: 'InvestorHub';
  colourAccent: Scalars['String']['output'];
  colourAccentText: Scalars['String']['output'];
  colourPrimary: Scalars['String']['output'];
  colourPrimaryText: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instagram?: Maybe<Scalars['String']['output']>;
  linkedin?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  registry?: Maybe<InvestorHubRegisters>;
  twitter?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type InvestorHubAnalysisStats = {
  __typename?: 'InvestorHubAnalysisStats';
  signups: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  views: Scalars['Int']['output'];
  visitors: Scalars['Int']['output'];
};

export type InvestorHubAnalytics = {
  __typename?: 'InvestorHubAnalytics';
  signupsDifference: Scalars['Int']['output'];
  totalSignups: Scalars['Int']['output'];
  totalUniqueVisitors: Scalars['Int']['output'];
  totalViews: Scalars['Int']['output'];
  totalViewsDifference: Scalars['Int']['output'];
  uniqueVisitorsDifference: Scalars['Int']['output'];
};

export type InvestorHubEngagement = {
  __typename?: 'InvestorHubEngagement';
  announcements: Array<Announcement>;
  campaigns: Array<EngagementCampaign>;
  close?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  date: Scalars['Date']['output'];
  signups: Scalars['Int']['output'];
  totalUniqueVisitors: Scalars['Int']['output'];
  totalViews: Scalars['Int']['output'];
  updates: Array<Update>;
};

export type InvestorHubInput = {
  colourAccent?: InputMaybe<Scalars['String']['input']>;
  colourAccentText?: InputMaybe<Scalars['String']['input']>;
  colourPrimary?: InputMaybe<Scalars['String']['input']>;
  colourPrimaryText?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  instagram?: InputMaybe<Scalars['String']['input']>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  registry?: InputMaybe<InvestorHubRegisters>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type InvestorHubMostEngagedInvestors = {
  __typename?: 'InvestorHubMostEngagedInvestors';
  investorUser: InvestorUser;
  likes: Scalars['Int']['output'];
  questions: Scalars['Int']['output'];
  surveyResponses: Scalars['Int']['output'];
};

export type InvestorHubPagePerformance = {
  __typename?: 'InvestorHubPagePerformance';
  name: Scalars['String']['output'];
  total: Scalars['Int']['output'];
  unique: Scalars['Int']['output'];
};

export enum InvestorHubRegisters {
  Automic = 'AUTOMIC',
  Avenir = 'AVENIR',
  Boardroom = 'BOARDROOM',
  Computershare = 'COMPUTERSHARE',
  Equiniti = 'EQUINITI',
  Link = 'LINK',
  Neville = 'NEVILLE',
  ShareRegistrars = 'SHARE_REGISTRARS',
  Slc = 'SLC',
  Xcend = 'XCEND',
}

export type InvestorHubSignupBreakdown = {
  __typename?: 'InvestorHubSignupBreakdown';
  existingShareholders: Scalars['Int']['output'];
  leads: Scalars['Int']['output'];
  nominatedShareholders: Scalars['Int']['output'];
  pastShareholders: Scalars['Int']['output'];
};

export enum InvestorNotificationChannel {
  Email = 'EMAIL',
}

export type InvestorNotificationPreference = {
  __typename?: 'InvestorNotificationPreference';
  channel?: Maybe<InvestorNotificationChannel>;
  hasEoi?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isOn?: Maybe<Scalars['Boolean']['output']>;
  scope?: Maybe<InvestorNotificationScope>;
};

export enum InvestorNotificationScope {
  SystemInteractiveAnnouncements = 'SYSTEM_INTERACTIVE_ANNOUNCEMENTS',
  SystemNewPlacements = 'SYSTEM_NEW_PLACEMENTS',
  SystemNewShareholderOffers = 'SYSTEM_NEW_SHAREHOLDER_OFFERS',
}

export type InvestorShareholderInformation = {
  __typename?: 'InvestorShareholderInformation';
  country: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  partialHin: Scalars['String']['output'];
  postcode?: Maybe<Scalars['String']['output']>;
};

export type InvestorShareholderInformationUk = {
  __typename?: 'InvestorShareholderInformationUk';
  accountName: Scalars['String']['output'];
  broker: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  postcode?: Maybe<Scalars['String']['output']>;
  sharesOwned?: Maybe<Scalars['Int']['output']>;
};

export type InvestorUser = {
  __typename?: 'InvestorUser';
  certificate?: Maybe<InvestorCertificate>;
  confirmedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  contact?: Maybe<Contact>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  hnwIdentifiedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  hnwStatus?: Maybe<InvestorHnwStatuses>;
  id: Scalars['ID']['output'];
  insertedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  isHoldingVerified: Scalars['Boolean']['output'];
  isSelfNominatedShareholder?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  notificationPreferences: Array<InvestorNotificationPreference>;
  provider?: Maybe<Scalars['String']['output']>;
  selfNominatedShareholderIdentifiedAt?: Maybe<
    Scalars['IsoNaiveDatetime']['output']
  >;
  shareholderInformation?: Maybe<InvestorShareholderInformation>;
  shareholderInformationUk?: Maybe<InvestorShareholderInformationUk>;
  shareholdings: Array<Shareholding>;
  username?: Maybe<Scalars['String']['output']>;
};

export type InvestorUserConnection = {
  __typename?: 'InvestorUserConnection';
  edges?: Maybe<Array<Maybe<InvestorUserEdge>>>;
  options?: Maybe<Options>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type InvestorUserConnectionTotalArgs = {
  options?: InputMaybe<OptionsInput>;
};

export type InvestorUserEdge = {
  __typename?: 'InvestorUserEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  investorUser: InvestorUser;
  node?: Maybe<InvestorUser>;
};

export type InviteAndCreateCompanyProfileUserErrorResult = {
  __typename?: 'InviteAndCreateCompanyProfileUserErrorResult';
  email?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

export type InviteAndCreateCompanyProfileUserResult = {
  __typename?: 'InviteAndCreateCompanyProfileUserResult';
  error?: Maybe<InviteAndCreateCompanyProfileUserErrorResult>;
  success?: Maybe<CompanyProfileUser>;
};

export type InviteCompanyProfileUserInput = {
  email: Scalars['String']['input'];
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  role: CompanyProfileUserRole;
};

export type KeyInsights = {
  __typename?: 'KeyInsights';
  churned: Scalars['Int']['output'];
  downgraders: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  new: Scalars['Int']['output'];
  returning: Scalars['Int']['output'];
  upgraders: Scalars['Int']['output'];
};

export enum LeadStatusUpdateOption {
  InvestorLead = 'INVESTOR_LEAD',
  NominatedShareholder = 'NOMINATED_SHAREHOLDER',
}

export type LinkedinOrganisation = {
  __typename?: 'LinkedinOrganisation';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type LinkedinPostStatistics = {
  __typename?: 'LinkedinPostStatistics';
  commentCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  impressionCount: Scalars['Int']['output'];
  likeCount: Scalars['Int']['output'];
  shareCount: Scalars['Int']['output'];
};

export type Media = {
  __typename?: 'Media';
  companyProfile?: Maybe<CompanyProfile>;
  email?: Maybe<Email>;
  emailDistributionMethod?: Maybe<EmailDistributionMethod>;
  id: Scalars['ID']['output'];
  mediaAnnouncement?: Maybe<MediaAnnouncement>;
  mediaUpdate?: Maybe<MediaUpdate>;
};

export type MediaAnnouncement = {
  __typename?: 'MediaAnnouncement';
  distributedSocial?: Maybe<DistributedSocial>;
  email?: Maybe<Email>;
  featuredOnHub: Scalars['Boolean']['output'];
  germanTranslatedHeader?: Maybe<Scalars['String']['output']>;
  germanTranslatedSummary?: Maybe<Scalars['String']['output']>;
  germanTranslatedUrl?: Maybe<Scalars['String']['output']>;
  germanTranslatedVideoUrl?: Maybe<Scalars['String']['output']>;
  header: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  likes: Scalars['Int']['output'];
  listingKey: Scalars['String']['output'];
  marketKey: Scalars['String']['output'];
  marketSensitive?: Maybe<Scalars['Boolean']['output']>;
  media: Media;
  mediaId: Scalars['ID']['output'];
  postedAt: Scalars['IsoNaiveDatetime']['output'];
  preparedAnnouncement?: Maybe<PreparedAnnouncement>;
  rectype: Scalars['String']['output'];
  socialVideoUrl?: Maybe<Scalars['String']['output']>;
  subtypes: Array<Maybe<Scalars['String']['output']>>;
  summary?: Maybe<Scalars['String']['output']>;
  summaryAi?: Maybe<Scalars['String']['output']>;
  thumbnailIsPortrait?: Maybe<Scalars['Boolean']['output']>;
  totalActiveCommentCount: Scalars['Int']['output'];
  totalActiveQuestionCount: Scalars['Int']['output'];
  totalCommentCount: Scalars['Int']['output'];
  totalCompanyCommentCount: Scalars['Int']['output'];
  totalQuestionCount: Scalars['Int']['output'];
  totalSignupsLastWeek: Scalars['Int']['output'];
  totalSurveyResponses: Scalars['Int']['output'];
  totalUniqueVisitors: Scalars['Int']['output'];
  totalViewCount: Scalars['Int']['output'];
  totalViewCountFromTimePeriod: Scalars['Int']['output'];
  url: Scalars['String']['output'];
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export type MediaAnnouncementTotalViewCountFromTimePeriodArgs = {
  endDate: Scalars['NaiveDateTime']['input'];
  startDate: Scalars['NaiveDateTime']['input'];
};

export type MediaAnnouncementConnection = {
  __typename?: 'MediaAnnouncementConnection';
  edges?: Maybe<Array<Maybe<MediaAnnouncementEdge>>>;
  options?: Maybe<Options>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type MediaAnnouncementConnectionTotalArgs = {
  options?: InputMaybe<OptionsInput>;
};

export type MediaAnnouncementEdge = {
  __typename?: 'MediaAnnouncementEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  mediaAnnouncement: MediaAnnouncement;
  node?: Maybe<MediaAnnouncement>;
};

export type MediaComment = {
  __typename?: 'MediaComment';
  annotationMetadata?: Maybe<MediaCommentAnnotationMetadata>;
  children?: Maybe<Array<MediaComment>>;
  commentSource?: Maybe<CommentSource>;
  commentSourceUrl?: Maybe<Scalars['String']['output']>;
  commentStar?: Maybe<Media>;
  companyAuthor?: Maybe<CompanyUser>;
  content: Scalars['String']['output'];
  done: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  investorUser?: Maybe<InvestorUser>;
  lastEditedByUser?: Maybe<CompanyUser>;
  likes: Scalars['Int']['output'];
  media?: Maybe<Media>;
  parentId?: Maybe<Scalars['ID']['output']>;
  private: Scalars['Boolean']['output'];
  updatedAt: Scalars['IsoNaiveDatetime']['output'];
  useCompanyAsUsername: Scalars['Boolean']['output'];
  userRead?: Maybe<MediaCommentRead>;
  userStarred?: Maybe<MediaCommentStar>;
};

export type MediaCommentAnnotationMetadata = {
  __typename?: 'MediaCommentAnnotationMetadata';
  left: Scalars['Float']['output'];
  pageIndex: Scalars['Int']['output'];
  top: Scalars['Float']['output'];
};

export type MediaCommentAnnotationMetadataInput = {
  left: Scalars['Float']['input'];
  pageIndex: Scalars['Int']['input'];
  top: Scalars['Float']['input'];
};

export type MediaCommentConnection = {
  __typename?: 'MediaCommentConnection';
  edges?: Maybe<Array<Maybe<MediaCommentEdge>>>;
  options?: Maybe<Options>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
  totalActive: Scalars['Int']['output'];
  totalAll: Scalars['Int']['output'];
  totalDone: Scalars['Int']['output'];
  totalStarred: Scalars['Int']['output'];
};

export type MediaCommentConnectionTotalArgs = {
  options?: InputMaybe<OptionsInput>;
};

export type MediaCommentConnectionTotalActiveArgs = {
  options?: InputMaybe<OptionsInput>;
};

export type MediaCommentConnectionTotalAllArgs = {
  options?: InputMaybe<OptionsInput>;
};

export type MediaCommentConnectionTotalDoneArgs = {
  options?: InputMaybe<OptionsInput>;
};

export type MediaCommentConnectionTotalStarredArgs = {
  options?: InputMaybe<OptionsInput>;
};

export type MediaCommentEdge = {
  __typename?: 'MediaCommentEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  mediaComment: MediaComment;
  node?: Maybe<MediaComment>;
};

export type MediaCommentRead = {
  __typename?: 'MediaCommentRead';
  id: Scalars['ID']['output'];
  read: Scalars['Boolean']['output'];
};

export type MediaCommentStar = {
  __typename?: 'MediaCommentStar';
  id: Scalars['ID']['output'];
  starred: Scalars['Boolean']['output'];
};

export type MediaStats = {
  __typename?: 'MediaStats';
  comments: Scalars['Int']['output'];
  reactions: Scalars['Int']['output'];
  surveyResponses: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
  visitors: Scalars['Int']['output'];
};

export type MediaSurveyAnswer = {
  __typename?: 'MediaSurveyAnswer';
  answer: MediaSurveyAnswerType;
  id: Scalars['ID']['output'];
  question: MediaSurveyQuestionType;
};

export enum MediaSurveyAnswerType {
  Buy = 'BUY',
  Equal = 'EQUAL',
  Higher = 'HIGHER',
  Hold = 'HOLD',
  Likely = 'LIKELY',
  Lower = 'LOWER',
  Neutral = 'NEUTRAL',
  Sell = 'SELL',
  Unlikely = 'UNLIKELY',
}

export enum MediaSurveyQuestionType {
  Action = 'ACTION',
  Recommend = 'RECOMMEND',
  Understanding = 'UNDERSTANDING',
}

export type MediaSurveyResponse = {
  __typename?: 'MediaSurveyResponse';
  answers: Array<Maybe<MediaSurveyResponseAnswers>>;
  question: MediaSurveyQuestionType;
  totalResponses: Scalars['Int']['output'];
};

export type MediaSurveyResponseAnswers = {
  __typename?: 'MediaSurveyResponseAnswers';
  answer: MediaSurveyAnswerType;
  responsePercentageAsFloat: Scalars['Float']['output'];
};

export type MediaUpdate = {
  __typename?: 'MediaUpdate';
  attachments: Array<MediaUpdateAttachment>;
  content?: Maybe<MediaUpdateContent>;
  distributedSocial?: Maybe<DistributedSocial>;
  email?: Maybe<Email>;
  id: Scalars['ID']['output'];
  includedTypes?: Maybe<Array<MediaUpdateType>>;
  isDraft: Scalars['Boolean']['output'];
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedBy: CompanyUser;
  likes: Scalars['Int']['output'];
  media: Media;
  mediaId: Scalars['ID']['output'];
  postedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  postedBy?: Maybe<CompanyUser>;
  slug: Scalars['String']['output'];
  thumbnailAttachment?: Maybe<MediaUpdateAttachment>;
  title: Scalars['String']['output'];
  totalActiveCommentCount: Scalars['Int']['output'];
  totalActiveQuestionCount: Scalars['Int']['output'];
  totalCommentCount: Scalars['Int']['output'];
  totalParentCompanyCommentCount: Scalars['Int']['output'];
  totalQuestionCount: Scalars['Int']['output'];
  totalSurveyResponses: Scalars['Int']['output'];
  totalViewCount: Scalars['Int']['output'];
};

export type MediaUpdateAttachment = {
  __typename?: 'MediaUpdateAttachment';
  id: Scalars['ID']['output'];
  orderId: Scalars['Int']['output'];
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: MediaUpdateAttachmentType;
  url: Scalars['String']['output'];
};

export enum MediaUpdateAttachmentType {
  Image = 'IMAGE',
  None = 'NONE',
  Pdf = 'PDF',
  Url = 'URL',
  Video = 'VIDEO',
}

export type MediaUpdateConnection = {
  __typename?: 'MediaUpdateConnection';
  edges?: Maybe<Array<Maybe<MediaUpdateEdge>>>;
  options?: Maybe<Options>;
  pageInfo: PageInfo;
  pinnedMediaUpdateExists: Scalars['Boolean']['output'];
  total: Scalars['Int']['output'];
};

export type MediaUpdateConnectionTotalArgs = {
  options?: InputMaybe<OptionsInput>;
};

export type MediaUpdateContent = {
  __typename?: 'MediaUpdateContent';
  commentContent?: Maybe<Scalars['String']['output']>;
  commentUseCompanyAsUsername?: Maybe<Scalars['Boolean']['output']>;
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type MediaUpdateEdge = {
  __typename?: 'MediaUpdateEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  mediaUpdate: MediaUpdate;
  node?: Maybe<MediaUpdate>;
};

export enum MediaUpdateType {
  Image = 'IMAGE',
  None = 'NONE',
  Pdf = 'PDF',
  Url = 'URL',
  Video = 'VIDEO',
}

export enum MovementType {
  Churn = 'CHURN',
  Downgrade = 'DOWNGRADE',
  New = 'NEW',
  Returning = 'RETURNING',
  Upgrade = 'UPGRADE',
}

export type NavMenuItem = {
  __typename?: 'NavMenuItem';
  children?: Maybe<Array<Maybe<NavMenuItem>>>;
  id: Scalars['ID']['output'];
  insertedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  openInNewTab?: Maybe<Scalars['Boolean']['output']>;
  page?: Maybe<Page>;
  pageId?: Maybe<Scalars['ID']['output']>;
  parent?: Maybe<NavMenuItem>;
  parentId?: Maybe<Scalars['ID']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Website>;
  websiteId?: Maybe<Scalars['ID']['output']>;
};

export type NavMenuItemOrderInput = {
  id: Scalars['ID']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type NotificationPreference = {
  __typename?: 'NotificationPreference';
  channel: CompanyUserNotificationChannel;
  hasEoi: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isOn: Scalars['Boolean']['output'];
  scope: CompanyUserNotificationScope;
};

export type NotificationPreferenceInput = {
  channel: CompanyUserNotificationChannel;
  isOn: Scalars['Boolean']['input'];
  scope: CompanyUserNotificationScope;
};

export type Options = {
  __typename?: 'Options';
  filters?: Maybe<Array<Maybe<Filter>>>;
  orders?: Maybe<Array<Maybe<Order>>>;
};

export type OptionsInput = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  orders?: InputMaybe<Array<InputMaybe<OrderInput>>>;
};

export type Order = {
  __typename?: 'Order';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type OrderInput = {
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type Page = {
  __typename?: 'Page';
  blocks?: Maybe<Array<Maybe<Block>>>;
  id: Scalars['ID']['output'];
  insertedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  invalidated?: Maybe<Scalars['Boolean']['output']>;
  isDraft?: Maybe<Scalars['Boolean']['output']>;
  lastEditedByCompanyUser?: Maybe<CompanyUser>;
  metaDescription?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  socialImageCloudinaryPublicId?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  website?: Maybe<Website>;
  websiteId?: Maybe<Scalars['ID']['output']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PastPlacement = {
  __typename?: 'PastPlacement';
  amountRaised?: Maybe<Scalars['Float']['output']>;
  /**
   * If tranche is T1/T2, amount_raised_total = T1 amount_raised + T2 amount_raised
   * Otherwise, amount_raised_total = amount_raised
   */
  amountRaisedTotal?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  issuePrice?: Maybe<Scalars['Float']['output']>;
  movementSince?: Maybe<Scalars['Float']['output']>;
  offerAnnouncedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  outcomeAnnouncedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  settledAt?: Maybe<Scalars['Date']['output']>;
  sharesAllocated?: Maybe<Scalars['Int']['output']>;
  sharesIssued?: Maybe<Scalars['Int']['output']>;
  tradingHaltPrice?: Maybe<Scalars['Float']['output']>;
  tranche?: Maybe<PastPlacementTranche>;
  /**
   * If tranche is T1, return tranche_two_settled_at
   * Otherwise, tranche_two_settled_at is nil
   */
  trancheTwoSettledAt?: Maybe<Scalars['Date']['output']>;
};

export type PastPlacementAftermarketStats = {
  __typename?: 'PastPlacementAftermarketStats';
  churnerCount: Scalars['Int']['output'];
  downgraderCount: Scalars['Int']['output'];
  upgraderCount: Scalars['Int']['output'];
};

export enum PastPlacementAftermarketTimeRange {
  OneMonth = 'ONE_MONTH',
  OneWeek = 'ONE_WEEK',
  ThreeMonths = 'THREE_MONTHS',
}

export type PastPlacementParticipant = {
  __typename?: 'PastPlacementParticipant';
  allocationShares?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  invalidated: Scalars['Boolean']['output'];
  oneMonthAfterSettlementShares?: Maybe<Scalars['Int']['output']>;
  oneWeekAfterSettlementShares?: Maybe<Scalars['Int']['output']>;
  settlementShares?: Maybe<Scalars['Int']['output']>;
  shareholding: Shareholding;
  threeMonthsAfterSettlementShares?: Maybe<Scalars['Int']['output']>;
  trancheType: PastPlacementParticipantTrancheType;
};

export type PastPlacementParticipantConnection = {
  __typename?: 'PastPlacementParticipantConnection';
  edges?: Maybe<Array<Maybe<PastPlacementParticipantEdge>>>;
  hasInvalidatedParticipants: Scalars['Boolean']['output'];
  options?: Maybe<Options>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type PastPlacementParticipantConnectionHasInvalidatedParticipantsArgs = {
  pastPlacementId: Scalars['ID']['input'];
};

export type PastPlacementParticipantConnectionTotalArgs = {
  options?: InputMaybe<OptionsInput>;
  pastPlacementId: Scalars['ID']['input'];
};

export type PastPlacementParticipantEdge = {
  __typename?: 'PastPlacementParticipantEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<PastPlacementParticipant>;
  pastPlacementParticipant: PastPlacementParticipant;
};

export enum PastPlacementParticipantTrancheType {
  None = 'NONE',
  T1 = 'T1',
  T2 = 'T2',
}

export enum PastPlacementTranche {
  None = 'NONE',
  T1 = 'T1',
  T2 = 'T2',
}

export type PlacementDemandExternalEstimate = {
  __typename?: 'PlacementDemandExternalEstimate';
  high?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  lastPublishedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  lastRequestedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  low?: Maybe<Scalars['Int']['output']>;
  mid?: Maybe<Scalars['Int']['output']>;
};

export type PlacementDemandInsight = {
  __typename?: 'PlacementDemandInsight';
  content?: Maybe<Scalars['String']['output']>;
  lastPublishedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  topic: PlacementDemandInsightTopic;
};

export enum PlacementDemandInsightTopic {
  Broker = 'BROKER',
  External = 'EXTERNAL',
  InvestorHub = 'INVESTOR_HUB',
  Market = 'MARKET',
  Register = 'REGISTER',
}

export type PlacementDemandInvestorHubEstimate = {
  __typename?: 'PlacementDemandInvestorHubEstimate';
  averageBidHigh?: Maybe<Scalars['Int']['output']>;
  averageBidLow?: Maybe<Scalars['Int']['output']>;
  averageBidMid?: Maybe<Scalars['Int']['output']>;
  eligibleInvestorCount?: Maybe<Scalars['Int']['output']>;
  high?: Maybe<Scalars['Int']['output']>;
  lastUpdatedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  low?: Maybe<Scalars['Int']['output']>;
  mid?: Maybe<Scalars['Int']['output']>;
};

export type PlacementDemandPotentialInvestor = {
  __typename?: 'PlacementDemandPotentialInvestor';
  address?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  maskedHin: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type PlacementDemandQuestion = {
  __typename?: 'PlacementDemandQuestion';
  comments?: Maybe<Scalars['String']['output']>;
  generalTopic: PlacementDemandQuestionGeneralTopic;
  id: Scalars['ID']['output'];
  specificTopics?: Maybe<Array<Maybe<PlacementDemandQuestionSpecificTopic>>>;
};

export enum PlacementDemandQuestionGeneralTopic {
  IndustryInsights = 'INDUSTRY_INSIGHTS',
  PotentialInvestors = 'POTENTIAL_INVESTORS',
  RequestingExternalDemandEstimate = 'REQUESTING_EXTERNAL_DEMAND_ESTIMATE',
  UnderstandingMyEstimate = 'UNDERSTANDING_MY_ESTIMATE',
}

export enum PlacementDemandQuestionSpecificTopic {
  BrokerInsights = 'BROKER_INSIGHTS',
  ExternalS708Demand = 'EXTERNAL_S708_DEMAND',
  GetMyListOfPotentialS708S = 'GET_MY_LIST_OF_POTENTIAL_S708S',
  HowToGrowMyEstimatedPlacementDemand = 'HOW_TO_GROW_MY_ESTIMATED_PLACEMENT_DEMAND',
  LaunchingAPlacement = 'LAUNCHING_A_PLACEMENT',
  MarketInsights = 'MARKET_INSIGHTS',
  MyEstimatedPlacementDemand = 'MY_ESTIMATED_PLACEMENT_DEMAND',
  RegisteredInvestorsList = 'REGISTERED_INVESTORS_LIST',
}

export type PlacementDemandRegisterEstimate = {
  __typename?: 'PlacementDemandRegisterEstimate';
  lastUpdatedDate?: Maybe<Scalars['Date']['output']>;
  potentialInvestorCount?: Maybe<Scalars['Int']['output']>;
  potentialInvestors: Array<PlacementDemandPotentialInvestor>;
};

export type PlacementDemandReport = {
  __typename?: 'PlacementDemandReport';
  externalEstimate?: Maybe<PlacementDemandExternalEstimate>;
  insights?: Maybe<Array<Maybe<PlacementDemandInsight>>>;
  investorHubEstimate?: Maybe<PlacementDemandInvestorHubEstimate>;
  registerEstimate?: Maybe<PlacementDemandRegisterEstimate>;
  registryDataStatus?: Maybe<RegistryDataStatus>;
  totalEstimate?: Maybe<PlacementDemandTotalEstimate>;
};

export type PlacementDemandTotalEstimate = {
  __typename?: 'PlacementDemandTotalEstimate';
  /** e.g. 0.56 */
  changeOverPastMonthInPercentage?: Maybe<Scalars['Float']['output']>;
  high?: Maybe<Scalars['Int']['output']>;
  lastUpdatedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  low?: Maybe<Scalars['Int']['output']>;
  mid?: Maybe<Scalars['Int']['output']>;
};

export type PreparedAnnouncement = {
  __typename?: 'PreparedAnnouncement';
  commentContent?: Maybe<Scalars['String']['output']>;
  commentUseCompanyAsUsername?: Maybe<Scalars['Boolean']['output']>;
  germanTranslatedHeader?: Maybe<Scalars['String']['output']>;
  germanTranslatedSummary?: Maybe<Scalars['String']['output']>;
  germanTranslatedUrl?: Maybe<Scalars['String']['output']>;
  germanTranslatedVideoUrl?: Maybe<Scalars['String']['output']>;
  hashId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  isDraft: Scalars['Boolean']['output'];
  mediaAnnouncement?: Maybe<MediaAnnouncement>;
  mediaId?: Maybe<Scalars['ID']['output']>;
  socialVideoUrl?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['IsoNaiveDatetime']['output'];
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export type PreparedAnnouncementInput = {
  commentContent?: InputMaybe<Scalars['String']['input']>;
  commentUseCompanyAsUsername?: InputMaybe<Scalars['Boolean']['input']>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  mediaId?: InputMaybe<Scalars['ID']['input']>;
  socialVideoUrl?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  videoUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PrivateViewer = {
  __typename?: 'PrivateViewer';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ProfitLoss = {
  __typename?: 'ProfitLoss';
  averageShareholderProfitLoss: Scalars['Float']['output'];
  shareholdersInProfit: Scalars['Float']['output'];
};

export type RaiseSppHistorical = {
  __typename?: 'RaiseSppHistorical';
  amountRaised?: Maybe<Scalars['Float']['output']>;
  annType?: Maybe<Scalars['String']['output']>;
  belongsToCurrentCompany: Scalars['Boolean']['output'];
  companyProfileId?: Maybe<Scalars['Int']['output']>;
  dateAllot?: Maybe<Scalars['NaiveDateTime']['output']>;
  dateAnn?: Maybe<Scalars['NaiveDateTime']['output']>;
  dateClose?: Maybe<Scalars['NaiveDateTime']['output']>;
  dateOpen?: Maybe<Scalars['NaiveDateTime']['output']>;
  dateRec?: Maybe<Scalars['NaiveDateTime']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  marketCap: Scalars['Float']['output'];
  maxSub?: Maybe<Scalars['Float']['output']>;
  mediaAnnouncementId?: Maybe<Scalars['Int']['output']>;
  minSub?: Maybe<Scalars['Float']['output']>;
  offerPrice?: Maybe<Scalars['Float']['output']>;
  oversub?: Maybe<Scalars['Boolean']['output']>;
  placementAmount?: Maybe<Scalars['Float']['output']>;
  placementComp?: Maybe<Scalars['Boolean']['output']>;
  sectorTicker: Scalars['String']['output'];
  sharesAlloc?: Maybe<Scalars['Float']['output']>;
  shortfall?: Maybe<Scalars['Boolean']['output']>;
  ticker: Scalars['String']['output'];
};

export type RaiseSppReachabilityStats = {
  __typename?: 'RaiseSppReachabilityStats';
  clickRate: Scalars['Float']['output'];
  engagedHubUsers: Scalars['Int']['output'];
  openRate: Scalars['Float']['output'];
  totalEmails: Scalars['Int']['output'];
  totalHubUsers: Scalars['Int']['output'];
  totalPhones: Scalars['Int']['output'];
};

export type RaiseSppRegressParams = {
  __typename?: 'RaiseSppRegressParams';
  coefficients: Scalars['Float']['output'];
  intercept: Scalars['Float']['output'];
};

export type RaiseSppShareholderStats = {
  __typename?: 'RaiseSppShareholderStats';
  averageHoldLength: Scalars['Float']['output'];
  averageHoldSize: Scalars['Float']['output'];
  totalActive: Scalars['Int']['output'];
  totalHnws: Scalars['Int']['output'];
  totalProfit: Scalars['Int']['output'];
  totalQual: Scalars['Int']['output'];
  totalShareholders: Scalars['Int']['output'];
};

export type RaisingPotential = {
  __typename?: 'RaisingPotential';
  averageUptake: Scalars['Float']['output'];
  high: Scalars['Float']['output'];
  low: Scalars['Float']['output'];
  mean: Scalars['Float']['output'];
  scenarioRange: ScenarioRange;
  shareholderParticipation: Scalars['Float']['output'];
};

export enum Registries {
  Automic = 'AUTOMIC',
  Avenir = 'AVENIR',
  Boardroom = 'BOARDROOM',
  Computershare = 'COMPUTERSHARE',
  Equiniti = 'EQUINITI',
  Link = 'LINK',
  Neville = 'NEVILLE',
  ShareRegistrars = 'SHARE_REGISTRARS',
  Slc = 'SLC',
  Xcend = 'XCEND',
}

export enum RegistryCredentialStatus {
  Expired = 'EXPIRED',
  None = 'NONE',
  Valid = 'VALID',
}

export type RegistryDataStatus = {
  __typename?: 'RegistryDataStatus';
  earliestReportDate?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastSuccessfulRegisterImportAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  latestReportDate?: Maybe<Scalars['Date']['output']>;
  registry: Registries;
  state: RegistryImportState;
};

export enum RegistryImportState {
  ApiImported = 'API_IMPORTED',
  ApiImporting = 'API_IMPORTING',
  FilesImported = 'FILES_IMPORTED',
  FilesImporting = 'FILES_IMPORTING',
  None = 'NONE',
}

export type RegistryStatus = {
  __typename?: 'RegistryStatus';
  expiryDate?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  status: RegistryCredentialStatus;
  username?: Maybe<Scalars['String']['output']>;
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** Activate current company profile user (change status to active) */
  activateCompanyProfileUser?: Maybe<CompanyProfileUser>;
  /** Activate all distribution settings for current company */
  activateCurrentCompanyDistributionSettings: CurrentCompanyDistributionSettings;
  activateDistributionSettingsForEmail?: Maybe<DistributionSettings>;
  autoUnblockCloudIp?: Maybe<Scalars['Boolean']['output']>;
  /** Change company profile user role */
  changeCompanyProfileUserRole?: Maybe<CompanyProfileUser>;
  /** Change password of current company profile user */
  changePassword?: Maybe<Scalars['Boolean']['output']>;
  changeShareholderOfferUsCitzenCheck?: Maybe<ShareholderOfferPage>;
  /** Confirm Company Detail if companies_profiles.information_confirmed_at is_nil */
  confirmCompanyProfileInformation: CompanyProfile;
  /**
   * 1. Confirm company user information if user.inforamtion_confirmed_at is_nil
   * 2. Change company profile user status to :active
   */
  confirmCompanyUserInformationAndActivateCompanyProfileUser?: Maybe<CompanyProfileUser>;
  contactUs?: Maybe<Scalars['Boolean']['output']>;
  /** Create a new block */
  createBlock: Block;
  /** Add computershare registry credential */
  createComputershareRegistryCredential?: Maybe<Scalars['Boolean']['output']>;
  /** Create a contact */
  createContact: Contact;
  /** Create a note on a contact */
  createContactNote: ContactNote;
  /**
   * Create a custom domain
   * 1. Create custom domain in database
   * 2. Create custom domain in Amazon SES
   * 3. Create custom domain in Vercel
   * 4. Assign domain redirect in Vercel
   */
  createCustomDomain?: Maybe<CustomDomain>;
  createDistributionSettingsEmail?: Maybe<DistributionSettingsEmail>;
  createDistributionSettingsForFlowType?: Maybe<DistributionSettings>;
  createDynamicList: DynamicList;
  createEmail: Email;
  createMediaComment?: Maybe<MediaComment>;
  /** Create a media comment from another source, with option to add a reply */
  createMediaCommentFromOtherSource?: Maybe<MediaComment>;
  /** Create a new navigation menu item */
  createNavMenuItem: NavMenuItem;
  createNewMediaUpdate?: Maybe<MediaUpdate>;
  /** Create a new page */
  createPage: Page;
  /** Create prepared announcement */
  createPreparedAnnouncement?: Maybe<PreparedAnnouncement>;
  /** Create shareholder offer */
  createShareholderOffer?: Maybe<ShareholderOffer>;
  /** Create shareholder offer page faq */
  createShareholderOfferPageFaq?: Maybe<Faq>;
  /** Create shareholder offer page instruction */
  createShareholderOfferPageInstruction?: Maybe<Instruction>;
  /** Create shareholder offer private viewer */
  createShareholderOfferPrivateViewer?: Maybe<PrivateViewer>;
  /** Create a tag on a contact */
  createTag: Tag;
  /** Create utm_link */
  createUtmLink?: Maybe<UtmLink>;
  /** Deactivate all distribution settings for current company */
  deactivateCurrentCompanyDistributionSettings: CurrentCompanyDistributionSettings;
  /** Delete a block */
  deleteBlock: Block;
  deleteDraftEmail: Scalars['Boolean']['output'];
  deleteDynamicList: Scalars['Boolean']['output'];
  /** Delete a navigation menu item */
  deleteNavMenuItem: NavMenuItem;
  /** Delete a page */
  deletePage: Page;
  /** Delete prepared announcement */
  deletePreparedAnnouncement?: Maybe<Scalars['Boolean']['output']>;
  /** Delete shareholder offer */
  deleteShareholderOffer?: Maybe<ShareholderOffer>;
  /** Delete shareholder offer page faq */
  deleteShareholderOfferPageFaq?: Maybe<Faq>;
  /** Delete shareholder offer page instruction */
  deleteShareholderOfferPageInstruction?: Maybe<Instruction>;
  /** Delete shareholder offer private viewer */
  deleteShareholderOfferPrivateViewer?: Maybe<Scalars['Boolean']['output']>;
  /** Delete utm_link */
  deleteUtmLink?: Maybe<Scalars['Boolean']['output']>;
  disableCompanyProfileUserProductTour?: Maybe<CompanyProfileUser>;
  /** Disconnect a social connection account */
  disconnectSocialConnection?: Maybe<SocialConnection>;
  duplicateEmailAndEmailRecipients: Email;
  generateAssetSignedUrl?: Maybe<Scalars['String']['output']>;
  /** Generate corporate page section attachment file signed url */
  generateCorporatePageSectionAttachmentFileSignedUrl: Scalars['String']['output'];
  generateLinkedinThumbnailSignedUrl: Scalars['String']['output'];
  generateMediaAnnouncementAiSummary: Scalars['String']['output'];
  generateMediaAnnouncementVideoSignedUrl: Scalars['String']['output'];
  generateMediaUpdateAttachmentSignedUrl: Scalars['String']['output'];
  /** Generate GCS signed URL to upload placement participant list. */
  generatePastPlacementParticipantListSignedUrl: Scalars['String']['output'];
  generatePreparedAnnouncementVideoSignedUrl: Scalars['String']['output'];
  /** Generate shareholder offer page raise reason hero media video signed url */
  generateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrl: Scalars['String']['output'];
  /** Invalidate Company Profile User. User need to be admin and can only invalidate their own company's user and can not be the user who request. */
  invalidateCompanyProfileUser?: Maybe<CompanyProfileUser>;
  invalidateMedia?: Maybe<Media>;
  invalidateMediaComment?: Maybe<MediaComment>;
  /** Invalidate past placement participant */
  invalidatePastPlacementParticipant?: Maybe<PastPlacementParticipant>;
  /**
   * 1. Create company users if not exist
   * 2. Create company profile users
   * 3. Send invitations
   * Company profile id comes from current company profile user.
   */
  inviteAndCreateCompanyProfileUsers?: Maybe<
    Array<Maybe<InviteAndCreateCompanyProfileUserResult>>
  >;
  /** Link contact with investor hub user */
  linkContactWithInvestor: Contact;
  /** Link contact with shareholdings */
  linkContactWithShareholdings: Contact;
  /** Manually link prepared announcement to media announcement */
  linkPreparedAnnouncementToAnnouncement?: Maybe<MediaAnnouncement>;
  /** Marks a feature as onboarded */
  markFeatureAsOnboarded?: Maybe<FeatureOnboarding>;
  /** Mark investor certificate (s708 or AFSL) as rejected */
  markInvestorCertificateAsRejected: InvestorCertificate;
  /** Mark investor certificate (s708 or AFSL) as verified */
  markInvestorCertificateAsVerified: InvestorCertificate;
  publishMediaUpdate?: Maybe<MediaUpdate>;
  /** Publish a website for the currently logged in users company profile */
  publishWebsite: Scalars['Boolean']['output'];
  publishWelcomePage?: Maybe<WelcomePage>;
  /** Remove a custom domain */
  removeCustomDomain?: Maybe<CustomDomain>;
  /**
   * Remove custom emails
   *
   * 1. Remove custom emails from database
   * 2. Remove custom domain from AWS SES
   */
  removeCustomEmails?: Maybe<Scalars['Boolean']['output']>;
  replyToMediaComment?: Maybe<MediaComment>;
  /**
   * Requests external placement demand estimate
   *
   * 1. Upsert estimate with requester and time
   * 2. Record any additional comments
   * 3. Confirm request via email (non-blocking)
   * 4. Notify InvestorHub via email (non-blocking)
   */
  requestExternalPlacementDemandEstimate: PlacementDemandExternalEstimate;
  /** Resend invitation token */
  resendInvitationToken?: Maybe<Scalars['Boolean']['output']>;
  resetMediaAnnouncement?: Maybe<MediaAnnouncement>;
  /** Reset password */
  resetPassword?: Maybe<Scalars['Boolean']['output']>;
  sendEmail: Email;
  sendManualSocialPost?: Maybe<DistributedSocial>;
  /** Send reset password instructions to email */
  sendResetPassword?: Maybe<Scalars['Boolean']['output']>;
  /** Send a start planning your spp email to service team */
  sendStartPlanningSppEmail?: Maybe<Scalars['Boolean']['output']>;
  sendTestEmail: Scalars['Boolean']['output'];
  sendTestEmailDomainConnectionEmail?: Maybe<Scalars['Boolean']['output']>;
  /** Set password by given valid invitation token if not set before */
  setPasswordFromInvitationToken?: Maybe<Scalars['Boolean']['output']>;
  /** Sort blocks for a given page */
  sortBlocks: Scalars['Boolean']['output'];
  /** Sort navigation menu items for a given parent */
  sortNavMenuItems: Scalars['Boolean']['output'];
  /** Sort navigation menu items when some are children of others */
  sortNavMenuItemsWithChildren: Scalars['Boolean']['output'];
  /**
   * Submits question to learn more about placement demand.
   * Also sends email to InvestorHub (non-blocking).
   */
  submitPlacementDemandQuestion: PlacementDemandQuestion;
  toggleMediaCommentPrivacy?: Maybe<Scalars['Boolean']['output']>;
  /** Unlink contact with investor hub user */
  unlinkContactWithInvestor: Contact;
  /** Unlink contact with shareholding */
  unlinkContactWithShareholding: Contact;
  /** Update a block */
  updateBlock: Block;
  /** Update Company information */
  updateCompanyInfomation: CompanyProfile;
  /** Update Company Investor Hub */
  updateCompanyInvestorHub: CompanyProfile;
  /**
   * 1. Update first name, last name, mobile number to company user
   * 2. Update title to company profile user
   */
  updateCompanyUserAndCompanyProfileUserInforamtion?: Maybe<CompanyProfileUser>;
  /** Update contact */
  updateContact: Contact;
  updateContactLeadStatus: Contact;
  /** Update note on a contact */
  updateContactNote: ContactNote;
  updateDistributionSettings?: Maybe<DistributionSettings>;
  updateDistributionSettingsEmail?: Maybe<DistributionSettingsEmail>;
  updateDynamicList: DynamicList;
  updateEmailAndEmailRecipients: Email;
  updateEmailSchedule: Email;
  updateMediaAnnouncement?: Maybe<MediaAnnouncement>;
  updateMediaCommentDone?: Maybe<MediaComment>;
  updateMediaCommentReply?: Maybe<MediaComment>;
  updateMediaUpdatePostedAt?: Maybe<MediaUpdate>;
  updateMediaUpdateTitle?: Maybe<MediaUpdate>;
  /** Update a navigation menu item */
  updateNavMenuItem: NavMenuItem;
  /** Update a page */
  updatePage: Page;
  updatePinnedMediaUpdate?: Maybe<MediaUpdate>;
  /** Update prepared announcement */
  updatePreparedAnnouncement?: Maybe<PreparedAnnouncement>;
  /** Update shareholder offer status */
  updateShareholderOfferStatus?: Maybe<ShareholderOffer>;
  /** Update shareholder offer title */
  updateShareholderOfferTitle?: Maybe<ShareholderOffer>;
  /** Update a social connection */
  updateSocialConnection?: Maybe<SocialConnection>;
  /** Update tag on a contact */
  updateTag: Tag;
  /** Update utm_link */
  updateUtmLink?: Maybe<UtmLink>;
  /** Update a website for the currently logged in users company profile */
  updateWebsite: Website;
  /** Upsert base email template */
  upsertBaseEmailTemplate: BaseEmailTemplate;
  /** Upsert contact unsubscribes */
  upsertContactUnsubscribes: Contact;
  /** Upsert corporate page */
  upsertCorporatePage?: Maybe<CorporatePage>;
  /** Upsert custom contacts */
  upsertCustomContacts: Scalars['Map']['output'];
  /** Upsert custom emails(transactional/marketing/both) */
  upsertCustomEmails?: Maybe<Scalars['Boolean']['output']>;
  upsertDistributionSettings: CurrentCompanyDistributionSettings;
  upsertMediaCommentRead?: Maybe<MediaCommentRead>;
  upsertMediaCommentStar?: Maybe<MediaCommentStar>;
  upsertMediaUpdate?: Maybe<MediaUpdate>;
  /** Upsert notification preference for current company profile user */
  upsertNotificationPreference?: Maybe<Scalars['Boolean']['output']>;
  /**
   * 1. Insert any missing participants
   * 2. Invalidate any incorrect participants
   */
  upsertPastPlacementParticipantList: Array<PastPlacementParticipant>;
  /** Upsert shareholder offer page */
  upsertShareholderOfferPage?: Maybe<ShareholderOfferPage>;
  /** Validate past placement participant */
  validatePastPlacementParticipant?: Maybe<PastPlacementParticipant>;
  verifyCustomDomainV2?: Maybe<CustomDomainVerification>;
};

export type RootMutationTypeActivateCurrentCompanyDistributionSettingsArgs = {
  flowType: FlowType;
};

export type RootMutationTypeActivateDistributionSettingsForEmailArgs = {
  distributionSettings: DistributionSettingsUpdateInput;
  distributionSettingsEmail: DistributionSettingsEmailInput;
  distributionSettingsId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeAutoUnblockCloudIpArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  marketKey: Scalars['String']['input'];
  ticker: Scalars['String']['input'];
};

export type RootMutationTypeChangeCompanyProfileUserRoleArgs = {
  id: Scalars['ID']['input'];
  role: CompanyProfileUserRole;
};

export type RootMutationTypeChangePasswordArgs = {
  oldPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type RootMutationTypeChangeShareholderOfferUsCitzenCheckArgs = {
  shareholderOfferPageId: Scalars['ID']['input'];
  updatedCheck: Scalars['Boolean']['input'];
};

export type RootMutationTypeConfirmCompanyProfileInformationArgs = {
  listingKey: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type RootMutationTypeConfirmCompanyUserInformationAndActivateCompanyProfileUserArgs =
  {
    firstName: Scalars['String']['input'];
    jobTitle: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    mobileNumber?: InputMaybe<Scalars['String']['input']>;
  };

export type RootMutationTypeContactUsArgs = {
  message: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type RootMutationTypeCreateBlockArgs = {
  content?: InputMaybe<Scalars['Map']['input']>;
  pageId: Scalars['ID']['input'];
  position: Scalars['Int']['input'];
  type: Scalars['String']['input'];
};

export type RootMutationTypeCreateComputershareRegistryCredentialArgs = {
  computershareRegistryCredential: ComputershareRegistryCredentialInput;
};

export type RootMutationTypeCreateContactArgs = {
  contact: ContactInput;
};

export type RootMutationTypeCreateContactNoteArgs = {
  contactId: Scalars['ID']['input'];
  contactNote: ContactNoteInput;
};

export type RootMutationTypeCreateCustomDomainArgs = {
  customDomain: Scalars['String']['input'];
};

export type RootMutationTypeCreateDistributionSettingsEmailArgs = {
  distributionSettingsEmail: DistributionSettingsEmailInput;
  distributionSettingsId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeCreateDistributionSettingsForFlowTypeArgs = {
  distributionSettings: DistributionSettingsCreateInput;
  flowType?: InputMaybe<FlowType>;
};

export type RootMutationTypeCreateDynamicListArgs = {
  dynamicList: DynamicListInput;
};

export type RootMutationTypeCreateEmailArgs = {
  campaignName: Scalars['String']['input'];
  mediaId?: InputMaybe<Scalars['ID']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeCreateMediaCommentArgs = {
  annotationMetadata?: InputMaybe<MediaCommentAnnotationMetadataInput>;
  content: Scalars['String']['input'];
  mediaId: Scalars['ID']['input'];
  useCompanyAsUsername?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootMutationTypeCreateMediaCommentFromOtherSourceArgs = {
  commentSource: CommentSource;
  commentSourceUrl?: InputMaybe<Scalars['String']['input']>;
  content: Scalars['String']['input'];
  mediaId: Scalars['ID']['input'];
  replyContent?: InputMaybe<Scalars['String']['input']>;
  replyUseCompanyAsUsername?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootMutationTypeCreateNavMenuItemArgs = {
  label: Scalars['String']['input'];
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  pageId?: InputMaybe<Scalars['ID']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  position: Scalars['Int']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeCreatePageArgs = {
  preset?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type RootMutationTypeCreatePreparedAnnouncementArgs = {
  title: Scalars['String']['input'];
};

export type RootMutationTypeCreateShareholderOfferArgs = {
  shareholderOffer: ShareholderOfferInput;
};

export type RootMutationTypeCreateShareholderOfferPageFaqArgs = {
  faq: FaqInput;
  shareholderOfferId: Scalars['ID']['input'];
};

export type RootMutationTypeCreateShareholderOfferPageInstructionArgs = {
  instruction: InstructionInput;
  shareholderOfferId: Scalars['ID']['input'];
};

export type RootMutationTypeCreateShareholderOfferPrivateViewerArgs = {
  email: Scalars['String']['input'];
  shareholderOfferId: Scalars['ID']['input'];
};

export type RootMutationTypeCreateTagArgs = {
  contactId: Scalars['ID']['input'];
  tag: TagInput;
};

export type RootMutationTypeCreateUtmLinkArgs = {
  utmLink?: InputMaybe<UtmLinkInput>;
};

export type RootMutationTypeDeactivateCurrentCompanyDistributionSettingsArgs = {
  flowType: FlowType;
};

export type RootMutationTypeDeleteBlockArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDeleteDraftEmailArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDeleteDynamicListArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDeleteNavMenuItemArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDeletePageArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDeletePreparedAnnouncementArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDeleteShareholderOfferArgs = {
  shareholderOfferId: Scalars['ID']['input'];
};

export type RootMutationTypeDeleteShareholderOfferPageFaqArgs = {
  faqId: Scalars['ID']['input'];
};

export type RootMutationTypeDeleteShareholderOfferPageInstructionArgs = {
  instructionId: Scalars['ID']['input'];
};

export type RootMutationTypeDeleteShareholderOfferPrivateViewerArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDeleteUtmLinkArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDisconnectSocialConnectionArgs = {
  socialPlatform: SocialPlatform;
};

export type RootMutationTypeDuplicateEmailAndEmailRecipientsArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeGenerateAssetSignedUrlArgs = {
  mimeType: Scalars['String']['input'];
};

export type RootMutationTypeGenerateCorporatePageSectionAttachmentFileSignedUrlArgs =
  {
    fileSize: Scalars['Int']['input'];
    mimeType: Scalars['String']['input'];
  };

export type RootMutationTypeGenerateLinkedinThumbnailSignedUrlArgs = {
  mimeType: Scalars['String']['input'];
};

export type RootMutationTypeGenerateMediaAnnouncementAiSummaryArgs = {
  mediaAnnouncementId: Scalars['ID']['input'];
};

export type RootMutationTypeGenerateMediaAnnouncementVideoSignedUrlArgs = {
  fileSize: Scalars['Int']['input'];
  mediaAnnouncementId: Scalars['ID']['input'];
  mimeType: Scalars['String']['input'];
};

export type RootMutationTypeGenerateMediaUpdateAttachmentSignedUrlArgs = {
  mimeType: Scalars['String']['input'];
};

export type RootMutationTypeGeneratePastPlacementParticipantListSignedUrlArgs =
  {
    mimeType: Scalars['String']['input'];
    pastPlacementId: Scalars['ID']['input'];
  };

export type RootMutationTypeGeneratePreparedAnnouncementVideoSignedUrlArgs = {
  fileSize: Scalars['Int']['input'];
  mimeType: Scalars['String']['input'];
  preparedAnnouncementId: Scalars['ID']['input'];
};

export type RootMutationTypeGenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlArgs =
  {
    fileSize: Scalars['Int']['input'];
    mimeType: Scalars['String']['input'];
    shareholderOfferId: Scalars['ID']['input'];
  };

export type RootMutationTypeInvalidateCompanyProfileUserArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeInvalidateMediaArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeInvalidateMediaCommentArgs = {
  mediaCommentId: Scalars['ID']['input'];
};

export type RootMutationTypeInvalidatePastPlacementParticipantArgs = {
  participantId: Scalars['ID']['input'];
};

export type RootMutationTypeInviteAndCreateCompanyProfileUsersArgs = {
  inviteCompanyProfileUsers?: InputMaybe<
    Array<InputMaybe<InviteCompanyProfileUserInput>>
  >;
};

export type RootMutationTypeLinkContactWithInvestorArgs = {
  id: Scalars['ID']['input'];
  investorUserId: Scalars['ID']['input'];
};

export type RootMutationTypeLinkContactWithShareholdingsArgs = {
  id: Scalars['ID']['input'];
  shareholdingIds: Array<Scalars['ID']['input']>;
};

export type RootMutationTypeLinkPreparedAnnouncementToAnnouncementArgs = {
  announcementMediaId: Scalars['ID']['input'];
  preparedAnnouncementId: Scalars['ID']['input'];
};

export type RootMutationTypeMarkFeatureAsOnboardedArgs = {
  featureName: Scalars['String']['input'];
};

export type RootMutationTypeMarkInvestorCertificateAsRejectedArgs = {
  certificateId: Scalars['ID']['input'];
  rejectionComment?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeMarkInvestorCertificateAsVerifiedArgs = {
  certificateId: Scalars['ID']['input'];
  expiresAt?: InputMaybe<Scalars['IsoNaiveDatetime']['input']>;
  type: InvestorCertificateType;
};

export type RootMutationTypePublishMediaUpdateArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypePublishWelcomePageArgs = {
  welcomePage: WelcomePageInput;
};

export type RootMutationTypeReplyToMediaCommentArgs = {
  content: Scalars['String']['input'];
  parentId: Scalars['ID']['input'];
  useCompanyAsUsername?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootMutationTypeRequestExternalPlacementDemandEstimateArgs = {
  comments?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeResendInvitationTokenArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeResetMediaAnnouncementArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeResetPasswordArgs = {
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type RootMutationTypeSendEmailArgs = {
  emailId: Scalars['ID']['input'];
};

export type RootMutationTypeSendManualSocialPostArgs = {
  linkedinThumbailUrl?: InputMaybe<Scalars['String']['input']>;
  mediaId: Scalars['ID']['input'];
  socialPlatform: SocialPlatform;
  text: Scalars['String']['input'];
};

export type RootMutationTypeSendResetPasswordArgs = {
  email: Scalars['String']['input'];
};

export type RootMutationTypeSendStartPlanningSppEmailArgs = {
  sendStartPlanningSppEmailInput: SendStartPlanningSppEmailInput;
};

export type RootMutationTypeSendTestEmailArgs = {
  emailHtml?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  recipientEmails: Array<Scalars['String']['input']>;
};

export type RootMutationTypeSendTestEmailDomainConnectionEmailArgs = {
  emailAddress: Scalars['String']['input'];
};

export type RootMutationTypeSetPasswordFromInvitationTokenArgs = {
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type RootMutationTypeSortBlocksArgs = {
  blockIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  pageId: Scalars['ID']['input'];
};

export type RootMutationTypeSortNavMenuItemsArgs = {
  navMenuItemIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeSortNavMenuItemsWithChildrenArgs = {
  navMenuItems?: InputMaybe<Array<InputMaybe<NavMenuItemOrderInput>>>;
};

export type RootMutationTypeSubmitPlacementDemandQuestionArgs = {
  comments?: InputMaybe<Scalars['String']['input']>;
  generalTopic: PlacementDemandQuestionGeneralTopic;
  specificTopics?: InputMaybe<
    Array<InputMaybe<PlacementDemandQuestionSpecificTopic>>
  >;
};

export type RootMutationTypeToggleMediaCommentPrivacyArgs = {
  id: Scalars['ID']['input'];
  private: Scalars['Boolean']['input'];
};

export type RootMutationTypeUnlinkContactWithInvestorArgs = {
  id: Scalars['ID']['input'];
  investorUserId: Scalars['ID']['input'];
};

export type RootMutationTypeUnlinkContactWithShareholdingArgs = {
  id: Scalars['ID']['input'];
  shareholdingId: Scalars['ID']['input'];
};

export type RootMutationTypeUpdateBlockArgs = {
  content: Scalars['Map']['input'];
  id: Scalars['ID']['input'];
};

export type RootMutationTypeUpdateCompanyInfomationArgs = {
  profile: CompanyProfileUpdateInput;
};

export type RootMutationTypeUpdateCompanyInvestorHubArgs = {
  profile: CompanyProfileUpdateInput;
};

export type RootMutationTypeUpdateCompanyUserAndCompanyProfileUserInforamtionArgs =
  {
    firstName: Scalars['String']['input'];
    jobTitle: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    mobileNumber?: InputMaybe<Scalars['String']['input']>;
  };

export type RootMutationTypeUpdateContactArgs = {
  contact: ContactInput;
  id: Scalars['ID']['input'];
};

export type RootMutationTypeUpdateContactLeadStatusArgs = {
  id: Scalars['ID']['input'];
  leadStatus: LeadStatusUpdateOption;
};

export type RootMutationTypeUpdateContactNoteArgs = {
  contactNote: ContactNoteInput;
  id: Scalars['ID']['input'];
};

export type RootMutationTypeUpdateDistributionSettingsArgs = {
  distributionSettings: DistributionSettingsUpdateInput;
  distributionSettingsId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeUpdateDistributionSettingsEmailArgs = {
  distributionSettingsEmail: DistributionSettingsEmailInput;
  distributionSettingsEmailId?: InputMaybe<Scalars['ID']['input']>;
};

export type RootMutationTypeUpdateDynamicListArgs = {
  dynamicList: DynamicListInput;
  id: Scalars['ID']['input'];
};

export type RootMutationTypeUpdateEmailAndEmailRecipientsArgs = {
  email: EmailInput;
  emailId: Scalars['ID']['input'];
};

export type RootMutationTypeUpdateEmailScheduleArgs = {
  email: EmailInput;
  emailId: Scalars['ID']['input'];
};

export type RootMutationTypeUpdateMediaAnnouncementArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMediaAnnouncementInput;
};

export type RootMutationTypeUpdateMediaCommentDoneArgs = {
  done: Scalars['Boolean']['input'];
  mediaCommentId: Scalars['ID']['input'];
};

export type RootMutationTypeUpdateMediaCommentReplyArgs = {
  content: Scalars['String']['input'];
  mediaCommentId: Scalars['ID']['input'];
  useCompanyAsUsername?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RootMutationTypeUpdateMediaUpdatePostedAtArgs = {
  id: Scalars['ID']['input'];
  postedAt: Scalars['IsoNaiveDatetime']['input'];
};

export type RootMutationTypeUpdateMediaUpdateTitleArgs = {
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type RootMutationTypeUpdateNavMenuItemArgs = {
  id: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  pageId?: InputMaybe<Scalars['ID']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeUpdatePageArgs = {
  id: Scalars['ID']['input'];
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  socialImageCloudinaryPublicId?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type RootMutationTypeUpdatePinnedMediaUpdateArgs = {
  id: Scalars['ID']['input'];
  value: Scalars['Boolean']['input'];
};

export type RootMutationTypeUpdatePreparedAnnouncementArgs = {
  id: Scalars['ID']['input'];
  preparedAnnouncement?: InputMaybe<PreparedAnnouncementInput>;
};

export type RootMutationTypeUpdateShareholderOfferStatusArgs = {
  isLive: Scalars['Boolean']['input'];
  scheduledAt?: InputMaybe<Scalars['IsoNaiveDatetime']['input']>;
  shareholderOfferId: Scalars['ID']['input'];
};

export type RootMutationTypeUpdateShareholderOfferTitleArgs = {
  shareholderOfferId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type RootMutationTypeUpdateSocialConnectionArgs = {
  socialConnection: SocialConnectionInput;
};

export type RootMutationTypeUpdateTagArgs = {
  id: Scalars['ID']['input'];
  tag: TagInput;
};

export type RootMutationTypeUpdateUtmLinkArgs = {
  id: Scalars['ID']['input'];
  updatedUtmAttrs?: InputMaybe<UtmLinkInput>;
};

export type RootMutationTypeUpdateWebsiteArgs = {
  emailPopupModalCloudinaryUrl?: InputMaybe<Scalars['String']['input']>;
  googleAnalyticsId?: InputMaybe<Scalars['String']['input']>;
  isEmailPopupModalEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFullWebsiteReplacement?: InputMaybe<Scalars['Boolean']['input']>;
  isStickySignupBannerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  logoCloudinaryUrl?: InputMaybe<Scalars['String']['input']>;
  logoSquareCloudinaryUrl?: InputMaybe<Scalars['String']['input']>;
  themeColourAccent?: InputMaybe<Scalars['String']['input']>;
  themeColourAccentText?: InputMaybe<Scalars['String']['input']>;
  themeColourPrimary?: InputMaybe<Scalars['String']['input']>;
  themeColourPrimaryText?: InputMaybe<Scalars['String']['input']>;
  themeFontBody?: InputMaybe<Scalars['String']['input']>;
  themeFontTitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeUpsertBaseEmailTemplateArgs = {
  emailHtml: Scalars['String']['input'];
  emailJson: Scalars['String']['input'];
  templateType?: InputMaybe<BaseEmailTemplateType>;
};

export type RootMutationTypeUpsertContactUnsubscribesArgs = {
  contactId: Scalars['ID']['input'];
  globalUnsubscribe: Scalars['Boolean']['input'];
  scopes: Array<ContactUnsubscribeScopeType>;
};

export type RootMutationTypeUpsertCorporatePageArgs = {
  corporatePage: CorporatePageInput;
};

export type RootMutationTypeUpsertCustomContactsArgs = {
  audienceTags?: InputMaybe<Array<Scalars['String']['input']>>;
  clientAnswerLastUsage?: InputMaybe<Scalars['String']['input']>;
  clientAnswerListSource?: InputMaybe<Scalars['String']['input']>;
  customContacts: Array<ContactInput>;
};

export type RootMutationTypeUpsertCustomEmailsArgs = {
  customEmails: Array<CustomEmailInput>;
};

export type RootMutationTypeUpsertDistributionSettingsArgs = {
  email?: InputMaybe<UpsertDistributionSettingsInput>;
  flowType: FlowType;
  linkedin?: InputMaybe<UpsertDistributionSettingsInput>;
  twitter?: InputMaybe<UpsertDistributionSettingsInput>;
};

export type RootMutationTypeUpsertMediaCommentReadArgs = {
  mediaCommentId: Scalars['ID']['input'];
  read: Scalars['Boolean']['input'];
};

export type RootMutationTypeUpsertMediaCommentStarArgs = {
  mediaCommentId: Scalars['ID']['input'];
  starred: Scalars['Boolean']['input'];
};

export type RootMutationTypeUpsertMediaUpdateArgs = {
  id: Scalars['ID']['input'];
  mediaUpdate: UpsertMediaUpdateInput;
};

export type RootMutationTypeUpsertNotificationPreferenceArgs = {
  notificationPreference: NotificationPreferenceInput;
};

export type RootMutationTypeUpsertPastPlacementParticipantListArgs = {
  participantList: Scalars['Upload']['input'];
  pastPlacementId: Scalars['ID']['input'];
};

export type RootMutationTypeUpsertShareholderOfferPageArgs = {
  faqs?: InputMaybe<Array<FaqInput>>;
  instructions?: InputMaybe<Array<InstructionInput>>;
  shareholderOfferId: Scalars['ID']['input'];
  shareholderOfferPage: ShareholderOfferPageInput;
};

export type RootMutationTypeValidatePastPlacementParticipantArgs = {
  participantId: Scalars['ID']['input'];
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  activeDistributionFlows?: Maybe<ActiveDistributionFlows>;
  /** Get current company all brokers */
  allBrokers?: Maybe<Array<Maybe<Broker>>>;
  /**
   * Get all contacts
   *
   * Use cases:
   * 1. CSV download
   * 2. Selecting all contacts as comms recipients
   */
  allContacts: Array<Contact>;
  /** Get all current company investor users for CSV download */
  allCurrentCompanyInvestorUsers: Array<InvestorUser>;
  /** Get all past placement participants by past_placement_id for CSV download */
  allPastPlacementParticipants: Array<PastPlacementParticipant>;
  /** Get amplify investors based on the search phrase */
  amplifyInvestors?: Maybe<AmplifyInvestorConnection>;
  /** Get announcement distribution email statistics */
  announcementEmailDistributionStatistics: Array<AnnouncementEmailDistributionStatistics>;
  /** Get individual announcement engagement */
  announcementEngagement: Array<AnnouncementEngagement>;
  /** Get announcement reach and engagement */
  announcementReachAndEngagement: Array<AnnouncementReachAndEngagement>;
  /** Get announcement analytics stats */
  announcementsAnalyticsStats: AnnouncementAnalyticsStats;
  /** Get paginated media_announcements and prepared_announcements */
  announcementsList?: Maybe<AnnouncementListConnection>;
  /** Get released announcements count */
  announcementsReleasedCount?: Maybe<Scalars['Int']['output']>;
  /** Get audience list, each with basic info only (source, count, last updated) */
  audienceList: AudienceList;
  /** Stats for automations, i.e. how many emails have been sent for each automation flow */
  automationStats?: Maybe<AutomationStats>;
  /** Get benchmark analytics */
  benchmarkAnalytics: BenchmarkAnalytics;
  /** Get a single block by ID */
  block?: Maybe<Block>;
  /** List all generated board report */
  boardReports?: Maybe<Array<BoardReport>>;
  /** Get the broker breakdown */
  brokerBreakdown: Array<BrokerBreakdown>;
  campaignChannelShareholdings: Array<Shareholding>;
  campaignChannelShareholdingsByActivityType: Array<Shareholding>;
  /** Gets certificate by ID */
  certificateById: CertificateById;
  checkDynamicListNameTaken: Scalars['Boolean']['output'];
  checkDynamicListSafeToDelete?: Maybe<CheckDynamicListSafeToDeleteResponse>;
  /** Check if given invitation token is valid for company profile user */
  companyProfileUserFromInvitationToken?: Maybe<CompanyProfileUser>;
  /** Get all company profile users by current company profile */
  companyProfileUsers: Array<CompanyProfileUser>;
  /** Get contact by id */
  contact?: Maybe<Contact>;
  /** Get contact activities by contact id */
  contactActivities?: Maybe<ContactActivityConnection>;
  /** Get contact shareholding summary */
  contactShareholdingSummary?: Maybe<ContactShareholdingSummary>;
  /** Get contacts */
  contacts?: Maybe<ContactConnection>;
  /** Get current company */
  currentCompany?: Maybe<CompanyProfile>;
  currentCompanyDistributionSettings: CurrentCompanyDistributionSettings;
  /** Gets the investor hub analysis stats */
  currentCompanyInvestorHubAnalysis?: Maybe<CurrentCompanyInvestorHubAnalysisStats>;
  /** Get the company overview */
  currentCompanyOverview?: Maybe<Array<Maybe<CompanyOverview>>>;
  /** Get current company profile user */
  currentCompanyProfileUser?: Maybe<CompanyProfileUser>;
  /** Get the company stats for dashboard */
  currentCompanyStats?: Maybe<CompanyStats>;
  /** Get current company user */
  currentCompanyUser?: Maybe<CompanyUser>;
  /** Get current coporate pages - Governance page and Team page */
  currentCorporatePages: Array<CorporatePage>;
  /** Get current governance page */
  currentGovernancePage?: Maybe<CorporatePage>;
  /** Get the current holding distributions */
  currentHoldingDistributions?: Maybe<CurrentHoldingDistributions>;
  /** Get the current holding insights */
  currentHoldingInsights?: Maybe<HoldingInsights>;
  /** Get the current holding lengths */
  currentHoldingLengths?: Maybe<CurrentHoldingLengths>;
  /** Get the current holding sizes */
  currentHoldingSizes?: Maybe<CurrentHoldingSizes>;
  /** Get the current shareholder contactabilities */
  currentShareholderContactabilities?: Maybe<CurrentShareholderContactabilities>;
  /** Get the current shareholder locations */
  currentShareholderLocations?: Maybe<CurrentShareholderLocations>;
  /** Get the current shareholder profits */
  currentShareholderProfits?: Maybe<CurrentShareholderProfits>;
  /** Get the currently editing website for the current company (not the published version) */
  currentWebsite?: Maybe<Website>;
  /** Get current welcome page */
  currentWelcomePage?: Maybe<WelcomePage>;
  /** Get custom domain */
  customDomain?: Maybe<CustomDomain>;
  /** Get transactional custom email and marketing custom email of current company */
  customEmails?: Maybe<CustomEmails>;
  distributionSettingsForChannelForFlowType?: Maybe<DistributionSettings>;
  dynamicList?: Maybe<DynamicList>;
  dynamicLists?: Maybe<DynamicListConnection>;
  email?: Maybe<Email>;
  emailList?: Maybe<EmailConnection>;
  emailRecipients?: Maybe<EmailRecipientConnection>;
  /** Get email reputation for a company */
  emailReputation?: Maybe<EmailReputation>;
  /** Get all email reputation data for a company */
  emailReputationData?: Maybe<EmailReputationData>;
  /** Get latest emails for email reputation */
  emailReputationEmailStats: Array<EmailStats>;
  /** Get company's email reputation data for a graph */
  emailReputationOverTime: Array<EmailReputationDataPoint>;
  /** Get engagement analytics overview */
  engagementAnalyticsOverview: EngagementAnalyticsOverview;
  estimateEmailRecipientsCount: Scalars['Int']['output'];
  /** All existing tags on current company */
  existingTags: Array<Tag>;
  generateBoardReport: Scalars['Boolean']['output'];
  getCampaignDistributionGraphData?: Maybe<
    Array<Maybe<CampaignDistributionGraphData>>
  >;
  /** Get color palette of a given hex code */
  getColorPalette?: Maybe<CompanyColorPalette>;
  getContactsGraphData?: Maybe<Array<Maybe<ContactsGraphData>>>;
  getEngagementGraphData?: Maybe<Array<Maybe<EngagementGraphData>>>;
  getLastestDailyHoldingDate: Scalars['Date']['output'];
  /** Get a single utm_link */
  getUtmLink?: Maybe<UtmLink>;
  /** Get the growth ratio timeseries */
  growthRatioTimeseries?: Maybe<Array<GrowthRatioTimeseries>>;
  /** Get how many high net worth investors were identified in the last 14 days */
  hnwIdentifiedNumbers?: Maybe<HnwIdentifiedStats>;
  /** Get high net worth investors */
  hnwInvestors?: Maybe<HnwInvestorConnection>;
  /** Get hub stats for the hub builder index page */
  hubStats?: Maybe<HubStats>;
  interactiveMediaStats: InteractiveMediaStats;
  investorActivityStats?: Maybe<ActivityStats>;
  /** Get investor hub analytics */
  investorHubAnalytics: InvestorHubAnalytics;
  /** Get investor_hub engagement */
  investorHubEngagement: Array<InvestorHubEngagement>;
  /** Get most engaged investors */
  investorHubMostEngagedInvestors: Array<InvestorHubMostEngagedInvestors>;
  /** Get page performance */
  investorHubPagePerformance: Array<InvestorHubPagePerformance>;
  /** Get investor hub signup breakdown */
  investorHubSignupBreakdown: InvestorHubSignupBreakdown;
  /** Get paginated investor users */
  investorUsers?: Maybe<InvestorUserConnection>;
  /** Get user feature onboarding */
  isFeatureOnboarded?: Maybe<Scalars['Boolean']['output']>;
  isFlagEnabled: Scalars['Boolean']['output'];
  /** Get key insights */
  keyInsights?: Maybe<KeyInsights>;
  /** Get linkedin organisations for current company */
  linkedinOrganisations: Array<LinkedinOrganisation>;
  /** Get stats for a linkedin post */
  linkedinPostStatistics?: Maybe<LinkedinPostStatistics>;
  mediaAnnouncement?: Maybe<MediaAnnouncement>;
  mediaAnnouncementViewerStats?: Maybe<AnnouncementViewerStats>;
  mediaAnnouncements?: Maybe<MediaAnnouncementConnection>;
  mediaComments: Array<MediaComment>;
  mediaCommentsCompanyAuthor: Array<MediaComment>;
  /** Get paginated media questions(comments from investor users) */
  mediaQuestionsList?: Maybe<MediaCommentConnection>;
  /** Gets the investor hub overview stats */
  mediaStats?: Maybe<MediaStats>;
  mediaSurveyAnswers: Array<MediaSurveyAnswer>;
  /** Gets the survey response stats for a media */
  mediaSurveyResponseStats?: Maybe<Array<Maybe<MediaSurveyResponse>>>;
  mediaUpdate?: Maybe<MediaUpdate>;
  mediaUpdates?: Maybe<MediaUpdateConnection>;
  /** Get the navigation menu items for the current draft website */
  navMenuItems?: Maybe<Array<NavMenuItem>>;
  /** Get list of non draft not linked prepared announcements with search */
  nonDraftNotLinkedPreparedAnnouncements: Array<PreparedAnnouncement>;
  oldestMediaAnnouncement?: Maybe<MediaAnnouncement>;
  oldestMediaAnnouncementDate?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  /** Get a single page by slug */
  page?: Maybe<Page>;
  /** Gets a past placement by ID */
  pastPlacement: PastPlacement;
  /** Gets aftermarket stats by past placement */
  pastPlacementAftermarketStats: PastPlacementAftermarketStats;
  /** Get paginated past placement participants by past_placement_id */
  pastPlacementParticipants?: Maybe<PastPlacementParticipantConnection>;
  /** Gets a list of past placements that have been announced since 01 July 2020 */
  pastPlacements: Array<PastPlacement>;
  /** Get pending bulk imports for the current company */
  pendingBulkImports: Array<BulkImport>;
  /** Get all pending certificates */
  pendingReviewCertificates: Array<InvestorCertificate>;
  /** Gets placement demand report */
  placementDemandReport: PlacementDemandReport;
  preparedAnnouncement?: Maybe<PreparedAnnouncement>;
  /** Get historical SPP */
  raisesSppHistorical: Array<RaiseSppHistorical>;
  /** Get historical SPP for current company */
  raisesSppHistoricalForCurrentCompany: Array<RaiseSppHistorical>;
  /** Get summary stats about reachability for SPPs */
  raisesSppReachabilityStats?: Maybe<RaiseSppReachabilityStats>;
  /** Get summary stats about shareholders for SPPs */
  raisesSppShareholderStats?: Maybe<RaiseSppShareholderStats>;
  /** Get registry data status */
  registryImportStatus?: Maybe<RegistryDataStatus>;
  /** Get status of Automic account */
  registryStatus?: Maybe<RegistryStatus>;
  /** Get best fit line for historical SPP */
  regressRaisesSppHistorical?: Maybe<RaiseSppRegressParams>;
  /** Get share movements */
  shareMovements: ShareMovements;
  /** Get a shareholder offer by ID */
  shareholderOffer: ShareholderOffer;
  shareholderOfferEngagement: Array<ShareholderOfferEngagement>;
  /** Get company's paginated shareholder offers */
  shareholderOffers?: Maybe<ShareholderOfferConnection>;
  shareholderTradingActivity?: Maybe<ShareholderTradingActivityConnection>;
  shareholding?: Maybe<Shareholding>;
  shareholdingSummary?: Maybe<ShareholdingSummary>;
  /** Get current company paginated shareholdings */
  shareholdings?: Maybe<ShareholdingConnection>;
  /** Get SPP outcomes */
  sppEstimate?: Maybe<SppEstimate>;
  submitFeedback: Scalars['Boolean']['output'];
  /** Collect the metadata required to render suggested actions appropriately */
  suggestedActionsMetadata?: Maybe<SuggestedActionsMetadata>;
  /** Get API token */
  token?: Maybe<Token>;
  /** Get top three viewed announcements */
  topThreeViewedAnnouncements: Array<MediaAnnouncement>;
  /** Get top three viewed updates */
  topThreeViewedUpdates: Array<MediaUpdate>;
  /** Get total number of media updates */
  totalUpdates: Scalars['Int']['output'];
  /** Get updates analytics stats */
  updatesAnalyticsStats: UpdateAnalyticsStats;
  /** Get update distribution email statistics */
  updatesEmailDistributionStatistics: Array<UpdatesEmailDistributionStatistics>;
  /** Get updates engagement by content type */
  updatesEngagementByContentType: Array<UpdatesTypeViewsAndEngagement>;
  /** Get update reach and engagement */
  updatesReachAndEngagement: Array<UpdateReachAndEngagement>;
  /** Get released updates count */
  updatesReleasedCount?: Maybe<Scalars['Int']['output']>;
  utmLinkAnalytics?: Maybe<UtmLinkAnalytics>;
  utmLinkAnalyticsSignups?: Maybe<UtmInvestorSignupConnection>;
  utmLinks?: Maybe<UtmLinkConnection>;
};

export type RootQueryTypeAllContactsArgs = {
  options?: InputMaybe<OptionsInput>;
};

export type RootQueryTypeAllPastPlacementParticipantsArgs = {
  options?: InputMaybe<OptionsInput>;
  pastPlacementId: Scalars['ID']['input'];
};

export type RootQueryTypeAmplifyInvestorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  searchTags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RootQueryTypeAnnouncementEmailDistributionStatisticsArgs = {
  endDate: Scalars['NaiveDateTime']['input'];
  startDate: Scalars['NaiveDateTime']['input'];
};

export type RootQueryTypeAnnouncementEngagementArgs = {
  dateRange?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['NaiveDateTime']['input']>;
  id: Scalars['ID']['input'];
  startDate?: InputMaybe<Scalars['NaiveDateTime']['input']>;
};

export type RootQueryTypeAnnouncementReachAndEngagementArgs = {
  endDate: Scalars['NaiveDateTime']['input'];
  startDate: Scalars['NaiveDateTime']['input'];
};

export type RootQueryTypeAnnouncementsAnalyticsStatsArgs = {
  endDate: Scalars['NaiveDateTime']['input'];
  startDate: Scalars['NaiveDateTime']['input'];
};

export type RootQueryTypeAnnouncementsListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
};

export type RootQueryTypeAnnouncementsReleasedCountArgs = {
  endDate: Scalars['NaiveDateTime']['input'];
  startDate: Scalars['NaiveDateTime']['input'];
};

export type RootQueryTypeBenchmarkAnalyticsArgs = {
  endDate: Scalars['NaiveDateTime']['input'];
  startDate: Scalars['NaiveDateTime']['input'];
};

export type RootQueryTypeBlockArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeBrokerBreakdownArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type RootQueryTypeCampaignChannelShareholdingsArgs = {
  channel: Scalars['String']['input'];
};

export type RootQueryTypeCampaignChannelShareholdingsByActivityTypeArgs = {
  channel: Scalars['String']['input'];
  endDate: Scalars['Date']['input'];
  shareholderActivityType: ShareholderActivityType;
  startDate: Scalars['Date']['input'];
};

export type RootQueryTypeCertificateByIdArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeCheckDynamicListNameTakenArgs = {
  name: Scalars['String']['input'];
};

export type RootQueryTypeCheckDynamicListSafeToDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeCompanyProfileUserFromInvitationTokenArgs = {
  token: Scalars['String']['input'];
};

export type RootQueryTypeContactArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeContactActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
};

export type RootQueryTypeContactShareholdingSummaryArgs = {
  contactId: Scalars['ID']['input'];
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type RootQueryTypeContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
};

export type RootQueryTypeCurrentCompanyDistributionSettingsArgs = {
  flowType: FlowType;
};

export type RootQueryTypeCurrentCompanyOverviewArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type RootQueryTypeDistributionSettingsForChannelForFlowTypeArgs = {
  channel?: InputMaybe<DistributionChannelType>;
  flowType?: InputMaybe<FlowType>;
};

export type RootQueryTypeDynamicListArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeDynamicListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
};

export type RootQueryTypeEmailArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeEmailListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
};

export type RootQueryTypeEmailRecipientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  emailId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
};

export type RootQueryTypeEmailReputationEmailStatsArgs = {
  type: Scalars['String']['input'];
};

export type RootQueryTypeEngagementAnalyticsOverviewArgs = {
  endDate: Scalars['NaiveDateTime']['input'];
  startDate: Scalars['NaiveDateTime']['input'];
};

export type RootQueryTypeEstimateEmailRecipientsCountArgs = {
  emailId: Scalars['ID']['input'];
};

export type RootQueryTypeGenerateBoardReportArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
};

export type RootQueryTypeGetCampaignDistributionGraphDataArgs = {
  lastNumberOfEmailCampaigns: Scalars['Int']['input'];
};

export type RootQueryTypeGetColorPaletteArgs = {
  hexCode: Scalars['String']['input'];
};

export type RootQueryTypeGetContactsGraphDataArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type RootQueryTypeGetEngagementGraphDataArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type RootQueryTypeGetUtmLinkArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeGrowthRatioTimeseriesArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type RootQueryTypeHnwInvestorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hnwType?: InputMaybe<HnwQueryType>;
  includePastInvestors?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<OrderInput>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeInvestorActivityStatsArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeInvestorHubAnalyticsArgs = {
  endDate: Scalars['NaiveDateTime']['input'];
  startDate: Scalars['NaiveDateTime']['input'];
};

export type RootQueryTypeInvestorHubEngagementArgs = {
  endDate: Scalars['NaiveDateTime']['input'];
  startDate: Scalars['NaiveDateTime']['input'];
};

export type RootQueryTypeInvestorHubMostEngagedInvestorsArgs = {
  endDate: Scalars['NaiveDateTime']['input'];
  startDate: Scalars['NaiveDateTime']['input'];
};

export type RootQueryTypeInvestorHubPagePerformanceArgs = {
  endDate: Scalars['NaiveDateTime']['input'];
  startDate: Scalars['NaiveDateTime']['input'];
};

export type RootQueryTypeInvestorHubSignupBreakdownArgs = {
  endDate: Scalars['NaiveDateTime']['input'];
  startDate: Scalars['NaiveDateTime']['input'];
};

export type RootQueryTypeInvestorUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
};

export type RootQueryTypeIsFeatureOnboardedArgs = {
  featureName: Scalars['String']['input'];
};

export type RootQueryTypeIsFlagEnabledArgs = {
  name: Scalars['String']['input'];
};

export type RootQueryTypeKeyInsightsArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type RootQueryTypeLinkedinPostStatisticsArgs = {
  postId: Scalars['String']['input'];
};

export type RootQueryTypeMediaAnnouncementArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeMediaAnnouncementViewerStatsArgs = {
  mediaId: Scalars['ID']['input'];
};

export type RootQueryTypeMediaAnnouncementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
};

export type RootQueryTypeMediaCommentsArgs = {
  isAnnotation?: InputMaybe<Scalars['Boolean']['input']>;
  mediaId: Scalars['ID']['input'];
};

export type RootQueryTypeMediaCommentsCompanyAuthorArgs = {
  mediaId: Scalars['ID']['input'];
};

export type RootQueryTypeMediaQuestionsListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
};

export type RootQueryTypeMediaSurveyAnswersArgs = {
  mediaId: Scalars['ID']['input'];
};

export type RootQueryTypeMediaSurveyResponseStatsArgs = {
  mediaId: Scalars['ID']['input'];
};

export type RootQueryTypeMediaUpdateArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeMediaUpdatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
};

export type RootQueryTypeNonDraftNotLinkedPreparedAnnouncementsArgs = {
  search: Scalars['String']['input'];
};

export type RootQueryTypePageArgs = {
  slug: Scalars['String']['input'];
};

export type RootQueryTypePastPlacementArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypePastPlacementAftermarketStatsArgs = {
  pastPlacementId: Scalars['ID']['input'];
  timeRange: PastPlacementAftermarketTimeRange;
};

export type RootQueryTypePastPlacementParticipantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
  pastPlacementId: Scalars['ID']['input'];
};

export type RootQueryTypePreparedAnnouncementArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeRaisesSppHistoricalArgs = {
  sectorTicker: Array<InputMaybe<Scalars['String']['input']>>;
  year: Scalars['Int']['input'];
};

export type RootQueryTypeRegressRaisesSppHistoricalArgs = {
  sectorTicker: Array<InputMaybe<Scalars['String']['input']>>;
  year: Scalars['Int']['input'];
};

export type RootQueryTypeShareMovementsArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type RootQueryTypeShareholderOfferArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeShareholderOffersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
};

export type RootQueryTypeShareholderTradingActivityArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<OrderInput>;
  shareholderTradingActivityInput: ShareholderTradingActivityInput;
};

export type RootQueryTypeShareholdingArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeShareholdingSummaryArgs = {
  endDate: Scalars['Date']['input'];
  id: Scalars['ID']['input'];
  startDate: Scalars['Date']['input'];
};

export type RootQueryTypeShareholdingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
};

export type RootQueryTypeSubmitFeedbackArgs = {
  message: Scalars['String']['input'];
};

export type RootQueryTypeTopThreeViewedAnnouncementsArgs = {
  endDate: Scalars['NaiveDateTime']['input'];
  startDate: Scalars['NaiveDateTime']['input'];
};

export type RootQueryTypeTopThreeViewedUpdatesArgs = {
  endDate: Scalars['NaiveDateTime']['input'];
  startDate: Scalars['NaiveDateTime']['input'];
};

export type RootQueryTypeUpdatesAnalyticsStatsArgs = {
  endDate: Scalars['NaiveDateTime']['input'];
  startDate: Scalars['NaiveDateTime']['input'];
};

export type RootQueryTypeUpdatesEmailDistributionStatisticsArgs = {
  endDate: Scalars['NaiveDateTime']['input'];
  startDate: Scalars['NaiveDateTime']['input'];
};

export type RootQueryTypeUpdatesEngagementByContentTypeArgs = {
  endDate: Scalars['NaiveDateTime']['input'];
  startDate: Scalars['NaiveDateTime']['input'];
};

export type RootQueryTypeUpdatesReachAndEngagementArgs = {
  endDate: Scalars['NaiveDateTime']['input'];
  startDate: Scalars['NaiveDateTime']['input'];
};

export type RootQueryTypeUpdatesReleasedCountArgs = {
  endDate: Scalars['NaiveDateTime']['input'];
  startDate: Scalars['NaiveDateTime']['input'];
};

export type RootQueryTypeUtmLinkAnalyticsArgs = {
  endDate?: InputMaybe<Scalars['NaiveDateTime']['input']>;
  id: Scalars['ID']['input'];
  startDate?: InputMaybe<Scalars['NaiveDateTime']['input']>;
};

export type RootQueryTypeUtmLinkAnalyticsSignupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
  utmLinkId: Scalars['ID']['input'];
};

export type RootQueryTypeUtmLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
};

export type RootSubscriptionType = {
  __typename?: 'RootSubscriptionType';
  companyProfileUserUpdated?: Maybe<CompanyProfileUser>;
  currentCompanyProfileUpdated?: Maybe<CompanyProfile>;
};

export type RootSubscriptionTypeCompanyProfileUserUpdatedArgs = {
  id: Scalars['ID']['input'];
};

export type ScenarioRange = {
  __typename?: 'ScenarioRange';
  allBad: ScenarioRangeData;
  allGood: ScenarioRangeData;
  rockyFinish: ScenarioRangeData;
};

export type ScenarioRangeData = {
  __typename?: 'ScenarioRangeData';
  averageUptake: Scalars['Float']['output'];
  high: Scalars['Float']['output'];
  low: Scalars['Float']['output'];
  mean: Scalars['Float']['output'];
  shareholderParticipation: Scalars['Float']['output'];
};

export type Section = {
  __typename?: 'Section';
  attachments: Array<Attachment>;
  description?: Maybe<Scalars['String']['output']>;
  heading: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
};

export type SectionInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  heading: Scalars['String']['input'];
};

export type SellerField = {
  __typename?: 'SellerField';
  accountName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  movement: Scalars['Int']['output'];
  movementType: MovementType;
};

export type SendStartPlanningSppEmailInput = {
  additionalComments?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  whatDoYouWantToLearnAbout: Scalars['String']['input'];
};

export type SentEmail = {
  __typename?: 'SentEmail';
  identifier?: Maybe<Scalars['Int']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type ShadeMap = {
  __typename?: 'ShadeMap';
  s10: Scalars['String']['output'];
  s20: Scalars['String']['output'];
  s30: Scalars['String']['output'];
  s40: Scalars['String']['output'];
  s50: Scalars['String']['output'];
  s60: Scalars['String']['output'];
  s70: Scalars['String']['output'];
  s80: Scalars['String']['output'];
  s90: Scalars['String']['output'];
};

export type ShareMovement = {
  __typename?: 'ShareMovement';
  closingBalance: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  movement: Scalars['Int']['output'];
  movementType?: Maybe<Scalars['String']['output']>;
  openingBalance: Scalars['Int']['output'];
  settledAt: Scalars['DateShort']['output'];
  transactionPrice?: Maybe<Scalars['Float']['output']>;
};

export type ShareMovements = {
  __typename?: 'ShareMovements';
  id: Scalars['ID']['output'];
  topBuyers: Array<BuyerField>;
  topSellers: Array<SellerField>;
  topShareholders: Array<TopShareholderField>;
  totalBought?: Maybe<Scalars['Int']['output']>;
  totalSold?: Maybe<Scalars['Int']['output']>;
};

export enum ShareholderActivityType {
  New = 'NEW',
}

export type ShareholderInsights = {
  __typename?: 'ShareholderInsights';
  addresses: Scalars['Int']['output'];
  eligibility: Scalars['Float']['output'];
  email: Scalars['Int']['output'];
  mobile: Scalars['Int']['output'];
  reachability: Scalars['Float']['output'];
  total: Scalars['Int']['output'];
};

export type ShareholderLocation = {
  __typename?: 'ShareholderLocation';
  id: Scalars['ID']['output'];
  location: Scalars['String']['output'];
  shareholdersCount: Scalars['Int']['output'];
  totalShares: Scalars['Int']['output'];
};

export type ShareholderOffer = {
  __typename?: 'ShareholderOffer';
  companyProfile: CompanyProfile;
  companyShareholderOfferPage?: Maybe<ShareholderOfferPage>;
  id: Scalars['ID']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  isLive: Scalars['Boolean']['output'];
  lastEditedByUser: CompanyUser;
  privateViewers: Array<PrivateViewer>;
  publishedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  scheduledAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  shareholderOfferEngagement: Array<ShareholderOfferEngagement>;
  signUpsDuringOfferPeriod: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  totalInvestorPresentationDownloadsCount: Scalars['Int']['output'];
  totalOfferBookletDownloadsCount: Scalars['Int']['output'];
  totalUniqueVisitorsCount: Scalars['Int']['output'];
  totalUniqueVisitorsCountFromHubUsers: Scalars['Int']['output'];
  totalViewCount: Scalars['Int']['output'];
  totalViewCountFromHubUsers: Scalars['Int']['output'];
  type: ShareholderOfferType;
  updatedAt: Scalars['IsoNaiveDatetime']['output'];
};

export type ShareholderOfferConnection = {
  __typename?: 'ShareholderOfferConnection';
  edges?: Maybe<Array<Maybe<ShareholderOfferEdge>>>;
  options?: Maybe<Options>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type ShareholderOfferConnectionTotalArgs = {
  options?: InputMaybe<OptionsInput>;
};

export type ShareholderOfferEdge = {
  __typename?: 'ShareholderOfferEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<ShareholderOffer>;
  shareholderOffer: ShareholderOffer;
};

export type ShareholderOfferEngagement = {
  __typename?: 'ShareholderOfferEngagement';
  date: Scalars['Date']['output'];
  totalUniqueVisitorsCount: Scalars['Int']['output'];
  totalViewCount: Scalars['Int']['output'];
};

export type ShareholderOfferInput = {
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  type?: InputMaybe<ShareholderOfferType>;
};

export type ShareholderOfferPage = {
  __typename?: 'ShareholderOfferPage';
  bannerUrl?: Maybe<Scalars['String']['output']>;
  closingDate?: Maybe<Scalars['Date']['output']>;
  faqs: Array<Faq>;
  id: Scalars['String']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  instructions: Array<Instruction>;
  interactiveAnnouncementLink?: Maybe<Scalars['String']['output']>;
  introductionHeading?: Maybe<Scalars['String']['output']>;
  introductionMessage?: Maybe<Scalars['String']['output']>;
  investorPresentationName?: Maybe<Scalars['String']['output']>;
  investorPresentationUrl?: Maybe<Scalars['String']['output']>;
  lastEditedByUser: CompanyUser;
  offerBookletName?: Maybe<Scalars['String']['output']>;
  offerBookletUrl?: Maybe<Scalars['String']['output']>;
  offerPrice?: Maybe<Scalars['String']['output']>;
  raiseApplyInstructionMessage?: Maybe<Scalars['String']['output']>;
  raiseDefinitionMessage?: Maybe<Scalars['String']['output']>;
  raiseDefinitionTimelineDiagramUrl?: Maybe<Scalars['String']['output']>;
  raiseReasonHeroMediaImageUrl?: Maybe<Scalars['String']['output']>;
  raiseReasonHeroMediaSocialVideoUrl?: Maybe<Scalars['String']['output']>;
  raiseReasonHeroMediaVideoUrl?: Maybe<Scalars['String']['output']>;
  raiseReasonMessage?: Maybe<Scalars['String']['output']>;
  raisingTarget?: Maybe<Scalars['Int']['output']>;
  shareholderOffer: ShareholderOffer;
  updatedAt: Scalars['IsoNaiveDatetime']['output'];
  usCitizenPageEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type ShareholderOfferPageInput = {
  banner?: InputMaybe<Scalars['Upload']['input']>;
  closingDate?: InputMaybe<Scalars['Date']['input']>;
  interactiveAnnouncementLink?: InputMaybe<Scalars['String']['input']>;
  introductionHeading?: InputMaybe<Scalars['String']['input']>;
  introductionMessage?: InputMaybe<Scalars['String']['input']>;
  investorPresentation?: InputMaybe<Scalars['Upload']['input']>;
  investorPresentationName?: InputMaybe<Scalars['String']['input']>;
  offerBooklet?: InputMaybe<Scalars['Upload']['input']>;
  offerBookletName?: InputMaybe<Scalars['String']['input']>;
  offerPrice?: InputMaybe<Scalars['String']['input']>;
  raiseApplyInstructionMessage?: InputMaybe<Scalars['String']['input']>;
  raiseDefinitionMessage?: InputMaybe<Scalars['String']['input']>;
  raiseDefinitionTimelineDiagram?: InputMaybe<Scalars['Upload']['input']>;
  raiseReasonHeroMediaImage?: InputMaybe<Scalars['Upload']['input']>;
  raiseReasonHeroMediaSocialVideoUrl?: InputMaybe<Scalars['String']['input']>;
  raiseReasonHeroMediaVideoUrl?: InputMaybe<Scalars['String']['input']>;
  raiseReasonMessage?: InputMaybe<Scalars['String']['input']>;
  raisingTarget?: InputMaybe<Scalars['Int']['input']>;
};

export enum ShareholderOfferType {
  Entitlement = 'ENTITLEMENT',
  Nreo = 'NREO',
  Spp = 'SPP',
}

export type ShareholderProfit = {
  __typename?: 'ShareholderProfit';
  id: Scalars['ID']['output'];
  lowerBound?: Maybe<Scalars['Float']['output']>;
  shareholdersCount: Scalars['Int']['output'];
  upperBound?: Maybe<Scalars['Float']['output']>;
};

export enum ShareholderStatus {
  InvestorLead = 'INVESTOR_LEAD',
  NominatedShareholder = 'NOMINATED_SHAREHOLDER',
  PastShareholder = 'PAST_SHAREHOLDER',
  Shareholder = 'SHAREHOLDER',
}

export type ShareholderTradingActivity = {
  __typename?: 'ShareholderTradingActivity';
  accountName: Scalars['String']['output'];
  daysTraded: Scalars['Int']['output'];
  email?: Maybe<Scalars['String']['output']>;
  holdings: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  movement: Scalars['Int']['output'];
  shareholdingId: Scalars['ID']['output'];
};

export type ShareholderTradingActivityConnection = {
  __typename?: 'ShareholderTradingActivityConnection';
  edges?: Maybe<Array<Maybe<ShareholderTradingActivityEdge>>>;
  order?: Maybe<Order>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type ShareholderTradingActivityEdge = {
  __typename?: 'ShareholderTradingActivityEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<ShareholderTradingActivity>;
};

export type ShareholderTradingActivityInput = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  tradingActivityType: TradingActivityType;
};

export type Shareholding = {
  __typename?: 'Shareholding';
  accountName: Scalars['String']['output'];
  addressCity?: Maybe<Scalars['String']['output']>;
  addressCountry?: Maybe<Scalars['String']['output']>;
  addressLineOne?: Maybe<Scalars['String']['output']>;
  addressLineTwo?: Maybe<Scalars['String']['output']>;
  addressPostcode?: Maybe<Scalars['String']['output']>;
  addressState?: Maybe<Scalars['String']['output']>;
  biggestMovement?: Maybe<Scalars['Int']['output']>;
  brokerNameShort?: Maybe<Scalars['String']['output']>;
  brokerPid?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Contact>;
  currentHoldingStartDate?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  estimatedProfitLoss?: Maybe<Scalars['Float']['output']>;
  estimatedTotalPurchaseValue?: Maybe<Scalars['Float']['output']>;
  estimatedTotalSaleValue?: Maybe<Scalars['Float']['output']>;
  hasEmailRecipient?: Maybe<Scalars['Boolean']['output']>;
  hasParticipatedInPlacement?: Maybe<Scalars['Boolean']['output']>;
  hasParticipatedInSpp?: Maybe<Scalars['Boolean']['output']>;
  hnwBehaviour?: Maybe<HnwBehaviours>;
  hnwIdentifiedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  holderIdMasked?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  initialPurchaseDate?: Maybe<Scalars['Date']['output']>;
  latestShareMovement?: Maybe<ShareMovement>;
  movementCount?: Maybe<Scalars['Int']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  shareCount?: Maybe<Scalars['Int']['output']>;
  shareCountRank?: Maybe<Scalars['Int']['output']>;
  sophisticatedInvestorStatus?: Maybe<SophisticatedInvestorValues>;
};

export type ShareholdingHasEmailRecipientArgs = {
  emailId: Scalars['ID']['input'];
};

export type ShareholdingConnection = {
  __typename?: 'ShareholdingConnection';
  edges?: Maybe<Array<Maybe<ShareholdingEdge>>>;
  options?: Maybe<Options>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type ShareholdingConnectionTotalArgs = {
  options?: InputMaybe<OptionsInput>;
};

export type ShareholdingEdge = {
  __typename?: 'ShareholdingEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Shareholding>;
  shareholding: Shareholding;
};

export type ShareholdingSummary = {
  __typename?: 'ShareholdingSummary';
  announcements: Array<Maybe<MediaAnnouncement>>;
  dailyHoldings: Array<Maybe<DailyHolding>>;
  id: Scalars['ID']['output'];
  shareMovements: Array<Maybe<ShareMovement>>;
  timeseries: Array<Maybe<Timeseries>>;
};

export type SocialConnection = {
  __typename?: 'SocialConnection';
  id: Scalars['ID']['output'];
  linkedinConnected?: Maybe<Scalars['Boolean']['output']>;
  linkedinOrganisationId?: Maybe<Scalars['String']['output']>;
  linkedinOrganisationName?: Maybe<Scalars['String']['output']>;
  linkedinRefreshTokenExpiresAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  linkedinSetupCompleted?: Maybe<Scalars['Boolean']['output']>;
  twitterSetupCompleted?: Maybe<Scalars['Boolean']['output']>;
  twitterUsername?: Maybe<Scalars['String']['output']>;
};

export type SocialConnectionInput = {
  linkedinOrganisationId?: InputMaybe<Scalars['String']['input']>;
  linkedinOrganisationName?: InputMaybe<Scalars['String']['input']>;
};

export enum SocialPlatform {
  Linkedin = 'LINKEDIN',
  Twitter = 'TWITTER',
}

export enum SophisticatedInvestorValues {
  Potential = 'POTENTIAL',
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED',
}

export type SppEstimate = {
  __typename?: 'SppEstimate';
  avgPart: Scalars['Float']['output'];
  highEstimate: Scalars['Float']['output'];
  lowEstimate: Scalars['Float']['output'];
  medEstimate: Scalars['Float']['output'];
  partRate: Scalars['Float']['output'];
  priorPart?: Maybe<Scalars['Int']['output']>;
  topPart?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  updatedAt: Scalars['IsoNaiveDatetime']['output'];
};

export type SuggestedActionsMetadata = {
  __typename?: 'SuggestedActionsMetadata';
  activeQuestionCount: Scalars['Int']['output'];
  aiSummariesEnabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isAutomatedDistributionSwitchedOn: Scalars['Boolean']['output'];
  lastUpdatePostedDate?: Maybe<Scalars['Date']['output']>;
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID']['output'];
  invalidated: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type TagInput = {
  invalidated?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Ticker = {
  __typename?: 'Ticker';
  id: Scalars['ID']['output'];
  listingKey: Scalars['String']['output'];
  marketKey: Scalars['String']['output'];
  marketListingKey: Scalars['String']['output'];
  refinitivIdentificationCode?: Maybe<Scalars['String']['output']>;
};

export type Timeseries = {
  __typename?: 'Timeseries';
  close?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  date: Scalars['DateShort']['output'];
  high?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  low?: Maybe<Scalars['Float']['output']>;
  open?: Maybe<Scalars['Float']['output']>;
  /** Could be decimals if timeseries is adjusted */
  volume?: Maybe<Scalars['Float']['output']>;
};

export type TintMap = {
  __typename?: 'TintMap';
  t10: Scalars['String']['output'];
  t20: Scalars['String']['output'];
  t30: Scalars['String']['output'];
  t40: Scalars['String']['output'];
  t50: Scalars['String']['output'];
  t60: Scalars['String']['output'];
  t70: Scalars['String']['output'];
  t80: Scalars['String']['output'];
  t90: Scalars['String']['output'];
};

export type Token = {
  __typename?: 'Token';
  value: Scalars['String']['output'];
};

export type TopShareholderField = {
  __typename?: 'TopShareholderField';
  accountName: Scalars['String']['output'];
  balance: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  movement: Scalars['Int']['output'];
  shareholdingId: Scalars['ID']['output'];
};

export type TrackingEmail = {
  __typename?: 'TrackingEmail';
  events: Array<CampaignEmailRecipientTrackingEvents>;
  id: Scalars['ID']['output'];
};

export enum TradingActivityType {
  Churned = 'CHURNED',
  Downgraded = 'DOWNGRADED',
  New = 'NEW',
  Returning = 'RETURNING',
  Upgraded = 'UPGRADED',
}

export type UnsubscribedAudience = {
  __typename?: 'UnsubscribedAudience';
  count: Scalars['Int']['output'];
  hub?: Maybe<Audience>;
  imported?: Maybe<Audience>;
  lastUpdatedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  shareholder?: Maybe<Audience>;
};

export type Update = {
  __typename?: 'Update';
  companyProfileId: Scalars['ID']['output'];
  date: Scalars['Date']['output'];
  title: Scalars['String']['output'];
  updateId: Scalars['ID']['output'];
};

export type UpdateAnalyticsStats = {
  __typename?: 'UpdateAnalyticsStats';
  totalLikes: Scalars['Int']['output'];
  totalLikesDifference: Scalars['Int']['output'];
  totalQuestions: Scalars['Int']['output'];
  totalQuestionsDifference: Scalars['Int']['output'];
  totalSurveyResponses: Scalars['Int']['output'];
  totalSurveyResponsesDifference: Scalars['Int']['output'];
  totalViews: Scalars['Int']['output'];
  totalViewsDifference: Scalars['Int']['output'];
};

export type UpdateMediaAnnouncementInput = {
  featuredOnHub?: InputMaybe<Scalars['Boolean']['input']>;
  germanTranslatedHeader?: InputMaybe<Scalars['String']['input']>;
  germanTranslatedSummary?: InputMaybe<Scalars['String']['input']>;
  germanTranslatedUrl?: InputMaybe<Scalars['String']['input']>;
  germanTranslatedVideoUrl?: InputMaybe<Scalars['String']['input']>;
  socialVideoUrl?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
  summaryAi?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Scalars['Upload']['input']>;
  videoUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateReachAndEngagement = {
  __typename?: 'UpdateReachAndEngagement';
  date: Scalars['Date']['output'];
  totalLikes: Scalars['Int']['output'];
  totalQuestions: Scalars['Int']['output'];
  totalSurveyResponses: Scalars['Int']['output'];
  totalViews: Scalars['Int']['output'];
  updates: Array<MediaUpdate>;
};

export type UpdatesEmailDistributionStatistics = {
  __typename?: 'UpdatesEmailDistributionStatistics';
  clicks: Scalars['Int']['output'];
  ctr: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
  openRate: Scalars['Float']['output'];
  opens: Scalars['Int']['output'];
  sends: Scalars['Int']['output'];
  updates: Array<MediaUpdate>;
};

export type UpdatesTypeViewsAndEngagement = {
  __typename?: 'UpdatesTypeViewsAndEngagement';
  includedMediaType: IncludedMediaUpdateType;
  totalEngagement: Scalars['Int']['output'];
  totalViews: Scalars['Int']['output'];
};

export type UpsertDistributionSettingsInput = {
  emailSettings?: InputMaybe<DistributionSettingsEmailInput>;
  /** List of contact id's that should be excluded from this distribution */
  excludedContacts?: InputMaybe<Array<Scalars['ID']['input']>>;
  includedAnnouncementTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  includedUpdateTypes?: InputMaybe<Array<MediaUpdateType>>;
  recipientListType?: InputMaybe<Array<DistributionRecipientListType>>;
  shareholderWelcomeEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  socialPostTemplate?: InputMaybe<Scalars['String']['input']>;
  /** Boolean value of whether the default suggested types were used */
  usedDefaultTypes?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpsertMediaUpdateAttachmentInput = {
  orderId: Scalars['Int']['input'];
  thumbnailUrl?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: MediaUpdateAttachmentType;
  url: Scalars['String']['input'];
};

export type UpsertMediaUpdateInput = {
  attachments?: InputMaybe<Array<InputMaybe<UpsertMediaUpdateAttachmentInput>>>;
  commentContent?: InputMaybe<Scalars['String']['input']>;
  commentUseCompanyAsUsername?: InputMaybe<Scalars['Boolean']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type UtmInvestorSignupConnection = {
  __typename?: 'UtmInvestorSignupConnection';
  edges?: Maybe<Array<Maybe<UtmInvestorSignupEdge>>>;
  options?: Maybe<Options>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
  utmLinkId: Scalars['ID']['output'];
};

export type UtmInvestorSignupConnectionTotalArgs = {
  options?: InputMaybe<OptionsInput>;
  utmLinkId: Scalars['ID']['input'];
};

export type UtmInvestorSignupEdge = {
  __typename?: 'UtmInvestorSignupEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Contact;
};

export type UtmLink = {
  __typename?: 'UtmLink';
  destinationUrl: Scalars['String']['output'];
  hash: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  updatedAt: Scalars['IsoNaiveDatetime']['output'];
  utmCampaign: Scalars['String']['output'];
  utmMedium: Scalars['String']['output'];
  utmSource: Scalars['String']['output'];
  utmUrl: Scalars['String']['output'];
};

export type UtmLinkAnalytics = {
  __typename?: 'UtmLinkAnalytics';
  currPeriodAnalytics: UtmLinkStatsForPeriod;
  prevPeriodAnalytics: UtmLinkStatsForPeriod;
};

export type UtmLinkConnection = {
  __typename?: 'UtmLinkConnection';
  edges?: Maybe<Array<Maybe<UtmLinkEdge>>>;
  options?: Maybe<Options>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type UtmLinkConnectionTotalArgs = {
  options?: InputMaybe<OptionsInput>;
};

export type UtmLinkEdge = {
  __typename?: 'UtmLinkEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<UtmLink>;
  utmLink: UtmLink;
};

export type UtmLinkInput = {
  destinationUrl: Scalars['String']['input'];
  utmCampaign: Scalars['String']['input'];
  utmMedium: Scalars['String']['input'];
  utmSource: Scalars['String']['input'];
  utmUrl: Scalars['String']['input'];
};

export type UtmLinkStatsForPeriod = {
  __typename?: 'UtmLinkStatsForPeriod';
  id: Scalars['ID']['output'];
  totalHits: Scalars['Int']['output'];
  totalHitsList: Array<Maybe<DailyHits>>;
  totalSignUps: Scalars['Int']['output'];
  totalSignUpsList: Array<Maybe<DailyUserSignups>>;
  totalUniqueHits: Scalars['Int']['output'];
  totalUniqueHitsList: Array<Maybe<DailyUniqueHits>>;
};

export type Website = {
  __typename?: 'Website';
  companyProfile?: Maybe<CompanyProfile>;
  companyProfileId?: Maybe<Scalars['ID']['output']>;
  emailPopupModalCloudinaryUrl?: Maybe<Scalars['String']['output']>;
  googleAnalyticsId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  insertedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  isEmailPopupModalEnabled?: Maybe<Scalars['Boolean']['output']>;
  isFullWebsiteReplacement?: Maybe<Scalars['Boolean']['output']>;
  isStickySignupBannerEnabled?: Maybe<Scalars['Boolean']['output']>;
  logoCloudinaryUrl?: Maybe<Scalars['String']['output']>;
  logoSquareCloudinaryUrl?: Maybe<Scalars['String']['output']>;
  navMenuItems?: Maybe<Array<NavMenuItem>>;
  pages?: Maybe<Array<Maybe<Page>>>;
  previewSecret?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
  publishedByCompanyUser?: Maybe<CompanyUser>;
  themeColourAccent?: Maybe<Scalars['String']['output']>;
  themeColourAccentText?: Maybe<Scalars['String']['output']>;
  themeColourPrimary?: Maybe<Scalars['String']['output']>;
  themeColourPrimaryText?: Maybe<Scalars['String']['output']>;
  themeFontBody?: Maybe<Scalars['String']['output']>;
  themeFontTitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['IsoNaiveDatetime']['output']>;
};

export type WelcomePage = {
  __typename?: 'WelcomePage';
  author: Scalars['String']['output'];
  bannerUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  insertedAt: Scalars['IsoNaiveDatetime']['output'];
  linkedinProfileUrl?: Maybe<Scalars['String']['output']>;
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
  publisher: CompanyProfileUser;
  signatureUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['IsoNaiveDatetime']['output'];
  welcomeMessage: Scalars['String']['output'];
};

export type WelcomePageInput = {
  author: Scalars['String']['input'];
  banner?: InputMaybe<Scalars['Upload']['input']>;
  linkedinProfileUrl?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['Upload']['input']>;
  signature?: InputMaybe<Scalars['Upload']['input']>;
  title: Scalars['String']['input'];
  welcomeMessage: Scalars['String']['input'];
};

export type CurrentCompanyDistributionSettingsFieldsFragment = {
  __typename?: 'CurrentCompanyDistributionSettings';
  id: string;
  updatedAt?: string | null;
  email?: {
    __typename?: 'DistributionSettings';
    id?: string | null;
    channel?: DistributionChannelType | null;
    excludedContacts?: Array<string> | null;
    includedAnnouncementTypes?: Array<string> | null;
    includedUpdateTypes?: Array<MediaUpdateType> | null;
    isActive?: boolean | null;
    recipientListType?: Array<DistributionRecipientListType> | null;
    socialPostTemplate?: string | null;
    usedDefaultTypes?: boolean | null;
    updatedAt?: string | null;
    shareholderWelcomeEnabled?: boolean | null;
    emailSettings?: {
      __typename?: 'DistributionSettingsEmail';
      id: string;
      emailHtml: string;
      emailJson: string;
      fromName?: string | null;
      subject?: string | null;
    } | null;
  } | null;
  linkedin?: {
    __typename?: 'DistributionSettings';
    id?: string | null;
    channel?: DistributionChannelType | null;
    excludedContacts?: Array<string> | null;
    includedAnnouncementTypes?: Array<string> | null;
    includedUpdateTypes?: Array<MediaUpdateType> | null;
    isActive?: boolean | null;
    recipientListType?: Array<DistributionRecipientListType> | null;
    socialPostTemplate?: string | null;
    usedDefaultTypes?: boolean | null;
    updatedAt?: string | null;
    shareholderWelcomeEnabled?: boolean | null;
    emailSettings?: {
      __typename?: 'DistributionSettingsEmail';
      id: string;
      emailHtml: string;
      emailJson: string;
      fromName?: string | null;
      subject?: string | null;
    } | null;
  } | null;
  twitter?: {
    __typename?: 'DistributionSettings';
    id?: string | null;
    channel?: DistributionChannelType | null;
    excludedContacts?: Array<string> | null;
    includedAnnouncementTypes?: Array<string> | null;
    includedUpdateTypes?: Array<MediaUpdateType> | null;
    isActive?: boolean | null;
    recipientListType?: Array<DistributionRecipientListType> | null;
    socialPostTemplate?: string | null;
    usedDefaultTypes?: boolean | null;
    updatedAt?: string | null;
    shareholderWelcomeEnabled?: boolean | null;
    emailSettings?: {
      __typename?: 'DistributionSettingsEmail';
      id: string;
      emailHtml: string;
      emailJson: string;
      fromName?: string | null;
      subject?: string | null;
    } | null;
  } | null;
};

export type DistributionSettingsFieldsFragment = {
  __typename?: 'DistributionSettings';
  id?: string | null;
  channel?: DistributionChannelType | null;
  excludedContacts?: Array<string> | null;
  includedAnnouncementTypes?: Array<string> | null;
  includedUpdateTypes?: Array<MediaUpdateType> | null;
  isActive?: boolean | null;
  recipientListType?: Array<DistributionRecipientListType> | null;
  socialPostTemplate?: string | null;
  usedDefaultTypes?: boolean | null;
  updatedAt?: string | null;
  shareholderWelcomeEnabled?: boolean | null;
  emailSettings?: {
    __typename?: 'DistributionSettingsEmail';
    id: string;
    emailHtml: string;
    emailJson: string;
    fromName?: string | null;
    subject?: string | null;
  } | null;
};

export type ActivateCompanyProfileUserMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ActivateCompanyProfileUserMutation = {
  __typename?: 'RootMutationType';
  activateCompanyProfileUser?: {
    __typename?: 'CompanyProfileUser';
    id: string;
    status: CompanyProfileUserStatus;
  } | null;
};

export type ActivateCurrentCompanyDistributionSettingsMutationVariables =
  Exact<{
    flowType: FlowType;
  }>;

export type ActivateCurrentCompanyDistributionSettingsMutation = {
  __typename?: 'RootMutationType';
  activateCurrentCompanyDistributionSettings: {
    __typename?: 'CurrentCompanyDistributionSettings';
    id: string;
    updatedAt?: string | null;
    email?: {
      __typename?: 'DistributionSettings';
      id?: string | null;
      channel?: DistributionChannelType | null;
      excludedContacts?: Array<string> | null;
      includedAnnouncementTypes?: Array<string> | null;
      includedUpdateTypes?: Array<MediaUpdateType> | null;
      isActive?: boolean | null;
      recipientListType?: Array<DistributionRecipientListType> | null;
      socialPostTemplate?: string | null;
      usedDefaultTypes?: boolean | null;
      updatedAt?: string | null;
      shareholderWelcomeEnabled?: boolean | null;
      emailSettings?: {
        __typename?: 'DistributionSettingsEmail';
        id: string;
        emailHtml: string;
        emailJson: string;
        fromName?: string | null;
        subject?: string | null;
      } | null;
    } | null;
    linkedin?: {
      __typename?: 'DistributionSettings';
      id?: string | null;
      channel?: DistributionChannelType | null;
      excludedContacts?: Array<string> | null;
      includedAnnouncementTypes?: Array<string> | null;
      includedUpdateTypes?: Array<MediaUpdateType> | null;
      isActive?: boolean | null;
      recipientListType?: Array<DistributionRecipientListType> | null;
      socialPostTemplate?: string | null;
      usedDefaultTypes?: boolean | null;
      updatedAt?: string | null;
      shareholderWelcomeEnabled?: boolean | null;
      emailSettings?: {
        __typename?: 'DistributionSettingsEmail';
        id: string;
        emailHtml: string;
        emailJson: string;
        fromName?: string | null;
        subject?: string | null;
      } | null;
    } | null;
    twitter?: {
      __typename?: 'DistributionSettings';
      id?: string | null;
      channel?: DistributionChannelType | null;
      excludedContacts?: Array<string> | null;
      includedAnnouncementTypes?: Array<string> | null;
      includedUpdateTypes?: Array<MediaUpdateType> | null;
      isActive?: boolean | null;
      recipientListType?: Array<DistributionRecipientListType> | null;
      socialPostTemplate?: string | null;
      usedDefaultTypes?: boolean | null;
      updatedAt?: string | null;
      shareholderWelcomeEnabled?: boolean | null;
      emailSettings?: {
        __typename?: 'DistributionSettingsEmail';
        id: string;
        emailHtml: string;
        emailJson: string;
        fromName?: string | null;
        subject?: string | null;
      } | null;
    } | null;
  };
};

export type AutoUnblockCloudIpMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
  ticker: Scalars['String']['input'];
  marketKey: Scalars['String']['input'];
}>;

export type AutoUnblockCloudIpMutation = {
  __typename?: 'RootMutationType';
  autoUnblockCloudIp?: boolean | null;
};

export type ChangeCompanyProfileUserRoleMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  role: CompanyProfileUserRole;
}>;

export type ChangeCompanyProfileUserRoleMutation = {
  __typename?: 'RootMutationType';
  changeCompanyProfileUserRole?: {
    __typename?: 'CompanyProfileUser';
    id: string;
  } | null;
};

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type ChangePasswordMutation = {
  __typename?: 'RootMutationType';
  changePassword?: boolean | null;
};

export type ChangeShareholderOfferUsCitzenCheckMutationVariables = Exact<{
  shareholderOfferPageId: Scalars['ID']['input'];
  updatedCheck: Scalars['Boolean']['input'];
}>;

export type ChangeShareholderOfferUsCitzenCheckMutation = {
  __typename?: 'RootMutationType';
  changeShareholderOfferUsCitzenCheck?: {
    __typename?: 'ShareholderOfferPage';
    id: string;
    usCitizenPageEnabled?: boolean | null;
  } | null;
};

export type ConfirmCompanyProfileInformationMutationVariables = Exact<{
  listingKey: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;

export type ConfirmCompanyProfileInformationMutation = {
  __typename?: 'RootMutationType';
  confirmCompanyProfileInformation: {
    __typename?: 'CompanyProfile';
    id: string;
    name: string;
    ticker: { __typename?: 'Ticker'; id: string; listingKey: string };
  };
};

export type ConfirmCompanyUserInformationAndActivateCompanyProfileUserMutationVariables =
  Exact<{
    firstName: Scalars['String']['input'];
    jobTitle: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    mobileNumber?: InputMaybe<Scalars['String']['input']>;
  }>;

export type ConfirmCompanyUserInformationAndActivateCompanyProfileUserMutation =
  {
    __typename?: 'RootMutationType';
    confirmCompanyUserInformationAndActivateCompanyProfileUser?: {
      __typename?: 'CompanyProfileUser';
      id: string;
      jobTitle?: string | null;
      status: CompanyProfileUserStatus;
      user: {
        __typename?: 'CompanyUser';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        mobileNumber?: string | null;
      };
    } | null;
  };

export type ContactUsMutationVariables = Exact<{
  message: Scalars['String']['input'];
  type: Scalars['String']['input'];
}>;

export type ContactUsMutation = {
  __typename?: 'RootMutationType';
  contactUs?: boolean | null;
};

export type CreateContactMutationVariables = Exact<{
  contact: ContactInput;
}>;

export type CreateContactMutation = {
  __typename?: 'RootMutationType';
  createContact: { __typename?: 'Contact'; id: string };
};

export type CreateContactNoteMutationVariables = Exact<{
  contactId: Scalars['ID']['input'];
  contactNote: ContactNoteInput;
}>;

export type CreateContactNoteMutation = {
  __typename?: 'RootMutationType';
  createContactNote: { __typename?: 'ContactNote'; id: string };
};

export type CreateTagMutationVariables = Exact<{
  contactId: Scalars['ID']['input'];
  tag: TagInput;
}>;

export type CreateTagMutation = {
  __typename?: 'RootMutationType';
  createTag: { __typename?: 'Tag'; id: string };
};

export type UpdateContactMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  contact: ContactInput;
}>;

export type UpdateContactMutation = {
  __typename?: 'RootMutationType';
  updateContact: { __typename?: 'Contact'; id: string };
};

export type UpdateContactNoteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  contactNote: ContactNoteInput;
}>;

export type UpdateContactNoteMutation = {
  __typename?: 'RootMutationType';
  updateContactNote: { __typename?: 'ContactNote'; id: string };
};

export type UpdateTagMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  tag: TagInput;
}>;

export type UpdateTagMutation = {
  __typename?: 'RootMutationType';
  updateTag: { __typename?: 'Tag'; id: string; invalidated: boolean };
};

export type CreateAudiencesContactListMutationVariables = Exact<{
  input: DynamicListInput;
}>;

export type CreateAudiencesContactListMutation = {
  __typename?: 'RootMutationType';
  createDynamicList: {
    __typename?: 'DynamicList';
    id: string;
    lastUpdatedAt?: string | null;
    name: string;
    estimatedContactsSize?: number | null;
    description?: string | null;
    lastUpdatedByProfileUser?: {
      __typename?: 'CompanyProfileUser';
      user: {
        __typename?: 'CompanyUser';
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
      };
    } | null;
    lastUsedOnEmail?: {
      __typename?: 'Email';
      id: string;
      campaignName: string;
    } | null;
    filters: Array<{
      __typename?: 'Filter';
      key?: string | null;
      value?: string | null;
    }>;
  };
};

export type CreateBlockMutationVariables = Exact<{
  pageId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
  position: Scalars['Int']['input'];
  content?: InputMaybe<Scalars['Map']['input']>;
}>;

export type CreateBlockMutation = {
  __typename?: 'RootMutationType';
  createBlock: { __typename?: 'Block'; id: string };
};

export type CreateComputershareRegistryCredentialMutationVariables = Exact<{
  credential: ComputershareRegistryCredentialInput;
}>;

export type CreateComputershareRegistryCredentialMutation = {
  __typename?: 'RootMutationType';
  createComputershareRegistryCredential?: boolean | null;
};

export type CreateCustomDomainMutationVariables = Exact<{
  customDomain: Scalars['String']['input'];
}>;

export type CreateCustomDomainMutation = {
  __typename?: 'RootMutationType';
  createCustomDomain?: {
    __typename?: 'CustomDomain';
    id: string;
    customDomain: string;
    sesDkimConfigurations?: Array<{
      __typename?: 'CustomDomainConfiguration';
      configured?: boolean | null;
      name?: string | null;
      value?: string | null;
      type?: string | null;
    } | null> | null;
    sesMailFromConfigurations?: Array<{
      __typename?: 'CustomDomainConfiguration';
      configured?: boolean | null;
      name?: string | null;
      value?: string | null;
      type?: string | null;
    } | null> | null;
    sesDmarcConfigurations?: Array<{
      __typename?: 'CustomDomainConfiguration';
      configured?: boolean | null;
      name?: string | null;
      value?: string | null;
      type?: string | null;
    } | null> | null;
    vercelConfiguration?: {
      __typename?: 'CustomDomainConfiguration';
      configured?: boolean | null;
      name?: string | null;
      value?: string | null;
      type?: string | null;
    } | null;
  } | null;
};

export type CreateDistributionSettingsEmailMutationVariables = Exact<{
  distributionSettingsId?: InputMaybe<Scalars['ID']['input']>;
  distributionSettingsEmail: DistributionSettingsEmailInput;
}>;

export type CreateDistributionSettingsEmailMutation = {
  __typename?: 'RootMutationType';
  createDistributionSettingsEmail?: {
    __typename?: 'DistributionSettingsEmail';
    id: string;
    subject?: string | null;
    fromName?: string | null;
    emailJson: string;
    emailHtml: string;
  } | null;
};

export type CreateDistributionSettingsForFlowTypeMutationVariables = Exact<{
  flowType: FlowType;
  distributionSettings: DistributionSettingsCreateInput;
}>;

export type CreateDistributionSettingsForFlowTypeMutation = {
  __typename?: 'RootMutationType';
  createDistributionSettingsForFlowType?: {
    __typename?: 'DistributionSettings';
    id?: string | null;
    channel?: DistributionChannelType | null;
    isActive?: boolean | null;
    shareholderWelcomeEnabled?: boolean | null;
  } | null;
};

export type CreateEmailMutationVariables = Exact<{
  campaignName: Scalars['String']['input'];
  mediaId?: InputMaybe<Scalars['ID']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateEmailMutation = {
  __typename?: 'RootMutationType';
  createEmail: { __typename?: 'Email'; id: string };
};

export type CreateMediaCommentFromOtherSourceMutationVariables = Exact<{
  content: Scalars['String']['input'];
  commentSource: CommentSource;
  commentSourceUrl?: InputMaybe<Scalars['String']['input']>;
  mediaId: Scalars['ID']['input'];
  replyContent?: InputMaybe<Scalars['String']['input']>;
  replyUseCompanyAsUsername?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CreateMediaCommentFromOtherSourceMutation = {
  __typename?: 'RootMutationType';
  createMediaCommentFromOtherSource?: {
    __typename?: 'MediaComment';
    id: string;
    commentSource?: CommentSource | null;
    commentSourceUrl?: string | null;
    media?: {
      __typename?: 'Media';
      mediaAnnouncement?: {
        __typename?: 'MediaAnnouncement';
        id: string;
      } | null;
      mediaUpdate?: { __typename?: 'MediaUpdate'; slug: string } | null;
    } | null;
  } | null;
};

export type CreateMediaCommentMutationVariables = Exact<{
  annotationMetadata?: InputMaybe<MediaCommentAnnotationMetadataInput>;
  content: Scalars['String']['input'];
  mediaId: Scalars['ID']['input'];
  useCompanyAsUsername?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CreateMediaCommentMutation = {
  __typename?: 'RootMutationType';
  createMediaComment?: { __typename?: 'MediaComment'; id: string } | null;
};

export type CreateNavMenuItemMutationVariables = Exact<{
  label: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  pageId?: InputMaybe<Scalars['ID']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CreateNavMenuItemMutation = {
  __typename?: 'RootMutationType';
  createNavMenuItem: {
    __typename?: 'NavMenuItem';
    id: string;
    label?: string | null;
    url?: string | null;
    position?: number | null;
    openInNewTab?: boolean | null;
    page?: {
      __typename?: 'Page';
      id: string;
      slug?: string | null;
      title?: string | null;
    } | null;
    parent?: {
      __typename?: 'NavMenuItem';
      id: string;
      label?: string | null;
    } | null;
  };
};

export type CreateNewMediaUpdateMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateNewMediaUpdateMutation = {
  __typename?: 'RootMutationType';
  createNewMediaUpdate?: {
    __typename?: 'MediaUpdate';
    id: string;
    slug: string;
  } | null;
};

export type CreatePageMutationVariables = Exact<{
  title: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  preset?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreatePageMutation = {
  __typename?: 'RootMutationType';
  createPage: {
    __typename?: 'Page';
    id: string;
    title?: string | null;
    slug?: string | null;
  };
};

export type CreatePreparedAnnouncementMutationVariables = Exact<{
  title: Scalars['String']['input'];
}>;

export type CreatePreparedAnnouncementMutation = {
  __typename?: 'RootMutationType';
  createPreparedAnnouncement?: {
    __typename?: 'PreparedAnnouncement';
    id: string;
  } | null;
};

export type CreateShareholderOfferPageFaqMutationVariables = Exact<{
  faq: FaqInput;
  shareholderOfferId: Scalars['ID']['input'];
}>;

export type CreateShareholderOfferPageFaqMutation = {
  __typename?: 'RootMutationType';
  createShareholderOfferPageFaq?: {
    __typename?: 'Faq';
    id?: string | null;
  } | null;
};

export type CreateShareholderOfferPageInstructionMutationVariables = Exact<{
  instruction: InstructionInput;
  shareholderOfferId: Scalars['ID']['input'];
}>;

export type CreateShareholderOfferPageInstructionMutation = {
  __typename?: 'RootMutationType';
  createShareholderOfferPageInstruction?: {
    __typename?: 'Instruction';
    id: string;
  } | null;
};

export type CreateShareholderOfferPrivateViewerMutationVariables = Exact<{
  email: Scalars['String']['input'];
  shareholderOfferId: Scalars['ID']['input'];
}>;

export type CreateShareholderOfferPrivateViewerMutation = {
  __typename?: 'RootMutationType';
  createShareholderOfferPrivateViewer?: {
    __typename?: 'PrivateViewer';
    id: string;
  } | null;
};

export type CreateShareholderOfferMutationVariables = Exact<{
  shareholderOffer: ShareholderOfferInput;
}>;

export type CreateShareholderOfferMutation = {
  __typename?: 'RootMutationType';
  createShareholderOffer?: {
    __typename?: 'ShareholderOffer';
    id: string;
    title: string;
    type: ShareholderOfferType;
    isLive: boolean;
    scheduledAt?: string | null;
    insertedAt: string;
    updatedAt: string;
    companyProfile: {
      __typename?: 'CompanyProfile';
      id: string;
      registry?: Registries | null;
    };
    lastEditedByUser: {
      __typename?: 'CompanyUser';
      email: string;
      firstName?: string | null;
      lastName?: string | null;
    };
  } | null;
};

export type CreateUtmLinkMutationVariables = Exact<{
  utmLink: UtmLinkInput;
}>;

export type CreateUtmLinkMutation = {
  __typename?: 'RootMutationType';
  createUtmLink?: {
    __typename?: 'UtmLink';
    id: string;
    destinationUrl: string;
    utmCampaign: string;
    utmMedium: string;
    utmSource: string;
    utmUrl: string;
    insertedAt: string;
    updatedAt: string;
  } | null;
};

export type DeactivateCurrentCompanyDistributionSettingsMutationVariables =
  Exact<{
    flowType: FlowType;
  }>;

export type DeactivateCurrentCompanyDistributionSettingsMutation = {
  __typename?: 'RootMutationType';
  deactivateCurrentCompanyDistributionSettings: {
    __typename?: 'CurrentCompanyDistributionSettings';
    id: string;
    updatedAt?: string | null;
    email?: {
      __typename?: 'DistributionSettings';
      id?: string | null;
      channel?: DistributionChannelType | null;
      excludedContacts?: Array<string> | null;
      includedAnnouncementTypes?: Array<string> | null;
      includedUpdateTypes?: Array<MediaUpdateType> | null;
      isActive?: boolean | null;
      recipientListType?: Array<DistributionRecipientListType> | null;
      socialPostTemplate?: string | null;
      usedDefaultTypes?: boolean | null;
      updatedAt?: string | null;
      shareholderWelcomeEnabled?: boolean | null;
      emailSettings?: {
        __typename?: 'DistributionSettingsEmail';
        id: string;
        emailHtml: string;
        emailJson: string;
        fromName?: string | null;
        subject?: string | null;
      } | null;
    } | null;
    linkedin?: {
      __typename?: 'DistributionSettings';
      id?: string | null;
      channel?: DistributionChannelType | null;
      excludedContacts?: Array<string> | null;
      includedAnnouncementTypes?: Array<string> | null;
      includedUpdateTypes?: Array<MediaUpdateType> | null;
      isActive?: boolean | null;
      recipientListType?: Array<DistributionRecipientListType> | null;
      socialPostTemplate?: string | null;
      usedDefaultTypes?: boolean | null;
      updatedAt?: string | null;
      shareholderWelcomeEnabled?: boolean | null;
      emailSettings?: {
        __typename?: 'DistributionSettingsEmail';
        id: string;
        emailHtml: string;
        emailJson: string;
        fromName?: string | null;
        subject?: string | null;
      } | null;
    } | null;
    twitter?: {
      __typename?: 'DistributionSettings';
      id?: string | null;
      channel?: DistributionChannelType | null;
      excludedContacts?: Array<string> | null;
      includedAnnouncementTypes?: Array<string> | null;
      includedUpdateTypes?: Array<MediaUpdateType> | null;
      isActive?: boolean | null;
      recipientListType?: Array<DistributionRecipientListType> | null;
      socialPostTemplate?: string | null;
      usedDefaultTypes?: boolean | null;
      updatedAt?: string | null;
      shareholderWelcomeEnabled?: boolean | null;
      emailSettings?: {
        __typename?: 'DistributionSettingsEmail';
        id: string;
        emailHtml: string;
        emailJson: string;
        fromName?: string | null;
        subject?: string | null;
      } | null;
    } | null;
  };
};

export type DeleteBlockMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteBlockMutation = {
  __typename?: 'RootMutationType';
  deleteBlock: { __typename?: 'Block'; id: string };
};

export type DeleteDraftEmailMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteDraftEmailMutation = {
  __typename?: 'RootMutationType';
  deleteDraftEmail: boolean;
};

export type DeleteDynamicListMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteDynamicListMutation = {
  __typename?: 'RootMutationType';
  deleteDynamicList: boolean;
};

export type DeleteNavMenuItemMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteNavMenuItemMutation = {
  __typename?: 'RootMutationType';
  deleteNavMenuItem: { __typename?: 'NavMenuItem'; id: string };
};

export type DeletePageMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeletePageMutation = {
  __typename?: 'RootMutationType';
  deletePage: { __typename?: 'Page'; id: string };
};

export type DeletePreparedAnnouncementMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeletePreparedAnnouncementMutation = {
  __typename?: 'RootMutationType';
  deletePreparedAnnouncement?: boolean | null;
};

export type DeleteShareholderOfferPageFaqMutationVariables = Exact<{
  faqId: Scalars['ID']['input'];
}>;

export type DeleteShareholderOfferPageFaqMutation = {
  __typename?: 'RootMutationType';
  deleteShareholderOfferPageFaq?: {
    __typename?: 'Faq';
    id?: string | null;
  } | null;
};

export type DeleteShareholderOfferPageInstructionMutationVariables = Exact<{
  instructionId: Scalars['ID']['input'];
}>;

export type DeleteShareholderOfferPageInstructionMutation = {
  __typename?: 'RootMutationType';
  deleteShareholderOfferPageInstruction?: {
    __typename?: 'Instruction';
    id: string;
  } | null;
};

export type DeleteShareholderOfferPrivateViewerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteShareholderOfferPrivateViewerMutation = {
  __typename?: 'RootMutationType';
  deleteShareholderOfferPrivateViewer?: boolean | null;
};

export type DeleteShareholderOfferMutationVariables = Exact<{
  shareholderOfferId: Scalars['ID']['input'];
}>;

export type DeleteShareholderOfferMutation = {
  __typename?: 'RootMutationType';
  deleteShareholderOffer?: {
    __typename?: 'ShareholderOffer';
    id: string;
    title: string;
    type: ShareholderOfferType;
    isLive: boolean;
    scheduledAt?: string | null;
    insertedAt: string;
    updatedAt: string;
    companyProfile: {
      __typename?: 'CompanyProfile';
      id: string;
      registry?: Registries | null;
    };
    lastEditedByUser: {
      __typename?: 'CompanyUser';
      email: string;
      firstName?: string | null;
      lastName?: string | null;
    };
  } | null;
};

export type DeleteUtmLinkMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteUtmLinkMutation = {
  __typename?: 'RootMutationType';
  deleteUtmLink?: boolean | null;
};

export type DisconnectSocialConnectionMutationVariables = Exact<{
  socialPlatform: SocialPlatform;
}>;

export type DisconnectSocialConnectionMutation = {
  __typename?: 'RootMutationType';
  disconnectSocialConnection?: {
    __typename?: 'SocialConnection';
    id: string;
    linkedinConnected?: boolean | null;
    linkedinSetupCompleted?: boolean | null;
    linkedinOrganisationName?: string | null;
    twitterSetupCompleted?: boolean | null;
    twitterUsername?: string | null;
  } | null;
};

export type DuplicateEmailAndEmailRecipientsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DuplicateEmailAndEmailRecipientsMutation = {
  __typename?: 'RootMutationType';
  duplicateEmailAndEmailRecipients: { __typename?: 'Email'; id: string };
};

export type GenerateAssetSignedUrlMutationVariables = Exact<{
  mimeType: Scalars['String']['input'];
}>;

export type GenerateAssetSignedUrlMutation = {
  __typename?: 'RootMutationType';
  generateAssetSignedUrl?: string | null;
};

export type GenerateCorporatePageSectionAttachmentFileSignedUrlMutationVariables =
  Exact<{
    fileSize: Scalars['Int']['input'];
    mimeType: Scalars['String']['input'];
  }>;

export type GenerateCorporatePageSectionAttachmentFileSignedUrlMutation = {
  __typename?: 'RootMutationType';
  generateCorporatePageSectionAttachmentFileSignedUrl: string;
};

export type GenerateLinkedinThumbnailSignedUrlMutationVariables = Exact<{
  mimeType: Scalars['String']['input'];
}>;

export type GenerateLinkedinThumbnailSignedUrlMutation = {
  __typename?: 'RootMutationType';
  generateLinkedinThumbnailSignedUrl: string;
};

export type GenerateMediaAnnouncementAiSummaryMutationVariables = Exact<{
  mediaAnnouncementId: Scalars['ID']['input'];
}>;

export type GenerateMediaAnnouncementAiSummaryMutation = {
  __typename?: 'RootMutationType';
  generateMediaAnnouncementAiSummary: string;
};

export type GenerateMediaAnnouncementVideoSignedUrlMutationVariables = Exact<{
  fileSize: Scalars['Int']['input'];
  mediaAnnouncementId: Scalars['ID']['input'];
  mimeType: Scalars['String']['input'];
}>;

export type GenerateMediaAnnouncementVideoSignedUrlMutation = {
  __typename?: 'RootMutationType';
  generateMediaAnnouncementVideoSignedUrl: string;
};

export type GenerateMediaUpdateAttachmentSignedUrlMutationVariables = Exact<{
  mimeType: Scalars['String']['input'];
}>;

export type GenerateMediaUpdateAttachmentSignedUrlMutation = {
  __typename?: 'RootMutationType';
  generateMediaUpdateAttachmentSignedUrl: string;
};

export type GeneratePastPlacementParticipantListSignedUrlMutationVariables =
  Exact<{
    mimeType: Scalars['String']['input'];
    pastPlacementId: Scalars['ID']['input'];
  }>;

export type GeneratePastPlacementParticipantListSignedUrlMutation = {
  __typename?: 'RootMutationType';
  generatePastPlacementParticipantListSignedUrl: string;
};

export type GeneratePreparedAnnouncementVideoSignedUrlMutationVariables =
  Exact<{
    fileSize: Scalars['Int']['input'];
    preparedAnnouncementId: Scalars['ID']['input'];
    mimeType: Scalars['String']['input'];
  }>;

export type GeneratePreparedAnnouncementVideoSignedUrlMutation = {
  __typename?: 'RootMutationType';
  generatePreparedAnnouncementVideoSignedUrl: string;
};

export type GenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutationVariables =
  Exact<{
    fileSize: Scalars['Int']['input'];
    shareholderOfferId: Scalars['ID']['input'];
    mimeType: Scalars['String']['input'];
  }>;

export type GenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutation =
  {
    __typename?: 'RootMutationType';
    generateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrl: string;
  };

export type InvalidateCompanyProfileUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type InvalidateCompanyProfileUserMutation = {
  __typename?: 'RootMutationType';
  invalidateCompanyProfileUser?: {
    __typename?: 'CompanyProfileUser';
    id: string;
  } | null;
};

export type InvalidateMediaCommentMutationVariables = Exact<{
  mediaCommentId: Scalars['ID']['input'];
}>;

export type InvalidateMediaCommentMutation = {
  __typename?: 'RootMutationType';
  invalidateMediaComment?: { __typename?: 'MediaComment'; id: string } | null;
};

export type InvalidateMediaMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type InvalidateMediaMutation = {
  __typename?: 'RootMutationType';
  invalidateMedia?: { __typename?: 'Media'; id: string } | null;
};

export type InvalidatePastPlacementParticipantMutationVariables = Exact<{
  participantId: Scalars['ID']['input'];
}>;

export type InvalidatePastPlacementParticipantMutation = {
  __typename?: 'RootMutationType';
  invalidatePastPlacementParticipant?: {
    __typename?: 'PastPlacementParticipant';
    id: string;
  } | null;
};

export type LinkPreparedAnnouncementToAnnouncementMutationVariables = Exact<{
  preparedAnnouncementId: Scalars['ID']['input'];
  announcementMediaId: Scalars['ID']['input'];
}>;

export type LinkPreparedAnnouncementToAnnouncementMutation = {
  __typename?: 'RootMutationType';
  linkPreparedAnnouncementToAnnouncement?: {
    __typename?: 'MediaAnnouncement';
    id: string;
  } | null;
};

export type LinkContactWithInvestorMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  investorUserId: Scalars['ID']['input'];
}>;

export type LinkContactWithInvestorMutation = {
  __typename?: 'RootMutationType';
  linkContactWithInvestor: { __typename?: 'Contact'; id: string };
};

export type LinkContactWithShareholdingsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  shareholdingIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type LinkContactWithShareholdingsMutation = {
  __typename?: 'RootMutationType';
  linkContactWithShareholdings: { __typename?: 'Contact'; id: string };
};

export type MarkFeatureAsOnboardedMutationVariables = Exact<{
  featureName: Scalars['String']['input'];
}>;

export type MarkFeatureAsOnboardedMutation = {
  __typename?: 'RootMutationType';
  markFeatureAsOnboarded?: {
    __typename?: 'FeatureOnboarding';
    id: string;
    featureName: string;
    onboardedAt: string;
    userId: string;
  } | null;
};

export type MarkInvestorCertificateAsRejectedMutationVariables = Exact<{
  certificateId: Scalars['ID']['input'];
  rejectionComment?: InputMaybe<Scalars['String']['input']>;
}>;

export type MarkInvestorCertificateAsRejectedMutation = {
  __typename?: 'RootMutationType';
  markInvestorCertificateAsRejected: {
    __typename?: 'InvestorCertificate';
    id: string;
    certificateUrl: string;
    insertedAt: string;
    status: InvestorCertificateStatus;
    investorUser: {
      __typename?: 'InvestorUser';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      username?: string | null;
      email: string;
      isHoldingVerified: boolean;
    };
  };
};

export type MarkInvestorCertificateAsVerifiedMutationVariables = Exact<{
  certificateId: Scalars['ID']['input'];
  expiresAt?: InputMaybe<Scalars['IsoNaiveDatetime']['input']>;
  type: InvestorCertificateType;
}>;

export type MarkInvestorCertificateAsVerifiedMutation = {
  __typename?: 'RootMutationType';
  markInvestorCertificateAsVerified: {
    __typename?: 'InvestorCertificate';
    id: string;
    certificateUrl: string;
    insertedAt: string;
    status: InvestorCertificateStatus;
    investorUser: {
      __typename?: 'InvestorUser';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      username?: string | null;
      email: string;
      isHoldingVerified: boolean;
    };
  };
};

export type PublishMediaUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PublishMediaUpdateMutation = {
  __typename?: 'RootMutationType';
  publishMediaUpdate?: {
    __typename?: 'MediaUpdate';
    id: string;
    isDraft: boolean;
    postedAt?: string | null;
    postedBy?: {
      __typename?: 'CompanyUser';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
  } | null;
};

export type PublishWebsiteMutationVariables = Exact<{ [key: string]: never }>;

export type PublishWebsiteMutation = {
  __typename?: 'RootMutationType';
  publishWebsite: boolean;
};

export type PublishWelcomePageMutationVariables = Exact<{
  welcomePage: WelcomePageInput;
}>;

export type PublishWelcomePageMutation = {
  __typename?: 'RootMutationType';
  publishWelcomePage?: { __typename?: 'WelcomePage'; id: string } | null;
};

export type RemoveCustomDomainMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RemoveCustomDomainMutation = {
  __typename?: 'RootMutationType';
  removeCustomDomain?: {
    __typename?: 'CustomDomain';
    id: string;
    customDomain: string;
    vercelConfiguration?: {
      __typename?: 'CustomDomainConfiguration';
      configured?: boolean | null;
      name?: string | null;
      value?: string | null;
      type?: string | null;
    } | null;
  } | null;
};

export type RemoveCustomEmailsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RemoveCustomEmailsMutation = {
  __typename?: 'RootMutationType';
  removeCustomEmails?: boolean | null;
};

export type ReplyToMediaCommentMutationVariables = Exact<{
  content: Scalars['String']['input'];
  parentId: Scalars['ID']['input'];
  useCompanyAsUsername?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ReplyToMediaCommentMutation = {
  __typename?: 'RootMutationType';
  replyToMediaComment?: { __typename?: 'MediaComment'; id: string } | null;
};

export type RequestExternalPlacementDemandEstimateMutationVariables = Exact<{
  comments?: InputMaybe<Scalars['String']['input']>;
}>;

export type RequestExternalPlacementDemandEstimateMutation = {
  __typename?: 'RootMutationType';
  requestExternalPlacementDemandEstimate: {
    __typename?: 'PlacementDemandExternalEstimate';
    id: string;
  };
};

export type ResendInvitationTokenMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ResendInvitationTokenMutation = {
  __typename?: 'RootMutationType';
  resendInvitationToken?: boolean | null;
};

export type ResetMediaAnnouncementMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ResetMediaAnnouncementMutation = {
  __typename?: 'RootMutationType';
  resetMediaAnnouncement?: {
    __typename?: 'MediaAnnouncement';
    id: string;
  } | null;
};

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;

export type ResetPasswordMutation = {
  __typename?: 'RootMutationType';
  resetPassword?: boolean | null;
};

export type SendEmailMutationVariables = Exact<{
  emailId: Scalars['ID']['input'];
}>;

export type SendEmailMutation = {
  __typename?: 'RootMutationType';
  sendEmail: { __typename?: 'Email'; id: string; sentAt?: string | null };
};

export type SendManualSocialPostMutationVariables = Exact<{
  mediaId: Scalars['ID']['input'];
  socialPlatform: SocialPlatform;
  text: Scalars['String']['input'];
  linkedinThumbailUrl?: InputMaybe<Scalars['String']['input']>;
}>;

export type SendManualSocialPostMutation = {
  __typename?: 'RootMutationType';
  sendManualSocialPost?: {
    __typename?: 'DistributedSocial';
    id: string;
    linkedinPostId?: string | null;
    linkedinPostUrl?: string | null;
    twitterPostId?: string | null;
    twitterPostUrl?: string | null;
  } | null;
};

export type SendResetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type SendResetPasswordMutation = {
  __typename?: 'RootMutationType';
  sendResetPassword?: boolean | null;
};

export type SendTestEmailDomainConnectionEmailMutationVariables = Exact<{
  emailAddress: Scalars['String']['input'];
}>;

export type SendTestEmailDomainConnectionEmailMutation = {
  __typename?: 'RootMutationType';
  sendTestEmailDomainConnectionEmail?: boolean | null;
};

export type SendTestEmailMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  recipientEmails:
    | Array<Scalars['String']['input']>
    | Scalars['String']['input'];
  emailHtml?: InputMaybe<Scalars['String']['input']>;
}>;

export type SendTestEmailMutation = {
  __typename?: 'RootMutationType';
  sendTestEmail: boolean;
};

export type InviteAndCreateCompanyProfileUsersMutationVariables = Exact<{
  inviteCompanyProfileUsers?: InputMaybe<
    | Array<InputMaybe<InviteCompanyProfileUserInput>>
    | InputMaybe<InviteCompanyProfileUserInput>
  >;
}>;

export type InviteAndCreateCompanyProfileUsersMutation = {
  __typename?: 'RootMutationType';
  inviteAndCreateCompanyProfileUsers?: Array<{
    __typename?: 'InviteAndCreateCompanyProfileUserResult';
    error?: {
      __typename?: 'InviteAndCreateCompanyProfileUserErrorResult';
      email?: string | null;
      reason?: string | null;
    } | null;
    success?: {
      __typename?: 'CompanyProfileUser';
      id: string;
      status: CompanyProfileUserStatus;
      user: { __typename?: 'CompanyUser'; email: string };
    } | null;
  } | null> | null;
};

export type SetPasswordFromInvitationTokenMutationVariables = Exact<{
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;

export type SetPasswordFromInvitationTokenMutation = {
  __typename?: 'RootMutationType';
  setPasswordFromInvitationToken?: boolean | null;
};

export type SortBlocksMutationVariables = Exact<{
  pageId: Scalars['ID']['input'];
  blockIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type SortBlocksMutation = {
  __typename?: 'RootMutationType';
  sortBlocks: boolean;
};

export type SortNavMenuItemsWithChildrenMutationVariables = Exact<{
  navMenuItems: Array<NavMenuItemOrderInput> | NavMenuItemOrderInput;
}>;

export type SortNavMenuItemsWithChildrenMutation = {
  __typename?: 'RootMutationType';
  sortNavMenuItemsWithChildren: boolean;
};

export type SortNavMenuItemsMutationVariables = Exact<{
  parentId?: InputMaybe<Scalars['ID']['input']>;
  navMenuItemIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type SortNavMenuItemsMutation = {
  __typename?: 'RootMutationType';
  sortNavMenuItems: boolean;
};

export type SubmitPlacementDemandQuestionMutationVariables = Exact<{
  comments?: InputMaybe<Scalars['String']['input']>;
  generalTopic: PlacementDemandQuestionGeneralTopic;
  specificTopics?: InputMaybe<
    | Array<InputMaybe<PlacementDemandQuestionSpecificTopic>>
    | InputMaybe<PlacementDemandQuestionSpecificTopic>
  >;
}>;

export type SubmitPlacementDemandQuestionMutation = {
  __typename?: 'RootMutationType';
  submitPlacementDemandQuestion: {
    __typename?: 'PlacementDemandQuestion';
    id: string;
  };
};

export type ToggleMediaCommentPrivacyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  private: Scalars['Boolean']['input'];
}>;

export type ToggleMediaCommentPrivacyMutation = {
  __typename?: 'RootMutationType';
  toggleMediaCommentPrivacy?: boolean | null;
};

export type UnlinkContactWithInvestorMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  investorUserId: Scalars['ID']['input'];
}>;

export type UnlinkContactWithInvestorMutation = {
  __typename?: 'RootMutationType';
  unlinkContactWithInvestor: { __typename?: 'Contact'; id: string };
};

export type UnlinkContactWithShareholdingMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  shareholdingId: Scalars['ID']['input'];
}>;

export type UnlinkContactWithShareholdingMutation = {
  __typename?: 'RootMutationType';
  unlinkContactWithShareholding: { __typename?: 'Contact'; id: string };
};

export type UpdateAudiencesContactListMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: DynamicListInput;
}>;

export type UpdateAudiencesContactListMutation = {
  __typename?: 'RootMutationType';
  updateDynamicList: {
    __typename?: 'DynamicList';
    id: string;
    lastUpdatedAt?: string | null;
    name: string;
    estimatedContactsSize?: number | null;
    description?: string | null;
    lastUpdatedByProfileUser?: {
      __typename?: 'CompanyProfileUser';
      user: {
        __typename?: 'CompanyUser';
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
      };
    } | null;
    lastUsedOnEmail?: {
      __typename?: 'Email';
      id: string;
      campaignName: string;
    } | null;
    filters: Array<{
      __typename?: 'Filter';
      key?: string | null;
      value?: string | null;
    }>;
  };
};

export type UpdateBlockMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  content: Scalars['Map']['input'];
}>;

export type UpdateBlockMutation = {
  __typename?: 'RootMutationType';
  updateBlock: { __typename?: 'Block'; id: string; content?: unknown | null };
};

export type UpdateCompanyInfomationMutationVariables = Exact<{
  profile: CompanyProfileUpdateInput;
}>;

export type UpdateCompanyInfomationMutation = {
  __typename?: 'RootMutationType';
  updateCompanyInfomation: {
    __typename?: 'CompanyProfile';
    id: string;
    defaultPublicInteractionsEnabled: boolean;
    aiSummariesEnabled: boolean;
    email?: string | null;
    hidePrivateQuestionsFromCount: boolean;
    investorCentreEnabled?: boolean | null;
    logoUrl: string;
    name: string;
    mobileNumber?: string | null;
    ticker: { __typename?: 'Ticker'; id: string; listingKey: string };
  };
};

export type UpdateCompanyInvestorHubMutationVariables = Exact<{
  profile: CompanyProfileUpdateInput;
}>;

export type UpdateCompanyInvestorHubMutation = {
  __typename?: 'RootMutationType';
  updateCompanyInvestorHub: {
    __typename?: 'CompanyProfile';
    id: string;
    email?: string | null;
    investorCentreEnabled?: boolean | null;
    name: string;
    mobileNumber?: string | null;
    investorHub: {
      __typename?: 'InvestorHub';
      id: string;
      email?: string | null;
      facebook?: string | null;
      instagram?: string | null;
      linkedin?: string | null;
      phone?: string | null;
      registry?: InvestorHubRegisters | null;
      twitter?: string | null;
      website?: string | null;
      colourPrimary: string;
      colourPrimaryText: string;
      colourAccent: string;
      colourAccentText: string;
    };
  };
};

export type UpdateCompanyUserAndCompanyProfileUserInforamtionMutationVariables =
  Exact<{
    firstName: Scalars['String']['input'];
    jobTitle: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    mobileNumber?: InputMaybe<Scalars['String']['input']>;
  }>;

export type UpdateCompanyUserAndCompanyProfileUserInforamtionMutation = {
  __typename?: 'RootMutationType';
  updateCompanyUserAndCompanyProfileUserInforamtion?: {
    __typename?: 'CompanyProfileUser';
    id: string;
    jobTitle?: string | null;
    user: {
      __typename?: 'CompanyUser';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      mobileNumber?: string | null;
    };
  } | null;
};

export type UpdateContactLeadStatusMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  leadStatus: LeadStatusUpdateOption;
}>;

export type UpdateContactLeadStatusMutation = {
  __typename?: 'RootMutationType';
  updateContactLeadStatus: {
    __typename?: 'Contact';
    id: string;
    shareholderStatus?: ShareholderStatus | null;
  };
};

export type UpdateEmailAndEmailRecipientsMutationVariables = Exact<{
  emailId: Scalars['ID']['input'];
  email: EmailInput;
}>;

export type UpdateEmailAndEmailRecipientsMutation = {
  __typename?: 'RootMutationType';
  updateEmailAndEmailRecipients: { __typename?: 'Email'; id: string };
};

export type UpdateEmailScheduleMutationVariables = Exact<{
  emailId: Scalars['ID']['input'];
  email: EmailInput;
}>;

export type UpdateEmailScheduleMutation = {
  __typename?: 'RootMutationType';
  updateEmailSchedule: { __typename?: 'Email'; id: string };
};

export type UpdateMediaAnnouncementMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateMediaAnnouncementInput;
}>;

export type UpdateMediaAnnouncementMutation = {
  __typename?: 'RootMutationType';
  updateMediaAnnouncement?: {
    __typename?: 'MediaAnnouncement';
    id: string;
    featuredOnHub: boolean;
    socialVideoUrl?: string | null;
    summary?: string | null;
    summaryAi?: string | null;
    videoUrl?: string | null;
    germanTranslatedVideoUrl?: string | null;
    germanTranslatedUrl?: string | null;
    germanTranslatedHeader?: string | null;
    germanTranslatedSummary?: string | null;
  } | null;
};

export type UpdateMediaCommentDoneMutationVariables = Exact<{
  mediaCommentId: Scalars['ID']['input'];
  done: Scalars['Boolean']['input'];
}>;

export type UpdateMediaCommentDoneMutation = {
  __typename?: 'RootMutationType';
  updateMediaCommentDone?: {
    __typename?: 'MediaComment';
    id: string;
    done: boolean;
  } | null;
};

export type UpdateMediaCommentReplyMutationVariables = Exact<{
  mediaCommentId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
  useCompanyAsUsername?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateMediaCommentReplyMutation = {
  __typename?: 'RootMutationType';
  updateMediaCommentReply?: {
    __typename?: 'MediaComment';
    id: string;
    content: string;
    useCompanyAsUsername: boolean;
  } | null;
};

export type UpdateMediaUpdatePostedAtMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  postedAt: Scalars['IsoNaiveDatetime']['input'];
}>;

export type UpdateMediaUpdatePostedAtMutation = {
  __typename?: 'RootMutationType';
  updateMediaUpdatePostedAt?: {
    __typename?: 'MediaUpdate';
    id: string;
    slug: string;
    title: string;
    includedTypes?: Array<MediaUpdateType> | null;
    isPinned?: boolean | null;
    attachments: Array<{
      __typename?: 'MediaUpdateAttachment';
      id: string;
      thumbnailUrl?: string | null;
      title?: string | null;
      type: MediaUpdateAttachmentType;
      url: string;
    }>;
    content?: {
      __typename?: 'MediaUpdateContent';
      id: string;
      content: string;
    } | null;
    lastUpdatedBy: {
      __typename?: 'CompanyUser';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    };
  } | null;
};

export type UpdateMediaUpdateTitleMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
}>;

export type UpdateMediaUpdateTitleMutation = {
  __typename?: 'RootMutationType';
  updateMediaUpdateTitle?: {
    __typename?: 'MediaUpdate';
    id: string;
    slug: string;
    title: string;
    includedTypes?: Array<MediaUpdateType> | null;
    isPinned?: boolean | null;
    attachments: Array<{
      __typename?: 'MediaUpdateAttachment';
      id: string;
      thumbnailUrl?: string | null;
      title?: string | null;
      type: MediaUpdateAttachmentType;
      url: string;
    }>;
    content?: {
      __typename?: 'MediaUpdateContent';
      id: string;
      content: string;
    } | null;
    lastUpdatedBy: {
      __typename?: 'CompanyUser';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    };
  } | null;
};

export type UpdateNavMenuItemMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  pageId?: InputMaybe<Scalars['ID']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type UpdateNavMenuItemMutation = {
  __typename?: 'RootMutationType';
  updateNavMenuItem: {
    __typename?: 'NavMenuItem';
    id: string;
    label?: string | null;
    url?: string | null;
    openInNewTab?: boolean | null;
    page?: {
      __typename?: 'Page';
      id: string;
      slug?: string | null;
      title?: string | null;
    } | null;
    parent?: {
      __typename?: 'NavMenuItem';
      id: string;
      label?: string | null;
    } | null;
  };
};

export type UpdatePageMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  socialImageCloudinaryPublicId?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdatePageMutation = {
  __typename?: 'RootMutationType';
  updatePage: {
    __typename?: 'Page';
    id: string;
    title?: string | null;
    slug?: string | null;
    metaDescription?: string | null;
    socialImageCloudinaryPublicId?: string | null;
  };
};

export type UpdatePinnedMediaUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  value: Scalars['Boolean']['input'];
}>;

export type UpdatePinnedMediaUpdateMutation = {
  __typename?: 'RootMutationType';
  updatePinnedMediaUpdate?: {
    __typename?: 'MediaUpdate';
    id: string;
    slug: string;
    title: string;
    includedTypes?: Array<MediaUpdateType> | null;
    isPinned?: boolean | null;
    attachments: Array<{
      __typename?: 'MediaUpdateAttachment';
      id: string;
      thumbnailUrl?: string | null;
      title?: string | null;
      type: MediaUpdateAttachmentType;
      url: string;
    }>;
    content?: {
      __typename?: 'MediaUpdateContent';
      id: string;
      content: string;
    } | null;
    lastUpdatedBy: {
      __typename?: 'CompanyUser';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    };
  } | null;
};

export type UpdatePreparedAnnouncementMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  preparedAnnouncement?: InputMaybe<PreparedAnnouncementInput>;
}>;

export type UpdatePreparedAnnouncementMutation = {
  __typename?: 'RootMutationType';
  updatePreparedAnnouncement?: {
    __typename?: 'PreparedAnnouncement';
    id: string;
    title?: string | null;
    socialVideoUrl?: string | null;
    videoUrl?: string | null;
    summary?: string | null;
    isDraft: boolean;
    commentContent?: string | null;
    commentUseCompanyAsUsername?: boolean | null;
    mediaId?: string | null;
  } | null;
};

export type UpdateShareholderOfferStatusMutationVariables = Exact<{
  isLive: Scalars['Boolean']['input'];
  scheduledAt?: InputMaybe<Scalars['IsoNaiveDatetime']['input']>;
  shareholderOfferId: Scalars['ID']['input'];
}>;

export type UpdateShareholderOfferStatusMutation = {
  __typename?: 'RootMutationType';
  updateShareholderOfferStatus?: {
    __typename?: 'ShareholderOffer';
    id: string;
    title: string;
    type: ShareholderOfferType;
    isLive: boolean;
    scheduledAt?: string | null;
    insertedAt: string;
    updatedAt: string;
    companyProfile: {
      __typename?: 'CompanyProfile';
      id: string;
      registry?: Registries | null;
    };
    lastEditedByUser: {
      __typename?: 'CompanyUser';
      email: string;
      firstName?: string | null;
      lastName?: string | null;
    };
  } | null;
};

export type UpdateShareholderOfferTitleMutationVariables = Exact<{
  title: Scalars['String']['input'];
  shareholderOfferId: Scalars['ID']['input'];
}>;

export type UpdateShareholderOfferTitleMutation = {
  __typename?: 'RootMutationType';
  updateShareholderOfferTitle?: {
    __typename?: 'ShareholderOffer';
    id: string;
    title: string;
    type: ShareholderOfferType;
    isLive: boolean;
    scheduledAt?: string | null;
    insertedAt: string;
    updatedAt: string;
    companyProfile: {
      __typename?: 'CompanyProfile';
      id: string;
      registry?: Registries | null;
    };
    lastEditedByUser: {
      __typename?: 'CompanyUser';
      email: string;
      firstName?: string | null;
      lastName?: string | null;
    };
  } | null;
};

export type UpdateUtmLinkMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  updatedUtmAttrs: UtmLinkInput;
}>;

export type UpdateUtmLinkMutation = {
  __typename?: 'RootMutationType';
  updateUtmLink?: {
    __typename?: 'UtmLink';
    id: string;
    hash: string;
    destinationUrl: string;
    utmCampaign: string;
    utmMedium: string;
    utmSource: string;
    utmUrl: string;
    insertedAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateSocialConnectionMutationVariables = Exact<{
  socialConnection: SocialConnectionInput;
}>;

export type UpdateSocialConnectionMutation = {
  __typename?: 'RootMutationType';
  updateSocialConnection?: {
    __typename?: 'SocialConnection';
    id: string;
    linkedinConnected?: boolean | null;
    linkedinSetupCompleted?: boolean | null;
    linkedinOrganisationName?: string | null;
    twitterSetupCompleted?: boolean | null;
    twitterUsername?: string | null;
  } | null;
};

export type UpsertContactUnsubscribesMutationVariables = Exact<{
  contactId: Scalars['ID']['input'];
  globalUnsubscribe: Scalars['Boolean']['input'];
  scopes: Array<ContactUnsubscribeScopeType> | ContactUnsubscribeScopeType;
}>;

export type UpsertContactUnsubscribesMutation = {
  __typename?: 'RootMutationType';
  upsertContactUnsubscribes: {
    __typename?: 'Contact';
    id: string;
    commsUnsubscribes: Array<{
      __typename?: 'ContactUnsubscribe';
      id: string;
      scope: ContactUnsubscribeScopeType;
    }>;
    globalUnsubscribe?: {
      __typename?: 'ContactGlobalUnsubscribe';
      id: string;
    } | null;
  };
};

export type UpsertCorporatePageMutationVariables = Exact<{
  corporatePage: CorporatePageInput;
}>;

export type UpsertCorporatePageMutation = {
  __typename?: 'RootMutationType';
  upsertCorporatePage?: {
    __typename?: 'CorporatePage';
    id: string;
    title: string;
    type: CorporatePageType;
    isLive: boolean;
    insertedAt: string;
    updatedAt: string;
    slug: string;
    lastEditedByUser: {
      __typename?: 'CompanyUser';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    };
    sections: Array<{
      __typename?: 'Section';
      id?: string | null;
      heading: string;
      description?: string | null;
      attachments: Array<{
        __typename?: 'Attachment';
        id?: string | null;
        orderId: number;
        heading?: string | null;
        subheading?: string | null;
        description?: string | null;
        fileUrl?: string | null;
      }>;
    }>;
  } | null;
};

export type UpsertCustomContactsMutationVariables = Exact<{
  customContacts: Array<ContactInput> | ContactInput;
  audienceTags: Array<Scalars['String']['input']> | Scalars['String']['input'];
  clientAnswerListSource?: InputMaybe<Scalars['String']['input']>;
  clientAnswerLastUsage?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpsertCustomContactsMutation = {
  __typename?: 'RootMutationType';
  upsertCustomContacts: unknown;
};

export type UpsertCustomEmailsMutationVariables = Exact<{
  customEmails: Array<CustomEmailInput> | CustomEmailInput;
}>;

export type UpsertCustomEmailsMutation = {
  __typename?: 'RootMutationType';
  upsertCustomEmails?: boolean | null;
};

export type UpsertDistributionSettingsMutationVariables = Exact<{
  flowType: FlowType;
  email?: InputMaybe<UpsertDistributionSettingsInput>;
  linkedin?: InputMaybe<UpsertDistributionSettingsInput>;
  twitter?: InputMaybe<UpsertDistributionSettingsInput>;
}>;

export type UpsertDistributionSettingsMutation = {
  __typename?: 'RootMutationType';
  upsertDistributionSettings: {
    __typename?: 'CurrentCompanyDistributionSettings';
    id: string;
    updatedAt?: string | null;
    email?: {
      __typename?: 'DistributionSettings';
      id?: string | null;
      channel?: DistributionChannelType | null;
      excludedContacts?: Array<string> | null;
      includedAnnouncementTypes?: Array<string> | null;
      includedUpdateTypes?: Array<MediaUpdateType> | null;
      isActive?: boolean | null;
      recipientListType?: Array<DistributionRecipientListType> | null;
      socialPostTemplate?: string | null;
      usedDefaultTypes?: boolean | null;
      updatedAt?: string | null;
      shareholderWelcomeEnabled?: boolean | null;
      emailSettings?: {
        __typename?: 'DistributionSettingsEmail';
        id: string;
        emailHtml: string;
        emailJson: string;
        fromName?: string | null;
        subject?: string | null;
      } | null;
    } | null;
    linkedin?: {
      __typename?: 'DistributionSettings';
      id?: string | null;
      channel?: DistributionChannelType | null;
      excludedContacts?: Array<string> | null;
      includedAnnouncementTypes?: Array<string> | null;
      includedUpdateTypes?: Array<MediaUpdateType> | null;
      isActive?: boolean | null;
      recipientListType?: Array<DistributionRecipientListType> | null;
      socialPostTemplate?: string | null;
      usedDefaultTypes?: boolean | null;
      updatedAt?: string | null;
      shareholderWelcomeEnabled?: boolean | null;
      emailSettings?: {
        __typename?: 'DistributionSettingsEmail';
        id: string;
        emailHtml: string;
        emailJson: string;
        fromName?: string | null;
        subject?: string | null;
      } | null;
    } | null;
    twitter?: {
      __typename?: 'DistributionSettings';
      id?: string | null;
      channel?: DistributionChannelType | null;
      excludedContacts?: Array<string> | null;
      includedAnnouncementTypes?: Array<string> | null;
      includedUpdateTypes?: Array<MediaUpdateType> | null;
      isActive?: boolean | null;
      recipientListType?: Array<DistributionRecipientListType> | null;
      socialPostTemplate?: string | null;
      usedDefaultTypes?: boolean | null;
      updatedAt?: string | null;
      shareholderWelcomeEnabled?: boolean | null;
      emailSettings?: {
        __typename?: 'DistributionSettingsEmail';
        id: string;
        emailHtml: string;
        emailJson: string;
        fromName?: string | null;
        subject?: string | null;
      } | null;
    } | null;
  };
};

export type UpsertMediaCommentReadMutationVariables = Exact<{
  mediaCommentId: Scalars['ID']['input'];
  read: Scalars['Boolean']['input'];
}>;

export type UpsertMediaCommentReadMutation = {
  __typename?: 'RootMutationType';
  upsertMediaCommentRead?: {
    __typename?: 'MediaCommentRead';
    id: string;
    read: boolean;
  } | null;
};

export type UpsertMediaCommentStarMutationVariables = Exact<{
  mediaCommentId: Scalars['ID']['input'];
  starred: Scalars['Boolean']['input'];
}>;

export type UpsertMediaCommentStarMutation = {
  __typename?: 'RootMutationType';
  upsertMediaCommentStar?: {
    __typename?: 'MediaCommentStar';
    id: string;
    starred: boolean;
  } | null;
};

export type UpsertMediaUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  mediaUpdate: UpsertMediaUpdateInput;
}>;

export type UpsertMediaUpdateMutation = {
  __typename?: 'RootMutationType';
  upsertMediaUpdate?: {
    __typename?: 'MediaUpdate';
    id: string;
    content?: {
      __typename?: 'MediaUpdateContent';
      id: string;
      content: string;
      commentContent?: string | null;
      commentUseCompanyAsUsername?: boolean | null;
    } | null;
    attachments: Array<{
      __typename?: 'MediaUpdateAttachment';
      id: string;
      orderId: number;
      type: MediaUpdateAttachmentType;
      title?: string | null;
      thumbnailUrl?: string | null;
      url: string;
    }>;
  } | null;
};

export type UpsertNotificationPreferenceMutationVariables = Exact<{
  notificationPreference: NotificationPreferenceInput;
}>;

export type UpsertNotificationPreferenceMutation = {
  __typename?: 'RootMutationType';
  upsertNotificationPreference?: boolean | null;
};

export type UpsertPastPlacementParticipantListMutationVariables = Exact<{
  pastPlacementId: Scalars['ID']['input'];
  participantList: Scalars['Upload']['input'];
}>;

export type UpsertPastPlacementParticipantListMutation = {
  __typename?: 'RootMutationType';
  upsertPastPlacementParticipantList: Array<{
    __typename?: 'PastPlacementParticipant';
    id: string;
  }>;
};

export type UpsertShareholderOfferPageMutationVariables = Exact<{
  faqs?: InputMaybe<Array<FaqInput> | FaqInput>;
  instructions?: InputMaybe<Array<InstructionInput> | InstructionInput>;
  shareholderOfferId: Scalars['ID']['input'];
  shareholderOfferPage: ShareholderOfferPageInput;
}>;

export type UpsertShareholderOfferPageMutation = {
  __typename?: 'RootMutationType';
  upsertShareholderOfferPage?: {
    __typename?: 'ShareholderOfferPage';
    id: string;
  } | null;
};

export type UpsertBaseEmailTemplateMutationVariables = Exact<{
  emailHtml: Scalars['String']['input'];
  emailJson: Scalars['String']['input'];
  templateType?: InputMaybe<BaseEmailTemplateType>;
}>;

export type UpsertBaseEmailTemplateMutation = {
  __typename?: 'RootMutationType';
  upsertBaseEmailTemplate: {
    __typename?: 'BaseEmailTemplate';
    emailHtml?: string | null;
    emailJson: string;
    updatedAt: string;
    lastEditedProfileUser?: {
      __typename?: 'CompanyProfileUser';
      id: string;
      user: {
        __typename?: 'CompanyUser';
        firstName?: string | null;
        lastName?: string | null;
      };
    } | null;
  };
};

export type ValidatePastPlacementParticipantMutationVariables = Exact<{
  participantId: Scalars['ID']['input'];
}>;

export type ValidatePastPlacementParticipantMutation = {
  __typename?: 'RootMutationType';
  validatePastPlacementParticipant?: {
    __typename?: 'PastPlacementParticipant';
    id: string;
  } | null;
};

export type VerifyCustomDomainV2MutationVariables = Exact<{
  [key: string]: never;
}>;

export type VerifyCustomDomainV2Mutation = {
  __typename?: 'RootMutationType';
  verifyCustomDomainV2?: {
    __typename?: 'CustomDomainVerification';
    isDmarcVerified: boolean;
    isDkimVerified: boolean;
    isVercelDomainVerified: boolean;
    isMailfromVerified: boolean;
  } | null;
};

export type ActiveDistributionFlowsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ActiveDistributionFlowsQuery = {
  __typename?: 'RootQueryType';
  activeDistributionFlows?: {
    __typename?: 'ActiveDistributionFlows';
    id: string;
    announcements: boolean;
    updates: boolean;
    newShareholderWelcome: boolean;
  } | null;
};

export type AllBrokersQueryVariables = Exact<{ [key: string]: never }>;

export type AllBrokersQuery = {
  __typename?: 'RootQueryType';
  allBrokers?: Array<{
    __typename?: 'Broker';
    id: string;
    name: string;
    pids: Array<string | null>;
    nameShort: string;
    shareholdingsCount?: number | null;
  } | null> | null;
};

export type AllContactsQueryVariables = Exact<{
  options?: InputMaybe<OptionsInput>;
}>;

export type AllContactsQuery = {
  __typename?: 'RootQueryType';
  allContacts: Array<{
    __typename?: 'Contact';
    id: string;
    insertedAt: string;
    email: string;
    firstName?: string | null;
    importedAt?: string | null;
    lastName?: string | null;
    investor?: { __typename?: 'InvestorUser'; id: string } | null;
    commsUnsubscribes: Array<{
      __typename?: 'ContactUnsubscribe';
      id: string;
      scope: ContactUnsubscribeScopeType;
    }>;
    globalUnsubscribe?: {
      __typename?: 'ContactGlobalUnsubscribe';
      id: string;
    } | null;
    shareholdings: Array<{
      __typename?: 'Shareholding';
      id: string;
      shareCount?: number | null;
    }>;
    tags: Array<{ __typename?: 'Tag'; id: string; name: string }>;
  }>;
};

export type AllCurrentCompanyInvestorUsersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AllCurrentCompanyInvestorUsersQuery = {
  __typename?: 'RootQueryType';
  allCurrentCompanyInvestorUsers: Array<{
    __typename?: 'InvestorUser';
    id: string;
    email: string;
    firstName?: string | null;
    isHoldingVerified: boolean;
    lastName?: string | null;
    username?: string | null;
    certificate?: {
      __typename?: 'InvestorCertificate';
      id: string;
      status: InvestorCertificateStatus;
      isExpired: boolean;
    } | null;
    notificationPreferences: Array<{
      __typename?: 'InvestorNotificationPreference';
      id: string;
      channel?: InvestorNotificationChannel | null;
      hasEoi?: boolean | null;
      isOn?: boolean | null;
      scope?: InvestorNotificationScope | null;
    }>;
    shareholderInformation?: {
      __typename?: 'InvestorShareholderInformation';
      id: string;
      country: string;
      partialHin: string;
      postcode?: string | null;
    } | null;
  }>;
};

export type AllPastPlacementParticipantsQueryVariables = Exact<{
  options?: InputMaybe<OptionsInput>;
  pastPlacementId: Scalars['ID']['input'];
}>;

export type AllPastPlacementParticipantsQuery = {
  __typename?: 'RootQueryType';
  allPastPlacementParticipants: Array<{
    __typename?: 'PastPlacementParticipant';
    id: string;
    allocationShares?: number | null;
    invalidated: boolean;
    oneMonthAfterSettlementShares?: number | null;
    oneWeekAfterSettlementShares?: number | null;
    settlementShares?: number | null;
    threeMonthsAfterSettlementShares?: number | null;
    trancheType: PastPlacementParticipantTrancheType;
    shareholding: {
      __typename?: 'Shareholding';
      id: string;
      accountName: string;
    };
  }>;
};

export type AmplifyInvestorsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchPhrase: Scalars['String']['input'];
  searchTags?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type AmplifyInvestorsQuery = {
  __typename?: 'RootQueryType';
  amplifyInvestors?: {
    __typename?: 'AmplifyInvestorConnection';
    total: number;
    edges?: Array<{
      __typename?: 'AmplifyInvestorEdge';
      node?: {
        __typename?: 'AmplifyInvestor';
        id: string;
        type: string;
        contact?: {
          __typename?: 'Contact';
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          investor?: {
            __typename?: 'InvestorUser';
            id: string;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
            username?: string | null;
          } | null;
          shareholdings: Array<{
            __typename?: 'Shareholding';
            id: string;
            accountName: string;
            email?: string | null;
          }>;
          tags: Array<{ __typename?: 'Tag'; id: string; name: string }>;
        } | null;
        shareholding?: {
          __typename?: 'Shareholding';
          id: string;
          accountName: string;
          email?: string | null;
        } | null;
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type AnnouncementsListQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
}>;

export type AnnouncementsListQuery = {
  __typename?: 'RootQueryType';
  announcementsList?: {
    __typename?: 'AnnouncementListConnection';
    total: number;
    edges?: Array<{
      __typename?: 'AnnouncementListEdge';
      node?: {
        __typename?: 'AnnouncementList';
        mediaAnnouncement?: {
          __typename?: 'MediaAnnouncement';
          id: string;
          featuredOnHub: boolean;
          header: string;
          likes: number;
          listingKey: string;
          marketKey: string;
          mediaId: string;
          postedAt: string;
          socialVideoUrl?: string | null;
          summary?: string | null;
          summaryAi?: string | null;
          totalActiveQuestionCount: number;
          totalCompanyCommentCount: number;
          totalQuestionCount: number;
          totalSurveyResponses: number;
          totalViewCount: number;
          videoUrl?: string | null;
          germanTranslatedVideoUrl?: string | null;
          germanTranslatedUrl?: string | null;
          germanTranslatedHeader?: string | null;
          germanTranslatedSummary?: string | null;
        } | null;
        preparedAnnouncement?: {
          __typename?: 'PreparedAnnouncement';
          id: string;
          commentContent?: string | null;
          commentUseCompanyAsUsername?: boolean | null;
          isDraft: boolean;
          mediaId?: string | null;
          socialVideoUrl?: string | null;
          summary?: string | null;
          title?: string | null;
          videoUrl?: string | null;
          insertedAt: string;
          updatedAt: string;
        } | null;
      } | null;
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type AudienceListQueryVariables = Exact<{ [key: string]: never }>;

export type AudienceListQuery = {
  __typename?: 'RootQueryType';
  audienceList: {
    __typename?: 'AudienceList';
    shareholder?: {
      __typename?: 'Audience';
      count: number;
      lastUpdatedAt?: string | null;
    } | null;
    hub?: {
      __typename?: 'Audience';
      count: number;
      lastUpdatedAt?: string | null;
    } | null;
    imported?: {
      __typename?: 'Audience';
      count: number;
      lastUpdatedAt?: string | null;
    } | null;
    unsubscribed: {
      __typename?: 'UnsubscribedAudience';
      count: number;
      lastUpdatedAt?: string | null;
      shareholder?: {
        __typename?: 'Audience';
        count: number;
        lastUpdatedAt?: string | null;
      } | null;
      hub?: {
        __typename?: 'Audience';
        count: number;
        lastUpdatedAt?: string | null;
      } | null;
      imported?: {
        __typename?: 'Audience';
        count: number;
        lastUpdatedAt?: string | null;
      } | null;
    };
  };
};

export type AudiencesContactListQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AudiencesContactListQuery = {
  __typename?: 'RootQueryType';
  dynamicList?: {
    __typename?: 'DynamicList';
    id: string;
    lastUpdatedAt?: string | null;
    name: string;
    estimatedContactsSize?: number | null;
    description?: string | null;
    insertedAt: string;
    updatedAt: string;
    lastUpdatedByProfileUser?: {
      __typename?: 'CompanyProfileUser';
      user: {
        __typename?: 'CompanyUser';
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
      };
    } | null;
    lastUsedOnEmail?: {
      __typename?: 'Email';
      id: string;
      campaignName: string;
    } | null;
    filters: Array<{
      __typename?: 'Filter';
      key?: string | null;
      value?: string | null;
    }>;
    companyProfile: { __typename?: 'CompanyProfile'; id: string };
  } | null;
};

export type AudiencesContactListsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
}>;

export type AudiencesContactListsQuery = {
  __typename?: 'RootQueryType';
  dynamicLists?: {
    __typename?: 'DynamicListConnection';
    total: number;
    edges?: Array<{
      __typename?: 'DynamicListEdge';
      node?: {
        __typename?: 'DynamicList';
        id: string;
        lastUpdatedAt?: string | null;
        name: string;
        estimatedContactsSize?: number | null;
        description?: string | null;
        insertedAt: string;
        updatedAt: string;
        lastUpdatedByProfileUser?: {
          __typename?: 'CompanyProfileUser';
          user: {
            __typename?: 'CompanyUser';
            id: string;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
          };
        } | null;
        lastUsedOnEmail?: {
          __typename?: 'Email';
          id: string;
          campaignName: string;
        } | null;
        filters: Array<{
          __typename?: 'Filter';
          key?: string | null;
          value?: string | null;
        }>;
        companyProfile: { __typename?: 'CompanyProfile'; id: string };
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type AutomationStatsQueryVariables = Exact<{ [key: string]: never }>;

export type AutomationStatsQuery = {
  __typename?: 'RootQueryType';
  automationStats?: {
    __typename?: 'AutomationStats';
    id: string;
    shareholderWelcomeEmailSentCount?: number | null;
  } | null;
};

export type BenchmarkAnalyticsQueryVariables = Exact<{
  startDate: Scalars['NaiveDateTime']['input'];
  endDate: Scalars['NaiveDateTime']['input'];
}>;

export type BenchmarkAnalyticsQuery = {
  __typename?: 'RootQueryType';
  benchmarkAnalytics: {
    __typename?: 'BenchmarkAnalytics';
    id: string;
    currentCompanyStats: {
      __typename?: 'BenchmarkStats';
      id: string;
      hubActionsCount: number;
      hubActionsTopFive: boolean;
      likesCount: number;
      nominatedShareholdersCount: number;
      questionsCount: number;
      signupsCount: number;
      signupsTopFive: boolean;
      uniqueVisitorsCount: number;
      viewsCount: number;
      viewsTopFive: boolean;
    };
    peerCompaniesStats: {
      __typename?: 'BenchmarkStats';
      id: string;
      hubActionsCount: number;
      hubActionsTopFive: boolean;
      likesCount: number;
      nominatedShareholdersCount: number;
      questionsCount: number;
      signupsCount: number;
      signupsTopFive: boolean;
      uniqueVisitorsCount: number;
      viewsCount: number;
      viewsTopFive: boolean;
    };
  };
};

export type BlockQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type BlockQuery = {
  __typename?: 'RootQueryType';
  block?: {
    __typename?: 'Block';
    id: string;
    type?: string | null;
    content?: unknown | null;
    position?: number | null;
    insertedAt?: string | null;
    updatedAt?: string | null;
    lastEditedByCompanyUser?: {
      __typename?: 'CompanyUser';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  } | null;
};

export type BoardReportV2GenerateQueryVariables = Exact<{
  endDate: Scalars['Date']['input'];
}>;

export type BoardReportV2GenerateQuery = {
  __typename?: 'RootQueryType';
  generateBoardReport: boolean;
};

export type BoardReportV2getLastestDailyHoldingDateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type BoardReportV2getLastestDailyHoldingDateQuery = {
  __typename?: 'RootQueryType';
  getLastestDailyHoldingDate: string;
};

export type BoardReportsQueryVariables = Exact<{ [key: string]: never }>;

export type BoardReportsQuery = {
  __typename?: 'RootQueryType';
  boardReports?: Array<{
    __typename?: 'BoardReport';
    id: string;
    endDate: string;
    link: string;
    pdfUrl?: string | null;
  }> | null;
};

export type BrokerBreakdownQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;

export type BrokerBreakdownQuery = {
  __typename?: 'RootQueryType';
  brokerBreakdown: Array<{
    __typename?: 'BrokerBreakdown';
    id: string;
    name: string;
    nameShort: string;
    netMovements: number;
    pids: Array<string>;
    shareholdersCount: number;
    totalShares: number;
  }>;
};

export type CampaignChannelShareholdingsByActivityTypeQueryVariables = Exact<{
  channel: Scalars['String']['input'];
  shareholderActivityType: ShareholderActivityType;
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;

export type CampaignChannelShareholdingsByActivityTypeQuery = {
  __typename?: 'RootQueryType';
  campaignChannelShareholdingsByActivityType: Array<{
    __typename?: 'Shareholding';
    id: string;
    accountName: string;
    email?: string | null;
    shareCount?: number | null;
    addressLineOne?: string | null;
    addressLineTwo?: string | null;
    addressCity?: string | null;
    addressState?: string | null;
    addressPostcode?: string | null;
    addressCountry?: string | null;
    movementCount?: number | null;
  }>;
};

export type CampaignChannelShareholdingsQueryVariables = Exact<{
  channel: Scalars['String']['input'];
}>;

export type CampaignChannelShareholdingsQuery = {
  __typename?: 'RootQueryType';
  campaignChannelShareholdings: Array<{
    __typename?: 'Shareholding';
    id: string;
    accountName: string;
    email?: string | null;
    shareCount?: number | null;
    addressLineOne?: string | null;
    addressLineTwo?: string | null;
    addressCity?: string | null;
    addressState?: string | null;
    addressPostcode?: string | null;
    addressCountry?: string | null;
    movementCount?: number | null;
  }>;
};

export type CertificateByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CertificateByIdQuery = {
  __typename?: 'RootQueryType';
  certificateById: {
    __typename?: 'CertificateById';
    nextPendingId?: string | null;
    totalPending: number;
    certificate: {
      __typename?: 'InvestorCertificate';
      id: string;
      certificateUrl: string;
      insertedAt: string;
      status: InvestorCertificateStatus;
      type?: InvestorCertificateType | null;
      isExpired: boolean;
      investorUser: {
        __typename?: 'InvestorUser';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        username?: string | null;
        email: string;
        isHoldingVerified: boolean;
        contact?: { __typename?: 'Contact'; id: string } | null;
        notificationPreferences: Array<{
          __typename?: 'InvestorNotificationPreference';
          id: string;
          channel?: InvestorNotificationChannel | null;
          hasEoi?: boolean | null;
          isOn?: boolean | null;
          scope?: InvestorNotificationScope | null;
        }>;
      };
    };
  };
};

export type CheckDynamicListNameTakenQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type CheckDynamicListNameTakenQuery = {
  __typename?: 'RootQueryType';
  checkDynamicListNameTaken: boolean;
};

export type CheckDynamicListSafeToDeleteQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CheckDynamicListSafeToDeleteQuery = {
  __typename?: 'RootQueryType';
  checkDynamicListSafeToDelete?: {
    __typename?: 'CheckDynamicListSafeToDeleteResponse';
    id: string;
    distributionSettings: Array<{
      __typename?: 'DistributionSettings';
      id?: string | null;
      includedAnnouncementTypes?: Array<string> | null;
      includedUpdateTypes?: Array<MediaUpdateType> | null;
    }>;
    draftEmails: Array<{
      __typename?: 'Email';
      id: string;
      campaignName: string;
      media?: {
        __typename?: 'Media';
        id: string;
        mediaAnnouncement?: {
          __typename?: 'MediaAnnouncement';
          id: string;
        } | null;
        mediaUpdate?: { __typename?: 'MediaUpdate'; id: string } | null;
      } | null;
    }>;
  } | null;
};

export type CompanyProfileUserFromInvitationTokenQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type CompanyProfileUserFromInvitationTokenQuery = {
  __typename?: 'RootQueryType';
  companyProfileUserFromInvitationToken?: {
    __typename?: 'CompanyProfileUser';
    id: string;
    jobTitle?: string | null;
    role: CompanyProfileUserRole;
    status: CompanyProfileUserStatus;
    expired?: boolean | null;
    profile: {
      __typename?: 'CompanyProfile';
      id: string;
      name: string;
      informationConfirmedAt?: string | null;
      ticker: {
        __typename?: 'Ticker';
        id: string;
        listingKey: string;
        marketListingKey: string;
      };
    };
    user: {
      __typename?: 'CompanyUser';
      id: string;
      email: string;
      firstName?: string | null;
      hasPassword: boolean;
      informationConfirmedAt?: string | null;
      lastName?: string | null;
    };
  } | null;
};

export type CompanyProfileUsersQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyProfileUsersQuery = {
  __typename?: 'RootQueryType';
  companyProfileUsers: Array<{
    __typename?: 'CompanyProfileUser';
    id: string;
    jobTitle?: string | null;
    role: CompanyProfileUserRole;
    status: CompanyProfileUserStatus;
    user: {
      __typename?: 'CompanyUser';
      id: string;
      confirmedAt?: string | null;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
    };
  }>;
};

export type ContactQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ContactQuery = {
  __typename?: 'RootQueryType';
  contact?: {
    __typename?: 'Contact';
    id: string;
    insertedAt: string;
    addressCity?: string | null;
    addressCountry?: string | null;
    addressLineOne?: string | null;
    addressLineTwo?: string | null;
    addressPostcode?: string | null;
    addressState?: string | null;
    email: string;
    firstName?: string | null;
    hnwIdentifiedAt?: string | null;
    hnwStatus?: ContactHnwStatuses | null;
    isNominatedShareholder?: boolean | null;
    nominatedShareholderIdentifiedAt?: string | null;
    lastName?: string | null;
    phoneNumber?: string | null;
    shareholderStatus?: ShareholderStatus | null;
    creatorName?: string | null;
    suppression?: {
      __typename?: 'ContactSuppression';
      id: string;
      insertedAt: string;
      reason: ContactSuppressionReasons;
      source: ContactSuppressionSources;
    } | null;
    creatorUser?: {
      __typename?: 'CompanyUser';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
    investor?: {
      __typename?: 'InvestorUser';
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      username?: string | null;
      hnwIdentifiedAt?: string | null;
      hnwStatus?: InvestorHnwStatuses | null;
      isSelfNominatedShareholder?: boolean | null;
      selfNominatedShareholderIdentifiedAt?: string | null;
      certificate?: { __typename?: 'InvestorCertificate'; id: string } | null;
      shareholderInformation?: {
        __typename?: 'InvestorShareholderInformation';
        id: string;
        country: string;
        partialHin: string;
        postcode?: string | null;
      } | null;
      shareholderInformationUk?: {
        __typename?: 'InvestorShareholderInformationUk';
        id: string;
        accountName: string;
        broker: string;
        sharesOwned?: number | null;
        postcode?: string | null;
      } | null;
    } | null;
    shareholdings: Array<{
      __typename?: 'Shareholding';
      id: string;
      accountName: string;
      addressCity?: string | null;
      biggestMovement?: number | null;
      brokerNameShort?: string | null;
      brokerPid?: string | null;
      holderIdMasked?: string | null;
      currentHoldingStartDate?: string | null;
      email?: string | null;
      estimatedProfitLoss?: number | null;
      estimatedTotalPurchaseValue?: number | null;
      estimatedTotalSaleValue?: number | null;
      hasParticipatedInSpp?: boolean | null;
      hasParticipatedInPlacement?: boolean | null;
      hnwIdentifiedAt?: string | null;
      hnwBehaviour?: HnwBehaviours | null;
      initialPurchaseDate?: string | null;
      movementCount?: number | null;
      phoneNumber?: string | null;
      shareCount?: number | null;
      shareCountRank?: number | null;
    }>;
    commsUnsubscribes: Array<{
      __typename?: 'ContactUnsubscribe';
      id: string;
      scope: ContactUnsubscribeScopeType;
    }>;
    globalUnsubscribe?: {
      __typename?: 'ContactGlobalUnsubscribe';
      id: string;
    } | null;
    tags: Array<{ __typename?: 'Tag'; id: string; name: string }>;
  } | null;
};

export type ContactActivitiesQueryVariables = Exact<{
  contactId: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
}>;

export type ContactActivitiesQuery = {
  __typename?: 'RootQueryType';
  contactActivities?: {
    __typename?: 'ContactActivityConnection';
    total: number;
    edges?: Array<{
      __typename?: 'ContactActivityEdge';
      node?: {
        __typename?: 'ContactActivity';
        id: string;
        metadata: unknown;
        timestamp: string;
        type: string;
        companyUser?: unknown | null;
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type ExistingTagsQueryVariables = Exact<{ [key: string]: never }>;

export type ExistingTagsQuery = {
  __typename?: 'RootQueryType';
  existingTags: Array<{ __typename?: 'Tag'; id: string; name: string }>;
};

export type ContactShareholdingSummaryQueryVariables = Exact<{
  contactId: Scalars['ID']['input'];
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
}>;

export type ContactShareholdingSummaryQuery = {
  __typename?: 'RootQueryType';
  contactShareholdingSummary?: {
    __typename?: 'ContactShareholdingSummary';
    id: string;
    announcements: Array<{
      __typename?: 'MediaAnnouncement';
      id: string;
      header: string;
      postedAt: string;
    } | null>;
    dailyHoldings: Array<{
      __typename?: 'DailyHolding';
      id: string;
      date: string;
      balance: number;
    } | null>;
    shareMovements: Array<{
      __typename?: 'ShareMovement';
      id: string;
      closingBalance: number;
      movement: number;
      movementType?: string | null;
      openingBalance: number;
      settledAt: string;
    } | null>;
    timeseries: Array<{
      __typename?: 'Timeseries';
      id: string;
      close?: number | null;
      open?: number | null;
      high?: number | null;
      low?: number | null;
      date: string;
      volume?: number | null;
    } | null>;
  } | null;
};

export type ContactsWithShareholdingsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
}>;

export type ContactsWithShareholdingsQuery = {
  __typename?: 'RootQueryType';
  contacts?: {
    __typename?: 'ContactConnection';
    total: number;
    edges?: Array<{
      __typename?: 'ContactEdge';
      node?: {
        __typename?: 'Contact';
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        shareholdings: Array<{
          __typename?: 'Shareholding';
          id: string;
          email?: string | null;
          accountName: string;
          shareCountRank?: number | null;
        }>;
        investor?: { __typename?: 'InvestorUser'; id: string } | null;
      } | null;
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type ContactsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
}>;

export type ContactsQuery = {
  __typename?: 'RootQueryType';
  contacts?: {
    __typename?: 'ContactConnection';
    total: number;
    edges?: Array<{
      __typename?: 'ContactEdge';
      node?: {
        __typename?: 'Contact';
        id: string;
        insertedAt: string;
        importedAt?: string | null;
        hnwStatus?: ContactHnwStatuses | null;
        hnwIdentifiedAt?: string | null;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        shareholderStatus?: ShareholderStatus | null;
        commsUnsubscribes: Array<{
          __typename?: 'ContactUnsubscribe';
          id: string;
          scope: ContactUnsubscribeScopeType;
        }>;
        globalUnsubscribe?: {
          __typename?: 'ContactGlobalUnsubscribe';
          id: string;
        } | null;
        investor?: {
          __typename?: 'InvestorUser';
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          username?: string | null;
          insertedAt?: string | null;
        } | null;
        suppression?: {
          __typename?: 'ContactSuppression';
          id: string;
          insertedAt: string;
          reason: ContactSuppressionReasons;
          source: ContactSuppressionSources;
        } | null;
        shareholdings: Array<{
          __typename?: 'Shareholding';
          id: string;
          accountName: string;
        }>;
        tags: Array<{ __typename?: 'Tag'; id: string; name: string }>;
      } | null;
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type CurrentCompanyDistributionSettingsQueryVariables = Exact<{
  flowType: FlowType;
}>;

export type CurrentCompanyDistributionSettingsQuery = {
  __typename?: 'RootQueryType';
  currentCompanyDistributionSettings: {
    __typename?: 'CurrentCompanyDistributionSettings';
    id: string;
    updatedAt?: string | null;
    email?: {
      __typename?: 'DistributionSettings';
      id?: string | null;
      channel?: DistributionChannelType | null;
      excludedContacts?: Array<string> | null;
      includedAnnouncementTypes?: Array<string> | null;
      includedUpdateTypes?: Array<MediaUpdateType> | null;
      isActive?: boolean | null;
      recipientListType?: Array<DistributionRecipientListType> | null;
      socialPostTemplate?: string | null;
      usedDefaultTypes?: boolean | null;
      updatedAt?: string | null;
      shareholderWelcomeEnabled?: boolean | null;
      emailSettings?: {
        __typename?: 'DistributionSettingsEmail';
        id: string;
        emailHtml: string;
        emailJson: string;
        fromName?: string | null;
        subject?: string | null;
      } | null;
    } | null;
    linkedin?: {
      __typename?: 'DistributionSettings';
      id?: string | null;
      channel?: DistributionChannelType | null;
      excludedContacts?: Array<string> | null;
      includedAnnouncementTypes?: Array<string> | null;
      includedUpdateTypes?: Array<MediaUpdateType> | null;
      isActive?: boolean | null;
      recipientListType?: Array<DistributionRecipientListType> | null;
      socialPostTemplate?: string | null;
      usedDefaultTypes?: boolean | null;
      updatedAt?: string | null;
      shareholderWelcomeEnabled?: boolean | null;
      emailSettings?: {
        __typename?: 'DistributionSettingsEmail';
        id: string;
        emailHtml: string;
        emailJson: string;
        fromName?: string | null;
        subject?: string | null;
      } | null;
    } | null;
    twitter?: {
      __typename?: 'DistributionSettings';
      id?: string | null;
      channel?: DistributionChannelType | null;
      excludedContacts?: Array<string> | null;
      includedAnnouncementTypes?: Array<string> | null;
      includedUpdateTypes?: Array<MediaUpdateType> | null;
      isActive?: boolean | null;
      recipientListType?: Array<DistributionRecipientListType> | null;
      socialPostTemplate?: string | null;
      usedDefaultTypes?: boolean | null;
      updatedAt?: string | null;
      shareholderWelcomeEnabled?: boolean | null;
      emailSettings?: {
        __typename?: 'DistributionSettingsEmail';
        id: string;
        emailHtml: string;
        emailJson: string;
        fromName?: string | null;
        subject?: string | null;
      } | null;
    } | null;
  };
};

export type CurrentCompanyInvestorHubAnalysisQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentCompanyInvestorHubAnalysisQuery = {
  __typename?: 'RootQueryType';
  currentCompanyInvestorHubAnalysis?: {
    __typename?: 'CurrentCompanyInvestorHubAnalysisStats';
    currentMonthInvestorHubStats: {
      __typename?: 'InvestorHubAnalysisStats';
      signups: number;
      title: string;
      views: number;
      visitors: number;
    };
    previousMonthInvestorHubStats: {
      __typename?: 'InvestorHubAnalysisStats';
      signups: number;
      title: string;
      views: number;
      visitors: number;
    };
    overallInvestorHubStats: {
      __typename?: 'InvestorHubAnalysisStats';
      signups: number;
      title: string;
      views: number;
      visitors: number;
    };
  } | null;
};

export type CurrentCompanyOverviewQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;

export type CurrentCompanyOverviewQuery = {
  __typename?: 'RootQueryType';
  currentCompanyOverview?: Array<{
    __typename?: 'CompanyOverview';
    date: string;
    currency?: string | null;
    shareholdingsCount: number;
    close?: number | null;
    volume?: number | null;
    announcements: Array<{
      __typename?: 'MediaAnnouncement';
      id: string;
      header: string;
    } | null>;
  } | null> | null;
};

export type CurrentCompanyProfileUserQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentCompanyProfileUserQuery = {
  __typename?: 'RootQueryType';
  currentCompanyProfileUser?: {
    __typename?: 'CompanyProfileUser';
    id: string;
    enabledProductTour: boolean;
    jobTitle?: string | null;
    role: CompanyProfileUserRole;
    simulatingAdminUserId?: string | null;
    status: CompanyProfileUserStatus;
    profile: {
      __typename?: 'CompanyProfile';
      id: string;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      country?: string | null;
      aiSummariesEnabled: boolean;
      defaultPublicInteractionsEnabled: boolean;
      dnsIntegrationPdfUrl?: string | null;
      email?: string | null;
      hidePrivateQuestionsFromCount: boolean;
      informationConfirmedAt?: string | null;
      insertedAt?: string | null;
      investorCentreEnabled?: boolean | null;
      isDemo: boolean;
      isPremium: boolean;
      isTrial: boolean;
      isUnlisted: boolean;
      currency: string;
      latestMarketCap: number;
      logoUrl: string;
      mobileNumber?: string | null;
      name: string;
      primaryBrandColor?: string | null;
      postalCode?: string | null;
      registry?: Registries | null;
      sectors?: Array<string | null> | null;
      state?: string | null;
      hub: string;
      totalShareholderOffers: number;
      hasCompanyShareholderOfferPermission: boolean;
      liveShareholderOfferId?: string | null;
      featuresEnabled: Array<string | null>;
      announcementTypes: {
        __typename?: 'AnnouncementTypes';
        allValues: Array<string>;
        recommendedValues: Array<string>;
        rectypeValues: Array<string>;
        list: Array<{
          __typename?: 'AnnouncementType';
          rectype: string;
          value: string;
          subtypes: Array<{
            __typename?: 'AnnouncementSubtype';
            isMarketSensitive: boolean;
            isRecommended: boolean;
            subtype: string;
            value: string;
          }>;
        }>;
      };
      colorPalette?: {
        __typename?: 'CompanyColorPalette';
        primary: {
          __typename?: 'BrandColor';
          hsl: string;
          shades: {
            __typename?: 'ShadeMap';
            s10: string;
            s20: string;
            s30: string;
            s40: string;
            s50: string;
            s60: string;
            s70: string;
            s80: string;
            s90: string;
          };
          tints: {
            __typename?: 'TintMap';
            t10: string;
            t20: string;
            t30: string;
            t40: string;
            t50: string;
            t60: string;
            t70: string;
            t80: string;
            t90: string;
          };
        };
      } | null;
      socialConnection?: {
        __typename?: 'SocialConnection';
        id: string;
        linkedinConnected?: boolean | null;
        linkedinSetupCompleted?: boolean | null;
        linkedinOrganisationName?: string | null;
        linkedinRefreshTokenExpiresAt?: string | null;
        twitterSetupCompleted?: boolean | null;
        twitterUsername?: string | null;
      } | null;
      ticker: {
        __typename?: 'Ticker';
        id: string;
        listingKey: string;
        marketKey: string;
        marketListingKey: string;
        refinitivIdentificationCode?: string | null;
      };
      liveHubBuilderWebsite?: {
        __typename?: 'Website';
        id: string;
        publishedAt?: string | null;
        updatedAt?: string | null;
        themeColourPrimary?: string | null;
        themeColourPrimaryText?: string | null;
        themeColourAccent?: string | null;
        themeColourAccentText?: string | null;
        themeFontTitle?: string | null;
        themeFontBody?: string | null;
        publishedByCompanyUser?: {
          __typename?: 'CompanyUser';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
      investorHub: {
        __typename?: 'InvestorHub';
        id: string;
        email?: string | null;
        facebook?: string | null;
        instagram?: string | null;
        linkedin?: string | null;
        phone?: string | null;
        registry?: InvestorHubRegisters | null;
        twitter?: string | null;
        website?: string | null;
        colourPrimary: string;
        colourPrimaryText: string;
        colourAccent: string;
        colourAccentText: string;
      };
    };
    user: {
      __typename?: 'CompanyUser';
      id: string;
      insertedAt: string;
      email: string;
      informationConfirmedAt?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      mobileNumber?: string | null;
      passwordLastUpdatedAt?: string | null;
      companyProfileUsers: Array<{
        __typename?: 'CompanyProfileUser';
        id: string;
        profile: {
          __typename?: 'CompanyProfile';
          id: string;
          name: string;
          logoUrl: string;
          ticker: {
            __typename?: 'Ticker';
            id: string;
            listingKey: string;
            marketListingKey: string;
          };
        };
      }>;
      notificationPreferences: Array<{
        __typename?: 'NotificationPreference';
        id: string;
        channel: CompanyUserNotificationChannel;
        isOn: boolean;
        scope: CompanyUserNotificationScope;
      }>;
    };
  } | null;
};

export type CurrentCompanyRaiseStatsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentCompanyRaiseStatsQuery = {
  __typename?: 'RootQueryType';
  currentCompanyStats?: {
    __typename?: 'CompanyStats';
    id: string;
    raisingPotential: {
      __typename?: 'RaisingPotential';
      high: number;
      low: number;
      mean: number;
    };
  } | null;
};

export type CurrentCompanySppsAndPlacementsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentCompanySppsAndPlacementsQuery = {
  __typename?: 'RootQueryType';
  currentCompany?: {
    __typename?: 'CompanyProfile';
    id: string;
    hasHadSpp: boolean;
    hasHadPlacement: boolean;
  } | null;
};

export type CurrentCompanyStatsQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentCompanyStatsQuery = {
  __typename?: 'RootQueryType';
  currentCompanyStats?: {
    __typename?: 'CompanyStats';
    id: string;
    averageHoldingTime: number;
    companyProfileId: string;
    growthRatioPastNinetyDays: number;
    profitLoss: {
      __typename?: 'ProfitLoss';
      averageShareholderProfitLoss: number;
      shareholdersInProfit: number;
    };
    raisingPotential: {
      __typename?: 'RaisingPotential';
      averageUptake: number;
      high: number;
      low: number;
      mean: number;
      shareholderParticipation: number;
      scenarioRange: {
        __typename?: 'ScenarioRange';
        allBad: {
          __typename?: 'ScenarioRangeData';
          averageUptake: number;
          high: number;
          low: number;
          mean: number;
          shareholderParticipation: number;
        };
        allGood: {
          __typename?: 'ScenarioRangeData';
          averageUptake: number;
          high: number;
          low: number;
          mean: number;
          shareholderParticipation: number;
        };
        rockyFinish: {
          __typename?: 'ScenarioRangeData';
          averageUptake: number;
          high: number;
          low: number;
          mean: number;
          shareholderParticipation: number;
        };
      };
    };
    shareholderInsights: {
      __typename?: 'ShareholderInsights';
      addresses: number;
      eligibility: number;
      email: number;
      mobile: number;
      reachability: number;
      total: number;
    };
  } | null;
};

export type CurrentCompanyUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentCompanyUserQuery = {
  __typename?: 'RootQueryType';
  currentCompanyUser?: {
    __typename?: 'CompanyUser';
    id: string;
    insertedAt: string;
    confirmedAt?: string | null;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    mobileNumber?: string | null;
    passwordLastUpdatedAt?: string | null;
    companyProfileUsers: Array<{
      __typename?: 'CompanyProfileUser';
      id: string;
      profile: {
        __typename?: 'CompanyProfile';
        id: string;
        name: string;
        logoUrl: string;
        ticker: {
          __typename?: 'Ticker';
          id: string;
          listingKey: string;
          marketListingKey: string;
        };
      };
    }>;
    notificationPreferences: Array<{
      __typename?: 'NotificationPreference';
      id: string;
      channel: CompanyUserNotificationChannel;
      isOn: boolean;
      scope: CompanyUserNotificationScope;
    }>;
  } | null;
};

export type CurrentCorporatePagesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentCorporatePagesQuery = {
  __typename?: 'RootQueryType';
  currentCorporatePages: Array<{
    __typename?: 'CorporatePage';
    id: string;
    title: string;
    type: CorporatePageType;
    isLive: boolean;
    insertedAt: string;
    updatedAt: string;
    slug: string;
    lastEditedByUser: {
      __typename?: 'CompanyUser';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    };
    sections: Array<{
      __typename?: 'Section';
      id?: string | null;
      heading: string;
      description?: string | null;
      attachments: Array<{
        __typename?: 'Attachment';
        id?: string | null;
        orderId: number;
        heading?: string | null;
        subheading?: string | null;
        description?: string | null;
        fileUrl?: string | null;
      }>;
    }>;
  }>;
};

export type CurrentGovernancePageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentGovernancePageQuery = {
  __typename?: 'RootQueryType';
  currentGovernancePage?: {
    __typename?: 'CorporatePage';
    id: string;
    title: string;
    type: CorporatePageType;
    isLive: boolean;
    insertedAt: string;
    updatedAt: string;
    slug: string;
    lastEditedByUser: {
      __typename?: 'CompanyUser';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    };
    sections: Array<{
      __typename?: 'Section';
      id?: string | null;
      heading: string;
      description?: string | null;
      attachments: Array<{
        __typename?: 'Attachment';
        id?: string | null;
        orderId: number;
        heading?: string | null;
        subheading?: string | null;
        description?: string | null;
        fileUrl?: string | null;
      }>;
    }>;
  } | null;
};

export type CurrentHoldingDistributionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentHoldingDistributionsQuery = {
  __typename?: 'RootQueryType';
  currentHoldingDistributions?: {
    __typename?: 'CurrentHoldingDistributions';
    date: string;
    id: string;
    holdingDistributions: Array<{
      __typename?: 'HoldingDistribution';
      id: string;
      lowerBound?: number | null;
      upperBound?: number | null;
      value: number;
    }>;
  } | null;
};

export type CurrentHoldingInsightsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentHoldingInsightsQuery = {
  __typename?: 'RootQueryType';
  currentHoldingInsights?: {
    __typename?: 'HoldingInsights';
    id: string;
    averageHoldingSize: number;
    averageHoldingValue: number;
    date: string;
    holdingTimeseries: Array<{
      __typename?: 'HoldingTimeseries';
      id: string;
      averageHoldingTime: number;
      date: string;
      shareholdingsCount: number;
    }>;
  } | null;
};

export type CurrentHoldingLengthsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentHoldingLengthsQuery = {
  __typename?: 'RootQueryType';
  currentHoldingLengths?: {
    __typename?: 'CurrentHoldingLengths';
    date: string;
    id: string;
    holdingLengths: Array<{
      __typename?: 'HoldingLength';
      id: string;
      label: string;
      shareholdersCount: number;
      totalShares: number;
    }>;
  } | null;
};

export type CurrentHoldingSizesQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentHoldingSizesQuery = {
  __typename?: 'RootQueryType';
  currentHoldingSizes?: {
    __typename?: 'CurrentHoldingSizes';
    date: string;
    id: string;
    holdingSizes: Array<{
      __typename?: 'HoldingSize';
      id: string;
      label: string;
      percentage: number;
      value: number;
    }>;
  } | null;
};

export type CurrentShareholderContactabilitiesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentShareholderContactabilitiesQuery = {
  __typename?: 'RootQueryType';
  currentShareholderContactabilities?: {
    __typename?: 'CurrentShareholderContactabilities';
    id: string;
    date: string;
    emailsCount: number;
    phonesCount: number;
    totalShareholders: number;
  } | null;
};

export type CurrentShareholderLocationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentShareholderLocationsQuery = {
  __typename?: 'RootQueryType';
  currentShareholderLocations?: {
    __typename?: 'CurrentShareholderLocations';
    id: string;
    date: string;
    shareholderLocations: Array<{
      __typename?: 'ShareholderLocation';
      id: string;
      location: string;
      shareholdersCount: number;
      totalShares: number;
    }>;
  } | null;
};

export type CurrentShareholderProfitsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentShareholderProfitsQuery = {
  __typename?: 'RootQueryType';
  currentShareholderProfits?: {
    __typename?: 'CurrentShareholderProfits';
    id: string;
    averageShareholdersProfit: number;
    shareholdersInLoss: number;
    shareholdersInProfit: number;
    totalUnrealisedGain: number;
    totalUnrealisedLoss: number;
    shareholderProfits: Array<{
      __typename?: 'ShareholderProfit';
      id: string;
      lowerBound?: number | null;
      shareholdersCount: number;
      upperBound?: number | null;
    }>;
  } | null;
};

export type CurrentWebsiteQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentWebsiteQuery = {
  __typename?: 'RootQueryType';
  currentWebsite?: {
    __typename?: 'Website';
    id: string;
    publishedAt?: string | null;
    insertedAt?: string | null;
    updatedAt?: string | null;
    themeColourPrimary?: string | null;
    themeColourPrimaryText?: string | null;
    themeColourAccent?: string | null;
    themeColourAccentText?: string | null;
    themeFontTitle?: string | null;
    themeFontBody?: string | null;
    logoCloudinaryUrl?: string | null;
    logoSquareCloudinaryUrl?: string | null;
    previewSecret?: string | null;
    isFullWebsiteReplacement?: boolean | null;
    isStickySignupBannerEnabled?: boolean | null;
    isEmailPopupModalEnabled?: boolean | null;
    emailPopupModalCloudinaryUrl?: string | null;
    googleAnalyticsId?: string | null;
    title?: string | null;
    pages?: Array<{
      __typename?: 'Page';
      id: string;
      title?: string | null;
      slug?: string | null;
    } | null> | null;
    navMenuItems?: Array<{
      __typename?: 'NavMenuItem';
      id: string;
      label?: string | null;
      url?: string | null;
      position?: number | null;
      openInNewTab?: boolean | null;
      page?: {
        __typename?: 'Page';
        id: string;
        title?: string | null;
        slug?: string | null;
      } | null;
      children?: Array<{
        __typename?: 'NavMenuItem';
        id: string;
        label?: string | null;
        url?: string | null;
        position?: number | null;
        openInNewTab?: boolean | null;
        page?: {
          __typename?: 'Page';
          id: string;
          title?: string | null;
          slug?: string | null;
        } | null;
      } | null> | null;
    }> | null;
  } | null;
};

export type CurrentWelcomePageQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentWelcomePageQuery = {
  __typename?: 'RootQueryType';
  currentWelcomePage?: {
    __typename?: 'WelcomePage';
    id: string;
    insertedAt: string;
    updatedAt: string;
    author: string;
    linkedinProfileUrl?: string | null;
    title: string;
    welcomeMessage: string;
    bannerUrl?: string | null;
    profilePictureUrl?: string | null;
    signatureUrl?: string | null;
    publisher: {
      __typename?: 'CompanyProfileUser';
      id: string;
      user: {
        __typename?: 'CompanyUser';
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
      };
    };
  } | null;
};

export type CustomDomainInstructionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CustomDomainInstructionQuery = {
  __typename?: 'RootQueryType';
  customDomain?: {
    __typename?: 'CustomDomain';
    id: string;
    customDomain: string;
    rootDomain: string;
    isDmarcVerified: boolean;
    isDkimVerified: boolean;
    isVercelDomainVerified: boolean;
    isMailfromVerified: boolean;
    canSendEmails: boolean;
    sesDkimConfigurations?: Array<{
      __typename?: 'CustomDomainConfiguration';
      configured?: boolean | null;
      name?: string | null;
      value?: string | null;
      type?: string | null;
    } | null> | null;
    sesMailFromConfigurations?: Array<{
      __typename?: 'CustomDomainConfiguration';
      configured?: boolean | null;
      name?: string | null;
      value?: string | null;
      priority?: string | null;
      type?: string | null;
    } | null> | null;
    sesDmarcConfigurations?: Array<{
      __typename?: 'CustomDomainConfiguration';
      configured?: boolean | null;
      name?: string | null;
      value?: string | null;
      type?: string | null;
    } | null> | null;
    vercelConfiguration?: {
      __typename?: 'CustomDomainConfiguration';
      configured?: boolean | null;
      name?: string | null;
      value?: string | null;
      type?: string | null;
    } | null;
  } | null;
};

export type CustomDomainQueryVariables = Exact<{ [key: string]: never }>;

export type CustomDomainQuery = {
  __typename?: 'RootQueryType';
  customDomain?: {
    __typename?: 'CustomDomain';
    id: string;
    customDomain: string;
    rootDomain: string;
    isDmarcVerified: boolean;
    isDkimVerified: boolean;
    isVercelDomainVerified: boolean;
    isMailfromVerified: boolean;
    canSendEmails: boolean;
  } | null;
};

export type CustomEmailsQueryVariables = Exact<{ [key: string]: never }>;

export type CustomEmailsQuery = {
  __typename?: 'RootQueryType';
  customEmails?: {
    __typename?: 'CustomEmails';
    isTransactionalEmailSameAsMarketingEmail?: boolean | null;
    marketingEmail?: {
      __typename?: 'CustomEmail';
      sendFromEmail: string;
      sendFromName: string;
      replyToEmail: string;
    } | null;
    transactionalEmail?: {
      __typename?: 'CustomEmail';
      sendFromEmail: string;
      sendFromName: string;
      replyToEmail: string;
    } | null;
    customCampaignTemplate: {
      __typename?: 'BaseEmailTemplate';
      emailHtml?: string | null;
      emailJson: string;
      templateType: BaseEmailTemplateType;
      updatedAt: string;
      lastEditedProfileUser?: {
        __typename?: 'CompanyProfileUser';
        id: string;
        user: {
          __typename?: 'CompanyUser';
          lastName?: string | null;
          firstName?: string | null;
        };
        profile: { __typename?: 'CompanyProfile'; id: string };
      } | null;
    };
    automatedDistributionTemplate: {
      __typename?: 'BaseEmailTemplate';
      emailHtml?: string | null;
      emailJson: string;
      templateType: BaseEmailTemplateType;
      updatedAt: string;
      lastEditedProfileUser?: {
        __typename?: 'CompanyProfileUser';
        id: string;
        user: {
          __typename?: 'CompanyUser';
          lastName?: string | null;
          firstName?: string | null;
        };
        profile: { __typename?: 'CompanyProfile'; id: string };
      } | null;
    };
    manualDistributionTemplate: {
      __typename?: 'BaseEmailTemplate';
      emailHtml?: string | null;
      emailJson: string;
      templateType: BaseEmailTemplateType;
      updatedAt: string;
      lastEditedProfileUser?: {
        __typename?: 'CompanyProfileUser';
        id: string;
        user: {
          __typename?: 'CompanyUser';
          lastName?: string | null;
          firstName?: string | null;
        };
        profile: { __typename?: 'CompanyProfile'; id: string };
      } | null;
    };
    newShareholderWelcomeTemplate: {
      __typename?: 'BaseEmailTemplate';
      emailHtml?: string | null;
      emailJson: string;
      templateType: BaseEmailTemplateType;
      updatedAt: string;
      lastEditedProfileUser?: {
        __typename?: 'CompanyProfileUser';
        id: string;
        user: {
          __typename?: 'CompanyUser';
          lastName?: string | null;
          firstName?: string | null;
        };
        profile: { __typename?: 'CompanyProfile'; id: string };
      } | null;
    };
  } | null;
};

export type DistributionAnnouncementsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
}>;

export type DistributionAnnouncementsQuery = {
  __typename?: 'RootQueryType';
  mediaAnnouncements?: {
    __typename?: 'MediaAnnouncementConnection';
    total: number;
    edges?: Array<{
      __typename?: 'MediaAnnouncementEdge';
      node?: {
        __typename?: 'MediaAnnouncement';
        id: string;
        header: string;
        rectype: string;
        subtypes: Array<string | null>;
        postedAt: string;
        marketSensitive?: boolean | null;
        media: {
          __typename?: 'Media';
          id: string;
          emailDistributionMethod?: EmailDistributionMethod | null;
          email?: {
            __typename?: 'Email';
            id: string;
            isDraft: boolean;
            sentAt?: string | null;
            scheduledAt?: string | null;
          } | null;
        };
        distributedSocial?: {
          __typename?: 'DistributedSocial';
          id: string;
          linkedinPostId?: string | null;
          twitterPostId?: string | null;
          linkedinPostUrl?: string | null;
          twitterPostUrl?: string | null;
        } | null;
      } | null;
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type DistributionUpdatesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
}>;

export type DistributionUpdatesQuery = {
  __typename?: 'RootQueryType';
  mediaUpdates?: {
    __typename?: 'MediaUpdateConnection';
    total: number;
    edges?: Array<{
      __typename?: 'MediaUpdateEdge';
      node?: {
        __typename?: 'MediaUpdate';
        id: string;
        postedAt?: string | null;
        title: string;
        includedTypes?: Array<MediaUpdateType> | null;
        media: {
          __typename?: 'Media';
          id: string;
          emailDistributionMethod?: EmailDistributionMethod | null;
          email?: {
            __typename?: 'Email';
            id: string;
            isDraft: boolean;
            scheduledAt?: string | null;
            sentAt?: string | null;
          } | null;
        };
        distributedSocial?: {
          __typename?: 'DistributedSocial';
          id: string;
          linkedinPostId?: string | null;
          twitterPostId?: string | null;
          linkedinPostUrl?: string | null;
          twitterPostUrl?: string | null;
        } | null;
      } | null;
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type EmailListQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
}>;

export type EmailListQuery = {
  __typename?: 'RootQueryType';
  emailList?: {
    __typename?: 'EmailConnection';
    total: number;
    edges?: Array<{
      __typename?: 'EmailEdge';
      node?: {
        __typename?: 'Email';
        id: string;
        fromName?: string | null;
        fromEmail?: string | null;
        campaignName: string;
        isDraft: boolean;
        updatedAt: string;
        scheduledAt?: string | null;
        sentAt?: string | null;
        lastUpdatedUser?: {
          __typename?: 'CompanyProfileUser';
          user: {
            __typename?: 'CompanyUser';
            firstName?: string | null;
            lastName?: string | null;
          };
        } | null;
        media?: {
          __typename?: 'Media';
          emailDistributionMethod?: EmailDistributionMethod | null;
          mediaAnnouncement?: {
            __typename?: 'MediaAnnouncement';
            id: string;
          } | null;
          mediaUpdate?: { __typename?: 'MediaUpdate'; id: string } | null;
        } | null;
      } | null;
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type EmailRecipientsAllQueryVariables = Exact<{
  emailId: Scalars['ID']['input'];
}>;

export type EmailRecipientsAllQuery = {
  __typename?: 'RootQueryType';
  email?: {
    __typename?: 'Email';
    id: string;
    emailRecipients: Array<{
      __typename?: 'EmailRecipient';
      id: string;
      contact?: { __typename?: 'Contact'; id: string } | null;
    } | null>;
  } | null;
};

export type EmailRecipientsQueryVariables = Exact<{
  emailId: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
}>;

export type EmailRecipientsQuery = {
  __typename?: 'RootQueryType';
  emailRecipients?: {
    __typename?: 'EmailRecipientConnection';
    total: number;
    edges?: Array<{
      __typename?: 'EmailRecipientEdge';
      node: {
        __typename?: 'EmailRecipient';
        id: string;
        sentAt?: string | null;
        contact?: {
          __typename?: 'Contact';
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          importedAt?: string | null;
          investor?: {
            __typename?: 'InvestorUser';
            id: string;
            firstName?: string | null;
          } | null;
          shareholdings: Array<{
            __typename?: 'Shareholding';
            id: string;
            accountName: string;
          }>;
          commsUnsubscribes: Array<{
            __typename?: 'ContactUnsubscribe';
            id: string;
            scope: ContactUnsubscribeScopeType;
          }>;
          globalUnsubscribe?: {
            __typename?: 'ContactGlobalUnsubscribe';
            id: string;
          } | null;
        } | null;
        trackingEmail?: {
          __typename?: 'TrackingEmail';
          events: Array<{
            __typename?: 'CampaignEmailRecipientTrackingEvents';
            eventType: string;
          }>;
        } | null;
      };
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type EmailReputationDataQueryVariables = Exact<{ [key: string]: never }>;

export type EmailReputationDataQuery = {
  __typename?: 'RootQueryType';
  emailReputationData?: {
    __typename?: 'EmailReputationData';
    reputation: number;
    bounces: number;
    sends: number;
    clicks: number;
    complaints: number;
    opens: number;
    unsubscribes: number;
  } | null;
};

export type EmailReputationEmailStatsQueryVariables = Exact<{
  type: Scalars['String']['input'];
}>;

export type EmailReputationEmailStatsQuery = {
  __typename?: 'RootQueryType';
  emailReputationEmailStats: Array<{
    __typename?: 'EmailStats';
    identifier?: number | null;
    subject?: string | null;
    type: string;
    method: string;
    sends: number;
    opens: number;
    clicks: number;
    bounces: number;
    complaints: number;
    unsubscribes: number;
    insertedAt: string;
    sentAt: string;
  }>;
};

export type EmailReputationOverTimeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EmailReputationOverTimeQuery = {
  __typename?: 'RootQueryType';
  emailReputationOverTime: Array<{
    __typename?: 'EmailReputationDataPoint';
    reputation: number;
    sends: number;
    date: string;
    emails: Array<{
      __typename?: 'SentEmail';
      identifier?: number | null;
      subject?: string | null;
      type: string;
    } | null>;
  }>;
};

export type EmailQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type EmailQuery = {
  __typename?: 'RootQueryType';
  email?: {
    __typename?: 'Email';
    id: string;
    fromName?: string | null;
    fromEmail?: string | null;
    campaignName: string;
    isDraft: boolean;
    scheduledAt?: string | null;
    sentAt?: string | null;
    subject?: string | null;
    emailHtml?: string | null;
    emailJson?: string | null;
    isWelcomeEmail: boolean;
    sendToAllContacts: boolean;
    totalClick: number;
    totalDelivery: number;
    totalOpen: number;
    totalUnsubscribed: number;
    totalComplaints: number;
    totalBounced: number;
    total: number;
    lastUpdatedUser?: {
      __typename?: 'CompanyProfileUser';
      id: string;
      user: { __typename?: 'CompanyUser'; firstName?: string | null };
    } | null;
    media?: {
      __typename?: 'Media';
      id: string;
      mediaAnnouncement?: {
        __typename?: 'MediaAnnouncement';
        id: string;
      } | null;
      mediaUpdate?: { __typename?: 'MediaUpdate'; id: string } | null;
    } | null;
    sendToContacts: Array<{
      __typename?: 'Contact';
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
    }>;
    sendToDynamicLists: Array<{
      __typename?: 'DynamicList';
      id: string;
      name: string;
    }>;
    doNotSendToContacts: Array<{
      __typename?: 'Contact';
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
    }>;
    doNotSendToDynamicLists: Array<{
      __typename?: 'DynamicList';
      id: string;
      name: string;
    }>;
  } | null;
};

export type EngagementAnalyticsAnnouncementsQueryVariables = Exact<{
  startDate: Scalars['NaiveDateTime']['input'];
  endDate: Scalars['NaiveDateTime']['input'];
}>;

export type EngagementAnalyticsAnnouncementsQuery = {
  __typename?: 'RootQueryType';
  announcementsReleasedCount?: number | null;
  announcementsAnalyticsStats: {
    __typename?: 'AnnouncementAnalyticsStats';
    totalLikes: number;
    totalLikesDifference: number;
    totalQuestions: number;
    totalQuestionsDifference: number;
    totalSurveyResponses: number;
    totalSurveyResponsesDifference: number;
    totalViews: number;
    totalViewsDifference: number;
  };
  announcementEmailDistributionStatistics: Array<{
    __typename?: 'AnnouncementEmailDistributionStatistics';
    date: string;
    clicks: number;
    sends: number;
    ctr: number;
    opens: number;
    openRate: number;
    announcements: Array<{
      __typename?: 'MediaAnnouncement';
      id: string;
      header: string;
      summary?: string | null;
      videoUrl?: string | null;
    }>;
  }>;
  topThreeViewedAnnouncements: Array<{
    __typename?: 'MediaAnnouncement';
    id: string;
    summary?: string | null;
    videoUrl?: string | null;
    socialVideoUrl?: string | null;
    header: string;
    totalViewCount: number;
    totalViewCountFromTimePeriod: number;
    likes: number;
    totalQuestionCount: number;
    totalSurveyResponses: number;
    distributedSocial?: {
      __typename?: 'DistributedSocial';
      linkedinPostId?: string | null;
      linkedinPostedAt?: string | null;
      twitterPostId?: string | null;
      twitterPostedAt?: string | null;
    } | null;
    email?: {
      __typename?: 'Email';
      sentAt?: string | null;
      subject?: string | null;
    } | null;
  }>;
  announcementReachAndEngagement: Array<{
    __typename?: 'AnnouncementReachAndEngagement';
    date: string;
    totalViews: number;
    totalLikes: number;
    totalQuestions: number;
    totalSurveyResponses: number;
    announcements: Array<{
      __typename?: 'MediaAnnouncement';
      id: string;
      header: string;
      summary?: string | null;
      videoUrl?: string | null;
    }>;
  }>;
};

export type EngagementAnalyticsInvestorHubQueryVariables = Exact<{
  startDate: Scalars['NaiveDateTime']['input'];
  endDate: Scalars['NaiveDateTime']['input'];
}>;

export type EngagementAnalyticsInvestorHubQuery = {
  __typename?: 'RootQueryType';
  investorHubAnalytics: {
    __typename?: 'InvestorHubAnalytics';
    signupsDifference: number;
    totalSignups: number;
    totalUniqueVisitors: number;
    totalViews: number;
    totalViewsDifference: number;
    uniqueVisitorsDifference: number;
  };
  investorHubMostEngagedInvestors: Array<{
    __typename?: 'InvestorHubMostEngagedInvestors';
    likes: number;
    questions: number;
    surveyResponses: number;
    investorUser: {
      __typename?: 'InvestorUser';
      username?: string | null;
      contact?: {
        __typename?: 'Contact';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        shareholderStatus?: ShareholderStatus | null;
        hnwStatus?: ContactHnwStatuses | null;
        hnwIdentifiedAt?: string | null;
        shareholdings: Array<{
          __typename?: 'Shareholding';
          hasParticipatedInPlacement?: boolean | null;
          hasParticipatedInSpp?: boolean | null;
          hnwBehaviour?: HnwBehaviours | null;
          hnwIdentifiedAt?: string | null;
          id: string;
          shareCount?: number | null;
          shareCountRank?: number | null;
        }>;
      } | null;
    };
  }>;
  investorHubSignupBreakdown: {
    __typename?: 'InvestorHubSignupBreakdown';
    existingShareholders: number;
    leads: number;
    pastShareholders: number;
    nominatedShareholders: number;
  };
  investorHubPagePerformance: Array<{
    __typename?: 'InvestorHubPagePerformance';
    name: string;
    unique: number;
    total: number;
  }>;
  investorHubEngagement: Array<{
    __typename?: 'InvestorHubEngagement';
    close?: number | null;
    currency?: string | null;
    date: string;
    signups: number;
    totalViews: number;
    totalUniqueVisitors: number;
    announcements: Array<{
      __typename?: 'Announcement';
      announcementId: string;
      header: string;
    }>;
    updates: Array<{ __typename?: 'Update'; updateId: string; title: string }>;
    campaigns: Array<{
      __typename?: 'EngagementCampaign';
      campaignId: string;
      name: string;
    }>;
  }>;
};

export type EngagementAnalyticsOverviewQueryVariables = Exact<{
  startDate: Scalars['NaiveDateTime']['input'];
  endDate: Scalars['NaiveDateTime']['input'];
}>;

export type EngagementAnalyticsOverviewQuery = {
  __typename?: 'RootQueryType';
  engagementAnalyticsOverview: {
    __typename?: 'EngagementAnalyticsOverview';
    totalLeads: number;
    totalNominatedShareholders: number;
    totalConvertedShareholders: number;
    totalUniqueVisitors: number;
    uniqueVisitorsDifference: number;
    leadsDifference: number;
    convertedShareholdersDifference: number;
    audiencesBreakdown: Array<{
      __typename?: 'AudienceBreakdown';
      date: string;
      totalLeads: number;
      totalUniqueVisitors: number;
      totalConvertedShareholders: number;
      totalNominatedShareholders: number;
      conversionRateSma: number;
      announcements: Array<{
        __typename?: 'Announcement';
        date: string;
        header: string;
        announcementId: string;
        companyProfileId: string;
      }>;
      campaigns: Array<{
        __typename?: 'EngagementCampaign';
        date: string;
        campaignId: string;
        name: string;
      }>;
      updates: Array<{
        __typename?: 'Update';
        date: string;
        updateId: string;
        companyProfileId: string;
        title: string;
      }>;
    }>;
  };
};

export type EngagementAnalyticsTotalUpdatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EngagementAnalyticsTotalUpdatesQuery = {
  __typename?: 'RootQueryType';
  totalUpdates: number;
};

export type EngagementAnalyticsUpdatesQueryVariables = Exact<{
  startDate: Scalars['NaiveDateTime']['input'];
  endDate: Scalars['NaiveDateTime']['input'];
}>;

export type EngagementAnalyticsUpdatesQuery = {
  __typename?: 'RootQueryType';
  updatesReleasedCount?: number | null;
  updatesAnalyticsStats: {
    __typename?: 'UpdateAnalyticsStats';
    totalLikes: number;
    totalLikesDifference: number;
    totalQuestions: number;
    totalQuestionsDifference: number;
    totalSurveyResponses: number;
    totalSurveyResponsesDifference: number;
    totalViews: number;
    totalViewsDifference: number;
  };
  topThreeViewedUpdates: Array<{
    __typename?: 'MediaUpdate';
    id: string;
    title: string;
    includedTypes?: Array<MediaUpdateType> | null;
    likes: number;
    totalViewCount: number;
    totalQuestionCount: number;
    totalSurveyResponses: number;
    distributedSocial?: {
      __typename?: 'DistributedSocial';
      linkedinPostId?: string | null;
      linkedinPostedAt?: string | null;
      twitterPostId?: string | null;
      twitterPostedAt?: string | null;
    } | null;
    email?: {
      __typename?: 'Email';
      sentAt?: string | null;
      subject?: string | null;
    } | null;
  }>;
  updatesReachAndEngagement: Array<{
    __typename?: 'UpdateReachAndEngagement';
    date: string;
    totalViews: number;
    totalLikes: number;
    totalQuestions: number;
    totalSurveyResponses: number;
    updates: Array<{
      __typename?: 'MediaUpdate';
      id: string;
      title: string;
      includedTypes?: Array<MediaUpdateType> | null;
    }>;
  }>;
  updatesEmailDistributionStatistics: Array<{
    __typename?: 'UpdatesEmailDistributionStatistics';
    opens: number;
    clicks: number;
    ctr: number;
    openRate: number;
    date: string;
    sends: number;
    updates: Array<{
      __typename?: 'MediaUpdate';
      id: string;
      title: string;
      includedTypes?: Array<MediaUpdateType> | null;
    }>;
  }>;
};

export type EstimateEmailRecipientsCountQueryVariables = Exact<{
  emailId: Scalars['ID']['input'];
}>;

export type EstimateEmailRecipientsCountQuery = {
  __typename?: 'RootQueryType';
  estimateEmailRecipientsCount: number;
};

export type GetColorPaletteQueryVariables = Exact<{
  hexCode: Scalars['String']['input'];
}>;

export type GetColorPaletteQuery = {
  __typename?: 'RootQueryType';
  getColorPalette?: {
    __typename?: 'CompanyColorPalette';
    primary: {
      __typename?: 'BrandColor';
      hsl: string;
      shades: {
        __typename?: 'ShadeMap';
        s10: string;
        s20: string;
        s30: string;
        s40: string;
        s50: string;
        s60: string;
        s70: string;
        s80: string;
        s90: string;
      };
      tints: {
        __typename?: 'TintMap';
        t10: string;
        t20: string;
        t30: string;
        t40: string;
        t50: string;
        t60: string;
        t70: string;
        t80: string;
        t90: string;
      };
    };
  } | null;
};

export type GrowthRatioTimeseriesQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;

export type GrowthRatioTimeseriesQuery = {
  __typename?: 'RootQueryType';
  growthRatioTimeseries?: Array<{
    __typename?: 'GrowthRatioTimeseries';
    id: string;
    date: string;
    new: number;
    returning: number;
    churned: number;
    growthRatio: number;
  }> | null;
};

export type HubBuilderIndexPageHubStatsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type HubBuilderIndexPageHubStatsQuery = {
  __typename?: 'RootQueryType';
  hubStats?: {
    __typename?: 'HubStats';
    signUpsLastThirtyDays?: number | null;
    uniqueVisitorsLastThirtyDays?: number | null;
    pendingQualifiedInvestors?: number | null;
  } | null;
};

export type InteractiveMediaComparisonStatsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type InteractiveMediaComparisonStatsQuery = {
  __typename?: 'RootQueryType';
  interactiveMediaStats: {
    __typename?: 'InteractiveMediaStats';
    totalActiveQuestions: number;
    totalQuestionsThisWeek: number;
    totalQuestionsLastWeek: number;
    totalQuestions: number;
    totalAnnouncementsLastMonth: number;
    totalAnnouncementsThisMonth: number;
    totalAnnouncementsThisYear: number;
    totalUpdatesLastMonth: number;
    totalUpdatesThisMonth: number;
    totalUpdatesThisYear: number;
  };
};

export type InteractiveMediaStatsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type InteractiveMediaStatsQuery = {
  __typename?: 'RootQueryType';
  interactiveMediaStats: {
    __typename?: 'InteractiveMediaStats';
    totalActiveQuestions: number;
    totalLikesLastWeek: number;
    totalLikesThisWeek: number;
    totalLikes: number;
    totalQuestionsThisWeek: number;
    totalQuestionsLastWeek: number;
    totalQuestions: number;
    totalSurveyResponsesLastWeek: number;
    totalSurveyResponsesThisWeek: number;
    totalSurveyResponses: number;
    totalAnnouncementsLastWeek: number;
    totalAnnouncementsThisWeek: number;
    totalAnnouncements: number;
    totalUpdatesLastWeek: number;
    totalUpdatesThisWeek: number;
    totalUpdates: number;
  };
};

export type InvestorActivityStatsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type InvestorActivityStatsQuery = {
  __typename?: 'RootQueryType';
  investorActivityStats?: {
    __typename?: 'ActivityStats';
    id: string;
    announcementsViewed: number;
    likes: number;
    questionsAsked: number;
    surveyResponses: number;
    updatesViewed: number;
    followers: number;
  } | null;
};

export type InvestorSearchContactsAndShareholdingsAutocompleteQueryVariables =
  Exact<{
    contactsAfter?: InputMaybe<Scalars['String']['input']>;
    contactsBefore?: InputMaybe<Scalars['String']['input']>;
    contactsFirst?: InputMaybe<Scalars['Int']['input']>;
    contactsLast?: InputMaybe<Scalars['Int']['input']>;
    contactsOptions?: InputMaybe<OptionsInput>;
    shareholdingsAfter?: InputMaybe<Scalars['String']['input']>;
    shareholdingsBefore?: InputMaybe<Scalars['String']['input']>;
    shareholdingsFirst?: InputMaybe<Scalars['Int']['input']>;
    shareholdingsLast?: InputMaybe<Scalars['Int']['input']>;
    shareholdingsOptions?: InputMaybe<OptionsInput>;
  }>;

export type InvestorSearchContactsAndShareholdingsAutocompleteQuery = {
  __typename?: 'RootQueryType';
  contacts?: {
    __typename?: 'ContactConnection';
    total: number;
    edges?: Array<{
      __typename?: 'ContactEdge';
      node?: {
        __typename?: 'Contact';
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        shareholdings: Array<{
          __typename?: 'Shareholding';
          id: string;
          email?: string | null;
          accountName: string;
          shareCountRank?: number | null;
        }>;
        investor?: {
          __typename?: 'InvestorUser';
          id: string;
          username?: string | null;
        } | null;
        tags: Array<{ __typename?: 'Tag'; id: string; name: string }>;
      } | null;
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
  shareholdings?: {
    __typename?: 'ShareholdingConnection';
    total: number;
    edges?: Array<{
      __typename?: 'ShareholdingEdge';
      node?: {
        __typename?: 'Shareholding';
        accountName: string;
        currentHoldingStartDate?: string | null;
        brokerNameShort?: string | null;
        email?: string | null;
        id: string;
        initialPurchaseDate?: string | null;
        movementCount?: number | null;
        shareCount?: number | null;
        phoneNumber?: string | null;
        addressCity?: string | null;
        contact?: {
          __typename?: 'Contact';
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type InvestorSearchContactsAndShareholdingsQueryVariables = Exact<{
  contactsAfter?: InputMaybe<Scalars['String']['input']>;
  contactsBefore?: InputMaybe<Scalars['String']['input']>;
  contactsFirst?: InputMaybe<Scalars['Int']['input']>;
  contactsLast?: InputMaybe<Scalars['Int']['input']>;
  contactsOptions?: InputMaybe<OptionsInput>;
  shareholdingsAfter?: InputMaybe<Scalars['String']['input']>;
  shareholdingsBefore?: InputMaybe<Scalars['String']['input']>;
  shareholdingsFirst?: InputMaybe<Scalars['Int']['input']>;
  shareholdingsLast?: InputMaybe<Scalars['Int']['input']>;
  shareholdingsOptions?: InputMaybe<OptionsInput>;
}>;

export type InvestorSearchContactsAndShareholdingsQuery = {
  __typename?: 'RootQueryType';
  contacts?: {
    __typename?: 'ContactConnection';
    total: number;
    edges?: Array<{
      __typename?: 'ContactEdge';
      node?: {
        __typename?: 'Contact';
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        hnwIdentifiedAt?: string | null;
        hnwStatus?: ContactHnwStatuses | null;
        insertedAt: string;
        shareholderStatus?: ShareholderStatus | null;
        shareholdings: Array<{
          __typename?: 'Shareholding';
          id: string;
          accountName: string;
        }>;
        investor?: {
          __typename?: 'InvestorUser';
          id: string;
          username?: string | null;
          insertedAt?: string | null;
        } | null;
        tags: Array<{ __typename?: 'Tag'; id: string; name: string }>;
      } | null;
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
  shareholdings?: {
    __typename?: 'ShareholdingConnection';
    total: number;
    edges?: Array<{
      __typename?: 'ShareholdingEdge';
      node?: {
        __typename?: 'Shareholding';
        accountName: string;
        currentHoldingStartDate?: string | null;
        brokerNameShort?: string | null;
        email?: string | null;
        id: string;
        initialPurchaseDate?: string | null;
        movementCount?: number | null;
        shareCount?: number | null;
        phoneNumber?: string | null;
        addressCity?: string | null;
        shareCountRank?: number | null;
        hasParticipatedInSpp?: boolean | null;
        hasParticipatedInPlacement?: boolean | null;
        hnwIdentifiedAt?: string | null;
        hnwBehaviour?: HnwBehaviours | null;
        contact?: {
          __typename?: 'Contact';
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type InvestorUsersQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
}>;

export type InvestorUsersQuery = {
  __typename?: 'RootQueryType';
  investorUsers?: {
    __typename?: 'InvestorUserConnection';
    total: number;
    edges?: Array<{
      __typename?: 'InvestorUserEdge';
      node?: {
        __typename?: 'InvestorUser';
        id: string;
        confirmedAt?: string | null;
        email: string;
        firstName?: string | null;
        isHoldingVerified: boolean;
        lastName?: string | null;
        provider?: string | null;
        username?: string | null;
        hnwIdentifiedAt?: string | null;
        hnwStatus?: InvestorHnwStatuses | null;
        certificate?: {
          __typename?: 'InvestorCertificate';
          id: string;
          status: InvestorCertificateStatus;
          isExpired: boolean;
        } | null;
        notificationPreferences: Array<{
          __typename?: 'InvestorNotificationPreference';
          id: string;
          channel?: InvestorNotificationChannel | null;
          hasEoi?: boolean | null;
          isOn?: boolean | null;
          scope?: InvestorNotificationScope | null;
        }>;
        shareholderInformation?: {
          __typename?: 'InvestorShareholderInformation';
          id: string;
          country: string;
          partialHin: string;
          postcode?: string | null;
        } | null;
        contact?: { __typename?: 'Contact'; id: string; email: string } | null;
      } | null;
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type IsFeatureOnboardedQueryVariables = Exact<{
  featureName: Scalars['String']['input'];
}>;

export type IsFeatureOnboardedQuery = {
  __typename?: 'RootQueryType';
  isFeatureOnboarded?: boolean | null;
};

export type KeyInsightsQueryVariables = Exact<{
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
}>;

export type KeyInsightsQuery = {
  __typename?: 'RootQueryType';
  keyInsights?: {
    __typename?: 'KeyInsights';
    id: string;
    churned: number;
    downgraders: number;
    new: number;
    returning: number;
    upgraders: number;
  } | null;
};

export type ContactsGraphDataQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;

export type ContactsGraphDataQuery = {
  __typename?: 'RootQueryType';
  getContactsGraphData?: Array<{
    __typename?: 'ContactsGraphData';
    date: string;
    hubEmails: number;
    totalEmails: number;
  } | null> | null;
};

export type EngagementGraphDataQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;

export type EngagementGraphDataQuery = {
  __typename?: 'RootQueryType';
  getEngagementGraphData?: Array<{
    __typename?: 'EngagementGraphData';
    date: string;
    uniqueVisitors: number;
    engagedUniqueVisitors: number;
    returningVisitors: number;
  } | null> | null;
};

export type CampaignDistributionGraphDataQueryVariables = Exact<{
  lastNumberOfEmailCampaigns: Scalars['Int']['input'];
}>;

export type CampaignDistributionGraphDataQuery = {
  __typename?: 'RootQueryType';
  getCampaignDistributionGraphData?: Array<{
    __typename?: 'CampaignDistributionGraphData';
    date: string;
    campaignName: string;
    clickThroughRate: number;
    openRate: number;
    type: string;
    totalSent: number;
  } | null> | null;
};

export type SubmitFeedbackQueryVariables = Exact<{
  message: Scalars['String']['input'];
}>;

export type SubmitFeedbackQuery = {
  __typename?: 'RootQueryType';
  submitFeedback: boolean;
};

export type LinkedinPostStatisticsQueryVariables = Exact<{
  postId: Scalars['String']['input'];
}>;

export type LinkedinPostStatisticsQuery = {
  __typename?: 'RootQueryType';
  linkedinPostStatistics?: {
    __typename?: 'LinkedinPostStatistics';
    id: string;
    impressionCount: number;
    commentCount: number;
    shareCount: number;
    likeCount: number;
  } | null;
};

export type AnnouncementEngagementQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  dateRange: Scalars['String']['input'];
}>;

export type AnnouncementEngagementQuery = {
  __typename?: 'RootQueryType';
  announcementEngagement: Array<{
    __typename?: 'AnnouncementEngagement';
    date: string;
    totalViews: number;
    totalUniqueVisitors: number;
  }>;
};

export type MediaAnnouncementViewerStatsQueryVariables = Exact<{
  mediaId: Scalars['ID']['input'];
}>;

export type MediaAnnouncementViewerStatsQuery = {
  __typename?: 'RootQueryType';
  mediaAnnouncementViewerStats?: {
    __typename?: 'AnnouncementViewerStats';
    campaignViews?: number | null;
    linkedinViews?: number | null;
    preparedLinkViews?: number | null;
    twitterViews?: number | null;
    other?: number | null;
  } | null;
};

export type MediaAnnouncementQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type MediaAnnouncementQuery = {
  __typename?: 'RootQueryType';
  mediaAnnouncement?: {
    __typename?: 'MediaAnnouncement';
    id: string;
    featuredOnHub: boolean;
    header: string;
    likes: number;
    listingKey: string;
    marketKey: string;
    marketSensitive?: boolean | null;
    mediaId: string;
    postedAt: string;
    rectype: string;
    socialVideoUrl?: string | null;
    subtypes: Array<string | null>;
    summary?: string | null;
    summaryAi?: string | null;
    totalQuestionCount: number;
    totalSurveyResponses: number;
    totalViewCount: number;
    totalUniqueVisitors: number;
    totalSignupsLastWeek: number;
    url: string;
    videoUrl?: string | null;
    germanTranslatedVideoUrl?: string | null;
    germanTranslatedUrl?: string | null;
    germanTranslatedHeader?: string | null;
    germanTranslatedSummary?: string | null;
    media: {
      __typename?: 'Media';
      emailDistributionMethod?: EmailDistributionMethod | null;
      email?: {
        __typename?: 'Email';
        id: string;
        sentAt?: string | null;
        isDraft: boolean;
        scheduledAt?: string | null;
        previewEmail?: string | null;
        previewEmailSubject?: string | null;
        subject?: string | null;
        totalClick: number;
        totalDelivery: number;
        totalOpen: number;
        totalUnsubscribed: number;
        totalComplaints: number;
        totalBounced: number;
        total: number;
      } | null;
    };
    distributedSocial?: {
      __typename?: 'DistributedSocial';
      id: string;
      linkedinPostId?: string | null;
      linkedinPostedAt?: string | null;
      linkedinPostUrl?: string | null;
      twitterPostId?: string | null;
      twitterPostUrl?: string | null;
      twitterPostedAt?: string | null;
    } | null;
    preparedAnnouncement?: {
      __typename?: 'PreparedAnnouncement';
      id: string;
      socialVideoUrl?: string | null;
      videoUrl?: string | null;
      commentContent?: string | null;
      commentUseCompanyAsUsername?: boolean | null;
      summary?: string | null;
    } | null;
  } | null;
};

export type MediaAnnouncementsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
}>;

export type MediaAnnouncementsQuery = {
  __typename?: 'RootQueryType';
  mediaAnnouncements?: {
    __typename?: 'MediaAnnouncementConnection';
    total: number;
    edges?: Array<{
      __typename?: 'MediaAnnouncementEdge';
      node?: {
        __typename?: 'MediaAnnouncement';
        id: string;
        featuredOnHub: boolean;
        header: string;
        likes: number;
        listingKey: string;
        marketKey: string;
        mediaId: string;
        postedAt: string;
        socialVideoUrl?: string | null;
        summary?: string | null;
        totalActiveQuestionCount: number;
        totalQuestionCount: number;
        totalSurveyResponses: number;
        totalViewCount: number;
        videoUrl?: string | null;
        germanTranslatedVideoUrl?: string | null;
        germanTranslatedUrl?: string | null;
        germanTranslatedHeader?: string | null;
        germanTranslatedSummary?: string | null;
      } | null;
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type MediaCommentsCompanyAuthorQueryVariables = Exact<{
  mediaId: Scalars['ID']['input'];
}>;

export type MediaCommentsCompanyAuthorQuery = {
  __typename?: 'RootQueryType';
  mediaCommentsCompanyAuthor: Array<{
    __typename?: 'MediaComment';
    id: string;
    insertedAt: string;
    updatedAt: string;
    content: string;
    likes: number;
    useCompanyAsUsername: boolean;
    annotationMetadata?: {
      __typename?: 'MediaCommentAnnotationMetadata';
      left: number;
      pageIndex: number;
      top: number;
    } | null;
    children?: Array<{
      __typename?: 'MediaComment';
      id: string;
      insertedAt: string;
      updatedAt: string;
      content: string;
      likes: number;
      private: boolean;
      useCompanyAsUsername: boolean;
      annotationMetadata?: {
        __typename?: 'MediaCommentAnnotationMetadata';
        left: number;
        pageIndex: number;
        top: number;
      } | null;
      companyAuthor?: {
        __typename?: 'CompanyUser';
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      investorUser?: {
        __typename?: 'InvestorUser';
        id: string;
        email: string;
        firstName?: string | null;
        isHoldingVerified: boolean;
        lastName?: string | null;
        username?: string | null;
        contact?: {
          __typename?: 'Contact';
          id: string;
          shareholderStatus?: ShareholderStatus | null;
        } | null;
      } | null;
    }> | null;
    companyAuthor?: {
      __typename?: 'CompanyUser';
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    lastEditedByUser?: {
      __typename?: 'CompanyUser';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  }>;
};

export type MediaCommentsQueryVariables = Exact<{
  isAnnotation?: InputMaybe<Scalars['Boolean']['input']>;
  mediaId: Scalars['ID']['input'];
}>;

export type MediaCommentsQuery = {
  __typename?: 'RootQueryType';
  mediaComments: Array<{
    __typename?: 'MediaComment';
    id: string;
    insertedAt: string;
    content: string;
    likes: number;
    private: boolean;
    useCompanyAsUsername: boolean;
    annotationMetadata?: {
      __typename?: 'MediaCommentAnnotationMetadata';
      left: number;
      pageIndex: number;
      top: number;
    } | null;
    children?: Array<{
      __typename?: 'MediaComment';
      id: string;
      insertedAt: string;
      updatedAt: string;
      content: string;
      likes: number;
      private: boolean;
      useCompanyAsUsername: boolean;
      annotationMetadata?: {
        __typename?: 'MediaCommentAnnotationMetadata';
        left: number;
        pageIndex: number;
        top: number;
      } | null;
      companyAuthor?: {
        __typename?: 'CompanyUser';
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      investorUser?: {
        __typename?: 'InvestorUser';
        id: string;
        email: string;
        firstName?: string | null;
        isHoldingVerified: boolean;
        isSelfNominatedShareholder?: boolean | null;
        lastName?: string | null;
        username?: string | null;
        contact?: {
          __typename?: 'Contact';
          id: string;
          shareholderStatus?: ShareholderStatus | null;
        } | null;
      } | null;
    }> | null;
    companyAuthor?: {
      __typename?: 'CompanyUser';
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    investorUser?: {
      __typename?: 'InvestorUser';
      id: string;
      email: string;
      firstName?: string | null;
      isHoldingVerified: boolean;
      isSelfNominatedShareholder?: boolean | null;
      lastName?: string | null;
      username?: string | null;
      contact?: { __typename?: 'Contact'; id: string } | null;
    } | null;
    userStarred?: {
      __typename?: 'MediaCommentStar';
      id: string;
      starred: boolean;
    } | null;
    userRead?: {
      __typename?: 'MediaCommentRead';
      id: string;
      read: boolean;
    } | null;
  }>;
};

export type MediaQuestionsListQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
}>;

export type MediaQuestionsListQuery = {
  __typename?: 'RootQueryType';
  mediaQuestionsList?: {
    __typename?: 'MediaCommentConnection';
    total: number;
    totalStarred: number;
    totalDone: number;
    totalActive: number;
    totalAll: number;
    edges?: Array<{
      __typename?: 'MediaCommentEdge';
      node?: {
        __typename?: 'MediaComment';
        id: string;
        insertedAt: string;
        updatedAt: string;
        commentSource?: CommentSource | null;
        commentSourceUrl?: string | null;
        content: string;
        done: boolean;
        likes: number;
        private: boolean;
        useCompanyAsUsername: boolean;
        annotationMetadata?: {
          __typename?: 'MediaCommentAnnotationMetadata';
          left: number;
          pageIndex: number;
          top: number;
        } | null;
        children?: Array<{
          __typename?: 'MediaComment';
          id: string;
          insertedAt: string;
          updatedAt: string;
          content: string;
          likes: number;
          private: boolean;
          useCompanyAsUsername: boolean;
          lastEditedByUser?: {
            __typename?: 'CompanyUser';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
          annotationMetadata?: {
            __typename?: 'MediaCommentAnnotationMetadata';
            left: number;
            pageIndex: number;
            top: number;
          } | null;
          companyAuthor?: {
            __typename?: 'CompanyUser';
            id: string;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
          investorUser?: {
            __typename?: 'InvestorUser';
            id: string;
            email: string;
            firstName?: string | null;
            isHoldingVerified: boolean;
            isSelfNominatedShareholder?: boolean | null;
            lastName?: string | null;
            username?: string | null;
            contact?: { __typename?: 'Contact'; id: string } | null;
          } | null;
        }> | null;
        companyAuthor?: {
          __typename?: 'CompanyUser';
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
        lastEditedByUser?: {
          __typename?: 'CompanyUser';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
        investorUser?: {
          __typename?: 'InvestorUser';
          id: string;
          email: string;
          firstName?: string | null;
          isHoldingVerified: boolean;
          isSelfNominatedShareholder?: boolean | null;
          lastName?: string | null;
          username?: string | null;
          contact?: {
            __typename?: 'Contact';
            id: string;
            shareholderStatus?: ShareholderStatus | null;
          } | null;
          shareholdings: Array<{
            __typename?: 'Shareholding';
            id: string;
            shareCountRank?: number | null;
            shareCount?: number | null;
            hasParticipatedInPlacement?: boolean | null;
            hasParticipatedInSpp?: boolean | null;
            hnwBehaviour?: HnwBehaviours | null;
            hnwIdentifiedAt?: string | null;
          }>;
        } | null;
        media?: {
          __typename?: 'Media';
          id: string;
          companyProfile?: {
            __typename?: 'CompanyProfile';
            id: string;
            name: string;
            ticker: {
              __typename?: 'Ticker';
              id: string;
              listingKey: string;
              marketKey: string;
            };
          } | null;
          mediaUpdate?: {
            __typename?: 'MediaUpdate';
            id: string;
            title: string;
            slug: string;
          } | null;
          mediaAnnouncement?: {
            __typename?: 'MediaAnnouncement';
            id: string;
            header: string;
          } | null;
        } | null;
        userStarred?: {
          __typename?: 'MediaCommentStar';
          id: string;
          starred: boolean;
        } | null;
        userRead?: {
          __typename?: 'MediaCommentRead';
          id: string;
          read: boolean;
        } | null;
      } | null;
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type MediaStatsQueryVariables = Exact<{ [key: string]: never }>;

export type MediaStatsQuery = {
  __typename?: 'RootQueryType';
  mediaStats?: {
    __typename?: 'MediaStats';
    comments: number;
    reactions: number;
    surveyResponses: number;
    views: number;
    visitors: number;
  } | null;
};

export type MediaSurveyAnswersQueryVariables = Exact<{
  mediaId: Scalars['ID']['input'];
}>;

export type MediaSurveyAnswersQuery = {
  __typename?: 'RootQueryType';
  mediaSurveyAnswers: Array<{
    __typename?: 'MediaSurveyAnswer';
    id: string;
    answer: MediaSurveyAnswerType;
    question: MediaSurveyQuestionType;
  }>;
};

export type MediaSurveyResponseStatsQueryVariables = Exact<{
  mediaId: Scalars['ID']['input'];
}>;

export type MediaSurveyResponseStatsQuery = {
  __typename?: 'RootQueryType';
  mediaSurveyResponseStats?: Array<{
    __typename?: 'MediaSurveyResponse';
    question: MediaSurveyQuestionType;
    totalResponses: number;
    answers: Array<{
      __typename?: 'MediaSurveyResponseAnswers';
      answer: MediaSurveyAnswerType;
      responsePercentageAsFloat: number;
    } | null>;
  } | null> | null;
};

export type MediaUpdateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type MediaUpdateQuery = {
  __typename?: 'RootQueryType';
  mediaUpdate?: {
    __typename?: 'MediaUpdate';
    id: string;
    isDraft: boolean;
    likes: number;
    mediaId: string;
    postedAt?: string | null;
    slug: string;
    title: string;
    totalActiveQuestionCount: number;
    totalParentCompanyCommentCount: number;
    totalQuestionCount: number;
    totalSurveyResponses: number;
    totalViewCount: number;
    includedTypes?: Array<MediaUpdateType> | null;
    attachments: Array<{
      __typename?: 'MediaUpdateAttachment';
      id: string;
      thumbnailUrl?: string | null;
      title?: string | null;
      type: MediaUpdateAttachmentType;
      url: string;
      orderId: number;
    }>;
    content?: {
      __typename?: 'MediaUpdateContent';
      id: string;
      content: string;
      commentContent?: string | null;
      commentUseCompanyAsUsername?: boolean | null;
    } | null;
    lastUpdatedBy: {
      __typename?: 'CompanyUser';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    };
    media: {
      __typename?: 'Media';
      emailDistributionMethod?: EmailDistributionMethod | null;
      email?: {
        __typename?: 'Email';
        id: string;
        sentAt?: string | null;
        isDraft: boolean;
        scheduledAt?: string | null;
        previewEmail?: string | null;
        previewEmailSubject?: string | null;
        subject?: string | null;
        totalClick: number;
        totalDelivery: number;
        totalOpen: number;
        totalUnsubscribed: number;
        totalComplaints: number;
        totalBounced: number;
        total: number;
      } | null;
    };
    distributedSocial?: {
      __typename?: 'DistributedSocial';
      id: string;
      linkedinPostId?: string | null;
      linkedinPostedAt?: string | null;
      linkedinPostUrl?: string | null;
      twitterPostId?: string | null;
      twitterPostUrl?: string | null;
      twitterPostedAt?: string | null;
    } | null;
    postedBy?: {
      __typename?: 'CompanyUser';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
  } | null;
};

export type MediaUpdatesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
}>;

export type MediaUpdatesQuery = {
  __typename?: 'RootQueryType';
  mediaUpdates?: {
    __typename?: 'MediaUpdateConnection';
    total: number;
    pinnedMediaUpdateExists: boolean;
    edges?: Array<{
      __typename?: 'MediaUpdateEdge';
      node?: {
        __typename?: 'MediaUpdate';
        id: string;
        isDraft: boolean;
        likes: number;
        mediaId: string;
        postedAt?: string | null;
        slug: string;
        title: string;
        totalActiveQuestionCount: number;
        totalParentCompanyCommentCount: number;
        totalQuestionCount: number;
        totalSurveyResponses: number;
        totalViewCount: number;
        includedTypes?: Array<MediaUpdateType> | null;
        isPinned?: boolean | null;
        thumbnailAttachment?: {
          __typename?: 'MediaUpdateAttachment';
          id: string;
          thumbnailUrl?: string | null;
          type: MediaUpdateAttachmentType;
          url: string;
          orderId: number;
        } | null;
        attachments: Array<{
          __typename?: 'MediaUpdateAttachment';
          id: string;
          thumbnailUrl?: string | null;
          type: MediaUpdateAttachmentType;
          url: string;
          orderId: number;
        }>;
        content?: {
          __typename?: 'MediaUpdateContent';
          id: string;
          content: string;
          commentContent?: string | null;
          commentUseCompanyAsUsername?: boolean | null;
        } | null;
        lastUpdatedBy: {
          __typename?: 'CompanyUser';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        };
        postedBy?: {
          __typename?: 'CompanyUser';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        } | null;
      } | null;
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type NavMenuItemsQueryVariables = Exact<{ [key: string]: never }>;

export type NavMenuItemsQuery = {
  __typename?: 'RootQueryType';
  navMenuItems?: Array<{
    __typename?: 'NavMenuItem';
    id: string;
    label?: string | null;
    url?: string | null;
    position?: number | null;
    openInNewTab?: boolean | null;
    parentId?: string | null;
    page?: {
      __typename?: 'Page';
      id: string;
      slug?: string | null;
      title?: string | null;
    } | null;
    children?: Array<{
      __typename?: 'NavMenuItem';
      id: string;
      label?: string | null;
      url?: string | null;
      position?: number | null;
      openInNewTab?: boolean | null;
      parentId?: string | null;
      page?: {
        __typename?: 'Page';
        id: string;
        slug?: string | null;
        title?: string | null;
      } | null;
    } | null> | null;
  }> | null;
};

export type NewCampaignAllContactsQueryVariables = Exact<{
  options?: InputMaybe<OptionsInput>;
}>;

export type NewCampaignAllContactsQuery = {
  __typename?: 'RootQueryType';
  allContacts: Array<{
    __typename?: 'Contact';
    id: string;
    insertedAt: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    shareholdingsWithoutPreloads: Array<{
      __typename?: 'Shareholding';
      id: string;
      shareCount?: number | null;
    }>;
    tags: Array<{ __typename?: 'Tag'; id: string; name: string }>;
  }>;
};

export type NonDraftNotLinkedPreparedAnnouncementsQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;

export type NonDraftNotLinkedPreparedAnnouncementsQuery = {
  __typename?: 'RootQueryType';
  nonDraftNotLinkedPreparedAnnouncements: Array<{
    __typename?: 'PreparedAnnouncement';
    id: string;
    title?: string | null;
    updatedAt: string;
  }>;
};

export type OldestMediaAnnouncementQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OldestMediaAnnouncementQuery = {
  __typename?: 'RootQueryType';
  oldestMediaAnnouncement?: {
    __typename?: 'MediaAnnouncement';
    id: string;
    header: string;
    likes: number;
    listingKey: string;
    marketKey: string;
    marketSensitive?: boolean | null;
    mediaId: string;
    postedAt: string;
    rectype: string;
    subtypes: Array<string | null>;
    summary?: string | null;
    totalQuestionCount: number;
    totalSurveyResponses: number;
    totalViewCount: number;
    url: string;
    videoUrl?: string | null;
  } | null;
};

export type PageQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type PageQuery = {
  __typename?: 'RootQueryType';
  page?: {
    __typename?: 'Page';
    id: string;
    title?: string | null;
    slug?: string | null;
    publishedAt?: string | null;
    isDraft?: boolean | null;
    metaDescription?: string | null;
    socialImageCloudinaryPublicId?: string | null;
    blocks?: Array<{
      __typename?: 'Block';
      id: string;
      type?: string | null;
      position?: number | null;
      content?: unknown | null;
    } | null> | null;
    lastEditedByCompanyUser?: {
      __typename?: 'CompanyUser';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  } | null;
};

export type PastPlacementParticipantsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
  pastPlacementId: Scalars['ID']['input'];
}>;

export type PastPlacementParticipantsQuery = {
  __typename?: 'RootQueryType';
  pastPlacementParticipants?: {
    __typename?: 'PastPlacementParticipantConnection';
    hasInvalidatedParticipants: boolean;
    total: number;
    edges?: Array<{
      __typename?: 'PastPlacementParticipantEdge';
      node?: {
        __typename?: 'PastPlacementParticipant';
        id: string;
        allocationShares?: number | null;
        invalidated: boolean;
        oneMonthAfterSettlementShares?: number | null;
        oneWeekAfterSettlementShares?: number | null;
        settlementShares?: number | null;
        threeMonthsAfterSettlementShares?: number | null;
        trancheType: PastPlacementParticipantTrancheType;
        shareholding: {
          __typename?: 'Shareholding';
          id: string;
          accountName: string;
          shareCount?: number | null;
        };
      } | null;
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type PastPlacementQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PastPlacementQuery = {
  __typename?: 'RootQueryType';
  pastPlacement: {
    __typename?: 'PastPlacement';
    id: string;
    amountRaised?: number | null;
    amountRaisedTotal?: number | null;
    issuePrice?: number | null;
    movementSince?: number | null;
    offerAnnouncedAt?: string | null;
    outcomeAnnouncedAt?: string | null;
    settledAt?: string | null;
    sharesAllocated?: number | null;
    sharesIssued?: number | null;
    tradingHaltPrice?: number | null;
    tranche?: PastPlacementTranche | null;
    trancheTwoSettledAt?: string | null;
  };
};

export type PastPlacementsQueryVariables = Exact<{ [key: string]: never }>;

export type PastPlacementsQuery = {
  __typename?: 'RootQueryType';
  pastPlacements: Array<{
    __typename?: 'PastPlacement';
    id: string;
    amountRaised?: number | null;
    amountRaisedTotal?: number | null;
    issuePrice?: number | null;
    movementSince?: number | null;
    offerAnnouncedAt?: string | null;
    outcomeAnnouncedAt?: string | null;
    settledAt?: string | null;
    sharesAllocated?: number | null;
    sharesIssued?: number | null;
    tradingHaltPrice?: number | null;
    tranche?: PastPlacementTranche | null;
    trancheTwoSettledAt?: string | null;
  }>;
};

export type PastPlacementAftermarketStatsQueryVariables = Exact<{
  timeRange: PastPlacementAftermarketTimeRange;
  pastPlacementId: Scalars['ID']['input'];
}>;

export type PastPlacementAftermarketStatsQuery = {
  __typename?: 'RootQueryType';
  pastPlacementAftermarketStats: {
    __typename?: 'PastPlacementAftermarketStats';
    churnerCount: number;
    downgraderCount: number;
    upgraderCount: number;
  };
};

export type PendingBulkImportsQueryVariables = Exact<{ [key: string]: never }>;

export type PendingBulkImportsQuery = {
  __typename?: 'RootQueryType';
  pendingBulkImports: Array<{
    __typename?: 'BulkImport';
    id: string;
    insertedAt: string;
    contactsCount: number;
    uploaderProfileUser: {
      __typename?: 'CompanyProfileUser';
      id: string;
      user: {
        __typename?: 'CompanyUser';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      };
    };
  }>;
};

export type PendingReviewCertificatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PendingReviewCertificatesQuery = {
  __typename?: 'RootQueryType';
  pendingReviewCertificates: Array<{
    __typename?: 'InvestorCertificate';
    id: string;
    investorUser: {
      __typename?: 'InvestorUser';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    };
  }>;
};

export type PlacementDemandReportQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PlacementDemandReportQuery = {
  __typename?: 'RootQueryType';
  placementDemandReport: {
    __typename?: 'PlacementDemandReport';
    insights?: Array<{
      __typename?: 'PlacementDemandInsight';
      content?: string | null;
      lastPublishedAt?: string | null;
      topic: PlacementDemandInsightTopic;
    } | null> | null;
    externalEstimate?: {
      __typename?: 'PlacementDemandExternalEstimate';
      id: string;
      high?: number | null;
      lastPublishedAt?: string | null;
      lastRequestedAt?: string | null;
      low?: number | null;
      mid?: number | null;
    } | null;
    investorHubEstimate?: {
      __typename?: 'PlacementDemandInvestorHubEstimate';
      averageBidHigh?: number | null;
      averageBidLow?: number | null;
      averageBidMid?: number | null;
      eligibleInvestorCount?: number | null;
      high?: number | null;
      lastUpdatedAt?: string | null;
      low?: number | null;
      mid?: number | null;
    } | null;
    registerEstimate?: {
      __typename?: 'PlacementDemandRegisterEstimate';
      lastUpdatedDate?: string | null;
      potentialInvestorCount?: number | null;
      potentialInvestors: Array<{
        __typename?: 'PlacementDemandPotentialInvestor';
        address?: string | null;
        email?: string | null;
        maskedHin: string;
        name?: string | null;
        phoneNumber?: string | null;
      }>;
    } | null;
    totalEstimate?: {
      __typename?: 'PlacementDemandTotalEstimate';
      changeOverPastMonthInPercentage?: number | null;
      high?: number | null;
      lastUpdatedAt?: string | null;
      low?: number | null;
      mid?: number | null;
    } | null;
    registryDataStatus?: {
      __typename?: 'RegistryDataStatus';
      latestReportDate?: string | null;
      state: RegistryImportState;
    } | null;
  };
};

export type PreparedAnnouncementQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PreparedAnnouncementQuery = {
  __typename?: 'RootQueryType';
  preparedAnnouncement?: {
    __typename?: 'PreparedAnnouncement';
    id: string;
    commentContent?: string | null;
    commentUseCompanyAsUsername?: boolean | null;
    hashId: string;
    insertedAt: string;
    isDraft: boolean;
    mediaId?: string | null;
    socialVideoUrl?: string | null;
    summary?: string | null;
    title?: string | null;
    updatedAt: string;
    videoUrl?: string | null;
    germanTranslatedVideoUrl?: string | null;
    germanTranslatedUrl?: string | null;
    germanTranslatedHeader?: string | null;
    germanTranslatedSummary?: string | null;
    mediaAnnouncement?: { __typename?: 'MediaAnnouncement'; id: string } | null;
  } | null;
};

export type RaisesSppHistoricalForCurrentCompanyQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RaisesSppHistoricalForCurrentCompanyQuery = {
  __typename?: 'RootQueryType';
  raisesSppHistoricalForCurrentCompany: Array<{
    __typename?: 'RaiseSppHistorical';
    ticker: string;
    sectorTicker: string;
    amountRaised?: number | null;
    marketCap: number;
    dateAnn?: string | null;
    belongsToCurrentCompany: boolean;
  }>;
};

export type RaiseSppHistoricalQueryVariables = Exact<{
  sectorTicker:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
}>;

export type RaiseSppHistoricalQuery = {
  __typename?: 'RootQueryType';
  raisesSppHistorical: Array<{
    __typename?: 'RaiseSppHistorical';
    ticker: string;
    sectorTicker: string;
    amountRaised?: number | null;
    marketCap: number;
    dateAnn?: string | null;
    belongsToCurrentCompany: boolean;
  }>;
};

export type RaisesSppReachabilityStatsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RaisesSppReachabilityStatsQuery = {
  __typename?: 'RootQueryType';
  raisesSppReachabilityStats?: {
    __typename?: 'RaiseSppReachabilityStats';
    totalHubUsers: number;
    engagedHubUsers: number;
    totalPhones: number;
    totalEmails: number;
    openRate: number;
    clickRate: number;
  } | null;
};

export type RaisesSppShareholderStatsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RaisesSppShareholderStatsQuery = {
  __typename?: 'RootQueryType';
  raisesSppShareholderStats?: {
    __typename?: 'RaiseSppShareholderStats';
    totalShareholders: number;
    averageHoldSize: number;
    averageHoldLength: number;
    totalHnws: number;
    totalQual: number;
    totalActive: number;
    totalProfit: number;
  } | null;
};

export type RefinitivTokenQueryVariables = Exact<{ [key: string]: never }>;

export type RefinitivTokenQuery = {
  __typename?: 'RootQueryType';
  token?: { __typename?: 'Token'; value: string } | null;
};

export type RegistryImportStatusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RegistryImportStatusQuery = {
  __typename?: 'RootQueryType';
  registryImportStatus?: {
    __typename?: 'RegistryDataStatus';
    id?: string | null;
    registry: Registries;
    state: RegistryImportState;
    lastSuccessfulRegisterImportAt?: string | null;
    latestReportDate?: string | null;
    earliestReportDate?: string | null;
  } | null;
};

export type RegistryStatusQueryVariables = Exact<{ [key: string]: never }>;

export type RegistryStatusQuery = {
  __typename?: 'RootQueryType';
  registryStatus?: {
    __typename?: 'RegistryStatus';
    expiryDate?: string | null;
    status: RegistryCredentialStatus;
    username?: string | null;
  } | null;
};

export type RegressRaiseSppHistoricalQueryVariables = Exact<{
  sectorTicker:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
}>;

export type RegressRaiseSppHistoricalQuery = {
  __typename?: 'RootQueryType';
  regressRaisesSppHistorical?: {
    __typename?: 'RaiseSppRegressParams';
    coefficients: number;
    intercept: number;
  } | null;
};

export type ShareMovementsQueryVariables = Exact<{
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;

export type ShareMovementsQuery = {
  __typename?: 'RootQueryType';
  shareMovements: {
    __typename?: 'ShareMovements';
    id: string;
    totalSold?: number | null;
    totalBought?: number | null;
    topBuyers: Array<{
      __typename?: 'BuyerField';
      id: string;
      accountName: string;
      movement: number;
      movementType: MovementType;
    }>;
    topSellers: Array<{
      __typename?: 'SellerField';
      id: string;
      accountName: string;
      movement: number;
      movementType: MovementType;
    }>;
    topShareholders: Array<{
      __typename?: 'TopShareholderField';
      id: string;
      balance: number;
      movement: number;
      accountName: string;
    }>;
  };
};

export type HnwIdentifiedNumbersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type HnwIdentifiedNumbersQuery = {
  __typename?: 'RootQueryType';
  hnwIdentifiedNumbers?: {
    __typename?: 'HnwIdentifiedStats';
    id: string;
    lastSevenDays?: number | null;
    sevenToFourteenDaysAgo?: number | null;
  } | null;
};

export type HnwInvestorsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  hnwType?: InputMaybe<HnwQueryType>;
  includePastInvestors?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<OrderInput>;
}>;

export type HnwInvestorsQuery = {
  __typename?: 'RootQueryType';
  hnwInvestors?: {
    __typename?: 'HnwInvestorConnection';
    total: number;
    edges?: Array<{
      __typename?: 'HnwInvestorEdge';
      node?: {
        __typename?: 'HnwInvestor';
        id: string;
        hnwIdentifiedAt: string;
        hnwStatus?: ContactHnwStatuses | null;
        hnwBehaviour?: HnwBehaviours | null;
        email?: string | null;
        name?: string | null;
        accountNames?: Array<string | null> | null;
        shareCount?: number | null;
        type: HnwInvestorType;
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
    totals: {
      __typename?: 'HnwTotals';
      id: string;
      all: number;
      nominatedWithoutCert: number;
      nominatedCertPending: number;
      nominatedCertVerified: number;
      identifiedViaBehaviour: number;
    };
  } | null;
};

export type ShareholderOfferQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ShareholderOfferQuery = {
  __typename?: 'RootQueryType';
  shareholderOffer: {
    __typename?: 'ShareholderOffer';
    id: string;
    title: string;
    type: ShareholderOfferType;
    isLive: boolean;
    publishedAt?: string | null;
    scheduledAt?: string | null;
    insertedAt: string;
    updatedAt: string;
    totalViewCount: number;
    totalViewCountFromHubUsers: number;
    totalUniqueVisitorsCount: number;
    totalUniqueVisitorsCountFromHubUsers: number;
    signUpsDuringOfferPeriod: number;
    totalInvestorPresentationDownloadsCount: number;
    totalOfferBookletDownloadsCount: number;
    companyProfile: {
      __typename?: 'CompanyProfile';
      id: string;
      registry?: Registries | null;
    };
    lastEditedByUser: {
      __typename?: 'CompanyUser';
      email: string;
      firstName?: string | null;
      lastName?: string | null;
    };
    privateViewers: Array<{
      __typename?: 'PrivateViewer';
      id: string;
      email: string;
    }>;
    shareholderOfferEngagement: Array<{
      __typename?: 'ShareholderOfferEngagement';
      date: string;
      totalViewCount: number;
      totalUniqueVisitorsCount: number;
    }>;
    companyShareholderOfferPage?: {
      __typename?: 'ShareholderOfferPage';
      id: string;
      bannerUrl?: string | null;
      closingDate?: string | null;
      interactiveAnnouncementLink?: string | null;
      introductionHeading?: string | null;
      introductionMessage?: string | null;
      investorPresentationName?: string | null;
      investorPresentationUrl?: string | null;
      offerBookletName?: string | null;
      offerBookletUrl?: string | null;
      offerPrice?: string | null;
      raiseApplyInstructionMessage?: string | null;
      raiseDefinitionMessage?: string | null;
      raiseDefinitionTimelineDiagramUrl?: string | null;
      raiseReasonHeroMediaImageUrl?: string | null;
      raiseReasonHeroMediaVideoUrl?: string | null;
      raiseReasonHeroMediaSocialVideoUrl?: string | null;
      raiseReasonMessage?: string | null;
      raisingTarget?: number | null;
      usCitizenPageEnabled?: boolean | null;
      insertedAt: string;
      updatedAt: string;
      lastEditedByUser: {
        __typename?: 'CompanyUser';
        email: string;
        firstName?: string | null;
        lastName?: string | null;
      };
      faqs: Array<{
        __typename?: 'Faq';
        id?: string | null;
        answer: string;
        question: string;
        orderId: number;
        shareholderOfferPageId: string;
      }>;
      instructions: Array<{
        __typename?: 'Instruction';
        id: string;
        subheading: string;
        message: string;
        buttonUrl?: string | null;
        buttonText?: string | null;
        orderId: number;
        shareholderOfferPageId: string;
      }>;
      shareholderOffer: {
        __typename?: 'ShareholderOffer';
        id: string;
        type: ShareholderOfferType;
        isLive: boolean;
        publishedAt?: string | null;
        scheduledAt?: string | null;
      };
    } | null;
  };
};

export type ShareholderOffersQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
}>;

export type ShareholderOffersQuery = {
  __typename?: 'RootQueryType';
  shareholderOffers?: {
    __typename?: 'ShareholderOfferConnection';
    total: number;
    edges?: Array<{
      __typename?: 'ShareholderOfferEdge';
      node?: {
        __typename?: 'ShareholderOffer';
        id: string;
        title: string;
        type: ShareholderOfferType;
        isLive: boolean;
        publishedAt?: string | null;
        scheduledAt?: string | null;
        insertedAt: string;
        updatedAt: string;
        companyProfile: {
          __typename?: 'CompanyProfile';
          id: string;
          registry?: Registries | null;
        };
        lastEditedByUser: {
          __typename?: 'CompanyUser';
          email: string;
          firstName?: string | null;
          lastName?: string | null;
        };
        companyShareholderOfferPage?: {
          __typename?: 'ShareholderOfferPage';
          id: string;
          insertedAt: string;
          updatedAt: string;
          lastEditedByUser: {
            __typename?: 'CompanyUser';
            email: string;
            firstName?: string | null;
            lastName?: string | null;
          };
        } | null;
      } | null;
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type ShareholderTradingActivityQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<OrderInput>;
  shareholderTradingActivityInput: ShareholderTradingActivityInput;
}>;

export type ShareholderTradingActivityQuery = {
  __typename?: 'RootQueryType';
  shareholderTradingActivity?: {
    __typename?: 'ShareholderTradingActivityConnection';
    total: number;
    edges?: Array<{
      __typename?: 'ShareholderTradingActivityEdge';
      node?: {
        __typename?: 'ShareholderTradingActivity';
        id: string;
        accountName: string;
        daysTraded: number;
        email?: string | null;
        holdings: number;
        movement: number;
        shareholdingId: string;
      } | null;
    } | null> | null;
    order?: {
      __typename?: 'Order';
      key?: string | null;
      value?: string | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type ShareholdingSummaryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
}>;

export type ShareholdingSummaryQuery = {
  __typename?: 'RootQueryType';
  shareholdingSummary?: {
    __typename?: 'ShareholdingSummary';
    id: string;
    announcements: Array<{
      __typename?: 'MediaAnnouncement';
      id: string;
      header: string;
      postedAt: string;
    } | null>;
    dailyHoldings: Array<{
      __typename?: 'DailyHolding';
      id: string;
      date: string;
      balance: number;
    } | null>;
    shareMovements: Array<{
      __typename?: 'ShareMovement';
      id: string;
      closingBalance: number;
      movement: number;
      movementType?: string | null;
      openingBalance: number;
      settledAt: string;
    } | null>;
    timeseries: Array<{
      __typename?: 'Timeseries';
      id: string;
      close?: number | null;
      open?: number | null;
      high?: number | null;
      low?: number | null;
      date: string;
      volume?: number | null;
    } | null>;
  } | null;
};

export type ShareholdingQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ShareholdingQuery = {
  __typename?: 'RootQueryType';
  shareholding?: {
    __typename?: 'Shareholding';
    id: string;
    accountName: string;
    addressCity?: string | null;
    addressCountry?: string | null;
    addressLineOne?: string | null;
    addressLineTwo?: string | null;
    addressPostcode?: string | null;
    addressState?: string | null;
    brokerNameShort?: string | null;
    hnwIdentifiedAt?: string | null;
    hnwBehaviour?: HnwBehaviours | null;
    holderIdMasked?: string | null;
    initialPurchaseDate?: string | null;
    currentHoldingStartDate?: string | null;
    email?: string | null;
    movementCount?: number | null;
    phoneNumber?: string | null;
    shareCount?: number | null;
    estimatedProfitLoss?: number | null;
    estimatedTotalPurchaseValue?: number | null;
    estimatedTotalSaleValue?: number | null;
    shareCountRank?: number | null;
    hasParticipatedInSpp?: boolean | null;
    hasParticipatedInPlacement?: boolean | null;
    contact?: {
      __typename?: 'Contact';
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      shareholdings: Array<{
        __typename?: 'Shareholding';
        id: string;
        accountName: string;
      }>;
    } | null;
    latestShareMovement?: {
      __typename?: 'ShareMovement';
      id: string;
      closingBalance: number;
      movement: number;
      openingBalance: number;
      settledAt: string;
    } | null;
  } | null;
};

export type ShareholdingsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<OptionsInput>;
}>;

export type ShareholdingsQuery = {
  __typename?: 'RootQueryType';
  shareholdings?: {
    __typename?: 'ShareholdingConnection';
    total: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    edges?: Array<{
      __typename?: 'ShareholdingEdge';
      node?: {
        __typename?: 'Shareholding';
        accountName: string;
        currentHoldingStartDate?: string | null;
        brokerNameShort?: string | null;
        email?: string | null;
        id: string;
        hnwIdentifiedAt?: string | null;
        hnwBehaviour?: HnwBehaviours | null;
        initialPurchaseDate?: string | null;
        movementCount?: number | null;
        shareCount?: number | null;
        phoneNumber?: string | null;
        addressCity?: string | null;
        shareCountRank?: number | null;
        hasParticipatedInSpp?: boolean | null;
        hasParticipatedInPlacement?: boolean | null;
        contact?: {
          __typename?: 'Contact';
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type LinkedinOrganisationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LinkedinOrganisationsQuery = {
  __typename?: 'RootQueryType';
  linkedinOrganisations: Array<{
    __typename?: 'LinkedinOrganisation';
    id: string;
    name: string;
  }>;
};

export type SppEstimateQueryVariables = Exact<{ [key: string]: never }>;

export type SppEstimateQuery = {
  __typename?: 'RootQueryType';
  sppEstimate?: {
    __typename?: 'SppEstimate';
    medEstimate: number;
    partRate: number;
    avgPart: number;
    priorPart?: number | null;
    updatedAt: string;
  } | null;
};

export type SuggestedActionsMetadataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SuggestedActionsMetadataQuery = {
  __typename?: 'RootQueryType';
  suggestedActionsMetadata?: {
    __typename?: 'SuggestedActionsMetadata';
    id: string;
    activeQuestionCount: number;
    aiSummariesEnabled: boolean;
    isAutomatedDistributionSwitchedOn: boolean;
    lastUpdatePostedDate?: string | null;
  } | null;
};

export type UpdateWebsiteMutationVariables = Exact<{
  themeColourPrimary?: InputMaybe<Scalars['String']['input']>;
  themeColourPrimaryText?: InputMaybe<Scalars['String']['input']>;
  themeColourAccent?: InputMaybe<Scalars['String']['input']>;
  themeColourAccentText?: InputMaybe<Scalars['String']['input']>;
  themeFontTitle?: InputMaybe<Scalars['String']['input']>;
  themeFontBody?: InputMaybe<Scalars['String']['input']>;
  logoCloudinaryUrl?: InputMaybe<Scalars['String']['input']>;
  logoSquareCloudinaryUrl?: InputMaybe<Scalars['String']['input']>;
  isFullWebsiteReplacement?: InputMaybe<Scalars['Boolean']['input']>;
  isStickySignupBannerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isEmailPopupModalEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  emailPopupModalCloudinaryUrl?: InputMaybe<Scalars['String']['input']>;
  googleAnalyticsId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateWebsiteMutation = {
  __typename?: 'RootMutationType';
  updateWebsite: { __typename?: 'Website'; id: string };
};

export type UpdatesEngagementByContentTypeQueryVariables = Exact<{
  startDate: Scalars['NaiveDateTime']['input'];
  endDate: Scalars['NaiveDateTime']['input'];
}>;

export type UpdatesEngagementByContentTypeQuery = {
  __typename?: 'RootQueryType';
  updatesEngagementByContentType: Array<{
    __typename?: 'UpdatesTypeViewsAndEngagement';
    totalEngagement: number;
    totalViews: number;
    includedMediaType: IncludedMediaUpdateType;
  }>;
};

export type UtmLinkAnalyticsSignupsQueryVariables = Exact<{
  utmLinkId: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
}>;

export type UtmLinkAnalyticsSignupsQuery = {
  __typename?: 'RootQueryType';
  utmLinkAnalyticsSignups?: {
    __typename?: 'UtmInvestorSignupConnection';
    total: number;
    edges?: Array<{
      __typename?: 'UtmInvestorSignupEdge';
      node: {
        __typename?: 'Contact';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
        insertedAt: string;
        hnwIdentifiedAt?: string | null;
        shareholderStatus?: ShareholderStatus | null;
        hnwStatus?: ContactHnwStatuses | null;
      };
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type UtmLinkAnalyticsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  startDate: Scalars['NaiveDateTime']['input'];
  endDate: Scalars['NaiveDateTime']['input'];
}>;

export type UtmLinkAnalyticsQuery = {
  __typename?: 'RootQueryType';
  utmLinkAnalytics?: {
    __typename?: 'UtmLinkAnalytics';
    currPeriodAnalytics: {
      __typename?: 'UtmLinkStatsForPeriod';
      id: string;
      totalHits: number;
      totalSignUps: number;
      totalUniqueHits: number;
      totalSignUpsList: Array<{
        __typename?: 'DailyUserSignups';
        date: string;
        totalSignups: number;
      } | null>;
      totalHitsList: Array<{
        __typename?: 'DailyHits';
        date: string;
        totalHits: number;
      } | null>;
      totalUniqueHitsList: Array<{
        __typename?: 'DailyUniqueHits';
        date: string;
        uniqueHits: number;
      } | null>;
    };
    prevPeriodAnalytics: {
      __typename?: 'UtmLinkStatsForPeriod';
      totalHits: number;
      totalSignUps: number;
      totalUniqueHits: number;
      totalSignUpsList: Array<{
        __typename?: 'DailyUserSignups';
        date: string;
        totalSignups: number;
      } | null>;
      totalHitsList: Array<{
        __typename?: 'DailyHits';
        date: string;
        totalHits: number;
      } | null>;
      totalUniqueHitsList: Array<{
        __typename?: 'DailyUniqueHits';
        date: string;
        uniqueHits: number;
      } | null>;
    };
  } | null;
};

export type GetUtmLinkQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetUtmLinkQuery = {
  __typename?: 'RootQueryType';
  getUtmLink?: {
    __typename?: 'UtmLink';
    id: string;
    hash: string;
    destinationUrl: string;
    utmCampaign: string;
    utmMedium: string;
    utmSource: string;
    utmUrl: string;
    insertedAt: string;
    updatedAt: string;
  } | null;
};

export type UtmLinksQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<OptionsInput>;
}>;

export type UtmLinksQuery = {
  __typename?: 'RootQueryType';
  utmLinks?: {
    __typename?: 'UtmLinkConnection';
    total: number;
    edges?: Array<{
      __typename?: 'UtmLinkEdge';
      node?: {
        __typename?: 'UtmLink';
        id: string;
        hash: string;
        destinationUrl: string;
        utmCampaign: string;
        utmSource: string;
        utmMedium: string;
        utmUrl: string;
        insertedAt: string;
        updatedAt: string;
      } | null;
    } | null> | null;
    options?: {
      __typename?: 'Options';
      filters?: Array<{
        __typename?: 'Filter';
        key?: string | null;
        value?: string | null;
      } | null> | null;
      orders?: Array<{
        __typename?: 'Order';
        key?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  } | null;
};

export type CompanyProfileUserUpdatedSubscriptionVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CompanyProfileUserUpdatedSubscription = {
  __typename?: 'RootSubscriptionType';
  companyProfileUserUpdated?: {
    __typename?: 'CompanyProfileUser';
    id: string;
    status: CompanyProfileUserStatus;
  } | null;
};

export type CurrentCompanyProfileUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentCompanyProfileUpdatedSubscription = {
  __typename?: 'RootSubscriptionType';
  currentCompanyProfileUpdated?: {
    __typename?: 'CompanyProfile';
    id: string;
    addressLine1?: string | null;
    addressLine2?: string | null;
    city?: string | null;
    country?: string | null;
    email?: string | null;
    informationConfirmedAt?: string | null;
    insertedAt?: string | null;
    investorCentreEnabled?: boolean | null;
    logoUrl: string;
    mobileNumber?: string | null;
    name: string;
    postalCode?: string | null;
    state?: string | null;
  } | null;
};

export const DistributionSettingsFieldsFragmentDoc = gql`
  fragment DistributionSettingsFields on DistributionSettings {
    id
    channel
    emailSettings {
      id
      emailHtml
      emailJson
      fromName
      subject
    }
    excludedContacts
    includedAnnouncementTypes
    includedUpdateTypes
    isActive
    recipientListType
    socialPostTemplate
    usedDefaultTypes
    updatedAt
    shareholderWelcomeEnabled
  }
`;
export const CurrentCompanyDistributionSettingsFieldsFragmentDoc = gql`
  fragment CurrentCompanyDistributionSettingsFields on CurrentCompanyDistributionSettings {
    id
    updatedAt
    email {
      ...DistributionSettingsFields
    }
    linkedin {
      ...DistributionSettingsFields
    }
    twitter {
      ...DistributionSettingsFields
    }
  }
  ${DistributionSettingsFieldsFragmentDoc}
`;
export const ActivateCompanyProfileUserDocument = gql`
  mutation ActivateCompanyProfileUser {
    activateCompanyProfileUser {
      id
      status
    }
  }
`;
export type ActivateCompanyProfileUserMutationFn = Apollo.MutationFunction<
  ActivateCompanyProfileUserMutation,
  ActivateCompanyProfileUserMutationVariables
>;

/**
 * __useActivateCompanyProfileUserMutation__
 *
 * To run a mutation, you first call `useActivateCompanyProfileUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCompanyProfileUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCompanyProfileUserMutation, { data, loading, error }] = useActivateCompanyProfileUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useActivateCompanyProfileUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateCompanyProfileUserMutation,
    ActivateCompanyProfileUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivateCompanyProfileUserMutation,
    ActivateCompanyProfileUserMutationVariables
  >(ActivateCompanyProfileUserDocument, options);
}
export type ActivateCompanyProfileUserMutationHookResult = ReturnType<
  typeof useActivateCompanyProfileUserMutation
>;
export type ActivateCompanyProfileUserMutationResult =
  Apollo.MutationResult<ActivateCompanyProfileUserMutation>;
export type ActivateCompanyProfileUserMutationOptions =
  Apollo.BaseMutationOptions<
    ActivateCompanyProfileUserMutation,
    ActivateCompanyProfileUserMutationVariables
  >;
export const ActivateCurrentCompanyDistributionSettingsDocument = gql`
  mutation ActivateCurrentCompanyDistributionSettings($flowType: FlowType!) {
    activateCurrentCompanyDistributionSettings(flowType: $flowType) {
      ...CurrentCompanyDistributionSettingsFields
    }
  }
  ${CurrentCompanyDistributionSettingsFieldsFragmentDoc}
`;
export type ActivateCurrentCompanyDistributionSettingsMutationFn =
  Apollo.MutationFunction<
    ActivateCurrentCompanyDistributionSettingsMutation,
    ActivateCurrentCompanyDistributionSettingsMutationVariables
  >;

/**
 * __useActivateCurrentCompanyDistributionSettingsMutation__
 *
 * To run a mutation, you first call `useActivateCurrentCompanyDistributionSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCurrentCompanyDistributionSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCurrentCompanyDistributionSettingsMutation, { data, loading, error }] = useActivateCurrentCompanyDistributionSettingsMutation({
 *   variables: {
 *      flowType: // value for 'flowType'
 *   },
 * });
 */
export function useActivateCurrentCompanyDistributionSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateCurrentCompanyDistributionSettingsMutation,
    ActivateCurrentCompanyDistributionSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivateCurrentCompanyDistributionSettingsMutation,
    ActivateCurrentCompanyDistributionSettingsMutationVariables
  >(ActivateCurrentCompanyDistributionSettingsDocument, options);
}
export type ActivateCurrentCompanyDistributionSettingsMutationHookResult =
  ReturnType<typeof useActivateCurrentCompanyDistributionSettingsMutation>;
export type ActivateCurrentCompanyDistributionSettingsMutationResult =
  Apollo.MutationResult<ActivateCurrentCompanyDistributionSettingsMutation>;
export type ActivateCurrentCompanyDistributionSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    ActivateCurrentCompanyDistributionSettingsMutation,
    ActivateCurrentCompanyDistributionSettingsMutationVariables
  >;
export const AutoUnblockCloudIpDocument = gql`
  mutation AutoUnblockCloudIp(
    $email: String
    $ticker: String!
    $marketKey: String!
  ) {
    autoUnblockCloudIp(email: $email, ticker: $ticker, marketKey: $marketKey)
  }
`;
export type AutoUnblockCloudIpMutationFn = Apollo.MutationFunction<
  AutoUnblockCloudIpMutation,
  AutoUnblockCloudIpMutationVariables
>;

/**
 * __useAutoUnblockCloudIpMutation__
 *
 * To run a mutation, you first call `useAutoUnblockCloudIpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAutoUnblockCloudIpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [autoUnblockCloudIpMutation, { data, loading, error }] = useAutoUnblockCloudIpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      ticker: // value for 'ticker'
 *      marketKey: // value for 'marketKey'
 *   },
 * });
 */
export function useAutoUnblockCloudIpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AutoUnblockCloudIpMutation,
    AutoUnblockCloudIpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AutoUnblockCloudIpMutation,
    AutoUnblockCloudIpMutationVariables
  >(AutoUnblockCloudIpDocument, options);
}
export type AutoUnblockCloudIpMutationHookResult = ReturnType<
  typeof useAutoUnblockCloudIpMutation
>;
export type AutoUnblockCloudIpMutationResult =
  Apollo.MutationResult<AutoUnblockCloudIpMutation>;
export type AutoUnblockCloudIpMutationOptions = Apollo.BaseMutationOptions<
  AutoUnblockCloudIpMutation,
  AutoUnblockCloudIpMutationVariables
>;
export const ChangeCompanyProfileUserRoleDocument = gql`
  mutation ChangeCompanyProfileUserRole(
    $id: ID!
    $role: CompanyProfileUserRole!
  ) {
    changeCompanyProfileUserRole(id: $id, role: $role) {
      id
    }
  }
`;
export type ChangeCompanyProfileUserRoleMutationFn = Apollo.MutationFunction<
  ChangeCompanyProfileUserRoleMutation,
  ChangeCompanyProfileUserRoleMutationVariables
>;

/**
 * __useChangeCompanyProfileUserRoleMutation__
 *
 * To run a mutation, you first call `useChangeCompanyProfileUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCompanyProfileUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCompanyProfileUserRoleMutation, { data, loading, error }] = useChangeCompanyProfileUserRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useChangeCompanyProfileUserRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeCompanyProfileUserRoleMutation,
    ChangeCompanyProfileUserRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeCompanyProfileUserRoleMutation,
    ChangeCompanyProfileUserRoleMutationVariables
  >(ChangeCompanyProfileUserRoleDocument, options);
}
export type ChangeCompanyProfileUserRoleMutationHookResult = ReturnType<
  typeof useChangeCompanyProfileUserRoleMutation
>;
export type ChangeCompanyProfileUserRoleMutationResult =
  Apollo.MutationResult<ChangeCompanyProfileUserRoleMutation>;
export type ChangeCompanyProfileUserRoleMutationOptions =
  Apollo.BaseMutationOptions<
    ChangeCompanyProfileUserRoleMutation,
    ChangeCompanyProfileUserRoleMutationVariables
  >;
export const ChangePasswordDocument = gql`
  mutation ChangePassword($oldPassword: String!, $password: String!) {
    changePassword(oldPassword: $oldPassword, password: $password)
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>;
export type ChangePasswordMutationResult =
  Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const ChangeShareholderOfferUsCitzenCheckDocument = gql`
  mutation ChangeShareholderOfferUsCitzenCheck(
    $shareholderOfferPageId: ID!
    $updatedCheck: Boolean!
  ) {
    changeShareholderOfferUsCitzenCheck(
      shareholderOfferPageId: $shareholderOfferPageId
      updatedCheck: $updatedCheck
    ) {
      id
      usCitizenPageEnabled
    }
  }
`;
export type ChangeShareholderOfferUsCitzenCheckMutationFn =
  Apollo.MutationFunction<
    ChangeShareholderOfferUsCitzenCheckMutation,
    ChangeShareholderOfferUsCitzenCheckMutationVariables
  >;

/**
 * __useChangeShareholderOfferUsCitzenCheckMutation__
 *
 * To run a mutation, you first call `useChangeShareholderOfferUsCitzenCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeShareholderOfferUsCitzenCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeShareholderOfferUsCitzenCheckMutation, { data, loading, error }] = useChangeShareholderOfferUsCitzenCheckMutation({
 *   variables: {
 *      shareholderOfferPageId: // value for 'shareholderOfferPageId'
 *      updatedCheck: // value for 'updatedCheck'
 *   },
 * });
 */
export function useChangeShareholderOfferUsCitzenCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeShareholderOfferUsCitzenCheckMutation,
    ChangeShareholderOfferUsCitzenCheckMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeShareholderOfferUsCitzenCheckMutation,
    ChangeShareholderOfferUsCitzenCheckMutationVariables
  >(ChangeShareholderOfferUsCitzenCheckDocument, options);
}
export type ChangeShareholderOfferUsCitzenCheckMutationHookResult = ReturnType<
  typeof useChangeShareholderOfferUsCitzenCheckMutation
>;
export type ChangeShareholderOfferUsCitzenCheckMutationResult =
  Apollo.MutationResult<ChangeShareholderOfferUsCitzenCheckMutation>;
export type ChangeShareholderOfferUsCitzenCheckMutationOptions =
  Apollo.BaseMutationOptions<
    ChangeShareholderOfferUsCitzenCheckMutation,
    ChangeShareholderOfferUsCitzenCheckMutationVariables
  >;
export const ConfirmCompanyProfileInformationDocument = gql`
  mutation ConfirmCompanyProfileInformation(
    $listingKey: String!
    $name: String!
  ) {
    confirmCompanyProfileInformation(listingKey: $listingKey, name: $name) {
      id
      name
      ticker {
        id
        listingKey
      }
    }
  }
`;
export type ConfirmCompanyProfileInformationMutationFn =
  Apollo.MutationFunction<
    ConfirmCompanyProfileInformationMutation,
    ConfirmCompanyProfileInformationMutationVariables
  >;

/**
 * __useConfirmCompanyProfileInformationMutation__
 *
 * To run a mutation, you first call `useConfirmCompanyProfileInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmCompanyProfileInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmCompanyProfileInformationMutation, { data, loading, error }] = useConfirmCompanyProfileInformationMutation({
 *   variables: {
 *      listingKey: // value for 'listingKey'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useConfirmCompanyProfileInformationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmCompanyProfileInformationMutation,
    ConfirmCompanyProfileInformationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmCompanyProfileInformationMutation,
    ConfirmCompanyProfileInformationMutationVariables
  >(ConfirmCompanyProfileInformationDocument, options);
}
export type ConfirmCompanyProfileInformationMutationHookResult = ReturnType<
  typeof useConfirmCompanyProfileInformationMutation
>;
export type ConfirmCompanyProfileInformationMutationResult =
  Apollo.MutationResult<ConfirmCompanyProfileInformationMutation>;
export type ConfirmCompanyProfileInformationMutationOptions =
  Apollo.BaseMutationOptions<
    ConfirmCompanyProfileInformationMutation,
    ConfirmCompanyProfileInformationMutationVariables
  >;
export const ConfirmCompanyUserInformationAndActivateCompanyProfileUserDocument = gql`
  mutation ConfirmCompanyUserInformationAndActivateCompanyProfileUser(
    $firstName: String!
    $jobTitle: String!
    $lastName: String!
    $mobileNumber: String
  ) {
    confirmCompanyUserInformationAndActivateCompanyProfileUser(
      firstName: $firstName
      jobTitle: $jobTitle
      lastName: $lastName
      mobileNumber: $mobileNumber
    ) {
      id
      jobTitle
      status
      user {
        id
        firstName
        lastName
        mobileNumber
      }
    }
  }
`;
export type ConfirmCompanyUserInformationAndActivateCompanyProfileUserMutationFn =
  Apollo.MutationFunction<
    ConfirmCompanyUserInformationAndActivateCompanyProfileUserMutation,
    ConfirmCompanyUserInformationAndActivateCompanyProfileUserMutationVariables
  >;

/**
 * __useConfirmCompanyUserInformationAndActivateCompanyProfileUserMutation__
 *
 * To run a mutation, you first call `useConfirmCompanyUserInformationAndActivateCompanyProfileUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmCompanyUserInformationAndActivateCompanyProfileUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmCompanyUserInformationAndActivateCompanyProfileUserMutation, { data, loading, error }] = useConfirmCompanyUserInformationAndActivateCompanyProfileUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      jobTitle: // value for 'jobTitle'
 *      lastName: // value for 'lastName'
 *      mobileNumber: // value for 'mobileNumber'
 *   },
 * });
 */
export function useConfirmCompanyUserInformationAndActivateCompanyProfileUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmCompanyUserInformationAndActivateCompanyProfileUserMutation,
    ConfirmCompanyUserInformationAndActivateCompanyProfileUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmCompanyUserInformationAndActivateCompanyProfileUserMutation,
    ConfirmCompanyUserInformationAndActivateCompanyProfileUserMutationVariables
  >(
    ConfirmCompanyUserInformationAndActivateCompanyProfileUserDocument,
    options
  );
}
export type ConfirmCompanyUserInformationAndActivateCompanyProfileUserMutationHookResult =
  ReturnType<
    typeof useConfirmCompanyUserInformationAndActivateCompanyProfileUserMutation
  >;
export type ConfirmCompanyUserInformationAndActivateCompanyProfileUserMutationResult =
  Apollo.MutationResult<ConfirmCompanyUserInformationAndActivateCompanyProfileUserMutation>;
export type ConfirmCompanyUserInformationAndActivateCompanyProfileUserMutationOptions =
  Apollo.BaseMutationOptions<
    ConfirmCompanyUserInformationAndActivateCompanyProfileUserMutation,
    ConfirmCompanyUserInformationAndActivateCompanyProfileUserMutationVariables
  >;
export const ContactUsDocument = gql`
  mutation ContactUs($message: String!, $type: String!) {
    contactUs(message: $message, type: $type)
  }
`;
export type ContactUsMutationFn = Apollo.MutationFunction<
  ContactUsMutation,
  ContactUsMutationVariables
>;

/**
 * __useContactUsMutation__
 *
 * To run a mutation, you first call `useContactUsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactUsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactUsMutation, { data, loading, error }] = useContactUsMutation({
 *   variables: {
 *      message: // value for 'message'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useContactUsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContactUsMutation,
    ContactUsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ContactUsMutation, ContactUsMutationVariables>(
    ContactUsDocument,
    options
  );
}
export type ContactUsMutationHookResult = ReturnType<
  typeof useContactUsMutation
>;
export type ContactUsMutationResult = Apollo.MutationResult<ContactUsMutation>;
export type ContactUsMutationOptions = Apollo.BaseMutationOptions<
  ContactUsMutation,
  ContactUsMutationVariables
>;
export const CreateContactDocument = gql`
  mutation CreateContact($contact: ContactInput!) {
    createContact(contact: $contact) {
      id
    }
  }
`;
export type CreateContactMutationFn = Apollo.MutationFunction<
  CreateContactMutation,
  CreateContactMutationVariables
>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useCreateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContactMutation,
    CreateContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateContactMutation,
    CreateContactMutationVariables
  >(CreateContactDocument, options);
}
export type CreateContactMutationHookResult = ReturnType<
  typeof useCreateContactMutation
>;
export type CreateContactMutationResult =
  Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<
  CreateContactMutation,
  CreateContactMutationVariables
>;
export const CreateContactNoteDocument = gql`
  mutation CreateContactNote($contactId: ID!, $contactNote: ContactNoteInput!) {
    createContactNote(contactId: $contactId, contactNote: $contactNote) {
      id
    }
  }
`;
export type CreateContactNoteMutationFn = Apollo.MutationFunction<
  CreateContactNoteMutation,
  CreateContactNoteMutationVariables
>;

/**
 * __useCreateContactNoteMutation__
 *
 * To run a mutation, you first call `useCreateContactNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactNoteMutation, { data, loading, error }] = useCreateContactNoteMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      contactNote: // value for 'contactNote'
 *   },
 * });
 */
export function useCreateContactNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContactNoteMutation,
    CreateContactNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateContactNoteMutation,
    CreateContactNoteMutationVariables
  >(CreateContactNoteDocument, options);
}
export type CreateContactNoteMutationHookResult = ReturnType<
  typeof useCreateContactNoteMutation
>;
export type CreateContactNoteMutationResult =
  Apollo.MutationResult<CreateContactNoteMutation>;
export type CreateContactNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateContactNoteMutation,
  CreateContactNoteMutationVariables
>;
export const CreateTagDocument = gql`
  mutation CreateTag($contactId: ID!, $tag: TagInput!) {
    createTag(contactId: $contactId, tag: $tag) {
      id
    }
  }
`;
export type CreateTagMutationFn = Apollo.MutationFunction<
  CreateTagMutation,
  CreateTagMutationVariables
>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useCreateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTagMutation,
    CreateTagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(
    CreateTagDocument,
    options
  );
}
export type CreateTagMutationHookResult = ReturnType<
  typeof useCreateTagMutation
>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<
  CreateTagMutation,
  CreateTagMutationVariables
>;
export const UpdateContactDocument = gql`
  mutation UpdateContact($id: ID!, $contact: ContactInput!) {
    updateContact(id: $id, contact: $contact) {
      id
    }
  }
`;
export type UpdateContactMutationFn = Apollo.MutationFunction<
  UpdateContactMutation,
  UpdateContactMutationVariables
>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useUpdateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContactMutation,
    UpdateContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContactMutation,
    UpdateContactMutationVariables
  >(UpdateContactDocument, options);
}
export type UpdateContactMutationHookResult = ReturnType<
  typeof useUpdateContactMutation
>;
export type UpdateContactMutationResult =
  Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactMutation,
  UpdateContactMutationVariables
>;
export const UpdateContactNoteDocument = gql`
  mutation UpdateContactNote($id: ID!, $contactNote: ContactNoteInput!) {
    updateContactNote(id: $id, contactNote: $contactNote) {
      id
    }
  }
`;
export type UpdateContactNoteMutationFn = Apollo.MutationFunction<
  UpdateContactNoteMutation,
  UpdateContactNoteMutationVariables
>;

/**
 * __useUpdateContactNoteMutation__
 *
 * To run a mutation, you first call `useUpdateContactNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactNoteMutation, { data, loading, error }] = useUpdateContactNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      contactNote: // value for 'contactNote'
 *   },
 * });
 */
export function useUpdateContactNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContactNoteMutation,
    UpdateContactNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContactNoteMutation,
    UpdateContactNoteMutationVariables
  >(UpdateContactNoteDocument, options);
}
export type UpdateContactNoteMutationHookResult = ReturnType<
  typeof useUpdateContactNoteMutation
>;
export type UpdateContactNoteMutationResult =
  Apollo.MutationResult<UpdateContactNoteMutation>;
export type UpdateContactNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactNoteMutation,
  UpdateContactNoteMutationVariables
>;
export const UpdateTagDocument = gql`
  mutation UpdateTag($id: ID!, $tag: TagInput!) {
    updateTag(id: $id, tag: $tag) {
      id
      invalidated
    }
  }
`;
export type UpdateTagMutationFn = Apollo.MutationFunction<
  UpdateTagMutation,
  UpdateTagMutationVariables
>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useUpdateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTagMutation,
    UpdateTagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(
    UpdateTagDocument,
    options
  );
}
export type UpdateTagMutationHookResult = ReturnType<
  typeof useUpdateTagMutation
>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<
  UpdateTagMutation,
  UpdateTagMutationVariables
>;
export const CreateAudiencesContactListDocument = gql`
  mutation CreateAudiencesContactList($input: DynamicListInput!) {
    createDynamicList(dynamicList: $input) {
      id
      lastUpdatedAt
      name
      lastUpdatedByProfileUser {
        user {
          id
          email
          firstName
          lastName
        }
      }
      lastUsedOnEmail {
        id
        campaignName
      }
      estimatedContactsSize
      filters {
        key
        value
      }
      description
    }
  }
`;
export type CreateAudiencesContactListMutationFn = Apollo.MutationFunction<
  CreateAudiencesContactListMutation,
  CreateAudiencesContactListMutationVariables
>;

/**
 * __useCreateAudiencesContactListMutation__
 *
 * To run a mutation, you first call `useCreateAudiencesContactListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAudiencesContactListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAudiencesContactListMutation, { data, loading, error }] = useCreateAudiencesContactListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAudiencesContactListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAudiencesContactListMutation,
    CreateAudiencesContactListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAudiencesContactListMutation,
    CreateAudiencesContactListMutationVariables
  >(CreateAudiencesContactListDocument, options);
}
export type CreateAudiencesContactListMutationHookResult = ReturnType<
  typeof useCreateAudiencesContactListMutation
>;
export type CreateAudiencesContactListMutationResult =
  Apollo.MutationResult<CreateAudiencesContactListMutation>;
export type CreateAudiencesContactListMutationOptions =
  Apollo.BaseMutationOptions<
    CreateAudiencesContactListMutation,
    CreateAudiencesContactListMutationVariables
  >;
export const CreateBlockDocument = gql`
  mutation CreateBlock(
    $pageId: ID!
    $type: String!
    $position: Int!
    $content: Map
  ) {
    createBlock(
      pageId: $pageId
      type: $type
      position: $position
      content: $content
    ) {
      id
    }
  }
`;
export type CreateBlockMutationFn = Apollo.MutationFunction<
  CreateBlockMutation,
  CreateBlockMutationVariables
>;

/**
 * __useCreateBlockMutation__
 *
 * To run a mutation, you first call `useCreateBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlockMutation, { data, loading, error }] = useCreateBlockMutation({
 *   variables: {
 *      pageId: // value for 'pageId'
 *      type: // value for 'type'
 *      position: // value for 'position'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBlockMutation,
    CreateBlockMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBlockMutation, CreateBlockMutationVariables>(
    CreateBlockDocument,
    options
  );
}
export type CreateBlockMutationHookResult = ReturnType<
  typeof useCreateBlockMutation
>;
export type CreateBlockMutationResult =
  Apollo.MutationResult<CreateBlockMutation>;
export type CreateBlockMutationOptions = Apollo.BaseMutationOptions<
  CreateBlockMutation,
  CreateBlockMutationVariables
>;
export const CreateComputershareRegistryCredentialDocument = gql`
  mutation CreateComputershareRegistryCredential(
    $credential: ComputershareRegistryCredentialInput!
  ) {
    createComputershareRegistryCredential(
      computershareRegistryCredential: $credential
    )
  }
`;
export type CreateComputershareRegistryCredentialMutationFn =
  Apollo.MutationFunction<
    CreateComputershareRegistryCredentialMutation,
    CreateComputershareRegistryCredentialMutationVariables
  >;

/**
 * __useCreateComputershareRegistryCredentialMutation__
 *
 * To run a mutation, you first call `useCreateComputershareRegistryCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateComputershareRegistryCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createComputershareRegistryCredentialMutation, { data, loading, error }] = useCreateComputershareRegistryCredentialMutation({
 *   variables: {
 *      credential: // value for 'credential'
 *   },
 * });
 */
export function useCreateComputershareRegistryCredentialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateComputershareRegistryCredentialMutation,
    CreateComputershareRegistryCredentialMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateComputershareRegistryCredentialMutation,
    CreateComputershareRegistryCredentialMutationVariables
  >(CreateComputershareRegistryCredentialDocument, options);
}
export type CreateComputershareRegistryCredentialMutationHookResult =
  ReturnType<typeof useCreateComputershareRegistryCredentialMutation>;
export type CreateComputershareRegistryCredentialMutationResult =
  Apollo.MutationResult<CreateComputershareRegistryCredentialMutation>;
export type CreateComputershareRegistryCredentialMutationOptions =
  Apollo.BaseMutationOptions<
    CreateComputershareRegistryCredentialMutation,
    CreateComputershareRegistryCredentialMutationVariables
  >;
export const CreateCustomDomainDocument = gql`
  mutation CreateCustomDomain($customDomain: String!) {
    createCustomDomain(customDomain: $customDomain) {
      id
      customDomain
      sesDkimConfigurations {
        configured
        name
        value
        type
      }
      sesMailFromConfigurations {
        configured
        name
        value
        type
      }
      sesDmarcConfigurations {
        configured
        name
        value
        type
      }
      vercelConfiguration {
        configured
        name
        value
        type
      }
    }
  }
`;
export type CreateCustomDomainMutationFn = Apollo.MutationFunction<
  CreateCustomDomainMutation,
  CreateCustomDomainMutationVariables
>;

/**
 * __useCreateCustomDomainMutation__
 *
 * To run a mutation, you first call `useCreateCustomDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomDomainMutation, { data, loading, error }] = useCreateCustomDomainMutation({
 *   variables: {
 *      customDomain: // value for 'customDomain'
 *   },
 * });
 */
export function useCreateCustomDomainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomDomainMutation,
    CreateCustomDomainMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCustomDomainMutation,
    CreateCustomDomainMutationVariables
  >(CreateCustomDomainDocument, options);
}
export type CreateCustomDomainMutationHookResult = ReturnType<
  typeof useCreateCustomDomainMutation
>;
export type CreateCustomDomainMutationResult =
  Apollo.MutationResult<CreateCustomDomainMutation>;
export type CreateCustomDomainMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomDomainMutation,
  CreateCustomDomainMutationVariables
>;
export const CreateDistributionSettingsEmailDocument = gql`
  mutation CreateDistributionSettingsEmail(
    $distributionSettingsId: ID
    $distributionSettingsEmail: DistributionSettingsEmailInput!
  ) {
    createDistributionSettingsEmail(
      distributionSettingsId: $distributionSettingsId
      distributionSettingsEmail: $distributionSettingsEmail
    ) {
      id
      subject
      fromName
      emailJson
      emailHtml
    }
  }
`;
export type CreateDistributionSettingsEmailMutationFn = Apollo.MutationFunction<
  CreateDistributionSettingsEmailMutation,
  CreateDistributionSettingsEmailMutationVariables
>;

/**
 * __useCreateDistributionSettingsEmailMutation__
 *
 * To run a mutation, you first call `useCreateDistributionSettingsEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDistributionSettingsEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDistributionSettingsEmailMutation, { data, loading, error }] = useCreateDistributionSettingsEmailMutation({
 *   variables: {
 *      distributionSettingsId: // value for 'distributionSettingsId'
 *      distributionSettingsEmail: // value for 'distributionSettingsEmail'
 *   },
 * });
 */
export function useCreateDistributionSettingsEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDistributionSettingsEmailMutation,
    CreateDistributionSettingsEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDistributionSettingsEmailMutation,
    CreateDistributionSettingsEmailMutationVariables
  >(CreateDistributionSettingsEmailDocument, options);
}
export type CreateDistributionSettingsEmailMutationHookResult = ReturnType<
  typeof useCreateDistributionSettingsEmailMutation
>;
export type CreateDistributionSettingsEmailMutationResult =
  Apollo.MutationResult<CreateDistributionSettingsEmailMutation>;
export type CreateDistributionSettingsEmailMutationOptions =
  Apollo.BaseMutationOptions<
    CreateDistributionSettingsEmailMutation,
    CreateDistributionSettingsEmailMutationVariables
  >;
export const CreateDistributionSettingsForFlowTypeDocument = gql`
  mutation CreateDistributionSettingsForFlowType(
    $flowType: FlowType!
    $distributionSettings: DistributionSettingsCreateInput!
  ) {
    createDistributionSettingsForFlowType(
      flowType: $flowType
      distributionSettings: $distributionSettings
    ) {
      id
      channel
      isActive
      shareholderWelcomeEnabled
    }
  }
`;
export type CreateDistributionSettingsForFlowTypeMutationFn =
  Apollo.MutationFunction<
    CreateDistributionSettingsForFlowTypeMutation,
    CreateDistributionSettingsForFlowTypeMutationVariables
  >;

/**
 * __useCreateDistributionSettingsForFlowTypeMutation__
 *
 * To run a mutation, you first call `useCreateDistributionSettingsForFlowTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDistributionSettingsForFlowTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDistributionSettingsForFlowTypeMutation, { data, loading, error }] = useCreateDistributionSettingsForFlowTypeMutation({
 *   variables: {
 *      flowType: // value for 'flowType'
 *      distributionSettings: // value for 'distributionSettings'
 *   },
 * });
 */
export function useCreateDistributionSettingsForFlowTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDistributionSettingsForFlowTypeMutation,
    CreateDistributionSettingsForFlowTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDistributionSettingsForFlowTypeMutation,
    CreateDistributionSettingsForFlowTypeMutationVariables
  >(CreateDistributionSettingsForFlowTypeDocument, options);
}
export type CreateDistributionSettingsForFlowTypeMutationHookResult =
  ReturnType<typeof useCreateDistributionSettingsForFlowTypeMutation>;
export type CreateDistributionSettingsForFlowTypeMutationResult =
  Apollo.MutationResult<CreateDistributionSettingsForFlowTypeMutation>;
export type CreateDistributionSettingsForFlowTypeMutationOptions =
  Apollo.BaseMutationOptions<
    CreateDistributionSettingsForFlowTypeMutation,
    CreateDistributionSettingsForFlowTypeMutationVariables
  >;
export const CreateEmailDocument = gql`
  mutation CreateEmail($campaignName: String!, $mediaId: ID, $subject: String) {
    createEmail(
      campaignName: $campaignName
      mediaId: $mediaId
      subject: $subject
    ) {
      id
    }
  }
`;
export type CreateEmailMutationFn = Apollo.MutationFunction<
  CreateEmailMutation,
  CreateEmailMutationVariables
>;

/**
 * __useCreateEmailMutation__
 *
 * To run a mutation, you first call `useCreateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailMutation, { data, loading, error }] = useCreateEmailMutation({
 *   variables: {
 *      campaignName: // value for 'campaignName'
 *      mediaId: // value for 'mediaId'
 *      subject: // value for 'subject'
 *   },
 * });
 */
export function useCreateEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEmailMutation,
    CreateEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEmailMutation, CreateEmailMutationVariables>(
    CreateEmailDocument,
    options
  );
}
export type CreateEmailMutationHookResult = ReturnType<
  typeof useCreateEmailMutation
>;
export type CreateEmailMutationResult =
  Apollo.MutationResult<CreateEmailMutation>;
export type CreateEmailMutationOptions = Apollo.BaseMutationOptions<
  CreateEmailMutation,
  CreateEmailMutationVariables
>;
export const CreateMediaCommentFromOtherSourceDocument = gql`
  mutation CreateMediaCommentFromOtherSource(
    $content: String!
    $commentSource: CommentSource!
    $commentSourceUrl: String
    $mediaId: ID!
    $replyContent: String
    $replyUseCompanyAsUsername: Boolean
  ) {
    createMediaCommentFromOtherSource(
      commentSource: $commentSource
      commentSourceUrl: $commentSourceUrl
      content: $content
      mediaId: $mediaId
      replyContent: $replyContent
      replyUseCompanyAsUsername: $replyUseCompanyAsUsername
    ) {
      id
      commentSource
      commentSourceUrl
      media {
        mediaAnnouncement {
          id
        }
        mediaUpdate {
          slug
        }
      }
    }
  }
`;
export type CreateMediaCommentFromOtherSourceMutationFn =
  Apollo.MutationFunction<
    CreateMediaCommentFromOtherSourceMutation,
    CreateMediaCommentFromOtherSourceMutationVariables
  >;

/**
 * __useCreateMediaCommentFromOtherSourceMutation__
 *
 * To run a mutation, you first call `useCreateMediaCommentFromOtherSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaCommentFromOtherSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaCommentFromOtherSourceMutation, { data, loading, error }] = useCreateMediaCommentFromOtherSourceMutation({
 *   variables: {
 *      content: // value for 'content'
 *      commentSource: // value for 'commentSource'
 *      commentSourceUrl: // value for 'commentSourceUrl'
 *      mediaId: // value for 'mediaId'
 *      replyContent: // value for 'replyContent'
 *      replyUseCompanyAsUsername: // value for 'replyUseCompanyAsUsername'
 *   },
 * });
 */
export function useCreateMediaCommentFromOtherSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMediaCommentFromOtherSourceMutation,
    CreateMediaCommentFromOtherSourceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMediaCommentFromOtherSourceMutation,
    CreateMediaCommentFromOtherSourceMutationVariables
  >(CreateMediaCommentFromOtherSourceDocument, options);
}
export type CreateMediaCommentFromOtherSourceMutationHookResult = ReturnType<
  typeof useCreateMediaCommentFromOtherSourceMutation
>;
export type CreateMediaCommentFromOtherSourceMutationResult =
  Apollo.MutationResult<CreateMediaCommentFromOtherSourceMutation>;
export type CreateMediaCommentFromOtherSourceMutationOptions =
  Apollo.BaseMutationOptions<
    CreateMediaCommentFromOtherSourceMutation,
    CreateMediaCommentFromOtherSourceMutationVariables
  >;
export const CreateMediaCommentDocument = gql`
  mutation CreateMediaComment(
    $annotationMetadata: MediaCommentAnnotationMetadataInput
    $content: String!
    $mediaId: ID!
    $useCompanyAsUsername: Boolean
  ) {
    createMediaComment(
      annotationMetadata: $annotationMetadata
      content: $content
      mediaId: $mediaId
      useCompanyAsUsername: $useCompanyAsUsername
    ) {
      id
    }
  }
`;
export type CreateMediaCommentMutationFn = Apollo.MutationFunction<
  CreateMediaCommentMutation,
  CreateMediaCommentMutationVariables
>;

/**
 * __useCreateMediaCommentMutation__
 *
 * To run a mutation, you first call `useCreateMediaCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaCommentMutation, { data, loading, error }] = useCreateMediaCommentMutation({
 *   variables: {
 *      annotationMetadata: // value for 'annotationMetadata'
 *      content: // value for 'content'
 *      mediaId: // value for 'mediaId'
 *      useCompanyAsUsername: // value for 'useCompanyAsUsername'
 *   },
 * });
 */
export function useCreateMediaCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMediaCommentMutation,
    CreateMediaCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMediaCommentMutation,
    CreateMediaCommentMutationVariables
  >(CreateMediaCommentDocument, options);
}
export type CreateMediaCommentMutationHookResult = ReturnType<
  typeof useCreateMediaCommentMutation
>;
export type CreateMediaCommentMutationResult =
  Apollo.MutationResult<CreateMediaCommentMutation>;
export type CreateMediaCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateMediaCommentMutation,
  CreateMediaCommentMutationVariables
>;
export const CreateNavMenuItemDocument = gql`
  mutation CreateNavMenuItem(
    $label: String!
    $url: String
    $position: Int!
    $openInNewTab: Boolean
    $pageId: ID
    $parentId: ID
  ) {
    createNavMenuItem(
      label: $label
      url: $url
      position: $position
      openInNewTab: $openInNewTab
      pageId: $pageId
      parentId: $parentId
    ) {
      id
      label
      url
      position
      openInNewTab
      page {
        id
        slug
        title
      }
      parent {
        id
        label
      }
    }
  }
`;
export type CreateNavMenuItemMutationFn = Apollo.MutationFunction<
  CreateNavMenuItemMutation,
  CreateNavMenuItemMutationVariables
>;

/**
 * __useCreateNavMenuItemMutation__
 *
 * To run a mutation, you first call `useCreateNavMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNavMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNavMenuItemMutation, { data, loading, error }] = useCreateNavMenuItemMutation({
 *   variables: {
 *      label: // value for 'label'
 *      url: // value for 'url'
 *      position: // value for 'position'
 *      openInNewTab: // value for 'openInNewTab'
 *      pageId: // value for 'pageId'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useCreateNavMenuItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNavMenuItemMutation,
    CreateNavMenuItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateNavMenuItemMutation,
    CreateNavMenuItemMutationVariables
  >(CreateNavMenuItemDocument, options);
}
export type CreateNavMenuItemMutationHookResult = ReturnType<
  typeof useCreateNavMenuItemMutation
>;
export type CreateNavMenuItemMutationResult =
  Apollo.MutationResult<CreateNavMenuItemMutation>;
export type CreateNavMenuItemMutationOptions = Apollo.BaseMutationOptions<
  CreateNavMenuItemMutation,
  CreateNavMenuItemMutationVariables
>;
export const CreateNewMediaUpdateDocument = gql`
  mutation CreateNewMediaUpdate {
    createNewMediaUpdate {
      id
      slug
    }
  }
`;
export type CreateNewMediaUpdateMutationFn = Apollo.MutationFunction<
  CreateNewMediaUpdateMutation,
  CreateNewMediaUpdateMutationVariables
>;

/**
 * __useCreateNewMediaUpdateMutation__
 *
 * To run a mutation, you first call `useCreateNewMediaUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewMediaUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewMediaUpdateMutation, { data, loading, error }] = useCreateNewMediaUpdateMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateNewMediaUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNewMediaUpdateMutation,
    CreateNewMediaUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateNewMediaUpdateMutation,
    CreateNewMediaUpdateMutationVariables
  >(CreateNewMediaUpdateDocument, options);
}
export type CreateNewMediaUpdateMutationHookResult = ReturnType<
  typeof useCreateNewMediaUpdateMutation
>;
export type CreateNewMediaUpdateMutationResult =
  Apollo.MutationResult<CreateNewMediaUpdateMutation>;
export type CreateNewMediaUpdateMutationOptions = Apollo.BaseMutationOptions<
  CreateNewMediaUpdateMutation,
  CreateNewMediaUpdateMutationVariables
>;
export const CreatePageDocument = gql`
  mutation CreatePage($title: String!, $slug: String!, $preset: String) {
    createPage(title: $title, slug: $slug, preset: $preset) {
      id
      title
      slug
    }
  }
`;
export type CreatePageMutationFn = Apollo.MutationFunction<
  CreatePageMutation,
  CreatePageMutationVariables
>;

/**
 * __useCreatePageMutation__
 *
 * To run a mutation, you first call `useCreatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPageMutation, { data, loading, error }] = useCreatePageMutation({
 *   variables: {
 *      title: // value for 'title'
 *      slug: // value for 'slug'
 *      preset: // value for 'preset'
 *   },
 * });
 */
export function useCreatePageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePageMutation,
    CreatePageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePageMutation, CreatePageMutationVariables>(
    CreatePageDocument,
    options
  );
}
export type CreatePageMutationHookResult = ReturnType<
  typeof useCreatePageMutation
>;
export type CreatePageMutationResult =
  Apollo.MutationResult<CreatePageMutation>;
export type CreatePageMutationOptions = Apollo.BaseMutationOptions<
  CreatePageMutation,
  CreatePageMutationVariables
>;
export const CreatePreparedAnnouncementDocument = gql`
  mutation CreatePreparedAnnouncement($title: String!) {
    createPreparedAnnouncement(title: $title) {
      id
    }
  }
`;
export type CreatePreparedAnnouncementMutationFn = Apollo.MutationFunction<
  CreatePreparedAnnouncementMutation,
  CreatePreparedAnnouncementMutationVariables
>;

/**
 * __useCreatePreparedAnnouncementMutation__
 *
 * To run a mutation, you first call `useCreatePreparedAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePreparedAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreparedAnnouncementMutation, { data, loading, error }] = useCreatePreparedAnnouncementMutation({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreatePreparedAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePreparedAnnouncementMutation,
    CreatePreparedAnnouncementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePreparedAnnouncementMutation,
    CreatePreparedAnnouncementMutationVariables
  >(CreatePreparedAnnouncementDocument, options);
}
export type CreatePreparedAnnouncementMutationHookResult = ReturnType<
  typeof useCreatePreparedAnnouncementMutation
>;
export type CreatePreparedAnnouncementMutationResult =
  Apollo.MutationResult<CreatePreparedAnnouncementMutation>;
export type CreatePreparedAnnouncementMutationOptions =
  Apollo.BaseMutationOptions<
    CreatePreparedAnnouncementMutation,
    CreatePreparedAnnouncementMutationVariables
  >;
export const CreateShareholderOfferPageFaqDocument = gql`
  mutation CreateShareholderOfferPageFaq(
    $faq: FaqInput!
    $shareholderOfferId: ID!
  ) {
    createShareholderOfferPageFaq(
      faq: $faq
      shareholderOfferId: $shareholderOfferId
    ) {
      id
    }
  }
`;
export type CreateShareholderOfferPageFaqMutationFn = Apollo.MutationFunction<
  CreateShareholderOfferPageFaqMutation,
  CreateShareholderOfferPageFaqMutationVariables
>;

/**
 * __useCreateShareholderOfferPageFaqMutation__
 *
 * To run a mutation, you first call `useCreateShareholderOfferPageFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShareholderOfferPageFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShareholderOfferPageFaqMutation, { data, loading, error }] = useCreateShareholderOfferPageFaqMutation({
 *   variables: {
 *      faq: // value for 'faq'
 *      shareholderOfferId: // value for 'shareholderOfferId'
 *   },
 * });
 */
export function useCreateShareholderOfferPageFaqMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateShareholderOfferPageFaqMutation,
    CreateShareholderOfferPageFaqMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateShareholderOfferPageFaqMutation,
    CreateShareholderOfferPageFaqMutationVariables
  >(CreateShareholderOfferPageFaqDocument, options);
}
export type CreateShareholderOfferPageFaqMutationHookResult = ReturnType<
  typeof useCreateShareholderOfferPageFaqMutation
>;
export type CreateShareholderOfferPageFaqMutationResult =
  Apollo.MutationResult<CreateShareholderOfferPageFaqMutation>;
export type CreateShareholderOfferPageFaqMutationOptions =
  Apollo.BaseMutationOptions<
    CreateShareholderOfferPageFaqMutation,
    CreateShareholderOfferPageFaqMutationVariables
  >;
export const CreateShareholderOfferPageInstructionDocument = gql`
  mutation CreateShareholderOfferPageInstruction(
    $instruction: InstructionInput!
    $shareholderOfferId: ID!
  ) {
    createShareholderOfferPageInstruction(
      instruction: $instruction
      shareholderOfferId: $shareholderOfferId
    ) {
      id
    }
  }
`;
export type CreateShareholderOfferPageInstructionMutationFn =
  Apollo.MutationFunction<
    CreateShareholderOfferPageInstructionMutation,
    CreateShareholderOfferPageInstructionMutationVariables
  >;

/**
 * __useCreateShareholderOfferPageInstructionMutation__
 *
 * To run a mutation, you first call `useCreateShareholderOfferPageInstructionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShareholderOfferPageInstructionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShareholderOfferPageInstructionMutation, { data, loading, error }] = useCreateShareholderOfferPageInstructionMutation({
 *   variables: {
 *      instruction: // value for 'instruction'
 *      shareholderOfferId: // value for 'shareholderOfferId'
 *   },
 * });
 */
export function useCreateShareholderOfferPageInstructionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateShareholderOfferPageInstructionMutation,
    CreateShareholderOfferPageInstructionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateShareholderOfferPageInstructionMutation,
    CreateShareholderOfferPageInstructionMutationVariables
  >(CreateShareholderOfferPageInstructionDocument, options);
}
export type CreateShareholderOfferPageInstructionMutationHookResult =
  ReturnType<typeof useCreateShareholderOfferPageInstructionMutation>;
export type CreateShareholderOfferPageInstructionMutationResult =
  Apollo.MutationResult<CreateShareholderOfferPageInstructionMutation>;
export type CreateShareholderOfferPageInstructionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateShareholderOfferPageInstructionMutation,
    CreateShareholderOfferPageInstructionMutationVariables
  >;
export const CreateShareholderOfferPrivateViewerDocument = gql`
  mutation CreateShareholderOfferPrivateViewer(
    $email: String!
    $shareholderOfferId: ID!
  ) {
    createShareholderOfferPrivateViewer(
      email: $email
      shareholderOfferId: $shareholderOfferId
    ) {
      id
    }
  }
`;
export type CreateShareholderOfferPrivateViewerMutationFn =
  Apollo.MutationFunction<
    CreateShareholderOfferPrivateViewerMutation,
    CreateShareholderOfferPrivateViewerMutationVariables
  >;

/**
 * __useCreateShareholderOfferPrivateViewerMutation__
 *
 * To run a mutation, you first call `useCreateShareholderOfferPrivateViewerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShareholderOfferPrivateViewerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShareholderOfferPrivateViewerMutation, { data, loading, error }] = useCreateShareholderOfferPrivateViewerMutation({
 *   variables: {
 *      email: // value for 'email'
 *      shareholderOfferId: // value for 'shareholderOfferId'
 *   },
 * });
 */
export function useCreateShareholderOfferPrivateViewerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateShareholderOfferPrivateViewerMutation,
    CreateShareholderOfferPrivateViewerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateShareholderOfferPrivateViewerMutation,
    CreateShareholderOfferPrivateViewerMutationVariables
  >(CreateShareholderOfferPrivateViewerDocument, options);
}
export type CreateShareholderOfferPrivateViewerMutationHookResult = ReturnType<
  typeof useCreateShareholderOfferPrivateViewerMutation
>;
export type CreateShareholderOfferPrivateViewerMutationResult =
  Apollo.MutationResult<CreateShareholderOfferPrivateViewerMutation>;
export type CreateShareholderOfferPrivateViewerMutationOptions =
  Apollo.BaseMutationOptions<
    CreateShareholderOfferPrivateViewerMutation,
    CreateShareholderOfferPrivateViewerMutationVariables
  >;
export const CreateShareholderOfferDocument = gql`
  mutation CreateShareholderOffer($shareholderOffer: ShareholderOfferInput!) {
    createShareholderOffer(shareholderOffer: $shareholderOffer) {
      id
      title
      type
      isLive
      scheduledAt
      companyProfile {
        id
        registry
      }
      lastEditedByUser {
        email
        firstName
        lastName
      }
      insertedAt
      updatedAt
    }
  }
`;
export type CreateShareholderOfferMutationFn = Apollo.MutationFunction<
  CreateShareholderOfferMutation,
  CreateShareholderOfferMutationVariables
>;

/**
 * __useCreateShareholderOfferMutation__
 *
 * To run a mutation, you first call `useCreateShareholderOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShareholderOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShareholderOfferMutation, { data, loading, error }] = useCreateShareholderOfferMutation({
 *   variables: {
 *      shareholderOffer: // value for 'shareholderOffer'
 *   },
 * });
 */
export function useCreateShareholderOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateShareholderOfferMutation,
    CreateShareholderOfferMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateShareholderOfferMutation,
    CreateShareholderOfferMutationVariables
  >(CreateShareholderOfferDocument, options);
}
export type CreateShareholderOfferMutationHookResult = ReturnType<
  typeof useCreateShareholderOfferMutation
>;
export type CreateShareholderOfferMutationResult =
  Apollo.MutationResult<CreateShareholderOfferMutation>;
export type CreateShareholderOfferMutationOptions = Apollo.BaseMutationOptions<
  CreateShareholderOfferMutation,
  CreateShareholderOfferMutationVariables
>;
export const CreateUtmLinkDocument = gql`
  mutation CreateUtmLink($utmLink: UtmLinkInput!) {
    createUtmLink(utmLink: $utmLink) {
      id
      destinationUrl
      utmCampaign
      utmMedium
      utmSource
      utmUrl
      insertedAt
      updatedAt
    }
  }
`;
export type CreateUtmLinkMutationFn = Apollo.MutationFunction<
  CreateUtmLinkMutation,
  CreateUtmLinkMutationVariables
>;

/**
 * __useCreateUtmLinkMutation__
 *
 * To run a mutation, you first call `useCreateUtmLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUtmLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUtmLinkMutation, { data, loading, error }] = useCreateUtmLinkMutation({
 *   variables: {
 *      utmLink: // value for 'utmLink'
 *   },
 * });
 */
export function useCreateUtmLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUtmLinkMutation,
    CreateUtmLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUtmLinkMutation,
    CreateUtmLinkMutationVariables
  >(CreateUtmLinkDocument, options);
}
export type CreateUtmLinkMutationHookResult = ReturnType<
  typeof useCreateUtmLinkMutation
>;
export type CreateUtmLinkMutationResult =
  Apollo.MutationResult<CreateUtmLinkMutation>;
export type CreateUtmLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateUtmLinkMutation,
  CreateUtmLinkMutationVariables
>;
export const DeactivateCurrentCompanyDistributionSettingsDocument = gql`
  mutation DeactivateCurrentCompanyDistributionSettings($flowType: FlowType!) {
    deactivateCurrentCompanyDistributionSettings(flowType: $flowType) {
      ...CurrentCompanyDistributionSettingsFields
    }
  }
  ${CurrentCompanyDistributionSettingsFieldsFragmentDoc}
`;
export type DeactivateCurrentCompanyDistributionSettingsMutationFn =
  Apollo.MutationFunction<
    DeactivateCurrentCompanyDistributionSettingsMutation,
    DeactivateCurrentCompanyDistributionSettingsMutationVariables
  >;

/**
 * __useDeactivateCurrentCompanyDistributionSettingsMutation__
 *
 * To run a mutation, you first call `useDeactivateCurrentCompanyDistributionSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateCurrentCompanyDistributionSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateCurrentCompanyDistributionSettingsMutation, { data, loading, error }] = useDeactivateCurrentCompanyDistributionSettingsMutation({
 *   variables: {
 *      flowType: // value for 'flowType'
 *   },
 * });
 */
export function useDeactivateCurrentCompanyDistributionSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivateCurrentCompanyDistributionSettingsMutation,
    DeactivateCurrentCompanyDistributionSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeactivateCurrentCompanyDistributionSettingsMutation,
    DeactivateCurrentCompanyDistributionSettingsMutationVariables
  >(DeactivateCurrentCompanyDistributionSettingsDocument, options);
}
export type DeactivateCurrentCompanyDistributionSettingsMutationHookResult =
  ReturnType<typeof useDeactivateCurrentCompanyDistributionSettingsMutation>;
export type DeactivateCurrentCompanyDistributionSettingsMutationResult =
  Apollo.MutationResult<DeactivateCurrentCompanyDistributionSettingsMutation>;
export type DeactivateCurrentCompanyDistributionSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    DeactivateCurrentCompanyDistributionSettingsMutation,
    DeactivateCurrentCompanyDistributionSettingsMutationVariables
  >;
export const DeleteBlockDocument = gql`
  mutation DeleteBlock($id: ID!) {
    deleteBlock(id: $id) {
      id
    }
  }
`;
export type DeleteBlockMutationFn = Apollo.MutationFunction<
  DeleteBlockMutation,
  DeleteBlockMutationVariables
>;

/**
 * __useDeleteBlockMutation__
 *
 * To run a mutation, you first call `useDeleteBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlockMutation, { data, loading, error }] = useDeleteBlockMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBlockMutation,
    DeleteBlockMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBlockMutation, DeleteBlockMutationVariables>(
    DeleteBlockDocument,
    options
  );
}
export type DeleteBlockMutationHookResult = ReturnType<
  typeof useDeleteBlockMutation
>;
export type DeleteBlockMutationResult =
  Apollo.MutationResult<DeleteBlockMutation>;
export type DeleteBlockMutationOptions = Apollo.BaseMutationOptions<
  DeleteBlockMutation,
  DeleteBlockMutationVariables
>;
export const DeleteDraftEmailDocument = gql`
  mutation DeleteDraftEmail($id: ID!) {
    deleteDraftEmail(id: $id)
  }
`;
export type DeleteDraftEmailMutationFn = Apollo.MutationFunction<
  DeleteDraftEmailMutation,
  DeleteDraftEmailMutationVariables
>;

/**
 * __useDeleteDraftEmailMutation__
 *
 * To run a mutation, you first call `useDeleteDraftEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDraftEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDraftEmailMutation, { data, loading, error }] = useDeleteDraftEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDraftEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDraftEmailMutation,
    DeleteDraftEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteDraftEmailMutation,
    DeleteDraftEmailMutationVariables
  >(DeleteDraftEmailDocument, options);
}
export type DeleteDraftEmailMutationHookResult = ReturnType<
  typeof useDeleteDraftEmailMutation
>;
export type DeleteDraftEmailMutationResult =
  Apollo.MutationResult<DeleteDraftEmailMutation>;
export type DeleteDraftEmailMutationOptions = Apollo.BaseMutationOptions<
  DeleteDraftEmailMutation,
  DeleteDraftEmailMutationVariables
>;
export const DeleteDynamicListDocument = gql`
  mutation DeleteDynamicList($id: ID!) {
    deleteDynamicList(id: $id)
  }
`;
export type DeleteDynamicListMutationFn = Apollo.MutationFunction<
  DeleteDynamicListMutation,
  DeleteDynamicListMutationVariables
>;

/**
 * __useDeleteDynamicListMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicListMutation, { data, loading, error }] = useDeleteDynamicListMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDynamicListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDynamicListMutation,
    DeleteDynamicListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteDynamicListMutation,
    DeleteDynamicListMutationVariables
  >(DeleteDynamicListDocument, options);
}
export type DeleteDynamicListMutationHookResult = ReturnType<
  typeof useDeleteDynamicListMutation
>;
export type DeleteDynamicListMutationResult =
  Apollo.MutationResult<DeleteDynamicListMutation>;
export type DeleteDynamicListMutationOptions = Apollo.BaseMutationOptions<
  DeleteDynamicListMutation,
  DeleteDynamicListMutationVariables
>;
export const DeleteNavMenuItemDocument = gql`
  mutation DeleteNavMenuItem($id: ID!) {
    deleteNavMenuItem(id: $id) {
      id
    }
  }
`;
export type DeleteNavMenuItemMutationFn = Apollo.MutationFunction<
  DeleteNavMenuItemMutation,
  DeleteNavMenuItemMutationVariables
>;

/**
 * __useDeleteNavMenuItemMutation__
 *
 * To run a mutation, you first call `useDeleteNavMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNavMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNavMenuItemMutation, { data, loading, error }] = useDeleteNavMenuItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNavMenuItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteNavMenuItemMutation,
    DeleteNavMenuItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteNavMenuItemMutation,
    DeleteNavMenuItemMutationVariables
  >(DeleteNavMenuItemDocument, options);
}
export type DeleteNavMenuItemMutationHookResult = ReturnType<
  typeof useDeleteNavMenuItemMutation
>;
export type DeleteNavMenuItemMutationResult =
  Apollo.MutationResult<DeleteNavMenuItemMutation>;
export type DeleteNavMenuItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteNavMenuItemMutation,
  DeleteNavMenuItemMutationVariables
>;
export const DeletePageDocument = gql`
  mutation DeletePage($id: ID!) {
    deletePage(id: $id) {
      id
    }
  }
`;
export type DeletePageMutationFn = Apollo.MutationFunction<
  DeletePageMutation,
  DeletePageMutationVariables
>;

/**
 * __useDeletePageMutation__
 *
 * To run a mutation, you first call `useDeletePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePageMutation, { data, loading, error }] = useDeletePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePageMutation,
    DeletePageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePageMutation, DeletePageMutationVariables>(
    DeletePageDocument,
    options
  );
}
export type DeletePageMutationHookResult = ReturnType<
  typeof useDeletePageMutation
>;
export type DeletePageMutationResult =
  Apollo.MutationResult<DeletePageMutation>;
export type DeletePageMutationOptions = Apollo.BaseMutationOptions<
  DeletePageMutation,
  DeletePageMutationVariables
>;
export const DeletePreparedAnnouncementDocument = gql`
  mutation DeletePreparedAnnouncement($id: ID!) {
    deletePreparedAnnouncement(id: $id)
  }
`;
export type DeletePreparedAnnouncementMutationFn = Apollo.MutationFunction<
  DeletePreparedAnnouncementMutation,
  DeletePreparedAnnouncementMutationVariables
>;

/**
 * __useDeletePreparedAnnouncementMutation__
 *
 * To run a mutation, you first call `useDeletePreparedAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePreparedAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePreparedAnnouncementMutation, { data, loading, error }] = useDeletePreparedAnnouncementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePreparedAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePreparedAnnouncementMutation,
    DeletePreparedAnnouncementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePreparedAnnouncementMutation,
    DeletePreparedAnnouncementMutationVariables
  >(DeletePreparedAnnouncementDocument, options);
}
export type DeletePreparedAnnouncementMutationHookResult = ReturnType<
  typeof useDeletePreparedAnnouncementMutation
>;
export type DeletePreparedAnnouncementMutationResult =
  Apollo.MutationResult<DeletePreparedAnnouncementMutation>;
export type DeletePreparedAnnouncementMutationOptions =
  Apollo.BaseMutationOptions<
    DeletePreparedAnnouncementMutation,
    DeletePreparedAnnouncementMutationVariables
  >;
export const DeleteShareholderOfferPageFaqDocument = gql`
  mutation DeleteShareholderOfferPageFaq($faqId: ID!) {
    deleteShareholderOfferPageFaq(faqId: $faqId) {
      id
    }
  }
`;
export type DeleteShareholderOfferPageFaqMutationFn = Apollo.MutationFunction<
  DeleteShareholderOfferPageFaqMutation,
  DeleteShareholderOfferPageFaqMutationVariables
>;

/**
 * __useDeleteShareholderOfferPageFaqMutation__
 *
 * To run a mutation, you first call `useDeleteShareholderOfferPageFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShareholderOfferPageFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShareholderOfferPageFaqMutation, { data, loading, error }] = useDeleteShareholderOfferPageFaqMutation({
 *   variables: {
 *      faqId: // value for 'faqId'
 *   },
 * });
 */
export function useDeleteShareholderOfferPageFaqMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteShareholderOfferPageFaqMutation,
    DeleteShareholderOfferPageFaqMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteShareholderOfferPageFaqMutation,
    DeleteShareholderOfferPageFaqMutationVariables
  >(DeleteShareholderOfferPageFaqDocument, options);
}
export type DeleteShareholderOfferPageFaqMutationHookResult = ReturnType<
  typeof useDeleteShareholderOfferPageFaqMutation
>;
export type DeleteShareholderOfferPageFaqMutationResult =
  Apollo.MutationResult<DeleteShareholderOfferPageFaqMutation>;
export type DeleteShareholderOfferPageFaqMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteShareholderOfferPageFaqMutation,
    DeleteShareholderOfferPageFaqMutationVariables
  >;
export const DeleteShareholderOfferPageInstructionDocument = gql`
  mutation DeleteShareholderOfferPageInstruction($instructionId: ID!) {
    deleteShareholderOfferPageInstruction(instructionId: $instructionId) {
      id
    }
  }
`;
export type DeleteShareholderOfferPageInstructionMutationFn =
  Apollo.MutationFunction<
    DeleteShareholderOfferPageInstructionMutation,
    DeleteShareholderOfferPageInstructionMutationVariables
  >;

/**
 * __useDeleteShareholderOfferPageInstructionMutation__
 *
 * To run a mutation, you first call `useDeleteShareholderOfferPageInstructionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShareholderOfferPageInstructionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShareholderOfferPageInstructionMutation, { data, loading, error }] = useDeleteShareholderOfferPageInstructionMutation({
 *   variables: {
 *      instructionId: // value for 'instructionId'
 *   },
 * });
 */
export function useDeleteShareholderOfferPageInstructionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteShareholderOfferPageInstructionMutation,
    DeleteShareholderOfferPageInstructionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteShareholderOfferPageInstructionMutation,
    DeleteShareholderOfferPageInstructionMutationVariables
  >(DeleteShareholderOfferPageInstructionDocument, options);
}
export type DeleteShareholderOfferPageInstructionMutationHookResult =
  ReturnType<typeof useDeleteShareholderOfferPageInstructionMutation>;
export type DeleteShareholderOfferPageInstructionMutationResult =
  Apollo.MutationResult<DeleteShareholderOfferPageInstructionMutation>;
export type DeleteShareholderOfferPageInstructionMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteShareholderOfferPageInstructionMutation,
    DeleteShareholderOfferPageInstructionMutationVariables
  >;
export const DeleteShareholderOfferPrivateViewerDocument = gql`
  mutation DeleteShareholderOfferPrivateViewer($id: ID!) {
    deleteShareholderOfferPrivateViewer(id: $id)
  }
`;
export type DeleteShareholderOfferPrivateViewerMutationFn =
  Apollo.MutationFunction<
    DeleteShareholderOfferPrivateViewerMutation,
    DeleteShareholderOfferPrivateViewerMutationVariables
  >;

/**
 * __useDeleteShareholderOfferPrivateViewerMutation__
 *
 * To run a mutation, you first call `useDeleteShareholderOfferPrivateViewerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShareholderOfferPrivateViewerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShareholderOfferPrivateViewerMutation, { data, loading, error }] = useDeleteShareholderOfferPrivateViewerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteShareholderOfferPrivateViewerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteShareholderOfferPrivateViewerMutation,
    DeleteShareholderOfferPrivateViewerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteShareholderOfferPrivateViewerMutation,
    DeleteShareholderOfferPrivateViewerMutationVariables
  >(DeleteShareholderOfferPrivateViewerDocument, options);
}
export type DeleteShareholderOfferPrivateViewerMutationHookResult = ReturnType<
  typeof useDeleteShareholderOfferPrivateViewerMutation
>;
export type DeleteShareholderOfferPrivateViewerMutationResult =
  Apollo.MutationResult<DeleteShareholderOfferPrivateViewerMutation>;
export type DeleteShareholderOfferPrivateViewerMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteShareholderOfferPrivateViewerMutation,
    DeleteShareholderOfferPrivateViewerMutationVariables
  >;
export const DeleteShareholderOfferDocument = gql`
  mutation DeleteShareholderOffer($shareholderOfferId: ID!) {
    deleteShareholderOffer(shareholderOfferId: $shareholderOfferId) {
      id
      title
      type
      isLive
      scheduledAt
      companyProfile {
        id
        registry
      }
      lastEditedByUser {
        email
        firstName
        lastName
      }
      insertedAt
      updatedAt
    }
  }
`;
export type DeleteShareholderOfferMutationFn = Apollo.MutationFunction<
  DeleteShareholderOfferMutation,
  DeleteShareholderOfferMutationVariables
>;

/**
 * __useDeleteShareholderOfferMutation__
 *
 * To run a mutation, you first call `useDeleteShareholderOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShareholderOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShareholderOfferMutation, { data, loading, error }] = useDeleteShareholderOfferMutation({
 *   variables: {
 *      shareholderOfferId: // value for 'shareholderOfferId'
 *   },
 * });
 */
export function useDeleteShareholderOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteShareholderOfferMutation,
    DeleteShareholderOfferMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteShareholderOfferMutation,
    DeleteShareholderOfferMutationVariables
  >(DeleteShareholderOfferDocument, options);
}
export type DeleteShareholderOfferMutationHookResult = ReturnType<
  typeof useDeleteShareholderOfferMutation
>;
export type DeleteShareholderOfferMutationResult =
  Apollo.MutationResult<DeleteShareholderOfferMutation>;
export type DeleteShareholderOfferMutationOptions = Apollo.BaseMutationOptions<
  DeleteShareholderOfferMutation,
  DeleteShareholderOfferMutationVariables
>;
export const DeleteUtmLinkDocument = gql`
  mutation DeleteUtmLink($id: ID!) {
    deleteUtmLink(id: $id)
  }
`;
export type DeleteUtmLinkMutationFn = Apollo.MutationFunction<
  DeleteUtmLinkMutation,
  DeleteUtmLinkMutationVariables
>;

/**
 * __useDeleteUtmLinkMutation__
 *
 * To run a mutation, you first call `useDeleteUtmLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUtmLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUtmLinkMutation, { data, loading, error }] = useDeleteUtmLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUtmLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUtmLinkMutation,
    DeleteUtmLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUtmLinkMutation,
    DeleteUtmLinkMutationVariables
  >(DeleteUtmLinkDocument, options);
}
export type DeleteUtmLinkMutationHookResult = ReturnType<
  typeof useDeleteUtmLinkMutation
>;
export type DeleteUtmLinkMutationResult =
  Apollo.MutationResult<DeleteUtmLinkMutation>;
export type DeleteUtmLinkMutationOptions = Apollo.BaseMutationOptions<
  DeleteUtmLinkMutation,
  DeleteUtmLinkMutationVariables
>;
export const DisconnectSocialConnectionDocument = gql`
  mutation DisconnectSocialConnection($socialPlatform: SocialPlatform!) {
    disconnectSocialConnection(socialPlatform: $socialPlatform) {
      id
      linkedinConnected
      linkedinSetupCompleted
      linkedinOrganisationName
      twitterSetupCompleted
      twitterUsername
    }
  }
`;
export type DisconnectSocialConnectionMutationFn = Apollo.MutationFunction<
  DisconnectSocialConnectionMutation,
  DisconnectSocialConnectionMutationVariables
>;

/**
 * __useDisconnectSocialConnectionMutation__
 *
 * To run a mutation, you first call `useDisconnectSocialConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectSocialConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectSocialConnectionMutation, { data, loading, error }] = useDisconnectSocialConnectionMutation({
 *   variables: {
 *      socialPlatform: // value for 'socialPlatform'
 *   },
 * });
 */
export function useDisconnectSocialConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisconnectSocialConnectionMutation,
    DisconnectSocialConnectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisconnectSocialConnectionMutation,
    DisconnectSocialConnectionMutationVariables
  >(DisconnectSocialConnectionDocument, options);
}
export type DisconnectSocialConnectionMutationHookResult = ReturnType<
  typeof useDisconnectSocialConnectionMutation
>;
export type DisconnectSocialConnectionMutationResult =
  Apollo.MutationResult<DisconnectSocialConnectionMutation>;
export type DisconnectSocialConnectionMutationOptions =
  Apollo.BaseMutationOptions<
    DisconnectSocialConnectionMutation,
    DisconnectSocialConnectionMutationVariables
  >;
export const DuplicateEmailAndEmailRecipientsDocument = gql`
  mutation DuplicateEmailAndEmailRecipients($id: ID!) {
    duplicateEmailAndEmailRecipients(id: $id) {
      id
    }
  }
`;
export type DuplicateEmailAndEmailRecipientsMutationFn =
  Apollo.MutationFunction<
    DuplicateEmailAndEmailRecipientsMutation,
    DuplicateEmailAndEmailRecipientsMutationVariables
  >;

/**
 * __useDuplicateEmailAndEmailRecipientsMutation__
 *
 * To run a mutation, you first call `useDuplicateEmailAndEmailRecipientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateEmailAndEmailRecipientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateEmailAndEmailRecipientsMutation, { data, loading, error }] = useDuplicateEmailAndEmailRecipientsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateEmailAndEmailRecipientsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateEmailAndEmailRecipientsMutation,
    DuplicateEmailAndEmailRecipientsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateEmailAndEmailRecipientsMutation,
    DuplicateEmailAndEmailRecipientsMutationVariables
  >(DuplicateEmailAndEmailRecipientsDocument, options);
}
export type DuplicateEmailAndEmailRecipientsMutationHookResult = ReturnType<
  typeof useDuplicateEmailAndEmailRecipientsMutation
>;
export type DuplicateEmailAndEmailRecipientsMutationResult =
  Apollo.MutationResult<DuplicateEmailAndEmailRecipientsMutation>;
export type DuplicateEmailAndEmailRecipientsMutationOptions =
  Apollo.BaseMutationOptions<
    DuplicateEmailAndEmailRecipientsMutation,
    DuplicateEmailAndEmailRecipientsMutationVariables
  >;
export const GenerateAssetSignedUrlDocument = gql`
  mutation GenerateAssetSignedUrl($mimeType: String!) {
    generateAssetSignedUrl(mimeType: $mimeType)
  }
`;
export type GenerateAssetSignedUrlMutationFn = Apollo.MutationFunction<
  GenerateAssetSignedUrlMutation,
  GenerateAssetSignedUrlMutationVariables
>;

/**
 * __useGenerateAssetSignedUrlMutation__
 *
 * To run a mutation, you first call `useGenerateAssetSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAssetSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAssetSignedUrlMutation, { data, loading, error }] = useGenerateAssetSignedUrlMutation({
 *   variables: {
 *      mimeType: // value for 'mimeType'
 *   },
 * });
 */
export function useGenerateAssetSignedUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateAssetSignedUrlMutation,
    GenerateAssetSignedUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateAssetSignedUrlMutation,
    GenerateAssetSignedUrlMutationVariables
  >(GenerateAssetSignedUrlDocument, options);
}
export type GenerateAssetSignedUrlMutationHookResult = ReturnType<
  typeof useGenerateAssetSignedUrlMutation
>;
export type GenerateAssetSignedUrlMutationResult =
  Apollo.MutationResult<GenerateAssetSignedUrlMutation>;
export type GenerateAssetSignedUrlMutationOptions = Apollo.BaseMutationOptions<
  GenerateAssetSignedUrlMutation,
  GenerateAssetSignedUrlMutationVariables
>;
export const GenerateCorporatePageSectionAttachmentFileSignedUrlDocument = gql`
  mutation GenerateCorporatePageSectionAttachmentFileSignedUrl(
    $fileSize: Int!
    $mimeType: String!
  ) {
    generateCorporatePageSectionAttachmentFileSignedUrl(
      fileSize: $fileSize
      mimeType: $mimeType
    )
  }
`;
export type GenerateCorporatePageSectionAttachmentFileSignedUrlMutationFn =
  Apollo.MutationFunction<
    GenerateCorporatePageSectionAttachmentFileSignedUrlMutation,
    GenerateCorporatePageSectionAttachmentFileSignedUrlMutationVariables
  >;

/**
 * __useGenerateCorporatePageSectionAttachmentFileSignedUrlMutation__
 *
 * To run a mutation, you first call `useGenerateCorporatePageSectionAttachmentFileSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCorporatePageSectionAttachmentFileSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCorporatePageSectionAttachmentFileSignedUrlMutation, { data, loading, error }] = useGenerateCorporatePageSectionAttachmentFileSignedUrlMutation({
 *   variables: {
 *      fileSize: // value for 'fileSize'
 *      mimeType: // value for 'mimeType'
 *   },
 * });
 */
export function useGenerateCorporatePageSectionAttachmentFileSignedUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateCorporatePageSectionAttachmentFileSignedUrlMutation,
    GenerateCorporatePageSectionAttachmentFileSignedUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateCorporatePageSectionAttachmentFileSignedUrlMutation,
    GenerateCorporatePageSectionAttachmentFileSignedUrlMutationVariables
  >(GenerateCorporatePageSectionAttachmentFileSignedUrlDocument, options);
}
export type GenerateCorporatePageSectionAttachmentFileSignedUrlMutationHookResult =
  ReturnType<
    typeof useGenerateCorporatePageSectionAttachmentFileSignedUrlMutation
  >;
export type GenerateCorporatePageSectionAttachmentFileSignedUrlMutationResult =
  Apollo.MutationResult<GenerateCorporatePageSectionAttachmentFileSignedUrlMutation>;
export type GenerateCorporatePageSectionAttachmentFileSignedUrlMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateCorporatePageSectionAttachmentFileSignedUrlMutation,
    GenerateCorporatePageSectionAttachmentFileSignedUrlMutationVariables
  >;
export const GenerateLinkedinThumbnailSignedUrlDocument = gql`
  mutation GenerateLinkedinThumbnailSignedUrl($mimeType: String!) {
    generateLinkedinThumbnailSignedUrl(mimeType: $mimeType)
  }
`;
export type GenerateLinkedinThumbnailSignedUrlMutationFn =
  Apollo.MutationFunction<
    GenerateLinkedinThumbnailSignedUrlMutation,
    GenerateLinkedinThumbnailSignedUrlMutationVariables
  >;

/**
 * __useGenerateLinkedinThumbnailSignedUrlMutation__
 *
 * To run a mutation, you first call `useGenerateLinkedinThumbnailSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateLinkedinThumbnailSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateLinkedinThumbnailSignedUrlMutation, { data, loading, error }] = useGenerateLinkedinThumbnailSignedUrlMutation({
 *   variables: {
 *      mimeType: // value for 'mimeType'
 *   },
 * });
 */
export function useGenerateLinkedinThumbnailSignedUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateLinkedinThumbnailSignedUrlMutation,
    GenerateLinkedinThumbnailSignedUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateLinkedinThumbnailSignedUrlMutation,
    GenerateLinkedinThumbnailSignedUrlMutationVariables
  >(GenerateLinkedinThumbnailSignedUrlDocument, options);
}
export type GenerateLinkedinThumbnailSignedUrlMutationHookResult = ReturnType<
  typeof useGenerateLinkedinThumbnailSignedUrlMutation
>;
export type GenerateLinkedinThumbnailSignedUrlMutationResult =
  Apollo.MutationResult<GenerateLinkedinThumbnailSignedUrlMutation>;
export type GenerateLinkedinThumbnailSignedUrlMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateLinkedinThumbnailSignedUrlMutation,
    GenerateLinkedinThumbnailSignedUrlMutationVariables
  >;
export const GenerateMediaAnnouncementAiSummaryDocument = gql`
  mutation GenerateMediaAnnouncementAiSummary($mediaAnnouncementId: ID!) {
    generateMediaAnnouncementAiSummary(
      mediaAnnouncementId: $mediaAnnouncementId
    )
  }
`;
export type GenerateMediaAnnouncementAiSummaryMutationFn =
  Apollo.MutationFunction<
    GenerateMediaAnnouncementAiSummaryMutation,
    GenerateMediaAnnouncementAiSummaryMutationVariables
  >;

/**
 * __useGenerateMediaAnnouncementAiSummaryMutation__
 *
 * To run a mutation, you first call `useGenerateMediaAnnouncementAiSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMediaAnnouncementAiSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMediaAnnouncementAiSummaryMutation, { data, loading, error }] = useGenerateMediaAnnouncementAiSummaryMutation({
 *   variables: {
 *      mediaAnnouncementId: // value for 'mediaAnnouncementId'
 *   },
 * });
 */
export function useGenerateMediaAnnouncementAiSummaryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateMediaAnnouncementAiSummaryMutation,
    GenerateMediaAnnouncementAiSummaryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateMediaAnnouncementAiSummaryMutation,
    GenerateMediaAnnouncementAiSummaryMutationVariables
  >(GenerateMediaAnnouncementAiSummaryDocument, options);
}
export type GenerateMediaAnnouncementAiSummaryMutationHookResult = ReturnType<
  typeof useGenerateMediaAnnouncementAiSummaryMutation
>;
export type GenerateMediaAnnouncementAiSummaryMutationResult =
  Apollo.MutationResult<GenerateMediaAnnouncementAiSummaryMutation>;
export type GenerateMediaAnnouncementAiSummaryMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateMediaAnnouncementAiSummaryMutation,
    GenerateMediaAnnouncementAiSummaryMutationVariables
  >;
export const GenerateMediaAnnouncementVideoSignedUrlDocument = gql`
  mutation GenerateMediaAnnouncementVideoSignedUrl(
    $fileSize: Int!
    $mediaAnnouncementId: ID!
    $mimeType: String!
  ) {
    generateMediaAnnouncementVideoSignedUrl(
      fileSize: $fileSize
      mediaAnnouncementId: $mediaAnnouncementId
      mimeType: $mimeType
    )
  }
`;
export type GenerateMediaAnnouncementVideoSignedUrlMutationFn =
  Apollo.MutationFunction<
    GenerateMediaAnnouncementVideoSignedUrlMutation,
    GenerateMediaAnnouncementVideoSignedUrlMutationVariables
  >;

/**
 * __useGenerateMediaAnnouncementVideoSignedUrlMutation__
 *
 * To run a mutation, you first call `useGenerateMediaAnnouncementVideoSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMediaAnnouncementVideoSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMediaAnnouncementVideoSignedUrlMutation, { data, loading, error }] = useGenerateMediaAnnouncementVideoSignedUrlMutation({
 *   variables: {
 *      fileSize: // value for 'fileSize'
 *      mediaAnnouncementId: // value for 'mediaAnnouncementId'
 *      mimeType: // value for 'mimeType'
 *   },
 * });
 */
export function useGenerateMediaAnnouncementVideoSignedUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateMediaAnnouncementVideoSignedUrlMutation,
    GenerateMediaAnnouncementVideoSignedUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateMediaAnnouncementVideoSignedUrlMutation,
    GenerateMediaAnnouncementVideoSignedUrlMutationVariables
  >(GenerateMediaAnnouncementVideoSignedUrlDocument, options);
}
export type GenerateMediaAnnouncementVideoSignedUrlMutationHookResult =
  ReturnType<typeof useGenerateMediaAnnouncementVideoSignedUrlMutation>;
export type GenerateMediaAnnouncementVideoSignedUrlMutationResult =
  Apollo.MutationResult<GenerateMediaAnnouncementVideoSignedUrlMutation>;
export type GenerateMediaAnnouncementVideoSignedUrlMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateMediaAnnouncementVideoSignedUrlMutation,
    GenerateMediaAnnouncementVideoSignedUrlMutationVariables
  >;
export const GenerateMediaUpdateAttachmentSignedUrlDocument = gql`
  mutation GenerateMediaUpdateAttachmentSignedUrl($mimeType: String!) {
    generateMediaUpdateAttachmentSignedUrl(mimeType: $mimeType)
  }
`;
export type GenerateMediaUpdateAttachmentSignedUrlMutationFn =
  Apollo.MutationFunction<
    GenerateMediaUpdateAttachmentSignedUrlMutation,
    GenerateMediaUpdateAttachmentSignedUrlMutationVariables
  >;

/**
 * __useGenerateMediaUpdateAttachmentSignedUrlMutation__
 *
 * To run a mutation, you first call `useGenerateMediaUpdateAttachmentSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMediaUpdateAttachmentSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMediaUpdateAttachmentSignedUrlMutation, { data, loading, error }] = useGenerateMediaUpdateAttachmentSignedUrlMutation({
 *   variables: {
 *      mimeType: // value for 'mimeType'
 *   },
 * });
 */
export function useGenerateMediaUpdateAttachmentSignedUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateMediaUpdateAttachmentSignedUrlMutation,
    GenerateMediaUpdateAttachmentSignedUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateMediaUpdateAttachmentSignedUrlMutation,
    GenerateMediaUpdateAttachmentSignedUrlMutationVariables
  >(GenerateMediaUpdateAttachmentSignedUrlDocument, options);
}
export type GenerateMediaUpdateAttachmentSignedUrlMutationHookResult =
  ReturnType<typeof useGenerateMediaUpdateAttachmentSignedUrlMutation>;
export type GenerateMediaUpdateAttachmentSignedUrlMutationResult =
  Apollo.MutationResult<GenerateMediaUpdateAttachmentSignedUrlMutation>;
export type GenerateMediaUpdateAttachmentSignedUrlMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateMediaUpdateAttachmentSignedUrlMutation,
    GenerateMediaUpdateAttachmentSignedUrlMutationVariables
  >;
export const GeneratePastPlacementParticipantListSignedUrlDocument = gql`
  mutation GeneratePastPlacementParticipantListSignedUrl(
    $mimeType: String!
    $pastPlacementId: ID!
  ) {
    generatePastPlacementParticipantListSignedUrl(
      mimeType: $mimeType
      pastPlacementId: $pastPlacementId
    )
  }
`;
export type GeneratePastPlacementParticipantListSignedUrlMutationFn =
  Apollo.MutationFunction<
    GeneratePastPlacementParticipantListSignedUrlMutation,
    GeneratePastPlacementParticipantListSignedUrlMutationVariables
  >;

/**
 * __useGeneratePastPlacementParticipantListSignedUrlMutation__
 *
 * To run a mutation, you first call `useGeneratePastPlacementParticipantListSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePastPlacementParticipantListSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePastPlacementParticipantListSignedUrlMutation, { data, loading, error }] = useGeneratePastPlacementParticipantListSignedUrlMutation({
 *   variables: {
 *      mimeType: // value for 'mimeType'
 *      pastPlacementId: // value for 'pastPlacementId'
 *   },
 * });
 */
export function useGeneratePastPlacementParticipantListSignedUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratePastPlacementParticipantListSignedUrlMutation,
    GeneratePastPlacementParticipantListSignedUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratePastPlacementParticipantListSignedUrlMutation,
    GeneratePastPlacementParticipantListSignedUrlMutationVariables
  >(GeneratePastPlacementParticipantListSignedUrlDocument, options);
}
export type GeneratePastPlacementParticipantListSignedUrlMutationHookResult =
  ReturnType<typeof useGeneratePastPlacementParticipantListSignedUrlMutation>;
export type GeneratePastPlacementParticipantListSignedUrlMutationResult =
  Apollo.MutationResult<GeneratePastPlacementParticipantListSignedUrlMutation>;
export type GeneratePastPlacementParticipantListSignedUrlMutationOptions =
  Apollo.BaseMutationOptions<
    GeneratePastPlacementParticipantListSignedUrlMutation,
    GeneratePastPlacementParticipantListSignedUrlMutationVariables
  >;
export const GeneratePreparedAnnouncementVideoSignedUrlDocument = gql`
  mutation GeneratePreparedAnnouncementVideoSignedUrl(
    $fileSize: Int!
    $preparedAnnouncementId: ID!
    $mimeType: String!
  ) {
    generatePreparedAnnouncementVideoSignedUrl(
      fileSize: $fileSize
      preparedAnnouncementId: $preparedAnnouncementId
      mimeType: $mimeType
    )
  }
`;
export type GeneratePreparedAnnouncementVideoSignedUrlMutationFn =
  Apollo.MutationFunction<
    GeneratePreparedAnnouncementVideoSignedUrlMutation,
    GeneratePreparedAnnouncementVideoSignedUrlMutationVariables
  >;

/**
 * __useGeneratePreparedAnnouncementVideoSignedUrlMutation__
 *
 * To run a mutation, you first call `useGeneratePreparedAnnouncementVideoSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePreparedAnnouncementVideoSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePreparedAnnouncementVideoSignedUrlMutation, { data, loading, error }] = useGeneratePreparedAnnouncementVideoSignedUrlMutation({
 *   variables: {
 *      fileSize: // value for 'fileSize'
 *      preparedAnnouncementId: // value for 'preparedAnnouncementId'
 *      mimeType: // value for 'mimeType'
 *   },
 * });
 */
export function useGeneratePreparedAnnouncementVideoSignedUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratePreparedAnnouncementVideoSignedUrlMutation,
    GeneratePreparedAnnouncementVideoSignedUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratePreparedAnnouncementVideoSignedUrlMutation,
    GeneratePreparedAnnouncementVideoSignedUrlMutationVariables
  >(GeneratePreparedAnnouncementVideoSignedUrlDocument, options);
}
export type GeneratePreparedAnnouncementVideoSignedUrlMutationHookResult =
  ReturnType<typeof useGeneratePreparedAnnouncementVideoSignedUrlMutation>;
export type GeneratePreparedAnnouncementVideoSignedUrlMutationResult =
  Apollo.MutationResult<GeneratePreparedAnnouncementVideoSignedUrlMutation>;
export type GeneratePreparedAnnouncementVideoSignedUrlMutationOptions =
  Apollo.BaseMutationOptions<
    GeneratePreparedAnnouncementVideoSignedUrlMutation,
    GeneratePreparedAnnouncementVideoSignedUrlMutationVariables
  >;
export const GenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlDocument = gql`
  mutation GenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrl(
    $fileSize: Int!
    $shareholderOfferId: ID!
    $mimeType: String!
  ) {
    generateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrl(
      fileSize: $fileSize
      shareholderOfferId: $shareholderOfferId
      mimeType: $mimeType
    )
  }
`;
export type GenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutationFn =
  Apollo.MutationFunction<
    GenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutation,
    GenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutationVariables
  >;

/**
 * __useGenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutation__
 *
 * To run a mutation, you first call `useGenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutation, { data, loading, error }] = useGenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutation({
 *   variables: {
 *      fileSize: // value for 'fileSize'
 *      shareholderOfferId: // value for 'shareholderOfferId'
 *      mimeType: // value for 'mimeType'
 *   },
 * });
 */
export function useGenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutation,
    GenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutation,
    GenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutationVariables
  >(
    GenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlDocument,
    options
  );
}
export type GenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutationHookResult =
  ReturnType<
    typeof useGenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutation
  >;
export type GenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutationResult =
  Apollo.MutationResult<GenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutation>;
export type GenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutation,
    GenerateShareholderOfferPageRaiseReasonHeroMediaVideoSignedUrlMutationVariables
  >;
export const InvalidateCompanyProfileUserDocument = gql`
  mutation InvalidateCompanyProfileUser($id: ID!) {
    invalidateCompanyProfileUser(id: $id) {
      id
    }
  }
`;
export type InvalidateCompanyProfileUserMutationFn = Apollo.MutationFunction<
  InvalidateCompanyProfileUserMutation,
  InvalidateCompanyProfileUserMutationVariables
>;

/**
 * __useInvalidateCompanyProfileUserMutation__
 *
 * To run a mutation, you first call `useInvalidateCompanyProfileUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvalidateCompanyProfileUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invalidateCompanyProfileUserMutation, { data, loading, error }] = useInvalidateCompanyProfileUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvalidateCompanyProfileUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InvalidateCompanyProfileUserMutation,
    InvalidateCompanyProfileUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InvalidateCompanyProfileUserMutation,
    InvalidateCompanyProfileUserMutationVariables
  >(InvalidateCompanyProfileUserDocument, options);
}
export type InvalidateCompanyProfileUserMutationHookResult = ReturnType<
  typeof useInvalidateCompanyProfileUserMutation
>;
export type InvalidateCompanyProfileUserMutationResult =
  Apollo.MutationResult<InvalidateCompanyProfileUserMutation>;
export type InvalidateCompanyProfileUserMutationOptions =
  Apollo.BaseMutationOptions<
    InvalidateCompanyProfileUserMutation,
    InvalidateCompanyProfileUserMutationVariables
  >;
export const InvalidateMediaCommentDocument = gql`
  mutation InvalidateMediaComment($mediaCommentId: ID!) {
    invalidateMediaComment(mediaCommentId: $mediaCommentId) {
      id
    }
  }
`;
export type InvalidateMediaCommentMutationFn = Apollo.MutationFunction<
  InvalidateMediaCommentMutation,
  InvalidateMediaCommentMutationVariables
>;

/**
 * __useInvalidateMediaCommentMutation__
 *
 * To run a mutation, you first call `useInvalidateMediaCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvalidateMediaCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invalidateMediaCommentMutation, { data, loading, error }] = useInvalidateMediaCommentMutation({
 *   variables: {
 *      mediaCommentId: // value for 'mediaCommentId'
 *   },
 * });
 */
export function useInvalidateMediaCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InvalidateMediaCommentMutation,
    InvalidateMediaCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InvalidateMediaCommentMutation,
    InvalidateMediaCommentMutationVariables
  >(InvalidateMediaCommentDocument, options);
}
export type InvalidateMediaCommentMutationHookResult = ReturnType<
  typeof useInvalidateMediaCommentMutation
>;
export type InvalidateMediaCommentMutationResult =
  Apollo.MutationResult<InvalidateMediaCommentMutation>;
export type InvalidateMediaCommentMutationOptions = Apollo.BaseMutationOptions<
  InvalidateMediaCommentMutation,
  InvalidateMediaCommentMutationVariables
>;
export const InvalidateMediaDocument = gql`
  mutation InvalidateMedia($id: ID!) {
    invalidateMedia(id: $id) {
      id
    }
  }
`;
export type InvalidateMediaMutationFn = Apollo.MutationFunction<
  InvalidateMediaMutation,
  InvalidateMediaMutationVariables
>;

/**
 * __useInvalidateMediaMutation__
 *
 * To run a mutation, you first call `useInvalidateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvalidateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invalidateMediaMutation, { data, loading, error }] = useInvalidateMediaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvalidateMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InvalidateMediaMutation,
    InvalidateMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InvalidateMediaMutation,
    InvalidateMediaMutationVariables
  >(InvalidateMediaDocument, options);
}
export type InvalidateMediaMutationHookResult = ReturnType<
  typeof useInvalidateMediaMutation
>;
export type InvalidateMediaMutationResult =
  Apollo.MutationResult<InvalidateMediaMutation>;
export type InvalidateMediaMutationOptions = Apollo.BaseMutationOptions<
  InvalidateMediaMutation,
  InvalidateMediaMutationVariables
>;
export const InvalidatePastPlacementParticipantDocument = gql`
  mutation InvalidatePastPlacementParticipant($participantId: ID!) {
    invalidatePastPlacementParticipant(participantId: $participantId) {
      id
    }
  }
`;
export type InvalidatePastPlacementParticipantMutationFn =
  Apollo.MutationFunction<
    InvalidatePastPlacementParticipantMutation,
    InvalidatePastPlacementParticipantMutationVariables
  >;

/**
 * __useInvalidatePastPlacementParticipantMutation__
 *
 * To run a mutation, you first call `useInvalidatePastPlacementParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvalidatePastPlacementParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invalidatePastPlacementParticipantMutation, { data, loading, error }] = useInvalidatePastPlacementParticipantMutation({
 *   variables: {
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useInvalidatePastPlacementParticipantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InvalidatePastPlacementParticipantMutation,
    InvalidatePastPlacementParticipantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InvalidatePastPlacementParticipantMutation,
    InvalidatePastPlacementParticipantMutationVariables
  >(InvalidatePastPlacementParticipantDocument, options);
}
export type InvalidatePastPlacementParticipantMutationHookResult = ReturnType<
  typeof useInvalidatePastPlacementParticipantMutation
>;
export type InvalidatePastPlacementParticipantMutationResult =
  Apollo.MutationResult<InvalidatePastPlacementParticipantMutation>;
export type InvalidatePastPlacementParticipantMutationOptions =
  Apollo.BaseMutationOptions<
    InvalidatePastPlacementParticipantMutation,
    InvalidatePastPlacementParticipantMutationVariables
  >;
export const LinkPreparedAnnouncementToAnnouncementDocument = gql`
  mutation LinkPreparedAnnouncementToAnnouncement(
    $preparedAnnouncementId: ID!
    $announcementMediaId: ID!
  ) {
    linkPreparedAnnouncementToAnnouncement(
      preparedAnnouncementId: $preparedAnnouncementId
      announcementMediaId: $announcementMediaId
    ) {
      id
    }
  }
`;
export type LinkPreparedAnnouncementToAnnouncementMutationFn =
  Apollo.MutationFunction<
    LinkPreparedAnnouncementToAnnouncementMutation,
    LinkPreparedAnnouncementToAnnouncementMutationVariables
  >;

/**
 * __useLinkPreparedAnnouncementToAnnouncementMutation__
 *
 * To run a mutation, you first call `useLinkPreparedAnnouncementToAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkPreparedAnnouncementToAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkPreparedAnnouncementToAnnouncementMutation, { data, loading, error }] = useLinkPreparedAnnouncementToAnnouncementMutation({
 *   variables: {
 *      preparedAnnouncementId: // value for 'preparedAnnouncementId'
 *      announcementMediaId: // value for 'announcementMediaId'
 *   },
 * });
 */
export function useLinkPreparedAnnouncementToAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkPreparedAnnouncementToAnnouncementMutation,
    LinkPreparedAnnouncementToAnnouncementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LinkPreparedAnnouncementToAnnouncementMutation,
    LinkPreparedAnnouncementToAnnouncementMutationVariables
  >(LinkPreparedAnnouncementToAnnouncementDocument, options);
}
export type LinkPreparedAnnouncementToAnnouncementMutationHookResult =
  ReturnType<typeof useLinkPreparedAnnouncementToAnnouncementMutation>;
export type LinkPreparedAnnouncementToAnnouncementMutationResult =
  Apollo.MutationResult<LinkPreparedAnnouncementToAnnouncementMutation>;
export type LinkPreparedAnnouncementToAnnouncementMutationOptions =
  Apollo.BaseMutationOptions<
    LinkPreparedAnnouncementToAnnouncementMutation,
    LinkPreparedAnnouncementToAnnouncementMutationVariables
  >;
export const LinkContactWithInvestorDocument = gql`
  mutation LinkContactWithInvestor($id: ID!, $investorUserId: ID!) {
    linkContactWithInvestor(id: $id, investorUserId: $investorUserId) {
      id
    }
  }
`;
export type LinkContactWithInvestorMutationFn = Apollo.MutationFunction<
  LinkContactWithInvestorMutation,
  LinkContactWithInvestorMutationVariables
>;

/**
 * __useLinkContactWithInvestorMutation__
 *
 * To run a mutation, you first call `useLinkContactWithInvestorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkContactWithInvestorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkContactWithInvestorMutation, { data, loading, error }] = useLinkContactWithInvestorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      investorUserId: // value for 'investorUserId'
 *   },
 * });
 */
export function useLinkContactWithInvestorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkContactWithInvestorMutation,
    LinkContactWithInvestorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LinkContactWithInvestorMutation,
    LinkContactWithInvestorMutationVariables
  >(LinkContactWithInvestorDocument, options);
}
export type LinkContactWithInvestorMutationHookResult = ReturnType<
  typeof useLinkContactWithInvestorMutation
>;
export type LinkContactWithInvestorMutationResult =
  Apollo.MutationResult<LinkContactWithInvestorMutation>;
export type LinkContactWithInvestorMutationOptions = Apollo.BaseMutationOptions<
  LinkContactWithInvestorMutation,
  LinkContactWithInvestorMutationVariables
>;
export const LinkContactWithShareholdingsDocument = gql`
  mutation LinkContactWithShareholdings($id: ID!, $shareholdingIds: [ID!]!) {
    linkContactWithShareholdings(id: $id, shareholdingIds: $shareholdingIds) {
      id
    }
  }
`;
export type LinkContactWithShareholdingsMutationFn = Apollo.MutationFunction<
  LinkContactWithShareholdingsMutation,
  LinkContactWithShareholdingsMutationVariables
>;

/**
 * __useLinkContactWithShareholdingsMutation__
 *
 * To run a mutation, you first call `useLinkContactWithShareholdingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkContactWithShareholdingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkContactWithShareholdingsMutation, { data, loading, error }] = useLinkContactWithShareholdingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      shareholdingIds: // value for 'shareholdingIds'
 *   },
 * });
 */
export function useLinkContactWithShareholdingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkContactWithShareholdingsMutation,
    LinkContactWithShareholdingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LinkContactWithShareholdingsMutation,
    LinkContactWithShareholdingsMutationVariables
  >(LinkContactWithShareholdingsDocument, options);
}
export type LinkContactWithShareholdingsMutationHookResult = ReturnType<
  typeof useLinkContactWithShareholdingsMutation
>;
export type LinkContactWithShareholdingsMutationResult =
  Apollo.MutationResult<LinkContactWithShareholdingsMutation>;
export type LinkContactWithShareholdingsMutationOptions =
  Apollo.BaseMutationOptions<
    LinkContactWithShareholdingsMutation,
    LinkContactWithShareholdingsMutationVariables
  >;
export const MarkFeatureAsOnboardedDocument = gql`
  mutation MarkFeatureAsOnboarded($featureName: String!) {
    markFeatureAsOnboarded(featureName: $featureName) {
      id
      featureName
      onboardedAt
      userId
    }
  }
`;
export type MarkFeatureAsOnboardedMutationFn = Apollo.MutationFunction<
  MarkFeatureAsOnboardedMutation,
  MarkFeatureAsOnboardedMutationVariables
>;

/**
 * __useMarkFeatureAsOnboardedMutation__
 *
 * To run a mutation, you first call `useMarkFeatureAsOnboardedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkFeatureAsOnboardedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markFeatureAsOnboardedMutation, { data, loading, error }] = useMarkFeatureAsOnboardedMutation({
 *   variables: {
 *      featureName: // value for 'featureName'
 *   },
 * });
 */
export function useMarkFeatureAsOnboardedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkFeatureAsOnboardedMutation,
    MarkFeatureAsOnboardedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkFeatureAsOnboardedMutation,
    MarkFeatureAsOnboardedMutationVariables
  >(MarkFeatureAsOnboardedDocument, options);
}
export type MarkFeatureAsOnboardedMutationHookResult = ReturnType<
  typeof useMarkFeatureAsOnboardedMutation
>;
export type MarkFeatureAsOnboardedMutationResult =
  Apollo.MutationResult<MarkFeatureAsOnboardedMutation>;
export type MarkFeatureAsOnboardedMutationOptions = Apollo.BaseMutationOptions<
  MarkFeatureAsOnboardedMutation,
  MarkFeatureAsOnboardedMutationVariables
>;
export const MarkInvestorCertificateAsRejectedDocument = gql`
  mutation MarkInvestorCertificateAsRejected(
    $certificateId: ID!
    $rejectionComment: String
  ) {
    markInvestorCertificateAsRejected(
      certificateId: $certificateId
      rejectionComment: $rejectionComment
    ) {
      id
      certificateUrl
      insertedAt
      investorUser {
        id
        firstName
        lastName
        username
        email
        isHoldingVerified
      }
      status
    }
  }
`;
export type MarkInvestorCertificateAsRejectedMutationFn =
  Apollo.MutationFunction<
    MarkInvestorCertificateAsRejectedMutation,
    MarkInvestorCertificateAsRejectedMutationVariables
  >;

/**
 * __useMarkInvestorCertificateAsRejectedMutation__
 *
 * To run a mutation, you first call `useMarkInvestorCertificateAsRejectedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkInvestorCertificateAsRejectedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markInvestorCertificateAsRejectedMutation, { data, loading, error }] = useMarkInvestorCertificateAsRejectedMutation({
 *   variables: {
 *      certificateId: // value for 'certificateId'
 *      rejectionComment: // value for 'rejectionComment'
 *   },
 * });
 */
export function useMarkInvestorCertificateAsRejectedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkInvestorCertificateAsRejectedMutation,
    MarkInvestorCertificateAsRejectedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkInvestorCertificateAsRejectedMutation,
    MarkInvestorCertificateAsRejectedMutationVariables
  >(MarkInvestorCertificateAsRejectedDocument, options);
}
export type MarkInvestorCertificateAsRejectedMutationHookResult = ReturnType<
  typeof useMarkInvestorCertificateAsRejectedMutation
>;
export type MarkInvestorCertificateAsRejectedMutationResult =
  Apollo.MutationResult<MarkInvestorCertificateAsRejectedMutation>;
export type MarkInvestorCertificateAsRejectedMutationOptions =
  Apollo.BaseMutationOptions<
    MarkInvestorCertificateAsRejectedMutation,
    MarkInvestorCertificateAsRejectedMutationVariables
  >;
export const MarkInvestorCertificateAsVerifiedDocument = gql`
  mutation MarkInvestorCertificateAsVerified(
    $certificateId: ID!
    $expiresAt: IsoNaiveDatetime
    $type: InvestorCertificateType!
  ) {
    markInvestorCertificateAsVerified(
      certificateId: $certificateId
      expiresAt: $expiresAt
      type: $type
    ) {
      id
      certificateUrl
      insertedAt
      investorUser {
        id
        firstName
        lastName
        username
        email
        isHoldingVerified
      }
      status
    }
  }
`;
export type MarkInvestorCertificateAsVerifiedMutationFn =
  Apollo.MutationFunction<
    MarkInvestorCertificateAsVerifiedMutation,
    MarkInvestorCertificateAsVerifiedMutationVariables
  >;

/**
 * __useMarkInvestorCertificateAsVerifiedMutation__
 *
 * To run a mutation, you first call `useMarkInvestorCertificateAsVerifiedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkInvestorCertificateAsVerifiedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markInvestorCertificateAsVerifiedMutation, { data, loading, error }] = useMarkInvestorCertificateAsVerifiedMutation({
 *   variables: {
 *      certificateId: // value for 'certificateId'
 *      expiresAt: // value for 'expiresAt'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useMarkInvestorCertificateAsVerifiedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkInvestorCertificateAsVerifiedMutation,
    MarkInvestorCertificateAsVerifiedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkInvestorCertificateAsVerifiedMutation,
    MarkInvestorCertificateAsVerifiedMutationVariables
  >(MarkInvestorCertificateAsVerifiedDocument, options);
}
export type MarkInvestorCertificateAsVerifiedMutationHookResult = ReturnType<
  typeof useMarkInvestorCertificateAsVerifiedMutation
>;
export type MarkInvestorCertificateAsVerifiedMutationResult =
  Apollo.MutationResult<MarkInvestorCertificateAsVerifiedMutation>;
export type MarkInvestorCertificateAsVerifiedMutationOptions =
  Apollo.BaseMutationOptions<
    MarkInvestorCertificateAsVerifiedMutation,
    MarkInvestorCertificateAsVerifiedMutationVariables
  >;
export const PublishMediaUpdateDocument = gql`
  mutation PublishMediaUpdate($id: ID!) {
    publishMediaUpdate(id: $id) {
      id
      isDraft
      postedAt
      postedBy {
        id
        firstName
        lastName
        email
      }
    }
  }
`;
export type PublishMediaUpdateMutationFn = Apollo.MutationFunction<
  PublishMediaUpdateMutation,
  PublishMediaUpdateMutationVariables
>;

/**
 * __usePublishMediaUpdateMutation__
 *
 * To run a mutation, you first call `usePublishMediaUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishMediaUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishMediaUpdateMutation, { data, loading, error }] = usePublishMediaUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishMediaUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishMediaUpdateMutation,
    PublishMediaUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishMediaUpdateMutation,
    PublishMediaUpdateMutationVariables
  >(PublishMediaUpdateDocument, options);
}
export type PublishMediaUpdateMutationHookResult = ReturnType<
  typeof usePublishMediaUpdateMutation
>;
export type PublishMediaUpdateMutationResult =
  Apollo.MutationResult<PublishMediaUpdateMutation>;
export type PublishMediaUpdateMutationOptions = Apollo.BaseMutationOptions<
  PublishMediaUpdateMutation,
  PublishMediaUpdateMutationVariables
>;
export const PublishWebsiteDocument = gql`
  mutation PublishWebsite {
    publishWebsite
  }
`;
export type PublishWebsiteMutationFn = Apollo.MutationFunction<
  PublishWebsiteMutation,
  PublishWebsiteMutationVariables
>;

/**
 * __usePublishWebsiteMutation__
 *
 * To run a mutation, you first call `usePublishWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishWebsiteMutation, { data, loading, error }] = usePublishWebsiteMutation({
 *   variables: {
 *   },
 * });
 */
export function usePublishWebsiteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishWebsiteMutation,
    PublishWebsiteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishWebsiteMutation,
    PublishWebsiteMutationVariables
  >(PublishWebsiteDocument, options);
}
export type PublishWebsiteMutationHookResult = ReturnType<
  typeof usePublishWebsiteMutation
>;
export type PublishWebsiteMutationResult =
  Apollo.MutationResult<PublishWebsiteMutation>;
export type PublishWebsiteMutationOptions = Apollo.BaseMutationOptions<
  PublishWebsiteMutation,
  PublishWebsiteMutationVariables
>;
export const PublishWelcomePageDocument = gql`
  mutation PublishWelcomePage($welcomePage: WelcomePageInput!) {
    publishWelcomePage(welcomePage: $welcomePage) {
      id
    }
  }
`;
export type PublishWelcomePageMutationFn = Apollo.MutationFunction<
  PublishWelcomePageMutation,
  PublishWelcomePageMutationVariables
>;

/**
 * __usePublishWelcomePageMutation__
 *
 * To run a mutation, you first call `usePublishWelcomePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishWelcomePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishWelcomePageMutation, { data, loading, error }] = usePublishWelcomePageMutation({
 *   variables: {
 *      welcomePage: // value for 'welcomePage'
 *   },
 * });
 */
export function usePublishWelcomePageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishWelcomePageMutation,
    PublishWelcomePageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishWelcomePageMutation,
    PublishWelcomePageMutationVariables
  >(PublishWelcomePageDocument, options);
}
export type PublishWelcomePageMutationHookResult = ReturnType<
  typeof usePublishWelcomePageMutation
>;
export type PublishWelcomePageMutationResult =
  Apollo.MutationResult<PublishWelcomePageMutation>;
export type PublishWelcomePageMutationOptions = Apollo.BaseMutationOptions<
  PublishWelcomePageMutation,
  PublishWelcomePageMutationVariables
>;
export const RemoveCustomDomainDocument = gql`
  mutation RemoveCustomDomain {
    removeCustomDomain {
      id
      customDomain
      vercelConfiguration {
        configured
        name
        value
        type
      }
    }
  }
`;
export type RemoveCustomDomainMutationFn = Apollo.MutationFunction<
  RemoveCustomDomainMutation,
  RemoveCustomDomainMutationVariables
>;

/**
 * __useRemoveCustomDomainMutation__
 *
 * To run a mutation, you first call `useRemoveCustomDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCustomDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCustomDomainMutation, { data, loading, error }] = useRemoveCustomDomainMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveCustomDomainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCustomDomainMutation,
    RemoveCustomDomainMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCustomDomainMutation,
    RemoveCustomDomainMutationVariables
  >(RemoveCustomDomainDocument, options);
}
export type RemoveCustomDomainMutationHookResult = ReturnType<
  typeof useRemoveCustomDomainMutation
>;
export type RemoveCustomDomainMutationResult =
  Apollo.MutationResult<RemoveCustomDomainMutation>;
export type RemoveCustomDomainMutationOptions = Apollo.BaseMutationOptions<
  RemoveCustomDomainMutation,
  RemoveCustomDomainMutationVariables
>;
export const RemoveCustomEmailsDocument = gql`
  mutation RemoveCustomEmails {
    removeCustomEmails
  }
`;
export type RemoveCustomEmailsMutationFn = Apollo.MutationFunction<
  RemoveCustomEmailsMutation,
  RemoveCustomEmailsMutationVariables
>;

/**
 * __useRemoveCustomEmailsMutation__
 *
 * To run a mutation, you first call `useRemoveCustomEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCustomEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCustomEmailsMutation, { data, loading, error }] = useRemoveCustomEmailsMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveCustomEmailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCustomEmailsMutation,
    RemoveCustomEmailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCustomEmailsMutation,
    RemoveCustomEmailsMutationVariables
  >(RemoveCustomEmailsDocument, options);
}
export type RemoveCustomEmailsMutationHookResult = ReturnType<
  typeof useRemoveCustomEmailsMutation
>;
export type RemoveCustomEmailsMutationResult =
  Apollo.MutationResult<RemoveCustomEmailsMutation>;
export type RemoveCustomEmailsMutationOptions = Apollo.BaseMutationOptions<
  RemoveCustomEmailsMutation,
  RemoveCustomEmailsMutationVariables
>;
export const ReplyToMediaCommentDocument = gql`
  mutation ReplyToMediaComment(
    $content: String!
    $parentId: ID!
    $useCompanyAsUsername: Boolean
  ) {
    replyToMediaComment(
      content: $content
      parentId: $parentId
      useCompanyAsUsername: $useCompanyAsUsername
    ) {
      id
    }
  }
`;
export type ReplyToMediaCommentMutationFn = Apollo.MutationFunction<
  ReplyToMediaCommentMutation,
  ReplyToMediaCommentMutationVariables
>;

/**
 * __useReplyToMediaCommentMutation__
 *
 * To run a mutation, you first call `useReplyToMediaCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplyToMediaCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replyToMediaCommentMutation, { data, loading, error }] = useReplyToMediaCommentMutation({
 *   variables: {
 *      content: // value for 'content'
 *      parentId: // value for 'parentId'
 *      useCompanyAsUsername: // value for 'useCompanyAsUsername'
 *   },
 * });
 */
export function useReplyToMediaCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReplyToMediaCommentMutation,
    ReplyToMediaCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReplyToMediaCommentMutation,
    ReplyToMediaCommentMutationVariables
  >(ReplyToMediaCommentDocument, options);
}
export type ReplyToMediaCommentMutationHookResult = ReturnType<
  typeof useReplyToMediaCommentMutation
>;
export type ReplyToMediaCommentMutationResult =
  Apollo.MutationResult<ReplyToMediaCommentMutation>;
export type ReplyToMediaCommentMutationOptions = Apollo.BaseMutationOptions<
  ReplyToMediaCommentMutation,
  ReplyToMediaCommentMutationVariables
>;
export const RequestExternalPlacementDemandEstimateDocument = gql`
  mutation RequestExternalPlacementDemandEstimate($comments: String) {
    requestExternalPlacementDemandEstimate(comments: $comments) {
      id
    }
  }
`;
export type RequestExternalPlacementDemandEstimateMutationFn =
  Apollo.MutationFunction<
    RequestExternalPlacementDemandEstimateMutation,
    RequestExternalPlacementDemandEstimateMutationVariables
  >;

/**
 * __useRequestExternalPlacementDemandEstimateMutation__
 *
 * To run a mutation, you first call `useRequestExternalPlacementDemandEstimateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestExternalPlacementDemandEstimateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestExternalPlacementDemandEstimateMutation, { data, loading, error }] = useRequestExternalPlacementDemandEstimateMutation({
 *   variables: {
 *      comments: // value for 'comments'
 *   },
 * });
 */
export function useRequestExternalPlacementDemandEstimateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestExternalPlacementDemandEstimateMutation,
    RequestExternalPlacementDemandEstimateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestExternalPlacementDemandEstimateMutation,
    RequestExternalPlacementDemandEstimateMutationVariables
  >(RequestExternalPlacementDemandEstimateDocument, options);
}
export type RequestExternalPlacementDemandEstimateMutationHookResult =
  ReturnType<typeof useRequestExternalPlacementDemandEstimateMutation>;
export type RequestExternalPlacementDemandEstimateMutationResult =
  Apollo.MutationResult<RequestExternalPlacementDemandEstimateMutation>;
export type RequestExternalPlacementDemandEstimateMutationOptions =
  Apollo.BaseMutationOptions<
    RequestExternalPlacementDemandEstimateMutation,
    RequestExternalPlacementDemandEstimateMutationVariables
  >;
export const ResendInvitationTokenDocument = gql`
  mutation ResendInvitationToken($id: ID!) {
    resendInvitationToken(id: $id)
  }
`;
export type ResendInvitationTokenMutationFn = Apollo.MutationFunction<
  ResendInvitationTokenMutation,
  ResendInvitationTokenMutationVariables
>;

/**
 * __useResendInvitationTokenMutation__
 *
 * To run a mutation, you first call `useResendInvitationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationTokenMutation, { data, loading, error }] = useResendInvitationTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendInvitationTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendInvitationTokenMutation,
    ResendInvitationTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendInvitationTokenMutation,
    ResendInvitationTokenMutationVariables
  >(ResendInvitationTokenDocument, options);
}
export type ResendInvitationTokenMutationHookResult = ReturnType<
  typeof useResendInvitationTokenMutation
>;
export type ResendInvitationTokenMutationResult =
  Apollo.MutationResult<ResendInvitationTokenMutation>;
export type ResendInvitationTokenMutationOptions = Apollo.BaseMutationOptions<
  ResendInvitationTokenMutation,
  ResendInvitationTokenMutationVariables
>;
export const ResetMediaAnnouncementDocument = gql`
  mutation ResetMediaAnnouncement($id: ID!) {
    resetMediaAnnouncement(id: $id) {
      id
    }
  }
`;
export type ResetMediaAnnouncementMutationFn = Apollo.MutationFunction<
  ResetMediaAnnouncementMutation,
  ResetMediaAnnouncementMutationVariables
>;

/**
 * __useResetMediaAnnouncementMutation__
 *
 * To run a mutation, you first call `useResetMediaAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetMediaAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetMediaAnnouncementMutation, { data, loading, error }] = useResetMediaAnnouncementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResetMediaAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetMediaAnnouncementMutation,
    ResetMediaAnnouncementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetMediaAnnouncementMutation,
    ResetMediaAnnouncementMutationVariables
  >(ResetMediaAnnouncementDocument, options);
}
export type ResetMediaAnnouncementMutationHookResult = ReturnType<
  typeof useResetMediaAnnouncementMutation
>;
export type ResetMediaAnnouncementMutationResult =
  Apollo.MutationResult<ResetMediaAnnouncementMutation>;
export type ResetMediaAnnouncementMutationOptions = Apollo.BaseMutationOptions<
  ResetMediaAnnouncementMutation,
  ResetMediaAnnouncementMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword(
    $password: String!
    $passwordConfirmation: String!
    $token: String!
  ) {
    resetPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      token: $token
    )
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const SendEmailDocument = gql`
  mutation SendEmail($emailId: ID!) {
    sendEmail(emailId: $emailId) {
      id
      sentAt
    }
  }
`;
export type SendEmailMutationFn = Apollo.MutationFunction<
  SendEmailMutation,
  SendEmailMutationVariables
>;

/**
 * __useSendEmailMutation__
 *
 * To run a mutation, you first call `useSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailMutation, { data, loading, error }] = useSendEmailMutation({
 *   variables: {
 *      emailId: // value for 'emailId'
 *   },
 * });
 */
export function useSendEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendEmailMutation,
    SendEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendEmailMutation, SendEmailMutationVariables>(
    SendEmailDocument,
    options
  );
}
export type SendEmailMutationHookResult = ReturnType<
  typeof useSendEmailMutation
>;
export type SendEmailMutationResult = Apollo.MutationResult<SendEmailMutation>;
export type SendEmailMutationOptions = Apollo.BaseMutationOptions<
  SendEmailMutation,
  SendEmailMutationVariables
>;
export const SendManualSocialPostDocument = gql`
  mutation SendManualSocialPost(
    $mediaId: ID!
    $socialPlatform: SocialPlatform!
    $text: String!
    $linkedinThumbailUrl: String
  ) {
    sendManualSocialPost(
      mediaId: $mediaId
      socialPlatform: $socialPlatform
      text: $text
      linkedinThumbailUrl: $linkedinThumbailUrl
    ) {
      id
      linkedinPostId
      linkedinPostUrl
      twitterPostId
      twitterPostUrl
    }
  }
`;
export type SendManualSocialPostMutationFn = Apollo.MutationFunction<
  SendManualSocialPostMutation,
  SendManualSocialPostMutationVariables
>;

/**
 * __useSendManualSocialPostMutation__
 *
 * To run a mutation, you first call `useSendManualSocialPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendManualSocialPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendManualSocialPostMutation, { data, loading, error }] = useSendManualSocialPostMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *      socialPlatform: // value for 'socialPlatform'
 *      text: // value for 'text'
 *      linkedinThumbailUrl: // value for 'linkedinThumbailUrl'
 *   },
 * });
 */
export function useSendManualSocialPostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendManualSocialPostMutation,
    SendManualSocialPostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendManualSocialPostMutation,
    SendManualSocialPostMutationVariables
  >(SendManualSocialPostDocument, options);
}
export type SendManualSocialPostMutationHookResult = ReturnType<
  typeof useSendManualSocialPostMutation
>;
export type SendManualSocialPostMutationResult =
  Apollo.MutationResult<SendManualSocialPostMutation>;
export type SendManualSocialPostMutationOptions = Apollo.BaseMutationOptions<
  SendManualSocialPostMutation,
  SendManualSocialPostMutationVariables
>;
export const SendResetPasswordDocument = gql`
  mutation SendResetPassword($email: String!) {
    sendResetPassword(email: $email)
  }
`;
export type SendResetPasswordMutationFn = Apollo.MutationFunction<
  SendResetPasswordMutation,
  SendResetPasswordMutationVariables
>;

/**
 * __useSendResetPasswordMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordMutation, { data, loading, error }] = useSendResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendResetPasswordMutation,
    SendResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendResetPasswordMutation,
    SendResetPasswordMutationVariables
  >(SendResetPasswordDocument, options);
}
export type SendResetPasswordMutationHookResult = ReturnType<
  typeof useSendResetPasswordMutation
>;
export type SendResetPasswordMutationResult =
  Apollo.MutationResult<SendResetPasswordMutation>;
export type SendResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  SendResetPasswordMutation,
  SendResetPasswordMutationVariables
>;
export const SendTestEmailDomainConnectionEmailDocument = gql`
  mutation SendTestEmailDomainConnectionEmail($emailAddress: String!) {
    sendTestEmailDomainConnectionEmail(emailAddress: $emailAddress)
  }
`;
export type SendTestEmailDomainConnectionEmailMutationFn =
  Apollo.MutationFunction<
    SendTestEmailDomainConnectionEmailMutation,
    SendTestEmailDomainConnectionEmailMutationVariables
  >;

/**
 * __useSendTestEmailDomainConnectionEmailMutation__
 *
 * To run a mutation, you first call `useSendTestEmailDomainConnectionEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTestEmailDomainConnectionEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTestEmailDomainConnectionEmailMutation, { data, loading, error }] = useSendTestEmailDomainConnectionEmailMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useSendTestEmailDomainConnectionEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendTestEmailDomainConnectionEmailMutation,
    SendTestEmailDomainConnectionEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendTestEmailDomainConnectionEmailMutation,
    SendTestEmailDomainConnectionEmailMutationVariables
  >(SendTestEmailDomainConnectionEmailDocument, options);
}
export type SendTestEmailDomainConnectionEmailMutationHookResult = ReturnType<
  typeof useSendTestEmailDomainConnectionEmailMutation
>;
export type SendTestEmailDomainConnectionEmailMutationResult =
  Apollo.MutationResult<SendTestEmailDomainConnectionEmailMutation>;
export type SendTestEmailDomainConnectionEmailMutationOptions =
  Apollo.BaseMutationOptions<
    SendTestEmailDomainConnectionEmailMutation,
    SendTestEmailDomainConnectionEmailMutationVariables
  >;
export const SendTestEmailDocument = gql`
  mutation SendTestEmail(
    $id: ID!
    $recipientEmails: [String!]!
    $emailHtml: String
  ) {
    sendTestEmail(
      id: $id
      recipientEmails: $recipientEmails
      emailHtml: $emailHtml
    )
  }
`;
export type SendTestEmailMutationFn = Apollo.MutationFunction<
  SendTestEmailMutation,
  SendTestEmailMutationVariables
>;

/**
 * __useSendTestEmailMutation__
 *
 * To run a mutation, you first call `useSendTestEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTestEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTestEmailMutation, { data, loading, error }] = useSendTestEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      recipientEmails: // value for 'recipientEmails'
 *      emailHtml: // value for 'emailHtml'
 *   },
 * });
 */
export function useSendTestEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendTestEmailMutation,
    SendTestEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendTestEmailMutation,
    SendTestEmailMutationVariables
  >(SendTestEmailDocument, options);
}
export type SendTestEmailMutationHookResult = ReturnType<
  typeof useSendTestEmailMutation
>;
export type SendTestEmailMutationResult =
  Apollo.MutationResult<SendTestEmailMutation>;
export type SendTestEmailMutationOptions = Apollo.BaseMutationOptions<
  SendTestEmailMutation,
  SendTestEmailMutationVariables
>;
export const InviteAndCreateCompanyProfileUsersDocument = gql`
  mutation InviteAndCreateCompanyProfileUsers(
    $inviteCompanyProfileUsers: [InviteCompanyProfileUserInput]
  ) {
    inviteAndCreateCompanyProfileUsers(
      inviteCompanyProfileUsers: $inviteCompanyProfileUsers
    ) {
      error {
        email
        reason
      }
      success {
        id
        status
        user {
          email
        }
      }
    }
  }
`;
export type InviteAndCreateCompanyProfileUsersMutationFn =
  Apollo.MutationFunction<
    InviteAndCreateCompanyProfileUsersMutation,
    InviteAndCreateCompanyProfileUsersMutationVariables
  >;

/**
 * __useInviteAndCreateCompanyProfileUsersMutation__
 *
 * To run a mutation, you first call `useInviteAndCreateCompanyProfileUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteAndCreateCompanyProfileUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteAndCreateCompanyProfileUsersMutation, { data, loading, error }] = useInviteAndCreateCompanyProfileUsersMutation({
 *   variables: {
 *      inviteCompanyProfileUsers: // value for 'inviteCompanyProfileUsers'
 *   },
 * });
 */
export function useInviteAndCreateCompanyProfileUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteAndCreateCompanyProfileUsersMutation,
    InviteAndCreateCompanyProfileUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteAndCreateCompanyProfileUsersMutation,
    InviteAndCreateCompanyProfileUsersMutationVariables
  >(InviteAndCreateCompanyProfileUsersDocument, options);
}
export type InviteAndCreateCompanyProfileUsersMutationHookResult = ReturnType<
  typeof useInviteAndCreateCompanyProfileUsersMutation
>;
export type InviteAndCreateCompanyProfileUsersMutationResult =
  Apollo.MutationResult<InviteAndCreateCompanyProfileUsersMutation>;
export type InviteAndCreateCompanyProfileUsersMutationOptions =
  Apollo.BaseMutationOptions<
    InviteAndCreateCompanyProfileUsersMutation,
    InviteAndCreateCompanyProfileUsersMutationVariables
  >;
export const SetPasswordFromInvitationTokenDocument = gql`
  mutation SetPasswordFromInvitationToken(
    $password: String!
    $passwordConfirmation: String!
    $token: String!
  ) {
    setPasswordFromInvitationToken(
      password: $password
      passwordConfirmation: $passwordConfirmation
      token: $token
    )
  }
`;
export type SetPasswordFromInvitationTokenMutationFn = Apollo.MutationFunction<
  SetPasswordFromInvitationTokenMutation,
  SetPasswordFromInvitationTokenMutationVariables
>;

/**
 * __useSetPasswordFromInvitationTokenMutation__
 *
 * To run a mutation, you first call `useSetPasswordFromInvitationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordFromInvitationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordFromInvitationTokenMutation, { data, loading, error }] = useSetPasswordFromInvitationTokenMutation({
 *   variables: {
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSetPasswordFromInvitationTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPasswordFromInvitationTokenMutation,
    SetPasswordFromInvitationTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetPasswordFromInvitationTokenMutation,
    SetPasswordFromInvitationTokenMutationVariables
  >(SetPasswordFromInvitationTokenDocument, options);
}
export type SetPasswordFromInvitationTokenMutationHookResult = ReturnType<
  typeof useSetPasswordFromInvitationTokenMutation
>;
export type SetPasswordFromInvitationTokenMutationResult =
  Apollo.MutationResult<SetPasswordFromInvitationTokenMutation>;
export type SetPasswordFromInvitationTokenMutationOptions =
  Apollo.BaseMutationOptions<
    SetPasswordFromInvitationTokenMutation,
    SetPasswordFromInvitationTokenMutationVariables
  >;
export const SortBlocksDocument = gql`
  mutation SortBlocks($pageId: ID!, $blockIds: [ID!]!) {
    sortBlocks(pageId: $pageId, blockIds: $blockIds)
  }
`;
export type SortBlocksMutationFn = Apollo.MutationFunction<
  SortBlocksMutation,
  SortBlocksMutationVariables
>;

/**
 * __useSortBlocksMutation__
 *
 * To run a mutation, you first call `useSortBlocksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortBlocksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortBlocksMutation, { data, loading, error }] = useSortBlocksMutation({
 *   variables: {
 *      pageId: // value for 'pageId'
 *      blockIds: // value for 'blockIds'
 *   },
 * });
 */
export function useSortBlocksMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SortBlocksMutation,
    SortBlocksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SortBlocksMutation, SortBlocksMutationVariables>(
    SortBlocksDocument,
    options
  );
}
export type SortBlocksMutationHookResult = ReturnType<
  typeof useSortBlocksMutation
>;
export type SortBlocksMutationResult =
  Apollo.MutationResult<SortBlocksMutation>;
export type SortBlocksMutationOptions = Apollo.BaseMutationOptions<
  SortBlocksMutation,
  SortBlocksMutationVariables
>;
export const SortNavMenuItemsWithChildrenDocument = gql`
  mutation SortNavMenuItemsWithChildren(
    $navMenuItems: [NavMenuItemOrderInput!]!
  ) {
    sortNavMenuItemsWithChildren(navMenuItems: $navMenuItems)
  }
`;
export type SortNavMenuItemsWithChildrenMutationFn = Apollo.MutationFunction<
  SortNavMenuItemsWithChildrenMutation,
  SortNavMenuItemsWithChildrenMutationVariables
>;

/**
 * __useSortNavMenuItemsWithChildrenMutation__
 *
 * To run a mutation, you first call `useSortNavMenuItemsWithChildrenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortNavMenuItemsWithChildrenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortNavMenuItemsWithChildrenMutation, { data, loading, error }] = useSortNavMenuItemsWithChildrenMutation({
 *   variables: {
 *      navMenuItems: // value for 'navMenuItems'
 *   },
 * });
 */
export function useSortNavMenuItemsWithChildrenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SortNavMenuItemsWithChildrenMutation,
    SortNavMenuItemsWithChildrenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SortNavMenuItemsWithChildrenMutation,
    SortNavMenuItemsWithChildrenMutationVariables
  >(SortNavMenuItemsWithChildrenDocument, options);
}
export type SortNavMenuItemsWithChildrenMutationHookResult = ReturnType<
  typeof useSortNavMenuItemsWithChildrenMutation
>;
export type SortNavMenuItemsWithChildrenMutationResult =
  Apollo.MutationResult<SortNavMenuItemsWithChildrenMutation>;
export type SortNavMenuItemsWithChildrenMutationOptions =
  Apollo.BaseMutationOptions<
    SortNavMenuItemsWithChildrenMutation,
    SortNavMenuItemsWithChildrenMutationVariables
  >;
export const SortNavMenuItemsDocument = gql`
  mutation SortNavMenuItems($parentId: ID, $navMenuItemIds: [ID!]!) {
    sortNavMenuItems(parentId: $parentId, navMenuItemIds: $navMenuItemIds)
  }
`;
export type SortNavMenuItemsMutationFn = Apollo.MutationFunction<
  SortNavMenuItemsMutation,
  SortNavMenuItemsMutationVariables
>;

/**
 * __useSortNavMenuItemsMutation__
 *
 * To run a mutation, you first call `useSortNavMenuItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortNavMenuItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortNavMenuItemsMutation, { data, loading, error }] = useSortNavMenuItemsMutation({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      navMenuItemIds: // value for 'navMenuItemIds'
 *   },
 * });
 */
export function useSortNavMenuItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SortNavMenuItemsMutation,
    SortNavMenuItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SortNavMenuItemsMutation,
    SortNavMenuItemsMutationVariables
  >(SortNavMenuItemsDocument, options);
}
export type SortNavMenuItemsMutationHookResult = ReturnType<
  typeof useSortNavMenuItemsMutation
>;
export type SortNavMenuItemsMutationResult =
  Apollo.MutationResult<SortNavMenuItemsMutation>;
export type SortNavMenuItemsMutationOptions = Apollo.BaseMutationOptions<
  SortNavMenuItemsMutation,
  SortNavMenuItemsMutationVariables
>;
export const SubmitPlacementDemandQuestionDocument = gql`
  mutation SubmitPlacementDemandQuestion(
    $comments: String
    $generalTopic: PlacementDemandQuestionGeneralTopic!
    $specificTopics: [PlacementDemandQuestionSpecificTopic]
  ) {
    submitPlacementDemandQuestion(
      comments: $comments
      generalTopic: $generalTopic
      specificTopics: $specificTopics
    ) {
      id
    }
  }
`;
export type SubmitPlacementDemandQuestionMutationFn = Apollo.MutationFunction<
  SubmitPlacementDemandQuestionMutation,
  SubmitPlacementDemandQuestionMutationVariables
>;

/**
 * __useSubmitPlacementDemandQuestionMutation__
 *
 * To run a mutation, you first call `useSubmitPlacementDemandQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPlacementDemandQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPlacementDemandQuestionMutation, { data, loading, error }] = useSubmitPlacementDemandQuestionMutation({
 *   variables: {
 *      comments: // value for 'comments'
 *      generalTopic: // value for 'generalTopic'
 *      specificTopics: // value for 'specificTopics'
 *   },
 * });
 */
export function useSubmitPlacementDemandQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitPlacementDemandQuestionMutation,
    SubmitPlacementDemandQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitPlacementDemandQuestionMutation,
    SubmitPlacementDemandQuestionMutationVariables
  >(SubmitPlacementDemandQuestionDocument, options);
}
export type SubmitPlacementDemandQuestionMutationHookResult = ReturnType<
  typeof useSubmitPlacementDemandQuestionMutation
>;
export type SubmitPlacementDemandQuestionMutationResult =
  Apollo.MutationResult<SubmitPlacementDemandQuestionMutation>;
export type SubmitPlacementDemandQuestionMutationOptions =
  Apollo.BaseMutationOptions<
    SubmitPlacementDemandQuestionMutation,
    SubmitPlacementDemandQuestionMutationVariables
  >;
export const ToggleMediaCommentPrivacyDocument = gql`
  mutation ToggleMediaCommentPrivacy($id: ID!, $private: Boolean!) {
    toggleMediaCommentPrivacy(id: $id, private: $private)
  }
`;
export type ToggleMediaCommentPrivacyMutationFn = Apollo.MutationFunction<
  ToggleMediaCommentPrivacyMutation,
  ToggleMediaCommentPrivacyMutationVariables
>;

/**
 * __useToggleMediaCommentPrivacyMutation__
 *
 * To run a mutation, you first call `useToggleMediaCommentPrivacyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleMediaCommentPrivacyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleMediaCommentPrivacyMutation, { data, loading, error }] = useToggleMediaCommentPrivacyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      private: // value for 'private'
 *   },
 * });
 */
export function useToggleMediaCommentPrivacyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleMediaCommentPrivacyMutation,
    ToggleMediaCommentPrivacyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleMediaCommentPrivacyMutation,
    ToggleMediaCommentPrivacyMutationVariables
  >(ToggleMediaCommentPrivacyDocument, options);
}
export type ToggleMediaCommentPrivacyMutationHookResult = ReturnType<
  typeof useToggleMediaCommentPrivacyMutation
>;
export type ToggleMediaCommentPrivacyMutationResult =
  Apollo.MutationResult<ToggleMediaCommentPrivacyMutation>;
export type ToggleMediaCommentPrivacyMutationOptions =
  Apollo.BaseMutationOptions<
    ToggleMediaCommentPrivacyMutation,
    ToggleMediaCommentPrivacyMutationVariables
  >;
export const UnlinkContactWithInvestorDocument = gql`
  mutation UnlinkContactWithInvestor($id: ID!, $investorUserId: ID!) {
    unlinkContactWithInvestor(id: $id, investorUserId: $investorUserId) {
      id
    }
  }
`;
export type UnlinkContactWithInvestorMutationFn = Apollo.MutationFunction<
  UnlinkContactWithInvestorMutation,
  UnlinkContactWithInvestorMutationVariables
>;

/**
 * __useUnlinkContactWithInvestorMutation__
 *
 * To run a mutation, you first call `useUnlinkContactWithInvestorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkContactWithInvestorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkContactWithInvestorMutation, { data, loading, error }] = useUnlinkContactWithInvestorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      investorUserId: // value for 'investorUserId'
 *   },
 * });
 */
export function useUnlinkContactWithInvestorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlinkContactWithInvestorMutation,
    UnlinkContactWithInvestorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnlinkContactWithInvestorMutation,
    UnlinkContactWithInvestorMutationVariables
  >(UnlinkContactWithInvestorDocument, options);
}
export type UnlinkContactWithInvestorMutationHookResult = ReturnType<
  typeof useUnlinkContactWithInvestorMutation
>;
export type UnlinkContactWithInvestorMutationResult =
  Apollo.MutationResult<UnlinkContactWithInvestorMutation>;
export type UnlinkContactWithInvestorMutationOptions =
  Apollo.BaseMutationOptions<
    UnlinkContactWithInvestorMutation,
    UnlinkContactWithInvestorMutationVariables
  >;
export const UnlinkContactWithShareholdingDocument = gql`
  mutation UnlinkContactWithShareholding($id: ID!, $shareholdingId: ID!) {
    unlinkContactWithShareholding(id: $id, shareholdingId: $shareholdingId) {
      id
    }
  }
`;
export type UnlinkContactWithShareholdingMutationFn = Apollo.MutationFunction<
  UnlinkContactWithShareholdingMutation,
  UnlinkContactWithShareholdingMutationVariables
>;

/**
 * __useUnlinkContactWithShareholdingMutation__
 *
 * To run a mutation, you first call `useUnlinkContactWithShareholdingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkContactWithShareholdingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkContactWithShareholdingMutation, { data, loading, error }] = useUnlinkContactWithShareholdingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      shareholdingId: // value for 'shareholdingId'
 *   },
 * });
 */
export function useUnlinkContactWithShareholdingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlinkContactWithShareholdingMutation,
    UnlinkContactWithShareholdingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnlinkContactWithShareholdingMutation,
    UnlinkContactWithShareholdingMutationVariables
  >(UnlinkContactWithShareholdingDocument, options);
}
export type UnlinkContactWithShareholdingMutationHookResult = ReturnType<
  typeof useUnlinkContactWithShareholdingMutation
>;
export type UnlinkContactWithShareholdingMutationResult =
  Apollo.MutationResult<UnlinkContactWithShareholdingMutation>;
export type UnlinkContactWithShareholdingMutationOptions =
  Apollo.BaseMutationOptions<
    UnlinkContactWithShareholdingMutation,
    UnlinkContactWithShareholdingMutationVariables
  >;
export const UpdateAudiencesContactListDocument = gql`
  mutation UpdateAudiencesContactList($id: ID!, $input: DynamicListInput!) {
    updateDynamicList(id: $id, dynamicList: $input) {
      id
      lastUpdatedAt
      name
      lastUpdatedByProfileUser {
        user {
          id
          email
          firstName
          lastName
        }
      }
      lastUsedOnEmail {
        id
        campaignName
      }
      estimatedContactsSize
      filters {
        key
        value
      }
      description
    }
  }
`;
export type UpdateAudiencesContactListMutationFn = Apollo.MutationFunction<
  UpdateAudiencesContactListMutation,
  UpdateAudiencesContactListMutationVariables
>;

/**
 * __useUpdateAudiencesContactListMutation__
 *
 * To run a mutation, you first call `useUpdateAudiencesContactListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAudiencesContactListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAudiencesContactListMutation, { data, loading, error }] = useUpdateAudiencesContactListMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAudiencesContactListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAudiencesContactListMutation,
    UpdateAudiencesContactListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAudiencesContactListMutation,
    UpdateAudiencesContactListMutationVariables
  >(UpdateAudiencesContactListDocument, options);
}
export type UpdateAudiencesContactListMutationHookResult = ReturnType<
  typeof useUpdateAudiencesContactListMutation
>;
export type UpdateAudiencesContactListMutationResult =
  Apollo.MutationResult<UpdateAudiencesContactListMutation>;
export type UpdateAudiencesContactListMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAudiencesContactListMutation,
    UpdateAudiencesContactListMutationVariables
  >;
export const UpdateBlockDocument = gql`
  mutation UpdateBlock($id: ID!, $content: Map!) {
    updateBlock(id: $id, content: $content) {
      id
      content
    }
  }
`;
export type UpdateBlockMutationFn = Apollo.MutationFunction<
  UpdateBlockMutation,
  UpdateBlockMutationVariables
>;

/**
 * __useUpdateBlockMutation__
 *
 * To run a mutation, you first call `useUpdateBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlockMutation, { data, loading, error }] = useUpdateBlockMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpdateBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBlockMutation,
    UpdateBlockMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBlockMutation, UpdateBlockMutationVariables>(
    UpdateBlockDocument,
    options
  );
}
export type UpdateBlockMutationHookResult = ReturnType<
  typeof useUpdateBlockMutation
>;
export type UpdateBlockMutationResult =
  Apollo.MutationResult<UpdateBlockMutation>;
export type UpdateBlockMutationOptions = Apollo.BaseMutationOptions<
  UpdateBlockMutation,
  UpdateBlockMutationVariables
>;
export const UpdateCompanyInfomationDocument = gql`
  mutation updateCompanyInfomation($profile: CompanyProfileUpdateInput!) {
    updateCompanyInfomation(profile: $profile) {
      id
      defaultPublicInteractionsEnabled
      aiSummariesEnabled
      email
      hidePrivateQuestionsFromCount
      investorCentreEnabled
      logoUrl
      name
      mobileNumber
      ticker {
        id
        listingKey
      }
    }
  }
`;
export type UpdateCompanyInfomationMutationFn = Apollo.MutationFunction<
  UpdateCompanyInfomationMutation,
  UpdateCompanyInfomationMutationVariables
>;

/**
 * __useUpdateCompanyInfomationMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyInfomationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyInfomationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyInfomationMutation, { data, loading, error }] = useUpdateCompanyInfomationMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useUpdateCompanyInfomationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyInfomationMutation,
    UpdateCompanyInfomationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCompanyInfomationMutation,
    UpdateCompanyInfomationMutationVariables
  >(UpdateCompanyInfomationDocument, options);
}
export type UpdateCompanyInfomationMutationHookResult = ReturnType<
  typeof useUpdateCompanyInfomationMutation
>;
export type UpdateCompanyInfomationMutationResult =
  Apollo.MutationResult<UpdateCompanyInfomationMutation>;
export type UpdateCompanyInfomationMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyInfomationMutation,
  UpdateCompanyInfomationMutationVariables
>;
export const UpdateCompanyInvestorHubDocument = gql`
  mutation updateCompanyInvestorHub($profile: CompanyProfileUpdateInput!) {
    updateCompanyInvestorHub(profile: $profile) {
      id
      email
      investorCentreEnabled
      name
      mobileNumber
      investorHub {
        id
        email
        facebook
        instagram
        linkedin
        phone
        registry
        twitter
        website
        colourPrimary
        colourPrimaryText
        colourAccent
        colourAccentText
      }
    }
  }
`;
export type UpdateCompanyInvestorHubMutationFn = Apollo.MutationFunction<
  UpdateCompanyInvestorHubMutation,
  UpdateCompanyInvestorHubMutationVariables
>;

/**
 * __useUpdateCompanyInvestorHubMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyInvestorHubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyInvestorHubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyInvestorHubMutation, { data, loading, error }] = useUpdateCompanyInvestorHubMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useUpdateCompanyInvestorHubMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyInvestorHubMutation,
    UpdateCompanyInvestorHubMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCompanyInvestorHubMutation,
    UpdateCompanyInvestorHubMutationVariables
  >(UpdateCompanyInvestorHubDocument, options);
}
export type UpdateCompanyInvestorHubMutationHookResult = ReturnType<
  typeof useUpdateCompanyInvestorHubMutation
>;
export type UpdateCompanyInvestorHubMutationResult =
  Apollo.MutationResult<UpdateCompanyInvestorHubMutation>;
export type UpdateCompanyInvestorHubMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCompanyInvestorHubMutation,
    UpdateCompanyInvestorHubMutationVariables
  >;
export const UpdateCompanyUserAndCompanyProfileUserInforamtionDocument = gql`
  mutation UpdateCompanyUserAndCompanyProfileUserInforamtion(
    $firstName: String!
    $jobTitle: String!
    $lastName: String!
    $mobileNumber: String
  ) {
    updateCompanyUserAndCompanyProfileUserInforamtion(
      firstName: $firstName
      jobTitle: $jobTitle
      lastName: $lastName
      mobileNumber: $mobileNumber
    ) {
      id
      jobTitle
      user {
        id
        firstName
        lastName
        mobileNumber
      }
    }
  }
`;
export type UpdateCompanyUserAndCompanyProfileUserInforamtionMutationFn =
  Apollo.MutationFunction<
    UpdateCompanyUserAndCompanyProfileUserInforamtionMutation,
    UpdateCompanyUserAndCompanyProfileUserInforamtionMutationVariables
  >;

/**
 * __useUpdateCompanyUserAndCompanyProfileUserInforamtionMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyUserAndCompanyProfileUserInforamtionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyUserAndCompanyProfileUserInforamtionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyUserAndCompanyProfileUserInforamtionMutation, { data, loading, error }] = useUpdateCompanyUserAndCompanyProfileUserInforamtionMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      jobTitle: // value for 'jobTitle'
 *      lastName: // value for 'lastName'
 *      mobileNumber: // value for 'mobileNumber'
 *   },
 * });
 */
export function useUpdateCompanyUserAndCompanyProfileUserInforamtionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyUserAndCompanyProfileUserInforamtionMutation,
    UpdateCompanyUserAndCompanyProfileUserInforamtionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCompanyUserAndCompanyProfileUserInforamtionMutation,
    UpdateCompanyUserAndCompanyProfileUserInforamtionMutationVariables
  >(UpdateCompanyUserAndCompanyProfileUserInforamtionDocument, options);
}
export type UpdateCompanyUserAndCompanyProfileUserInforamtionMutationHookResult =
  ReturnType<
    typeof useUpdateCompanyUserAndCompanyProfileUserInforamtionMutation
  >;
export type UpdateCompanyUserAndCompanyProfileUserInforamtionMutationResult =
  Apollo.MutationResult<UpdateCompanyUserAndCompanyProfileUserInforamtionMutation>;
export type UpdateCompanyUserAndCompanyProfileUserInforamtionMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCompanyUserAndCompanyProfileUserInforamtionMutation,
    UpdateCompanyUserAndCompanyProfileUserInforamtionMutationVariables
  >;
export const UpdateContactLeadStatusDocument = gql`
  mutation UpdateContactLeadStatus(
    $id: ID!
    $leadStatus: LeadStatusUpdateOption!
  ) {
    updateContactLeadStatus(id: $id, leadStatus: $leadStatus) {
      id
      shareholderStatus
    }
  }
`;
export type UpdateContactLeadStatusMutationFn = Apollo.MutationFunction<
  UpdateContactLeadStatusMutation,
  UpdateContactLeadStatusMutationVariables
>;

/**
 * __useUpdateContactLeadStatusMutation__
 *
 * To run a mutation, you first call `useUpdateContactLeadStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactLeadStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactLeadStatusMutation, { data, loading, error }] = useUpdateContactLeadStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      leadStatus: // value for 'leadStatus'
 *   },
 * });
 */
export function useUpdateContactLeadStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContactLeadStatusMutation,
    UpdateContactLeadStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContactLeadStatusMutation,
    UpdateContactLeadStatusMutationVariables
  >(UpdateContactLeadStatusDocument, options);
}
export type UpdateContactLeadStatusMutationHookResult = ReturnType<
  typeof useUpdateContactLeadStatusMutation
>;
export type UpdateContactLeadStatusMutationResult =
  Apollo.MutationResult<UpdateContactLeadStatusMutation>;
export type UpdateContactLeadStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactLeadStatusMutation,
  UpdateContactLeadStatusMutationVariables
>;
export const UpdateEmailAndEmailRecipientsDocument = gql`
  mutation UpdateEmailAndEmailRecipients($emailId: ID!, $email: EmailInput!) {
    updateEmailAndEmailRecipients(emailId: $emailId, email: $email) {
      id
    }
  }
`;
export type UpdateEmailAndEmailRecipientsMutationFn = Apollo.MutationFunction<
  UpdateEmailAndEmailRecipientsMutation,
  UpdateEmailAndEmailRecipientsMutationVariables
>;

/**
 * __useUpdateEmailAndEmailRecipientsMutation__
 *
 * To run a mutation, you first call `useUpdateEmailAndEmailRecipientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailAndEmailRecipientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailAndEmailRecipientsMutation, { data, loading, error }] = useUpdateEmailAndEmailRecipientsMutation({
 *   variables: {
 *      emailId: // value for 'emailId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateEmailAndEmailRecipientsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEmailAndEmailRecipientsMutation,
    UpdateEmailAndEmailRecipientsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEmailAndEmailRecipientsMutation,
    UpdateEmailAndEmailRecipientsMutationVariables
  >(UpdateEmailAndEmailRecipientsDocument, options);
}
export type UpdateEmailAndEmailRecipientsMutationHookResult = ReturnType<
  typeof useUpdateEmailAndEmailRecipientsMutation
>;
export type UpdateEmailAndEmailRecipientsMutationResult =
  Apollo.MutationResult<UpdateEmailAndEmailRecipientsMutation>;
export type UpdateEmailAndEmailRecipientsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateEmailAndEmailRecipientsMutation,
    UpdateEmailAndEmailRecipientsMutationVariables
  >;
export const UpdateEmailScheduleDocument = gql`
  mutation UpdateEmailSchedule($emailId: ID!, $email: EmailInput!) {
    updateEmailSchedule(emailId: $emailId, email: $email) {
      id
    }
  }
`;
export type UpdateEmailScheduleMutationFn = Apollo.MutationFunction<
  UpdateEmailScheduleMutation,
  UpdateEmailScheduleMutationVariables
>;

/**
 * __useUpdateEmailScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateEmailScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailScheduleMutation, { data, loading, error }] = useUpdateEmailScheduleMutation({
 *   variables: {
 *      emailId: // value for 'emailId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateEmailScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEmailScheduleMutation,
    UpdateEmailScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEmailScheduleMutation,
    UpdateEmailScheduleMutationVariables
  >(UpdateEmailScheduleDocument, options);
}
export type UpdateEmailScheduleMutationHookResult = ReturnType<
  typeof useUpdateEmailScheduleMutation
>;
export type UpdateEmailScheduleMutationResult =
  Apollo.MutationResult<UpdateEmailScheduleMutation>;
export type UpdateEmailScheduleMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmailScheduleMutation,
  UpdateEmailScheduleMutationVariables
>;
export const UpdateMediaAnnouncementDocument = gql`
  mutation UpdateMediaAnnouncement(
    $id: ID!
    $input: UpdateMediaAnnouncementInput!
  ) {
    updateMediaAnnouncement(id: $id, input: $input) {
      id
      featuredOnHub
      socialVideoUrl
      summary
      summaryAi
      videoUrl
      germanTranslatedVideoUrl
      germanTranslatedUrl
      germanTranslatedHeader
      germanTranslatedSummary
    }
  }
`;
export type UpdateMediaAnnouncementMutationFn = Apollo.MutationFunction<
  UpdateMediaAnnouncementMutation,
  UpdateMediaAnnouncementMutationVariables
>;

/**
 * __useUpdateMediaAnnouncementMutation__
 *
 * To run a mutation, you first call `useUpdateMediaAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaAnnouncementMutation, { data, loading, error }] = useUpdateMediaAnnouncementMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMediaAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMediaAnnouncementMutation,
    UpdateMediaAnnouncementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMediaAnnouncementMutation,
    UpdateMediaAnnouncementMutationVariables
  >(UpdateMediaAnnouncementDocument, options);
}
export type UpdateMediaAnnouncementMutationHookResult = ReturnType<
  typeof useUpdateMediaAnnouncementMutation
>;
export type UpdateMediaAnnouncementMutationResult =
  Apollo.MutationResult<UpdateMediaAnnouncementMutation>;
export type UpdateMediaAnnouncementMutationOptions = Apollo.BaseMutationOptions<
  UpdateMediaAnnouncementMutation,
  UpdateMediaAnnouncementMutationVariables
>;
export const UpdateMediaCommentDoneDocument = gql`
  mutation UpdateMediaCommentDone($mediaCommentId: ID!, $done: Boolean!) {
    updateMediaCommentDone(mediaCommentId: $mediaCommentId, done: $done) {
      id
      done
    }
  }
`;
export type UpdateMediaCommentDoneMutationFn = Apollo.MutationFunction<
  UpdateMediaCommentDoneMutation,
  UpdateMediaCommentDoneMutationVariables
>;

/**
 * __useUpdateMediaCommentDoneMutation__
 *
 * To run a mutation, you first call `useUpdateMediaCommentDoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaCommentDoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaCommentDoneMutation, { data, loading, error }] = useUpdateMediaCommentDoneMutation({
 *   variables: {
 *      mediaCommentId: // value for 'mediaCommentId'
 *      done: // value for 'done'
 *   },
 * });
 */
export function useUpdateMediaCommentDoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMediaCommentDoneMutation,
    UpdateMediaCommentDoneMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMediaCommentDoneMutation,
    UpdateMediaCommentDoneMutationVariables
  >(UpdateMediaCommentDoneDocument, options);
}
export type UpdateMediaCommentDoneMutationHookResult = ReturnType<
  typeof useUpdateMediaCommentDoneMutation
>;
export type UpdateMediaCommentDoneMutationResult =
  Apollo.MutationResult<UpdateMediaCommentDoneMutation>;
export type UpdateMediaCommentDoneMutationOptions = Apollo.BaseMutationOptions<
  UpdateMediaCommentDoneMutation,
  UpdateMediaCommentDoneMutationVariables
>;
export const UpdateMediaCommentReplyDocument = gql`
  mutation UpdateMediaCommentReply(
    $mediaCommentId: ID!
    $content: String!
    $useCompanyAsUsername: Boolean
  ) {
    updateMediaCommentReply(
      mediaCommentId: $mediaCommentId
      content: $content
      useCompanyAsUsername: $useCompanyAsUsername
    ) {
      id
      content
      useCompanyAsUsername
    }
  }
`;
export type UpdateMediaCommentReplyMutationFn = Apollo.MutationFunction<
  UpdateMediaCommentReplyMutation,
  UpdateMediaCommentReplyMutationVariables
>;

/**
 * __useUpdateMediaCommentReplyMutation__
 *
 * To run a mutation, you first call `useUpdateMediaCommentReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaCommentReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaCommentReplyMutation, { data, loading, error }] = useUpdateMediaCommentReplyMutation({
 *   variables: {
 *      mediaCommentId: // value for 'mediaCommentId'
 *      content: // value for 'content'
 *      useCompanyAsUsername: // value for 'useCompanyAsUsername'
 *   },
 * });
 */
export function useUpdateMediaCommentReplyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMediaCommentReplyMutation,
    UpdateMediaCommentReplyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMediaCommentReplyMutation,
    UpdateMediaCommentReplyMutationVariables
  >(UpdateMediaCommentReplyDocument, options);
}
export type UpdateMediaCommentReplyMutationHookResult = ReturnType<
  typeof useUpdateMediaCommentReplyMutation
>;
export type UpdateMediaCommentReplyMutationResult =
  Apollo.MutationResult<UpdateMediaCommentReplyMutation>;
export type UpdateMediaCommentReplyMutationOptions = Apollo.BaseMutationOptions<
  UpdateMediaCommentReplyMutation,
  UpdateMediaCommentReplyMutationVariables
>;
export const UpdateMediaUpdatePostedAtDocument = gql`
  mutation UpdateMediaUpdatePostedAt($id: ID!, $postedAt: IsoNaiveDatetime!) {
    updateMediaUpdatePostedAt(id: $id, postedAt: $postedAt) {
      id
      attachments {
        id
        thumbnailUrl
        title
        type
        url
      }
      content {
        id
        content
      }
      lastUpdatedBy {
        id
        firstName
        lastName
      }
      slug
      title
      includedTypes
      isPinned
    }
  }
`;
export type UpdateMediaUpdatePostedAtMutationFn = Apollo.MutationFunction<
  UpdateMediaUpdatePostedAtMutation,
  UpdateMediaUpdatePostedAtMutationVariables
>;

/**
 * __useUpdateMediaUpdatePostedAtMutation__
 *
 * To run a mutation, you first call `useUpdateMediaUpdatePostedAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaUpdatePostedAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaUpdatePostedAtMutation, { data, loading, error }] = useUpdateMediaUpdatePostedAtMutation({
 *   variables: {
 *      id: // value for 'id'
 *      postedAt: // value for 'postedAt'
 *   },
 * });
 */
export function useUpdateMediaUpdatePostedAtMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMediaUpdatePostedAtMutation,
    UpdateMediaUpdatePostedAtMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMediaUpdatePostedAtMutation,
    UpdateMediaUpdatePostedAtMutationVariables
  >(UpdateMediaUpdatePostedAtDocument, options);
}
export type UpdateMediaUpdatePostedAtMutationHookResult = ReturnType<
  typeof useUpdateMediaUpdatePostedAtMutation
>;
export type UpdateMediaUpdatePostedAtMutationResult =
  Apollo.MutationResult<UpdateMediaUpdatePostedAtMutation>;
export type UpdateMediaUpdatePostedAtMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMediaUpdatePostedAtMutation,
    UpdateMediaUpdatePostedAtMutationVariables
  >;
export const UpdateMediaUpdateTitleDocument = gql`
  mutation UpdateMediaUpdateTitle($id: ID!, $title: String!) {
    updateMediaUpdateTitle(id: $id, title: $title) {
      id
      attachments {
        id
        thumbnailUrl
        title
        type
        url
      }
      content {
        id
        content
      }
      lastUpdatedBy {
        id
        firstName
        lastName
      }
      slug
      title
      includedTypes
      isPinned
    }
  }
`;
export type UpdateMediaUpdateTitleMutationFn = Apollo.MutationFunction<
  UpdateMediaUpdateTitleMutation,
  UpdateMediaUpdateTitleMutationVariables
>;

/**
 * __useUpdateMediaUpdateTitleMutation__
 *
 * To run a mutation, you first call `useUpdateMediaUpdateTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaUpdateTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaUpdateTitleMutation, { data, loading, error }] = useUpdateMediaUpdateTitleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateMediaUpdateTitleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMediaUpdateTitleMutation,
    UpdateMediaUpdateTitleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMediaUpdateTitleMutation,
    UpdateMediaUpdateTitleMutationVariables
  >(UpdateMediaUpdateTitleDocument, options);
}
export type UpdateMediaUpdateTitleMutationHookResult = ReturnType<
  typeof useUpdateMediaUpdateTitleMutation
>;
export type UpdateMediaUpdateTitleMutationResult =
  Apollo.MutationResult<UpdateMediaUpdateTitleMutation>;
export type UpdateMediaUpdateTitleMutationOptions = Apollo.BaseMutationOptions<
  UpdateMediaUpdateTitleMutation,
  UpdateMediaUpdateTitleMutationVariables
>;
export const UpdateNavMenuItemDocument = gql`
  mutation UpdateNavMenuItem(
    $id: ID!
    $label: String
    $url: String
    $openInNewTab: Boolean
    $pageId: ID
    $parentId: ID
  ) {
    updateNavMenuItem(
      id: $id
      label: $label
      url: $url
      openInNewTab: $openInNewTab
      pageId: $pageId
      parentId: $parentId
    ) {
      id
      label
      url
      openInNewTab
      page {
        id
        slug
        title
      }
      parent {
        id
        label
      }
    }
  }
`;
export type UpdateNavMenuItemMutationFn = Apollo.MutationFunction<
  UpdateNavMenuItemMutation,
  UpdateNavMenuItemMutationVariables
>;

/**
 * __useUpdateNavMenuItemMutation__
 *
 * To run a mutation, you first call `useUpdateNavMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNavMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNavMenuItemMutation, { data, loading, error }] = useUpdateNavMenuItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      label: // value for 'label'
 *      url: // value for 'url'
 *      openInNewTab: // value for 'openInNewTab'
 *      pageId: // value for 'pageId'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useUpdateNavMenuItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNavMenuItemMutation,
    UpdateNavMenuItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNavMenuItemMutation,
    UpdateNavMenuItemMutationVariables
  >(UpdateNavMenuItemDocument, options);
}
export type UpdateNavMenuItemMutationHookResult = ReturnType<
  typeof useUpdateNavMenuItemMutation
>;
export type UpdateNavMenuItemMutationResult =
  Apollo.MutationResult<UpdateNavMenuItemMutation>;
export type UpdateNavMenuItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateNavMenuItemMutation,
  UpdateNavMenuItemMutationVariables
>;
export const UpdatePageDocument = gql`
  mutation UpdatePage(
    $id: ID!
    $title: String!
    $slug: String!
    $metaDescription: String
    $socialImageCloudinaryPublicId: String
  ) {
    updatePage(
      id: $id
      title: $title
      slug: $slug
      metaDescription: $metaDescription
      socialImageCloudinaryPublicId: $socialImageCloudinaryPublicId
    ) {
      id
      title
      slug
      metaDescription
      socialImageCloudinaryPublicId
    }
  }
`;
export type UpdatePageMutationFn = Apollo.MutationFunction<
  UpdatePageMutation,
  UpdatePageMutationVariables
>;

/**
 * __useUpdatePageMutation__
 *
 * To run a mutation, you first call `useUpdatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageMutation, { data, loading, error }] = useUpdatePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      slug: // value for 'slug'
 *      metaDescription: // value for 'metaDescription'
 *      socialImageCloudinaryPublicId: // value for 'socialImageCloudinaryPublicId'
 *   },
 * });
 */
export function useUpdatePageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePageMutation,
    UpdatePageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePageMutation, UpdatePageMutationVariables>(
    UpdatePageDocument,
    options
  );
}
export type UpdatePageMutationHookResult = ReturnType<
  typeof useUpdatePageMutation
>;
export type UpdatePageMutationResult =
  Apollo.MutationResult<UpdatePageMutation>;
export type UpdatePageMutationOptions = Apollo.BaseMutationOptions<
  UpdatePageMutation,
  UpdatePageMutationVariables
>;
export const UpdatePinnedMediaUpdateDocument = gql`
  mutation UpdatePinnedMediaUpdate($id: ID!, $value: Boolean!) {
    updatePinnedMediaUpdate(id: $id, value: $value) {
      id
      attachments {
        id
        thumbnailUrl
        title
        type
        url
      }
      content {
        id
        content
      }
      lastUpdatedBy {
        id
        firstName
        lastName
      }
      slug
      title
      includedTypes
      isPinned
    }
  }
`;
export type UpdatePinnedMediaUpdateMutationFn = Apollo.MutationFunction<
  UpdatePinnedMediaUpdateMutation,
  UpdatePinnedMediaUpdateMutationVariables
>;

/**
 * __useUpdatePinnedMediaUpdateMutation__
 *
 * To run a mutation, you first call `useUpdatePinnedMediaUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePinnedMediaUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePinnedMediaUpdateMutation, { data, loading, error }] = useUpdatePinnedMediaUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdatePinnedMediaUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePinnedMediaUpdateMutation,
    UpdatePinnedMediaUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePinnedMediaUpdateMutation,
    UpdatePinnedMediaUpdateMutationVariables
  >(UpdatePinnedMediaUpdateDocument, options);
}
export type UpdatePinnedMediaUpdateMutationHookResult = ReturnType<
  typeof useUpdatePinnedMediaUpdateMutation
>;
export type UpdatePinnedMediaUpdateMutationResult =
  Apollo.MutationResult<UpdatePinnedMediaUpdateMutation>;
export type UpdatePinnedMediaUpdateMutationOptions = Apollo.BaseMutationOptions<
  UpdatePinnedMediaUpdateMutation,
  UpdatePinnedMediaUpdateMutationVariables
>;
export const UpdatePreparedAnnouncementDocument = gql`
  mutation UpdatePreparedAnnouncement(
    $id: ID!
    $preparedAnnouncement: PreparedAnnouncementInput
  ) {
    updatePreparedAnnouncement(
      id: $id
      preparedAnnouncement: $preparedAnnouncement
    ) {
      id
      title
      socialVideoUrl
      videoUrl
      summary
      isDraft
      commentContent
      commentUseCompanyAsUsername
      mediaId
    }
  }
`;
export type UpdatePreparedAnnouncementMutationFn = Apollo.MutationFunction<
  UpdatePreparedAnnouncementMutation,
  UpdatePreparedAnnouncementMutationVariables
>;

/**
 * __useUpdatePreparedAnnouncementMutation__
 *
 * To run a mutation, you first call `useUpdatePreparedAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreparedAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreparedAnnouncementMutation, { data, loading, error }] = useUpdatePreparedAnnouncementMutation({
 *   variables: {
 *      id: // value for 'id'
 *      preparedAnnouncement: // value for 'preparedAnnouncement'
 *   },
 * });
 */
export function useUpdatePreparedAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePreparedAnnouncementMutation,
    UpdatePreparedAnnouncementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePreparedAnnouncementMutation,
    UpdatePreparedAnnouncementMutationVariables
  >(UpdatePreparedAnnouncementDocument, options);
}
export type UpdatePreparedAnnouncementMutationHookResult = ReturnType<
  typeof useUpdatePreparedAnnouncementMutation
>;
export type UpdatePreparedAnnouncementMutationResult =
  Apollo.MutationResult<UpdatePreparedAnnouncementMutation>;
export type UpdatePreparedAnnouncementMutationOptions =
  Apollo.BaseMutationOptions<
    UpdatePreparedAnnouncementMutation,
    UpdatePreparedAnnouncementMutationVariables
  >;
export const UpdateShareholderOfferStatusDocument = gql`
  mutation UpdateShareholderOfferStatus(
    $isLive: Boolean!
    $scheduledAt: IsoNaiveDatetime
    $shareholderOfferId: ID!
  ) {
    updateShareholderOfferStatus(
      isLive: $isLive
      scheduledAt: $scheduledAt
      shareholderOfferId: $shareholderOfferId
    ) {
      id
      title
      type
      isLive
      scheduledAt
      companyProfile {
        id
        registry
      }
      lastEditedByUser {
        email
        firstName
        lastName
      }
      insertedAt
      updatedAt
    }
  }
`;
export type UpdateShareholderOfferStatusMutationFn = Apollo.MutationFunction<
  UpdateShareholderOfferStatusMutation,
  UpdateShareholderOfferStatusMutationVariables
>;

/**
 * __useUpdateShareholderOfferStatusMutation__
 *
 * To run a mutation, you first call `useUpdateShareholderOfferStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShareholderOfferStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShareholderOfferStatusMutation, { data, loading, error }] = useUpdateShareholderOfferStatusMutation({
 *   variables: {
 *      isLive: // value for 'isLive'
 *      scheduledAt: // value for 'scheduledAt'
 *      shareholderOfferId: // value for 'shareholderOfferId'
 *   },
 * });
 */
export function useUpdateShareholderOfferStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShareholderOfferStatusMutation,
    UpdateShareholderOfferStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateShareholderOfferStatusMutation,
    UpdateShareholderOfferStatusMutationVariables
  >(UpdateShareholderOfferStatusDocument, options);
}
export type UpdateShareholderOfferStatusMutationHookResult = ReturnType<
  typeof useUpdateShareholderOfferStatusMutation
>;
export type UpdateShareholderOfferStatusMutationResult =
  Apollo.MutationResult<UpdateShareholderOfferStatusMutation>;
export type UpdateShareholderOfferStatusMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateShareholderOfferStatusMutation,
    UpdateShareholderOfferStatusMutationVariables
  >;
export const UpdateShareholderOfferTitleDocument = gql`
  mutation UpdateShareholderOfferTitle(
    $title: String!
    $shareholderOfferId: ID!
  ) {
    updateShareholderOfferTitle(
      title: $title
      shareholderOfferId: $shareholderOfferId
    ) {
      id
      title
      type
      isLive
      scheduledAt
      companyProfile {
        id
        registry
      }
      lastEditedByUser {
        email
        firstName
        lastName
      }
      insertedAt
      updatedAt
    }
  }
`;
export type UpdateShareholderOfferTitleMutationFn = Apollo.MutationFunction<
  UpdateShareholderOfferTitleMutation,
  UpdateShareholderOfferTitleMutationVariables
>;

/**
 * __useUpdateShareholderOfferTitleMutation__
 *
 * To run a mutation, you first call `useUpdateShareholderOfferTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShareholderOfferTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShareholderOfferTitleMutation, { data, loading, error }] = useUpdateShareholderOfferTitleMutation({
 *   variables: {
 *      title: // value for 'title'
 *      shareholderOfferId: // value for 'shareholderOfferId'
 *   },
 * });
 */
export function useUpdateShareholderOfferTitleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShareholderOfferTitleMutation,
    UpdateShareholderOfferTitleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateShareholderOfferTitleMutation,
    UpdateShareholderOfferTitleMutationVariables
  >(UpdateShareholderOfferTitleDocument, options);
}
export type UpdateShareholderOfferTitleMutationHookResult = ReturnType<
  typeof useUpdateShareholderOfferTitleMutation
>;
export type UpdateShareholderOfferTitleMutationResult =
  Apollo.MutationResult<UpdateShareholderOfferTitleMutation>;
export type UpdateShareholderOfferTitleMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateShareholderOfferTitleMutation,
    UpdateShareholderOfferTitleMutationVariables
  >;
export const UpdateUtmLinkDocument = gql`
  mutation UpdateUtmLink($id: ID!, $updatedUtmAttrs: UtmLinkInput!) {
    updateUtmLink(id: $id, updatedUtmAttrs: $updatedUtmAttrs) {
      id
      hash
      destinationUrl
      utmCampaign
      utmMedium
      utmSource
      utmUrl
      insertedAt
      updatedAt
    }
  }
`;
export type UpdateUtmLinkMutationFn = Apollo.MutationFunction<
  UpdateUtmLinkMutation,
  UpdateUtmLinkMutationVariables
>;

/**
 * __useUpdateUtmLinkMutation__
 *
 * To run a mutation, you first call `useUpdateUtmLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUtmLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUtmLinkMutation, { data, loading, error }] = useUpdateUtmLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updatedUtmAttrs: // value for 'updatedUtmAttrs'
 *   },
 * });
 */
export function useUpdateUtmLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUtmLinkMutation,
    UpdateUtmLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUtmLinkMutation,
    UpdateUtmLinkMutationVariables
  >(UpdateUtmLinkDocument, options);
}
export type UpdateUtmLinkMutationHookResult = ReturnType<
  typeof useUpdateUtmLinkMutation
>;
export type UpdateUtmLinkMutationResult =
  Apollo.MutationResult<UpdateUtmLinkMutation>;
export type UpdateUtmLinkMutationOptions = Apollo.BaseMutationOptions<
  UpdateUtmLinkMutation,
  UpdateUtmLinkMutationVariables
>;
export const UpdateSocialConnectionDocument = gql`
  mutation UpdateSocialConnection($socialConnection: SocialConnectionInput!) {
    updateSocialConnection(socialConnection: $socialConnection) {
      id
      linkedinConnected
      linkedinSetupCompleted
      linkedinOrganisationName
      twitterSetupCompleted
      twitterUsername
    }
  }
`;
export type UpdateSocialConnectionMutationFn = Apollo.MutationFunction<
  UpdateSocialConnectionMutation,
  UpdateSocialConnectionMutationVariables
>;

/**
 * __useUpdateSocialConnectionMutation__
 *
 * To run a mutation, you first call `useUpdateSocialConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSocialConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSocialConnectionMutation, { data, loading, error }] = useUpdateSocialConnectionMutation({
 *   variables: {
 *      socialConnection: // value for 'socialConnection'
 *   },
 * });
 */
export function useUpdateSocialConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSocialConnectionMutation,
    UpdateSocialConnectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSocialConnectionMutation,
    UpdateSocialConnectionMutationVariables
  >(UpdateSocialConnectionDocument, options);
}
export type UpdateSocialConnectionMutationHookResult = ReturnType<
  typeof useUpdateSocialConnectionMutation
>;
export type UpdateSocialConnectionMutationResult =
  Apollo.MutationResult<UpdateSocialConnectionMutation>;
export type UpdateSocialConnectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateSocialConnectionMutation,
  UpdateSocialConnectionMutationVariables
>;
export const UpsertContactUnsubscribesDocument = gql`
  mutation UpsertContactUnsubscribes(
    $contactId: ID!
    $globalUnsubscribe: Boolean!
    $scopes: [ContactUnsubscribeScopeType!]!
  ) {
    upsertContactUnsubscribes(
      contactId: $contactId
      globalUnsubscribe: $globalUnsubscribe
      scopes: $scopes
    ) {
      id
      commsUnsubscribes {
        id
        scope
      }
      globalUnsubscribe {
        id
      }
    }
  }
`;
export type UpsertContactUnsubscribesMutationFn = Apollo.MutationFunction<
  UpsertContactUnsubscribesMutation,
  UpsertContactUnsubscribesMutationVariables
>;

/**
 * __useUpsertContactUnsubscribesMutation__
 *
 * To run a mutation, you first call `useUpsertContactUnsubscribesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertContactUnsubscribesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertContactUnsubscribesMutation, { data, loading, error }] = useUpsertContactUnsubscribesMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      globalUnsubscribe: // value for 'globalUnsubscribe'
 *      scopes: // value for 'scopes'
 *   },
 * });
 */
export function useUpsertContactUnsubscribesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertContactUnsubscribesMutation,
    UpsertContactUnsubscribesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertContactUnsubscribesMutation,
    UpsertContactUnsubscribesMutationVariables
  >(UpsertContactUnsubscribesDocument, options);
}
export type UpsertContactUnsubscribesMutationHookResult = ReturnType<
  typeof useUpsertContactUnsubscribesMutation
>;
export type UpsertContactUnsubscribesMutationResult =
  Apollo.MutationResult<UpsertContactUnsubscribesMutation>;
export type UpsertContactUnsubscribesMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertContactUnsubscribesMutation,
    UpsertContactUnsubscribesMutationVariables
  >;
export const UpsertCorporatePageDocument = gql`
  mutation UpsertCorporatePage($corporatePage: CorporatePageInput!) {
    upsertCorporatePage(corporatePage: $corporatePage) {
      id
      title
      type
      isLive
      insertedAt
      updatedAt
      lastEditedByUser {
        id
        firstName
        lastName
        email
      }
      sections {
        id
        heading
        description
        attachments {
          id
          orderId
          heading
          subheading
          description
          fileUrl
        }
      }
      slug
    }
  }
`;
export type UpsertCorporatePageMutationFn = Apollo.MutationFunction<
  UpsertCorporatePageMutation,
  UpsertCorporatePageMutationVariables
>;

/**
 * __useUpsertCorporatePageMutation__
 *
 * To run a mutation, you first call `useUpsertCorporatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCorporatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCorporatePageMutation, { data, loading, error }] = useUpsertCorporatePageMutation({
 *   variables: {
 *      corporatePage: // value for 'corporatePage'
 *   },
 * });
 */
export function useUpsertCorporatePageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertCorporatePageMutation,
    UpsertCorporatePageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertCorporatePageMutation,
    UpsertCorporatePageMutationVariables
  >(UpsertCorporatePageDocument, options);
}
export type UpsertCorporatePageMutationHookResult = ReturnType<
  typeof useUpsertCorporatePageMutation
>;
export type UpsertCorporatePageMutationResult =
  Apollo.MutationResult<UpsertCorporatePageMutation>;
export type UpsertCorporatePageMutationOptions = Apollo.BaseMutationOptions<
  UpsertCorporatePageMutation,
  UpsertCorporatePageMutationVariables
>;
export const UpsertCustomContactsDocument = gql`
  mutation UpsertCustomContacts(
    $customContacts: [ContactInput!]!
    $audienceTags: [String!]!
    $clientAnswerListSource: String
    $clientAnswerLastUsage: String
  ) {
    upsertCustomContacts(
      customContacts: $customContacts
      audienceTags: $audienceTags
      clientAnswerListSource: $clientAnswerListSource
      clientAnswerLastUsage: $clientAnswerLastUsage
    )
  }
`;
export type UpsertCustomContactsMutationFn = Apollo.MutationFunction<
  UpsertCustomContactsMutation,
  UpsertCustomContactsMutationVariables
>;

/**
 * __useUpsertCustomContactsMutation__
 *
 * To run a mutation, you first call `useUpsertCustomContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCustomContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCustomContactsMutation, { data, loading, error }] = useUpsertCustomContactsMutation({
 *   variables: {
 *      customContacts: // value for 'customContacts'
 *      audienceTags: // value for 'audienceTags'
 *      clientAnswerListSource: // value for 'clientAnswerListSource'
 *      clientAnswerLastUsage: // value for 'clientAnswerLastUsage'
 *   },
 * });
 */
export function useUpsertCustomContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertCustomContactsMutation,
    UpsertCustomContactsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertCustomContactsMutation,
    UpsertCustomContactsMutationVariables
  >(UpsertCustomContactsDocument, options);
}
export type UpsertCustomContactsMutationHookResult = ReturnType<
  typeof useUpsertCustomContactsMutation
>;
export type UpsertCustomContactsMutationResult =
  Apollo.MutationResult<UpsertCustomContactsMutation>;
export type UpsertCustomContactsMutationOptions = Apollo.BaseMutationOptions<
  UpsertCustomContactsMutation,
  UpsertCustomContactsMutationVariables
>;
export const UpsertCustomEmailsDocument = gql`
  mutation UpsertCustomEmails($customEmails: [CustomEmailInput!]!) {
    upsertCustomEmails(customEmails: $customEmails)
  }
`;
export type UpsertCustomEmailsMutationFn = Apollo.MutationFunction<
  UpsertCustomEmailsMutation,
  UpsertCustomEmailsMutationVariables
>;

/**
 * __useUpsertCustomEmailsMutation__
 *
 * To run a mutation, you first call `useUpsertCustomEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCustomEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCustomEmailsMutation, { data, loading, error }] = useUpsertCustomEmailsMutation({
 *   variables: {
 *      customEmails: // value for 'customEmails'
 *   },
 * });
 */
export function useUpsertCustomEmailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertCustomEmailsMutation,
    UpsertCustomEmailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertCustomEmailsMutation,
    UpsertCustomEmailsMutationVariables
  >(UpsertCustomEmailsDocument, options);
}
export type UpsertCustomEmailsMutationHookResult = ReturnType<
  typeof useUpsertCustomEmailsMutation
>;
export type UpsertCustomEmailsMutationResult =
  Apollo.MutationResult<UpsertCustomEmailsMutation>;
export type UpsertCustomEmailsMutationOptions = Apollo.BaseMutationOptions<
  UpsertCustomEmailsMutation,
  UpsertCustomEmailsMutationVariables
>;
export const UpsertDistributionSettingsDocument = gql`
  mutation UpsertDistributionSettings(
    $flowType: FlowType!
    $email: UpsertDistributionSettingsInput
    $linkedin: UpsertDistributionSettingsInput
    $twitter: UpsertDistributionSettingsInput
  ) {
    upsertDistributionSettings(
      flowType: $flowType
      email: $email
      linkedin: $linkedin
      twitter: $twitter
    ) {
      ...CurrentCompanyDistributionSettingsFields
    }
  }
  ${CurrentCompanyDistributionSettingsFieldsFragmentDoc}
`;
export type UpsertDistributionSettingsMutationFn = Apollo.MutationFunction<
  UpsertDistributionSettingsMutation,
  UpsertDistributionSettingsMutationVariables
>;

/**
 * __useUpsertDistributionSettingsMutation__
 *
 * To run a mutation, you first call `useUpsertDistributionSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDistributionSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDistributionSettingsMutation, { data, loading, error }] = useUpsertDistributionSettingsMutation({
 *   variables: {
 *      flowType: // value for 'flowType'
 *      email: // value for 'email'
 *      linkedin: // value for 'linkedin'
 *      twitter: // value for 'twitter'
 *   },
 * });
 */
export function useUpsertDistributionSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertDistributionSettingsMutation,
    UpsertDistributionSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertDistributionSettingsMutation,
    UpsertDistributionSettingsMutationVariables
  >(UpsertDistributionSettingsDocument, options);
}
export type UpsertDistributionSettingsMutationHookResult = ReturnType<
  typeof useUpsertDistributionSettingsMutation
>;
export type UpsertDistributionSettingsMutationResult =
  Apollo.MutationResult<UpsertDistributionSettingsMutation>;
export type UpsertDistributionSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertDistributionSettingsMutation,
    UpsertDistributionSettingsMutationVariables
  >;
export const UpsertMediaCommentReadDocument = gql`
  mutation UpsertMediaCommentRead($mediaCommentId: ID!, $read: Boolean!) {
    upsertMediaCommentRead(mediaCommentId: $mediaCommentId, read: $read) {
      id
      read
    }
  }
`;
export type UpsertMediaCommentReadMutationFn = Apollo.MutationFunction<
  UpsertMediaCommentReadMutation,
  UpsertMediaCommentReadMutationVariables
>;

/**
 * __useUpsertMediaCommentReadMutation__
 *
 * To run a mutation, you first call `useUpsertMediaCommentReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMediaCommentReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMediaCommentReadMutation, { data, loading, error }] = useUpsertMediaCommentReadMutation({
 *   variables: {
 *      mediaCommentId: // value for 'mediaCommentId'
 *      read: // value for 'read'
 *   },
 * });
 */
export function useUpsertMediaCommentReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertMediaCommentReadMutation,
    UpsertMediaCommentReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertMediaCommentReadMutation,
    UpsertMediaCommentReadMutationVariables
  >(UpsertMediaCommentReadDocument, options);
}
export type UpsertMediaCommentReadMutationHookResult = ReturnType<
  typeof useUpsertMediaCommentReadMutation
>;
export type UpsertMediaCommentReadMutationResult =
  Apollo.MutationResult<UpsertMediaCommentReadMutation>;
export type UpsertMediaCommentReadMutationOptions = Apollo.BaseMutationOptions<
  UpsertMediaCommentReadMutation,
  UpsertMediaCommentReadMutationVariables
>;
export const UpsertMediaCommentStarDocument = gql`
  mutation UpsertMediaCommentStar($mediaCommentId: ID!, $starred: Boolean!) {
    upsertMediaCommentStar(mediaCommentId: $mediaCommentId, starred: $starred) {
      id
      starred
    }
  }
`;
export type UpsertMediaCommentStarMutationFn = Apollo.MutationFunction<
  UpsertMediaCommentStarMutation,
  UpsertMediaCommentStarMutationVariables
>;

/**
 * __useUpsertMediaCommentStarMutation__
 *
 * To run a mutation, you first call `useUpsertMediaCommentStarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMediaCommentStarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMediaCommentStarMutation, { data, loading, error }] = useUpsertMediaCommentStarMutation({
 *   variables: {
 *      mediaCommentId: // value for 'mediaCommentId'
 *      starred: // value for 'starred'
 *   },
 * });
 */
export function useUpsertMediaCommentStarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertMediaCommentStarMutation,
    UpsertMediaCommentStarMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertMediaCommentStarMutation,
    UpsertMediaCommentStarMutationVariables
  >(UpsertMediaCommentStarDocument, options);
}
export type UpsertMediaCommentStarMutationHookResult = ReturnType<
  typeof useUpsertMediaCommentStarMutation
>;
export type UpsertMediaCommentStarMutationResult =
  Apollo.MutationResult<UpsertMediaCommentStarMutation>;
export type UpsertMediaCommentStarMutationOptions = Apollo.BaseMutationOptions<
  UpsertMediaCommentStarMutation,
  UpsertMediaCommentStarMutationVariables
>;
export const UpsertMediaUpdateDocument = gql`
  mutation UpsertMediaUpdate($id: ID!, $mediaUpdate: UpsertMediaUpdateInput!) {
    upsertMediaUpdate(id: $id, mediaUpdate: $mediaUpdate) {
      id
      content {
        id
        content
        commentContent
        commentUseCompanyAsUsername
      }
      attachments {
        id
        orderId
        type
        title
        thumbnailUrl
        url
      }
    }
  }
`;
export type UpsertMediaUpdateMutationFn = Apollo.MutationFunction<
  UpsertMediaUpdateMutation,
  UpsertMediaUpdateMutationVariables
>;

/**
 * __useUpsertMediaUpdateMutation__
 *
 * To run a mutation, you first call `useUpsertMediaUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMediaUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMediaUpdateMutation, { data, loading, error }] = useUpsertMediaUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      mediaUpdate: // value for 'mediaUpdate'
 *   },
 * });
 */
export function useUpsertMediaUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertMediaUpdateMutation,
    UpsertMediaUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertMediaUpdateMutation,
    UpsertMediaUpdateMutationVariables
  >(UpsertMediaUpdateDocument, options);
}
export type UpsertMediaUpdateMutationHookResult = ReturnType<
  typeof useUpsertMediaUpdateMutation
>;
export type UpsertMediaUpdateMutationResult =
  Apollo.MutationResult<UpsertMediaUpdateMutation>;
export type UpsertMediaUpdateMutationOptions = Apollo.BaseMutationOptions<
  UpsertMediaUpdateMutation,
  UpsertMediaUpdateMutationVariables
>;
export const UpsertNotificationPreferenceDocument = gql`
  mutation UpsertNotificationPreference(
    $notificationPreference: NotificationPreferenceInput!
  ) {
    upsertNotificationPreference(
      notificationPreference: $notificationPreference
    )
  }
`;
export type UpsertNotificationPreferenceMutationFn = Apollo.MutationFunction<
  UpsertNotificationPreferenceMutation,
  UpsertNotificationPreferenceMutationVariables
>;

/**
 * __useUpsertNotificationPreferenceMutation__
 *
 * To run a mutation, you first call `useUpsertNotificationPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertNotificationPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertNotificationPreferenceMutation, { data, loading, error }] = useUpsertNotificationPreferenceMutation({
 *   variables: {
 *      notificationPreference: // value for 'notificationPreference'
 *   },
 * });
 */
export function useUpsertNotificationPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertNotificationPreferenceMutation,
    UpsertNotificationPreferenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertNotificationPreferenceMutation,
    UpsertNotificationPreferenceMutationVariables
  >(UpsertNotificationPreferenceDocument, options);
}
export type UpsertNotificationPreferenceMutationHookResult = ReturnType<
  typeof useUpsertNotificationPreferenceMutation
>;
export type UpsertNotificationPreferenceMutationResult =
  Apollo.MutationResult<UpsertNotificationPreferenceMutation>;
export type UpsertNotificationPreferenceMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertNotificationPreferenceMutation,
    UpsertNotificationPreferenceMutationVariables
  >;
export const UpsertPastPlacementParticipantListDocument = gql`
  mutation UpsertPastPlacementParticipantList(
    $pastPlacementId: ID!
    $participantList: Upload!
  ) {
    upsertPastPlacementParticipantList(
      pastPlacementId: $pastPlacementId
      participantList: $participantList
    ) {
      id
    }
  }
`;
export type UpsertPastPlacementParticipantListMutationFn =
  Apollo.MutationFunction<
    UpsertPastPlacementParticipantListMutation,
    UpsertPastPlacementParticipantListMutationVariables
  >;

/**
 * __useUpsertPastPlacementParticipantListMutation__
 *
 * To run a mutation, you first call `useUpsertPastPlacementParticipantListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPastPlacementParticipantListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPastPlacementParticipantListMutation, { data, loading, error }] = useUpsertPastPlacementParticipantListMutation({
 *   variables: {
 *      pastPlacementId: // value for 'pastPlacementId'
 *      participantList: // value for 'participantList'
 *   },
 * });
 */
export function useUpsertPastPlacementParticipantListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertPastPlacementParticipantListMutation,
    UpsertPastPlacementParticipantListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertPastPlacementParticipantListMutation,
    UpsertPastPlacementParticipantListMutationVariables
  >(UpsertPastPlacementParticipantListDocument, options);
}
export type UpsertPastPlacementParticipantListMutationHookResult = ReturnType<
  typeof useUpsertPastPlacementParticipantListMutation
>;
export type UpsertPastPlacementParticipantListMutationResult =
  Apollo.MutationResult<UpsertPastPlacementParticipantListMutation>;
export type UpsertPastPlacementParticipantListMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertPastPlacementParticipantListMutation,
    UpsertPastPlacementParticipantListMutationVariables
  >;
export const UpsertShareholderOfferPageDocument = gql`
  mutation UpsertShareholderOfferPage(
    $faqs: [FaqInput!]
    $instructions: [InstructionInput!]
    $shareholderOfferId: ID!
    $shareholderOfferPage: ShareholderOfferPageInput!
  ) {
    upsertShareholderOfferPage(
      faqs: $faqs
      instructions: $instructions
      shareholderOfferId: $shareholderOfferId
      shareholderOfferPage: $shareholderOfferPage
    ) {
      id
    }
  }
`;
export type UpsertShareholderOfferPageMutationFn = Apollo.MutationFunction<
  UpsertShareholderOfferPageMutation,
  UpsertShareholderOfferPageMutationVariables
>;

/**
 * __useUpsertShareholderOfferPageMutation__
 *
 * To run a mutation, you first call `useUpsertShareholderOfferPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertShareholderOfferPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertShareholderOfferPageMutation, { data, loading, error }] = useUpsertShareholderOfferPageMutation({
 *   variables: {
 *      faqs: // value for 'faqs'
 *      instructions: // value for 'instructions'
 *      shareholderOfferId: // value for 'shareholderOfferId'
 *      shareholderOfferPage: // value for 'shareholderOfferPage'
 *   },
 * });
 */
export function useUpsertShareholderOfferPageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertShareholderOfferPageMutation,
    UpsertShareholderOfferPageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertShareholderOfferPageMutation,
    UpsertShareholderOfferPageMutationVariables
  >(UpsertShareholderOfferPageDocument, options);
}
export type UpsertShareholderOfferPageMutationHookResult = ReturnType<
  typeof useUpsertShareholderOfferPageMutation
>;
export type UpsertShareholderOfferPageMutationResult =
  Apollo.MutationResult<UpsertShareholderOfferPageMutation>;
export type UpsertShareholderOfferPageMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertShareholderOfferPageMutation,
    UpsertShareholderOfferPageMutationVariables
  >;
export const UpsertBaseEmailTemplateDocument = gql`
  mutation UpsertBaseEmailTemplate(
    $emailHtml: String!
    $emailJson: String!
    $templateType: BaseEmailTemplateType
  ) {
    upsertBaseEmailTemplate(
      emailHtml: $emailHtml
      emailJson: $emailJson
      templateType: $templateType
    ) {
      emailHtml
      emailJson
      lastEditedProfileUser {
        id
        user {
          firstName
          lastName
        }
      }
      updatedAt
    }
  }
`;
export type UpsertBaseEmailTemplateMutationFn = Apollo.MutationFunction<
  UpsertBaseEmailTemplateMutation,
  UpsertBaseEmailTemplateMutationVariables
>;

/**
 * __useUpsertBaseEmailTemplateMutation__
 *
 * To run a mutation, you first call `useUpsertBaseEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertBaseEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertBaseEmailTemplateMutation, { data, loading, error }] = useUpsertBaseEmailTemplateMutation({
 *   variables: {
 *      emailHtml: // value for 'emailHtml'
 *      emailJson: // value for 'emailJson'
 *      templateType: // value for 'templateType'
 *   },
 * });
 */
export function useUpsertBaseEmailTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertBaseEmailTemplateMutation,
    UpsertBaseEmailTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertBaseEmailTemplateMutation,
    UpsertBaseEmailTemplateMutationVariables
  >(UpsertBaseEmailTemplateDocument, options);
}
export type UpsertBaseEmailTemplateMutationHookResult = ReturnType<
  typeof useUpsertBaseEmailTemplateMutation
>;
export type UpsertBaseEmailTemplateMutationResult =
  Apollo.MutationResult<UpsertBaseEmailTemplateMutation>;
export type UpsertBaseEmailTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpsertBaseEmailTemplateMutation,
  UpsertBaseEmailTemplateMutationVariables
>;
export const ValidatePastPlacementParticipantDocument = gql`
  mutation ValidatePastPlacementParticipant($participantId: ID!) {
    validatePastPlacementParticipant(participantId: $participantId) {
      id
    }
  }
`;
export type ValidatePastPlacementParticipantMutationFn =
  Apollo.MutationFunction<
    ValidatePastPlacementParticipantMutation,
    ValidatePastPlacementParticipantMutationVariables
  >;

/**
 * __useValidatePastPlacementParticipantMutation__
 *
 * To run a mutation, you first call `useValidatePastPlacementParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidatePastPlacementParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validatePastPlacementParticipantMutation, { data, loading, error }] = useValidatePastPlacementParticipantMutation({
 *   variables: {
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useValidatePastPlacementParticipantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidatePastPlacementParticipantMutation,
    ValidatePastPlacementParticipantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ValidatePastPlacementParticipantMutation,
    ValidatePastPlacementParticipantMutationVariables
  >(ValidatePastPlacementParticipantDocument, options);
}
export type ValidatePastPlacementParticipantMutationHookResult = ReturnType<
  typeof useValidatePastPlacementParticipantMutation
>;
export type ValidatePastPlacementParticipantMutationResult =
  Apollo.MutationResult<ValidatePastPlacementParticipantMutation>;
export type ValidatePastPlacementParticipantMutationOptions =
  Apollo.BaseMutationOptions<
    ValidatePastPlacementParticipantMutation,
    ValidatePastPlacementParticipantMutationVariables
  >;
export const VerifyCustomDomainV2Document = gql`
  mutation VerifyCustomDomainV2 {
    verifyCustomDomainV2 {
      isDmarcVerified
      isDkimVerified
      isVercelDomainVerified
      isMailfromVerified
    }
  }
`;
export type VerifyCustomDomainV2MutationFn = Apollo.MutationFunction<
  VerifyCustomDomainV2Mutation,
  VerifyCustomDomainV2MutationVariables
>;

/**
 * __useVerifyCustomDomainV2Mutation__
 *
 * To run a mutation, you first call `useVerifyCustomDomainV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyCustomDomainV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyCustomDomainV2Mutation, { data, loading, error }] = useVerifyCustomDomainV2Mutation({
 *   variables: {
 *   },
 * });
 */
export function useVerifyCustomDomainV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyCustomDomainV2Mutation,
    VerifyCustomDomainV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyCustomDomainV2Mutation,
    VerifyCustomDomainV2MutationVariables
  >(VerifyCustomDomainV2Document, options);
}
export type VerifyCustomDomainV2MutationHookResult = ReturnType<
  typeof useVerifyCustomDomainV2Mutation
>;
export type VerifyCustomDomainV2MutationResult =
  Apollo.MutationResult<VerifyCustomDomainV2Mutation>;
export type VerifyCustomDomainV2MutationOptions = Apollo.BaseMutationOptions<
  VerifyCustomDomainV2Mutation,
  VerifyCustomDomainV2MutationVariables
>;
export const ActiveDistributionFlowsDocument = gql`
  query ActiveDistributionFlows {
    activeDistributionFlows {
      id
      announcements
      updates
      newShareholderWelcome
    }
  }
`;

/**
 * __useActiveDistributionFlowsQuery__
 *
 * To run a query within a React component, call `useActiveDistributionFlowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveDistributionFlowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveDistributionFlowsQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveDistributionFlowsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ActiveDistributionFlowsQuery,
    ActiveDistributionFlowsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ActiveDistributionFlowsQuery,
    ActiveDistributionFlowsQueryVariables
  >(ActiveDistributionFlowsDocument, options);
}
export function useActiveDistributionFlowsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActiveDistributionFlowsQuery,
    ActiveDistributionFlowsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActiveDistributionFlowsQuery,
    ActiveDistributionFlowsQueryVariables
  >(ActiveDistributionFlowsDocument, options);
}
export function useActiveDistributionFlowsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ActiveDistributionFlowsQuery,
    ActiveDistributionFlowsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ActiveDistributionFlowsQuery,
    ActiveDistributionFlowsQueryVariables
  >(ActiveDistributionFlowsDocument, options);
}
export type ActiveDistributionFlowsQueryHookResult = ReturnType<
  typeof useActiveDistributionFlowsQuery
>;
export type ActiveDistributionFlowsLazyQueryHookResult = ReturnType<
  typeof useActiveDistributionFlowsLazyQuery
>;
export type ActiveDistributionFlowsSuspenseQueryHookResult = ReturnType<
  typeof useActiveDistributionFlowsSuspenseQuery
>;
export type ActiveDistributionFlowsQueryResult = Apollo.QueryResult<
  ActiveDistributionFlowsQuery,
  ActiveDistributionFlowsQueryVariables
>;
export const AllBrokersDocument = gql`
  query AllBrokers {
    allBrokers {
      id
      name
      pids
      nameShort
      shareholdingsCount
    }
  }
`;

/**
 * __useAllBrokersQuery__
 *
 * To run a query within a React component, call `useAllBrokersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBrokersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBrokersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllBrokersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllBrokersQuery,
    AllBrokersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllBrokersQuery, AllBrokersQueryVariables>(
    AllBrokersDocument,
    options
  );
}
export function useAllBrokersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllBrokersQuery,
    AllBrokersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllBrokersQuery, AllBrokersQueryVariables>(
    AllBrokersDocument,
    options
  );
}
export function useAllBrokersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AllBrokersQuery,
    AllBrokersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AllBrokersQuery, AllBrokersQueryVariables>(
    AllBrokersDocument,
    options
  );
}
export type AllBrokersQueryHookResult = ReturnType<typeof useAllBrokersQuery>;
export type AllBrokersLazyQueryHookResult = ReturnType<
  typeof useAllBrokersLazyQuery
>;
export type AllBrokersSuspenseQueryHookResult = ReturnType<
  typeof useAllBrokersSuspenseQuery
>;
export type AllBrokersQueryResult = Apollo.QueryResult<
  AllBrokersQuery,
  AllBrokersQueryVariables
>;
export const AllContactsDocument = gql`
  query AllContacts($options: OptionsInput) {
    allContacts(options: $options) {
      id
      insertedAt
      email
      firstName
      importedAt
      investor {
        id
      }
      lastName
      commsUnsubscribes {
        id
        scope
      }
      globalUnsubscribe {
        id
      }
      shareholdings {
        id
        shareCount
      }
      tags {
        id
        name
      }
    }
  }
`;

/**
 * __useAllContactsQuery__
 *
 * To run a query within a React component, call `useAllContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllContactsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAllContactsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllContactsQuery,
    AllContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllContactsQuery, AllContactsQueryVariables>(
    AllContactsDocument,
    options
  );
}
export function useAllContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllContactsQuery,
    AllContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllContactsQuery, AllContactsQueryVariables>(
    AllContactsDocument,
    options
  );
}
export function useAllContactsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AllContactsQuery,
    AllContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AllContactsQuery, AllContactsQueryVariables>(
    AllContactsDocument,
    options
  );
}
export type AllContactsQueryHookResult = ReturnType<typeof useAllContactsQuery>;
export type AllContactsLazyQueryHookResult = ReturnType<
  typeof useAllContactsLazyQuery
>;
export type AllContactsSuspenseQueryHookResult = ReturnType<
  typeof useAllContactsSuspenseQuery
>;
export type AllContactsQueryResult = Apollo.QueryResult<
  AllContactsQuery,
  AllContactsQueryVariables
>;
export const AllCurrentCompanyInvestorUsersDocument = gql`
  query AllCurrentCompanyInvestorUsers {
    allCurrentCompanyInvestorUsers {
      id
      certificate {
        id
        status
        isExpired
      }
      email
      firstName
      isHoldingVerified
      lastName
      notificationPreferences {
        id
        channel
        hasEoi
        isOn
        scope
      }
      username
      shareholderInformation {
        id
        country
        partialHin
        postcode
      }
    }
  }
`;

/**
 * __useAllCurrentCompanyInvestorUsersQuery__
 *
 * To run a query within a React component, call `useAllCurrentCompanyInvestorUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCurrentCompanyInvestorUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCurrentCompanyInvestorUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCurrentCompanyInvestorUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllCurrentCompanyInvestorUsersQuery,
    AllCurrentCompanyInvestorUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllCurrentCompanyInvestorUsersQuery,
    AllCurrentCompanyInvestorUsersQueryVariables
  >(AllCurrentCompanyInvestorUsersDocument, options);
}
export function useAllCurrentCompanyInvestorUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllCurrentCompanyInvestorUsersQuery,
    AllCurrentCompanyInvestorUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllCurrentCompanyInvestorUsersQuery,
    AllCurrentCompanyInvestorUsersQueryVariables
  >(AllCurrentCompanyInvestorUsersDocument, options);
}
export function useAllCurrentCompanyInvestorUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AllCurrentCompanyInvestorUsersQuery,
    AllCurrentCompanyInvestorUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AllCurrentCompanyInvestorUsersQuery,
    AllCurrentCompanyInvestorUsersQueryVariables
  >(AllCurrentCompanyInvestorUsersDocument, options);
}
export type AllCurrentCompanyInvestorUsersQueryHookResult = ReturnType<
  typeof useAllCurrentCompanyInvestorUsersQuery
>;
export type AllCurrentCompanyInvestorUsersLazyQueryHookResult = ReturnType<
  typeof useAllCurrentCompanyInvestorUsersLazyQuery
>;
export type AllCurrentCompanyInvestorUsersSuspenseQueryHookResult = ReturnType<
  typeof useAllCurrentCompanyInvestorUsersSuspenseQuery
>;
export type AllCurrentCompanyInvestorUsersQueryResult = Apollo.QueryResult<
  AllCurrentCompanyInvestorUsersQuery,
  AllCurrentCompanyInvestorUsersQueryVariables
>;
export const AllPastPlacementParticipantsDocument = gql`
  query AllPastPlacementParticipants(
    $options: OptionsInput
    $pastPlacementId: ID!
  ) {
    allPastPlacementParticipants(
      options: $options
      pastPlacementId: $pastPlacementId
    ) {
      id
      allocationShares
      invalidated
      oneMonthAfterSettlementShares
      oneWeekAfterSettlementShares
      settlementShares
      shareholding {
        id
        accountName
      }
      threeMonthsAfterSettlementShares
      trancheType
    }
  }
`;

/**
 * __useAllPastPlacementParticipantsQuery__
 *
 * To run a query within a React component, call `useAllPastPlacementParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPastPlacementParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPastPlacementParticipantsQuery({
 *   variables: {
 *      options: // value for 'options'
 *      pastPlacementId: // value for 'pastPlacementId'
 *   },
 * });
 */
export function useAllPastPlacementParticipantsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllPastPlacementParticipantsQuery,
    AllPastPlacementParticipantsQueryVariables
  > &
    (
      | {
          variables: AllPastPlacementParticipantsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllPastPlacementParticipantsQuery,
    AllPastPlacementParticipantsQueryVariables
  >(AllPastPlacementParticipantsDocument, options);
}
export function useAllPastPlacementParticipantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllPastPlacementParticipantsQuery,
    AllPastPlacementParticipantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllPastPlacementParticipantsQuery,
    AllPastPlacementParticipantsQueryVariables
  >(AllPastPlacementParticipantsDocument, options);
}
export function useAllPastPlacementParticipantsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AllPastPlacementParticipantsQuery,
    AllPastPlacementParticipantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AllPastPlacementParticipantsQuery,
    AllPastPlacementParticipantsQueryVariables
  >(AllPastPlacementParticipantsDocument, options);
}
export type AllPastPlacementParticipantsQueryHookResult = ReturnType<
  typeof useAllPastPlacementParticipantsQuery
>;
export type AllPastPlacementParticipantsLazyQueryHookResult = ReturnType<
  typeof useAllPastPlacementParticipantsLazyQuery
>;
export type AllPastPlacementParticipantsSuspenseQueryHookResult = ReturnType<
  typeof useAllPastPlacementParticipantsSuspenseQuery
>;
export type AllPastPlacementParticipantsQueryResult = Apollo.QueryResult<
  AllPastPlacementParticipantsQuery,
  AllPastPlacementParticipantsQueryVariables
>;
export const AmplifyInvestorsDocument = gql`
  query AmplifyInvestors(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchPhrase: String!
    $searchTags: [String!]
  ) {
    amplifyInvestors(
      after: $after
      before: $before
      first: $first
      last: $last
      searchPhrase: $searchPhrase
      searchTags: $searchTags
    ) {
      edges {
        node {
          id
          contact {
            id
            email
            firstName
            lastName
            investor {
              id
              email
              firstName
              lastName
              username
            }
            shareholdings {
              id
              accountName
              email
            }
            tags {
              id
              name
            }
          }
          shareholding {
            id
            accountName
            email
          }
          type
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(searchPhrase: $searchPhrase)
    }
  }
`;

/**
 * __useAmplifyInvestorsQuery__
 *
 * To run a query within a React component, call `useAmplifyInvestorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAmplifyInvestorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAmplifyInvestorsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      searchPhrase: // value for 'searchPhrase'
 *      searchTags: // value for 'searchTags'
 *   },
 * });
 */
export function useAmplifyInvestorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AmplifyInvestorsQuery,
    AmplifyInvestorsQueryVariables
  > &
    (
      | { variables: AmplifyInvestorsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AmplifyInvestorsQuery, AmplifyInvestorsQueryVariables>(
    AmplifyInvestorsDocument,
    options
  );
}
export function useAmplifyInvestorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AmplifyInvestorsQuery,
    AmplifyInvestorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AmplifyInvestorsQuery,
    AmplifyInvestorsQueryVariables
  >(AmplifyInvestorsDocument, options);
}
export function useAmplifyInvestorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AmplifyInvestorsQuery,
    AmplifyInvestorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AmplifyInvestorsQuery,
    AmplifyInvestorsQueryVariables
  >(AmplifyInvestorsDocument, options);
}
export type AmplifyInvestorsQueryHookResult = ReturnType<
  typeof useAmplifyInvestorsQuery
>;
export type AmplifyInvestorsLazyQueryHookResult = ReturnType<
  typeof useAmplifyInvestorsLazyQuery
>;
export type AmplifyInvestorsSuspenseQueryHookResult = ReturnType<
  typeof useAmplifyInvestorsSuspenseQuery
>;
export type AmplifyInvestorsQueryResult = Apollo.QueryResult<
  AmplifyInvestorsQuery,
  AmplifyInvestorsQueryVariables
>;
export const AnnouncementsListDocument = gql`
  query AnnouncementsList(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $options: OptionsInput
  ) {
    announcementsList(
      after: $after
      before: $before
      first: $first
      last: $last
      options: $options
    ) {
      edges {
        node {
          mediaAnnouncement {
            id
            featuredOnHub
            header
            likes
            listingKey
            marketKey
            mediaId
            postedAt
            socialVideoUrl
            summary
            summaryAi
            totalActiveQuestionCount
            totalCompanyCommentCount
            totalQuestionCount
            totalSurveyResponses
            totalViewCount
            videoUrl
            germanTranslatedVideoUrl
            germanTranslatedUrl
            germanTranslatedHeader
            germanTranslatedSummary
          }
          preparedAnnouncement {
            id
            commentContent
            commentUseCompanyAsUsername
            isDraft
            mediaId
            socialVideoUrl
            summary
            title
            videoUrl
            insertedAt
            updatedAt
          }
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $options)
    }
  }
`;

/**
 * __useAnnouncementsListQuery__
 *
 * To run a query within a React component, call `useAnnouncementsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementsListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAnnouncementsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AnnouncementsListQuery,
    AnnouncementsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnnouncementsListQuery,
    AnnouncementsListQueryVariables
  >(AnnouncementsListDocument, options);
}
export function useAnnouncementsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnnouncementsListQuery,
    AnnouncementsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnnouncementsListQuery,
    AnnouncementsListQueryVariables
  >(AnnouncementsListDocument, options);
}
export function useAnnouncementsListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AnnouncementsListQuery,
    AnnouncementsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AnnouncementsListQuery,
    AnnouncementsListQueryVariables
  >(AnnouncementsListDocument, options);
}
export type AnnouncementsListQueryHookResult = ReturnType<
  typeof useAnnouncementsListQuery
>;
export type AnnouncementsListLazyQueryHookResult = ReturnType<
  typeof useAnnouncementsListLazyQuery
>;
export type AnnouncementsListSuspenseQueryHookResult = ReturnType<
  typeof useAnnouncementsListSuspenseQuery
>;
export type AnnouncementsListQueryResult = Apollo.QueryResult<
  AnnouncementsListQuery,
  AnnouncementsListQueryVariables
>;
export const AudienceListDocument = gql`
  query AudienceList {
    audienceList {
      shareholder {
        count
        lastUpdatedAt
      }
      hub {
        count
        lastUpdatedAt
      }
      imported {
        count
        lastUpdatedAt
      }
      unsubscribed {
        count
        lastUpdatedAt
        shareholder {
          count
          lastUpdatedAt
        }
        hub {
          count
          lastUpdatedAt
        }
        imported {
          count
          lastUpdatedAt
        }
      }
    }
  }
`;

/**
 * __useAudienceListQuery__
 *
 * To run a query within a React component, call `useAudienceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAudienceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAudienceListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAudienceListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AudienceListQuery,
    AudienceListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AudienceListQuery, AudienceListQueryVariables>(
    AudienceListDocument,
    options
  );
}
export function useAudienceListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AudienceListQuery,
    AudienceListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AudienceListQuery, AudienceListQueryVariables>(
    AudienceListDocument,
    options
  );
}
export function useAudienceListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AudienceListQuery,
    AudienceListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AudienceListQuery, AudienceListQueryVariables>(
    AudienceListDocument,
    options
  );
}
export type AudienceListQueryHookResult = ReturnType<
  typeof useAudienceListQuery
>;
export type AudienceListLazyQueryHookResult = ReturnType<
  typeof useAudienceListLazyQuery
>;
export type AudienceListSuspenseQueryHookResult = ReturnType<
  typeof useAudienceListSuspenseQuery
>;
export type AudienceListQueryResult = Apollo.QueryResult<
  AudienceListQuery,
  AudienceListQueryVariables
>;
export const AudiencesContactListDocument = gql`
  query AudiencesContactList($id: ID!) {
    dynamicList(id: $id) {
      id
      lastUpdatedAt
      name
      lastUpdatedByProfileUser {
        user {
          id
          email
          firstName
          lastName
        }
      }
      lastUsedOnEmail {
        id
        campaignName
      }
      estimatedContactsSize
      filters {
        key
        value
      }
      description
      companyProfile {
        id
      }
      insertedAt
      updatedAt
    }
  }
`;

/**
 * __useAudiencesContactListQuery__
 *
 * To run a query within a React component, call `useAudiencesContactListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAudiencesContactListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAudiencesContactListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAudiencesContactListQuery(
  baseOptions: Apollo.QueryHookOptions<
    AudiencesContactListQuery,
    AudiencesContactListQueryVariables
  > &
    (
      | { variables: AudiencesContactListQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AudiencesContactListQuery,
    AudiencesContactListQueryVariables
  >(AudiencesContactListDocument, options);
}
export function useAudiencesContactListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AudiencesContactListQuery,
    AudiencesContactListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AudiencesContactListQuery,
    AudiencesContactListQueryVariables
  >(AudiencesContactListDocument, options);
}
export function useAudiencesContactListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AudiencesContactListQuery,
    AudiencesContactListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AudiencesContactListQuery,
    AudiencesContactListQueryVariables
  >(AudiencesContactListDocument, options);
}
export type AudiencesContactListQueryHookResult = ReturnType<
  typeof useAudiencesContactListQuery
>;
export type AudiencesContactListLazyQueryHookResult = ReturnType<
  typeof useAudiencesContactListLazyQuery
>;
export type AudiencesContactListSuspenseQueryHookResult = ReturnType<
  typeof useAudiencesContactListSuspenseQuery
>;
export type AudiencesContactListQueryResult = Apollo.QueryResult<
  AudiencesContactListQuery,
  AudiencesContactListQueryVariables
>;
export const AudiencesContactListsDocument = gql`
  query AudiencesContactLists(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $options: OptionsInput
  ) {
    dynamicLists(
      after: $after
      before: $before
      first: $first
      last: $last
      options: $options
    ) {
      edges {
        node {
          id
          lastUpdatedAt
          name
          lastUpdatedByProfileUser {
            user {
              id
              email
              firstName
              lastName
            }
          }
          lastUsedOnEmail {
            id
            campaignName
          }
          estimatedContactsSize
          filters {
            key
            value
          }
          description
          companyProfile {
            id
          }
          insertedAt
          updatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $options)
    }
  }
`;

/**
 * __useAudiencesContactListsQuery__
 *
 * To run a query within a React component, call `useAudiencesContactListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAudiencesContactListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAudiencesContactListsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAudiencesContactListsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AudiencesContactListsQuery,
    AudiencesContactListsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AudiencesContactListsQuery,
    AudiencesContactListsQueryVariables
  >(AudiencesContactListsDocument, options);
}
export function useAudiencesContactListsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AudiencesContactListsQuery,
    AudiencesContactListsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AudiencesContactListsQuery,
    AudiencesContactListsQueryVariables
  >(AudiencesContactListsDocument, options);
}
export function useAudiencesContactListsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AudiencesContactListsQuery,
    AudiencesContactListsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AudiencesContactListsQuery,
    AudiencesContactListsQueryVariables
  >(AudiencesContactListsDocument, options);
}
export type AudiencesContactListsQueryHookResult = ReturnType<
  typeof useAudiencesContactListsQuery
>;
export type AudiencesContactListsLazyQueryHookResult = ReturnType<
  typeof useAudiencesContactListsLazyQuery
>;
export type AudiencesContactListsSuspenseQueryHookResult = ReturnType<
  typeof useAudiencesContactListsSuspenseQuery
>;
export type AudiencesContactListsQueryResult = Apollo.QueryResult<
  AudiencesContactListsQuery,
  AudiencesContactListsQueryVariables
>;
export const AutomationStatsDocument = gql`
  query AutomationStats {
    automationStats {
      id
      shareholderWelcomeEmailSentCount
    }
  }
`;

/**
 * __useAutomationStatsQuery__
 *
 * To run a query within a React component, call `useAutomationStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutomationStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutomationStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAutomationStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AutomationStatsQuery,
    AutomationStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AutomationStatsQuery, AutomationStatsQueryVariables>(
    AutomationStatsDocument,
    options
  );
}
export function useAutomationStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AutomationStatsQuery,
    AutomationStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AutomationStatsQuery,
    AutomationStatsQueryVariables
  >(AutomationStatsDocument, options);
}
export function useAutomationStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AutomationStatsQuery,
    AutomationStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AutomationStatsQuery,
    AutomationStatsQueryVariables
  >(AutomationStatsDocument, options);
}
export type AutomationStatsQueryHookResult = ReturnType<
  typeof useAutomationStatsQuery
>;
export type AutomationStatsLazyQueryHookResult = ReturnType<
  typeof useAutomationStatsLazyQuery
>;
export type AutomationStatsSuspenseQueryHookResult = ReturnType<
  typeof useAutomationStatsSuspenseQuery
>;
export type AutomationStatsQueryResult = Apollo.QueryResult<
  AutomationStatsQuery,
  AutomationStatsQueryVariables
>;
export const BenchmarkAnalyticsDocument = gql`
  query BenchmarkAnalytics(
    $startDate: NaiveDateTime!
    $endDate: NaiveDateTime!
  ) {
    benchmarkAnalytics(startDate: $startDate, endDate: $endDate) {
      id
      currentCompanyStats {
        id
        hubActionsCount
        hubActionsTopFive
        likesCount
        nominatedShareholdersCount
        questionsCount
        signupsCount
        signupsTopFive
        uniqueVisitorsCount
        viewsCount
        viewsTopFive
      }
      peerCompaniesStats {
        id
        hubActionsCount
        hubActionsTopFive
        likesCount
        nominatedShareholdersCount
        questionsCount
        signupsCount
        signupsTopFive
        uniqueVisitorsCount
        viewsCount
        viewsTopFive
      }
    }
  }
`;

/**
 * __useBenchmarkAnalyticsQuery__
 *
 * To run a query within a React component, call `useBenchmarkAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenchmarkAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenchmarkAnalyticsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useBenchmarkAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BenchmarkAnalyticsQuery,
    BenchmarkAnalyticsQueryVariables
  > &
    (
      | { variables: BenchmarkAnalyticsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BenchmarkAnalyticsQuery,
    BenchmarkAnalyticsQueryVariables
  >(BenchmarkAnalyticsDocument, options);
}
export function useBenchmarkAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BenchmarkAnalyticsQuery,
    BenchmarkAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BenchmarkAnalyticsQuery,
    BenchmarkAnalyticsQueryVariables
  >(BenchmarkAnalyticsDocument, options);
}
export function useBenchmarkAnalyticsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BenchmarkAnalyticsQuery,
    BenchmarkAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BenchmarkAnalyticsQuery,
    BenchmarkAnalyticsQueryVariables
  >(BenchmarkAnalyticsDocument, options);
}
export type BenchmarkAnalyticsQueryHookResult = ReturnType<
  typeof useBenchmarkAnalyticsQuery
>;
export type BenchmarkAnalyticsLazyQueryHookResult = ReturnType<
  typeof useBenchmarkAnalyticsLazyQuery
>;
export type BenchmarkAnalyticsSuspenseQueryHookResult = ReturnType<
  typeof useBenchmarkAnalyticsSuspenseQuery
>;
export type BenchmarkAnalyticsQueryResult = Apollo.QueryResult<
  BenchmarkAnalyticsQuery,
  BenchmarkAnalyticsQueryVariables
>;
export const BlockDocument = gql`
  query Block($id: ID!) {
    block(id: $id) {
      id
      type
      content
      position
      insertedAt
      updatedAt
      lastEditedByCompanyUser {
        id
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useBlockQuery__
 *
 * To run a query within a React component, call `useBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBlockQuery(
  baseOptions: Apollo.QueryHookOptions<BlockQuery, BlockQueryVariables> &
    ({ variables: BlockQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BlockQuery, BlockQueryVariables>(
    BlockDocument,
    options
  );
}
export function useBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BlockQuery, BlockQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BlockQuery, BlockQueryVariables>(
    BlockDocument,
    options
  );
}
export function useBlockSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BlockQuery, BlockQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BlockQuery, BlockQueryVariables>(
    BlockDocument,
    options
  );
}
export type BlockQueryHookResult = ReturnType<typeof useBlockQuery>;
export type BlockLazyQueryHookResult = ReturnType<typeof useBlockLazyQuery>;
export type BlockSuspenseQueryHookResult = ReturnType<
  typeof useBlockSuspenseQuery
>;
export type BlockQueryResult = Apollo.QueryResult<
  BlockQuery,
  BlockQueryVariables
>;
export const BoardReportV2GenerateDocument = gql`
  query boardReportV2Generate($endDate: Date!) {
    generateBoardReport(endDate: $endDate)
  }
`;

/**
 * __useBoardReportV2GenerateQuery__
 *
 * To run a query within a React component, call `useBoardReportV2GenerateQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardReportV2GenerateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardReportV2GenerateQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useBoardReportV2GenerateQuery(
  baseOptions: Apollo.QueryHookOptions<
    BoardReportV2GenerateQuery,
    BoardReportV2GenerateQueryVariables
  > &
    (
      | { variables: BoardReportV2GenerateQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BoardReportV2GenerateQuery,
    BoardReportV2GenerateQueryVariables
  >(BoardReportV2GenerateDocument, options);
}
export function useBoardReportV2GenerateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BoardReportV2GenerateQuery,
    BoardReportV2GenerateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BoardReportV2GenerateQuery,
    BoardReportV2GenerateQueryVariables
  >(BoardReportV2GenerateDocument, options);
}
export function useBoardReportV2GenerateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BoardReportV2GenerateQuery,
    BoardReportV2GenerateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BoardReportV2GenerateQuery,
    BoardReportV2GenerateQueryVariables
  >(BoardReportV2GenerateDocument, options);
}
export type BoardReportV2GenerateQueryHookResult = ReturnType<
  typeof useBoardReportV2GenerateQuery
>;
export type BoardReportV2GenerateLazyQueryHookResult = ReturnType<
  typeof useBoardReportV2GenerateLazyQuery
>;
export type BoardReportV2GenerateSuspenseQueryHookResult = ReturnType<
  typeof useBoardReportV2GenerateSuspenseQuery
>;
export type BoardReportV2GenerateQueryResult = Apollo.QueryResult<
  BoardReportV2GenerateQuery,
  BoardReportV2GenerateQueryVariables
>;
export const BoardReportV2getLastestDailyHoldingDateDocument = gql`
  query boardReportV2getLastestDailyHoldingDate {
    getLastestDailyHoldingDate
  }
`;

/**
 * __useBoardReportV2getLastestDailyHoldingDateQuery__
 *
 * To run a query within a React component, call `useBoardReportV2getLastestDailyHoldingDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardReportV2getLastestDailyHoldingDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardReportV2getLastestDailyHoldingDateQuery({
 *   variables: {
 *   },
 * });
 */
export function useBoardReportV2getLastestDailyHoldingDateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BoardReportV2getLastestDailyHoldingDateQuery,
    BoardReportV2getLastestDailyHoldingDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BoardReportV2getLastestDailyHoldingDateQuery,
    BoardReportV2getLastestDailyHoldingDateQueryVariables
  >(BoardReportV2getLastestDailyHoldingDateDocument, options);
}
export function useBoardReportV2getLastestDailyHoldingDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BoardReportV2getLastestDailyHoldingDateQuery,
    BoardReportV2getLastestDailyHoldingDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BoardReportV2getLastestDailyHoldingDateQuery,
    BoardReportV2getLastestDailyHoldingDateQueryVariables
  >(BoardReportV2getLastestDailyHoldingDateDocument, options);
}
export function useBoardReportV2getLastestDailyHoldingDateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BoardReportV2getLastestDailyHoldingDateQuery,
    BoardReportV2getLastestDailyHoldingDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BoardReportV2getLastestDailyHoldingDateQuery,
    BoardReportV2getLastestDailyHoldingDateQueryVariables
  >(BoardReportV2getLastestDailyHoldingDateDocument, options);
}
export type BoardReportV2getLastestDailyHoldingDateQueryHookResult = ReturnType<
  typeof useBoardReportV2getLastestDailyHoldingDateQuery
>;
export type BoardReportV2getLastestDailyHoldingDateLazyQueryHookResult =
  ReturnType<typeof useBoardReportV2getLastestDailyHoldingDateLazyQuery>;
export type BoardReportV2getLastestDailyHoldingDateSuspenseQueryHookResult =
  ReturnType<typeof useBoardReportV2getLastestDailyHoldingDateSuspenseQuery>;
export type BoardReportV2getLastestDailyHoldingDateQueryResult =
  Apollo.QueryResult<
    BoardReportV2getLastestDailyHoldingDateQuery,
    BoardReportV2getLastestDailyHoldingDateQueryVariables
  >;
export const BoardReportsDocument = gql`
  query BoardReports {
    boardReports {
      id
      endDate
      link
      pdfUrl
    }
  }
`;

/**
 * __useBoardReportsQuery__
 *
 * To run a query within a React component, call `useBoardReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBoardReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BoardReportsQuery,
    BoardReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BoardReportsQuery, BoardReportsQueryVariables>(
    BoardReportsDocument,
    options
  );
}
export function useBoardReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BoardReportsQuery,
    BoardReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BoardReportsQuery, BoardReportsQueryVariables>(
    BoardReportsDocument,
    options
  );
}
export function useBoardReportsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BoardReportsQuery,
    BoardReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BoardReportsQuery, BoardReportsQueryVariables>(
    BoardReportsDocument,
    options
  );
}
export type BoardReportsQueryHookResult = ReturnType<
  typeof useBoardReportsQuery
>;
export type BoardReportsLazyQueryHookResult = ReturnType<
  typeof useBoardReportsLazyQuery
>;
export type BoardReportsSuspenseQueryHookResult = ReturnType<
  typeof useBoardReportsSuspenseQuery
>;
export type BoardReportsQueryResult = Apollo.QueryResult<
  BoardReportsQuery,
  BoardReportsQueryVariables
>;
export const BrokerBreakdownDocument = gql`
  query BrokerBreakdown($startDate: Date!, $endDate: Date!) {
    brokerBreakdown(startDate: $startDate, endDate: $endDate) {
      id
      name
      nameShort
      netMovements
      pids
      shareholdersCount
      totalShares
    }
  }
`;

/**
 * __useBrokerBreakdownQuery__
 *
 * To run a query within a React component, call `useBrokerBreakdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrokerBreakdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrokerBreakdownQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useBrokerBreakdownQuery(
  baseOptions: Apollo.QueryHookOptions<
    BrokerBreakdownQuery,
    BrokerBreakdownQueryVariables
  > &
    (
      | { variables: BrokerBreakdownQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrokerBreakdownQuery, BrokerBreakdownQueryVariables>(
    BrokerBreakdownDocument,
    options
  );
}
export function useBrokerBreakdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BrokerBreakdownQuery,
    BrokerBreakdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BrokerBreakdownQuery,
    BrokerBreakdownQueryVariables
  >(BrokerBreakdownDocument, options);
}
export function useBrokerBreakdownSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BrokerBreakdownQuery,
    BrokerBreakdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BrokerBreakdownQuery,
    BrokerBreakdownQueryVariables
  >(BrokerBreakdownDocument, options);
}
export type BrokerBreakdownQueryHookResult = ReturnType<
  typeof useBrokerBreakdownQuery
>;
export type BrokerBreakdownLazyQueryHookResult = ReturnType<
  typeof useBrokerBreakdownLazyQuery
>;
export type BrokerBreakdownSuspenseQueryHookResult = ReturnType<
  typeof useBrokerBreakdownSuspenseQuery
>;
export type BrokerBreakdownQueryResult = Apollo.QueryResult<
  BrokerBreakdownQuery,
  BrokerBreakdownQueryVariables
>;
export const CampaignChannelShareholdingsByActivityTypeDocument = gql`
  query CampaignChannelShareholdingsByActivityType(
    $channel: String!
    $shareholderActivityType: ShareholderActivityType!
    $startDate: Date!
    $endDate: Date!
  ) {
    campaignChannelShareholdingsByActivityType(
      channel: $channel
      shareholderActivityType: $shareholderActivityType
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      accountName
      email
      shareCount
      addressLineOne
      addressLineTwo
      addressCity
      addressState
      addressPostcode
      addressCountry
      movementCount
    }
  }
`;

/**
 * __useCampaignChannelShareholdingsByActivityTypeQuery__
 *
 * To run a query within a React component, call `useCampaignChannelShareholdingsByActivityTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignChannelShareholdingsByActivityTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignChannelShareholdingsByActivityTypeQuery({
 *   variables: {
 *      channel: // value for 'channel'
 *      shareholderActivityType: // value for 'shareholderActivityType'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCampaignChannelShareholdingsByActivityTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    CampaignChannelShareholdingsByActivityTypeQuery,
    CampaignChannelShareholdingsByActivityTypeQueryVariables
  > &
    (
      | {
          variables: CampaignChannelShareholdingsByActivityTypeQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CampaignChannelShareholdingsByActivityTypeQuery,
    CampaignChannelShareholdingsByActivityTypeQueryVariables
  >(CampaignChannelShareholdingsByActivityTypeDocument, options);
}
export function useCampaignChannelShareholdingsByActivityTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CampaignChannelShareholdingsByActivityTypeQuery,
    CampaignChannelShareholdingsByActivityTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CampaignChannelShareholdingsByActivityTypeQuery,
    CampaignChannelShareholdingsByActivityTypeQueryVariables
  >(CampaignChannelShareholdingsByActivityTypeDocument, options);
}
export function useCampaignChannelShareholdingsByActivityTypeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CampaignChannelShareholdingsByActivityTypeQuery,
    CampaignChannelShareholdingsByActivityTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CampaignChannelShareholdingsByActivityTypeQuery,
    CampaignChannelShareholdingsByActivityTypeQueryVariables
  >(CampaignChannelShareholdingsByActivityTypeDocument, options);
}
export type CampaignChannelShareholdingsByActivityTypeQueryHookResult =
  ReturnType<typeof useCampaignChannelShareholdingsByActivityTypeQuery>;
export type CampaignChannelShareholdingsByActivityTypeLazyQueryHookResult =
  ReturnType<typeof useCampaignChannelShareholdingsByActivityTypeLazyQuery>;
export type CampaignChannelShareholdingsByActivityTypeSuspenseQueryHookResult =
  ReturnType<typeof useCampaignChannelShareholdingsByActivityTypeSuspenseQuery>;
export type CampaignChannelShareholdingsByActivityTypeQueryResult =
  Apollo.QueryResult<
    CampaignChannelShareholdingsByActivityTypeQuery,
    CampaignChannelShareholdingsByActivityTypeQueryVariables
  >;
export const CampaignChannelShareholdingsDocument = gql`
  query CampaignChannelShareholdings($channel: String!) {
    campaignChannelShareholdings(channel: $channel) {
      id
      accountName
      email
      shareCount
      addressLineOne
      addressLineTwo
      addressCity
      addressState
      addressPostcode
      addressCountry
      movementCount
    }
  }
`;

/**
 * __useCampaignChannelShareholdingsQuery__
 *
 * To run a query within a React component, call `useCampaignChannelShareholdingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignChannelShareholdingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignChannelShareholdingsQuery({
 *   variables: {
 *      channel: // value for 'channel'
 *   },
 * });
 */
export function useCampaignChannelShareholdingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CampaignChannelShareholdingsQuery,
    CampaignChannelShareholdingsQueryVariables
  > &
    (
      | {
          variables: CampaignChannelShareholdingsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CampaignChannelShareholdingsQuery,
    CampaignChannelShareholdingsQueryVariables
  >(CampaignChannelShareholdingsDocument, options);
}
export function useCampaignChannelShareholdingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CampaignChannelShareholdingsQuery,
    CampaignChannelShareholdingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CampaignChannelShareholdingsQuery,
    CampaignChannelShareholdingsQueryVariables
  >(CampaignChannelShareholdingsDocument, options);
}
export function useCampaignChannelShareholdingsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CampaignChannelShareholdingsQuery,
    CampaignChannelShareholdingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CampaignChannelShareholdingsQuery,
    CampaignChannelShareholdingsQueryVariables
  >(CampaignChannelShareholdingsDocument, options);
}
export type CampaignChannelShareholdingsQueryHookResult = ReturnType<
  typeof useCampaignChannelShareholdingsQuery
>;
export type CampaignChannelShareholdingsLazyQueryHookResult = ReturnType<
  typeof useCampaignChannelShareholdingsLazyQuery
>;
export type CampaignChannelShareholdingsSuspenseQueryHookResult = ReturnType<
  typeof useCampaignChannelShareholdingsSuspenseQuery
>;
export type CampaignChannelShareholdingsQueryResult = Apollo.QueryResult<
  CampaignChannelShareholdingsQuery,
  CampaignChannelShareholdingsQueryVariables
>;
export const CertificateByIdDocument = gql`
  query CertificateById($id: ID!) {
    certificateById(id: $id) {
      certificate {
        id
        certificateUrl
        insertedAt
        investorUser {
          id
          firstName
          lastName
          username
          email
          isHoldingVerified
          contact {
            id
          }
          notificationPreferences {
            id
            channel
            hasEoi
            isOn
            scope
          }
        }
        status
        type
        isExpired
        type
      }
      nextPendingId
      totalPending
    }
  }
`;

/**
 * __useCertificateByIdQuery__
 *
 * To run a query within a React component, call `useCertificateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificateByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCertificateByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    CertificateByIdQuery,
    CertificateByIdQueryVariables
  > &
    (
      | { variables: CertificateByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CertificateByIdQuery, CertificateByIdQueryVariables>(
    CertificateByIdDocument,
    options
  );
}
export function useCertificateByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CertificateByIdQuery,
    CertificateByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CertificateByIdQuery,
    CertificateByIdQueryVariables
  >(CertificateByIdDocument, options);
}
export function useCertificateByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CertificateByIdQuery,
    CertificateByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CertificateByIdQuery,
    CertificateByIdQueryVariables
  >(CertificateByIdDocument, options);
}
export type CertificateByIdQueryHookResult = ReturnType<
  typeof useCertificateByIdQuery
>;
export type CertificateByIdLazyQueryHookResult = ReturnType<
  typeof useCertificateByIdLazyQuery
>;
export type CertificateByIdSuspenseQueryHookResult = ReturnType<
  typeof useCertificateByIdSuspenseQuery
>;
export type CertificateByIdQueryResult = Apollo.QueryResult<
  CertificateByIdQuery,
  CertificateByIdQueryVariables
>;
export const CheckDynamicListNameTakenDocument = gql`
  query CheckDynamicListNameTaken($name: String!) {
    checkDynamicListNameTaken(name: $name)
  }
`;

/**
 * __useCheckDynamicListNameTakenQuery__
 *
 * To run a query within a React component, call `useCheckDynamicListNameTakenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDynamicListNameTakenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDynamicListNameTakenQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCheckDynamicListNameTakenQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckDynamicListNameTakenQuery,
    CheckDynamicListNameTakenQueryVariables
  > &
    (
      | { variables: CheckDynamicListNameTakenQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckDynamicListNameTakenQuery,
    CheckDynamicListNameTakenQueryVariables
  >(CheckDynamicListNameTakenDocument, options);
}
export function useCheckDynamicListNameTakenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckDynamicListNameTakenQuery,
    CheckDynamicListNameTakenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckDynamicListNameTakenQuery,
    CheckDynamicListNameTakenQueryVariables
  >(CheckDynamicListNameTakenDocument, options);
}
export function useCheckDynamicListNameTakenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CheckDynamicListNameTakenQuery,
    CheckDynamicListNameTakenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CheckDynamicListNameTakenQuery,
    CheckDynamicListNameTakenQueryVariables
  >(CheckDynamicListNameTakenDocument, options);
}
export type CheckDynamicListNameTakenQueryHookResult = ReturnType<
  typeof useCheckDynamicListNameTakenQuery
>;
export type CheckDynamicListNameTakenLazyQueryHookResult = ReturnType<
  typeof useCheckDynamicListNameTakenLazyQuery
>;
export type CheckDynamicListNameTakenSuspenseQueryHookResult = ReturnType<
  typeof useCheckDynamicListNameTakenSuspenseQuery
>;
export type CheckDynamicListNameTakenQueryResult = Apollo.QueryResult<
  CheckDynamicListNameTakenQuery,
  CheckDynamicListNameTakenQueryVariables
>;
export const CheckDynamicListSafeToDeleteDocument = gql`
  query CheckDynamicListSafeToDelete($id: ID!) {
    checkDynamicListSafeToDelete(id: $id) {
      id
      distributionSettings {
        id
        includedAnnouncementTypes
        includedUpdateTypes
      }
      draftEmails {
        id
        campaignName
        media {
          id
          mediaAnnouncement {
            id
          }
          mediaUpdate {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useCheckDynamicListSafeToDeleteQuery__
 *
 * To run a query within a React component, call `useCheckDynamicListSafeToDeleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDynamicListSafeToDeleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDynamicListSafeToDeleteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckDynamicListSafeToDeleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckDynamicListSafeToDeleteQuery,
    CheckDynamicListSafeToDeleteQueryVariables
  > &
    (
      | {
          variables: CheckDynamicListSafeToDeleteQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckDynamicListSafeToDeleteQuery,
    CheckDynamicListSafeToDeleteQueryVariables
  >(CheckDynamicListSafeToDeleteDocument, options);
}
export function useCheckDynamicListSafeToDeleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckDynamicListSafeToDeleteQuery,
    CheckDynamicListSafeToDeleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckDynamicListSafeToDeleteQuery,
    CheckDynamicListSafeToDeleteQueryVariables
  >(CheckDynamicListSafeToDeleteDocument, options);
}
export function useCheckDynamicListSafeToDeleteSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CheckDynamicListSafeToDeleteQuery,
    CheckDynamicListSafeToDeleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CheckDynamicListSafeToDeleteQuery,
    CheckDynamicListSafeToDeleteQueryVariables
  >(CheckDynamicListSafeToDeleteDocument, options);
}
export type CheckDynamicListSafeToDeleteQueryHookResult = ReturnType<
  typeof useCheckDynamicListSafeToDeleteQuery
>;
export type CheckDynamicListSafeToDeleteLazyQueryHookResult = ReturnType<
  typeof useCheckDynamicListSafeToDeleteLazyQuery
>;
export type CheckDynamicListSafeToDeleteSuspenseQueryHookResult = ReturnType<
  typeof useCheckDynamicListSafeToDeleteSuspenseQuery
>;
export type CheckDynamicListSafeToDeleteQueryResult = Apollo.QueryResult<
  CheckDynamicListSafeToDeleteQuery,
  CheckDynamicListSafeToDeleteQueryVariables
>;
export const CompanyProfileUserFromInvitationTokenDocument = gql`
  query CompanyProfileUserFromInvitationToken($token: String!) {
    companyProfileUserFromInvitationToken(token: $token) {
      id
      jobTitle
      profile {
        id
        name
        informationConfirmedAt
        ticker {
          id
          listingKey
          marketListingKey
        }
      }
      role
      status
      user {
        id
        email
        firstName
        hasPassword
        informationConfirmedAt
        lastName
      }
      expired
    }
  }
`;

/**
 * __useCompanyProfileUserFromInvitationTokenQuery__
 *
 * To run a query within a React component, call `useCompanyProfileUserFromInvitationTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyProfileUserFromInvitationTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyProfileUserFromInvitationTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCompanyProfileUserFromInvitationTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyProfileUserFromInvitationTokenQuery,
    CompanyProfileUserFromInvitationTokenQueryVariables
  > &
    (
      | {
          variables: CompanyProfileUserFromInvitationTokenQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyProfileUserFromInvitationTokenQuery,
    CompanyProfileUserFromInvitationTokenQueryVariables
  >(CompanyProfileUserFromInvitationTokenDocument, options);
}
export function useCompanyProfileUserFromInvitationTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyProfileUserFromInvitationTokenQuery,
    CompanyProfileUserFromInvitationTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyProfileUserFromInvitationTokenQuery,
    CompanyProfileUserFromInvitationTokenQueryVariables
  >(CompanyProfileUserFromInvitationTokenDocument, options);
}
export function useCompanyProfileUserFromInvitationTokenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CompanyProfileUserFromInvitationTokenQuery,
    CompanyProfileUserFromInvitationTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompanyProfileUserFromInvitationTokenQuery,
    CompanyProfileUserFromInvitationTokenQueryVariables
  >(CompanyProfileUserFromInvitationTokenDocument, options);
}
export type CompanyProfileUserFromInvitationTokenQueryHookResult = ReturnType<
  typeof useCompanyProfileUserFromInvitationTokenQuery
>;
export type CompanyProfileUserFromInvitationTokenLazyQueryHookResult =
  ReturnType<typeof useCompanyProfileUserFromInvitationTokenLazyQuery>;
export type CompanyProfileUserFromInvitationTokenSuspenseQueryHookResult =
  ReturnType<typeof useCompanyProfileUserFromInvitationTokenSuspenseQuery>;
export type CompanyProfileUserFromInvitationTokenQueryResult =
  Apollo.QueryResult<
    CompanyProfileUserFromInvitationTokenQuery,
    CompanyProfileUserFromInvitationTokenQueryVariables
  >;
export const CompanyProfileUsersDocument = gql`
  query CompanyProfileUsers {
    companyProfileUsers {
      id
      jobTitle
      role
      status
      user {
        id
        confirmedAt
        email
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useCompanyProfileUsersQuery__
 *
 * To run a query within a React component, call `useCompanyProfileUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyProfileUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyProfileUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyProfileUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyProfileUsersQuery,
    CompanyProfileUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyProfileUsersQuery,
    CompanyProfileUsersQueryVariables
  >(CompanyProfileUsersDocument, options);
}
export function useCompanyProfileUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyProfileUsersQuery,
    CompanyProfileUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyProfileUsersQuery,
    CompanyProfileUsersQueryVariables
  >(CompanyProfileUsersDocument, options);
}
export function useCompanyProfileUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CompanyProfileUsersQuery,
    CompanyProfileUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompanyProfileUsersQuery,
    CompanyProfileUsersQueryVariables
  >(CompanyProfileUsersDocument, options);
}
export type CompanyProfileUsersQueryHookResult = ReturnType<
  typeof useCompanyProfileUsersQuery
>;
export type CompanyProfileUsersLazyQueryHookResult = ReturnType<
  typeof useCompanyProfileUsersLazyQuery
>;
export type CompanyProfileUsersSuspenseQueryHookResult = ReturnType<
  typeof useCompanyProfileUsersSuspenseQuery
>;
export type CompanyProfileUsersQueryResult = Apollo.QueryResult<
  CompanyProfileUsersQuery,
  CompanyProfileUsersQueryVariables
>;
export const ContactDocument = gql`
  query Contact($id: ID!) {
    contact(id: $id) {
      id
      insertedAt
      addressCity
      addressCountry
      addressLineOne
      addressLineTwo
      addressPostcode
      addressState
      email
      firstName
      hnwIdentifiedAt
      hnwStatus
      isNominatedShareholder
      nominatedShareholderIdentifiedAt
      lastName
      phoneNumber
      shareholderStatus
      creatorName
      suppression {
        id
        insertedAt
        reason
        source
      }
      creatorUser {
        id
        firstName
        lastName
        email
      }
      investor {
        id
        email
        firstName
        lastName
        username
        hnwIdentifiedAt
        hnwStatus
        isSelfNominatedShareholder
        selfNominatedShareholderIdentifiedAt
        certificate {
          id
        }
        shareholderInformation {
          id
          country
          partialHin
          postcode
        }
        shareholderInformationUk {
          id
          accountName
          broker
          sharesOwned
          postcode
        }
      }
      shareholderStatus
      shareholdings {
        id
        accountName
        addressCity
        biggestMovement
        brokerNameShort
        brokerPid
        holderIdMasked
        currentHoldingStartDate
        email
        estimatedProfitLoss
        estimatedTotalPurchaseValue
        estimatedTotalSaleValue
        hasParticipatedInSpp
        hasParticipatedInPlacement
        hnwIdentifiedAt
        hnwBehaviour
        initialPurchaseDate
        movementCount
        phoneNumber
        shareCount
        shareCountRank
      }
      commsUnsubscribes {
        id
        scope
      }
      globalUnsubscribe {
        id
      }
      tags {
        id
        name
      }
    }
  }
`;

/**
 * __useContactQuery__
 *
 * To run a query within a React component, call `useContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactQuery(
  baseOptions: Apollo.QueryHookOptions<ContactQuery, ContactQueryVariables> &
    ({ variables: ContactQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContactQuery, ContactQueryVariables>(
    ContactDocument,
    options
  );
}
export function useContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContactQuery, ContactQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContactQuery, ContactQueryVariables>(
    ContactDocument,
    options
  );
}
export function useContactSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ContactQuery,
    ContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ContactQuery, ContactQueryVariables>(
    ContactDocument,
    options
  );
}
export type ContactQueryHookResult = ReturnType<typeof useContactQuery>;
export type ContactLazyQueryHookResult = ReturnType<typeof useContactLazyQuery>;
export type ContactSuspenseQueryHookResult = ReturnType<
  typeof useContactSuspenseQuery
>;
export type ContactQueryResult = Apollo.QueryResult<
  ContactQuery,
  ContactQueryVariables
>;
export const ContactActivitiesDocument = gql`
  query ContactActivities(
    $contactId: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $options: OptionsInput
  ) {
    contactActivities(
      contactId: $contactId
      after: $after
      before: $before
      first: $first
      last: $last
      options: $options
    ) {
      edges {
        node {
          id
          metadata
          timestamp
          type
          companyUser
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(contactId: $contactId, options: $options)
    }
  }
`;

/**
 * __useContactActivitiesQuery__
 *
 * To run a query within a React component, call `useContactActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactActivitiesQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useContactActivitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContactActivitiesQuery,
    ContactActivitiesQueryVariables
  > &
    (
      | { variables: ContactActivitiesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ContactActivitiesQuery,
    ContactActivitiesQueryVariables
  >(ContactActivitiesDocument, options);
}
export function useContactActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContactActivitiesQuery,
    ContactActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ContactActivitiesQuery,
    ContactActivitiesQueryVariables
  >(ContactActivitiesDocument, options);
}
export function useContactActivitiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ContactActivitiesQuery,
    ContactActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ContactActivitiesQuery,
    ContactActivitiesQueryVariables
  >(ContactActivitiesDocument, options);
}
export type ContactActivitiesQueryHookResult = ReturnType<
  typeof useContactActivitiesQuery
>;
export type ContactActivitiesLazyQueryHookResult = ReturnType<
  typeof useContactActivitiesLazyQuery
>;
export type ContactActivitiesSuspenseQueryHookResult = ReturnType<
  typeof useContactActivitiesSuspenseQuery
>;
export type ContactActivitiesQueryResult = Apollo.QueryResult<
  ContactActivitiesQuery,
  ContactActivitiesQueryVariables
>;
export const ExistingTagsDocument = gql`
  query ExistingTags {
    existingTags {
      id
      name
    }
  }
`;

/**
 * __useExistingTagsQuery__
 *
 * To run a query within a React component, call `useExistingTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExistingTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExistingTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExistingTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ExistingTagsQuery,
    ExistingTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExistingTagsQuery, ExistingTagsQueryVariables>(
    ExistingTagsDocument,
    options
  );
}
export function useExistingTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExistingTagsQuery,
    ExistingTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExistingTagsQuery, ExistingTagsQueryVariables>(
    ExistingTagsDocument,
    options
  );
}
export function useExistingTagsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExistingTagsQuery,
    ExistingTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExistingTagsQuery, ExistingTagsQueryVariables>(
    ExistingTagsDocument,
    options
  );
}
export type ExistingTagsQueryHookResult = ReturnType<
  typeof useExistingTagsQuery
>;
export type ExistingTagsLazyQueryHookResult = ReturnType<
  typeof useExistingTagsLazyQuery
>;
export type ExistingTagsSuspenseQueryHookResult = ReturnType<
  typeof useExistingTagsSuspenseQuery
>;
export type ExistingTagsQueryResult = Apollo.QueryResult<
  ExistingTagsQuery,
  ExistingTagsQueryVariables
>;
export const ContactShareholdingSummaryDocument = gql`
  query ContactShareholdingSummary(
    $contactId: ID!
    $endDate: Date!
    $startDate: Date!
  ) {
    contactShareholdingSummary(
      contactId: $contactId
      endDate: $endDate
      startDate: $startDate
    ) {
      id
      announcements {
        id
        header
        postedAt
      }
      dailyHoldings {
        id
        date
        balance
      }
      shareMovements {
        id
        closingBalance
        movement
        movementType
        openingBalance
        settledAt
      }
      timeseries {
        id
        close
        open
        high
        low
        date
        volume
      }
    }
  }
`;

/**
 * __useContactShareholdingSummaryQuery__
 *
 * To run a query within a React component, call `useContactShareholdingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactShareholdingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactShareholdingSummaryQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useContactShareholdingSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContactShareholdingSummaryQuery,
    ContactShareholdingSummaryQueryVariables
  > &
    (
      | { variables: ContactShareholdingSummaryQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ContactShareholdingSummaryQuery,
    ContactShareholdingSummaryQueryVariables
  >(ContactShareholdingSummaryDocument, options);
}
export function useContactShareholdingSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContactShareholdingSummaryQuery,
    ContactShareholdingSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ContactShareholdingSummaryQuery,
    ContactShareholdingSummaryQueryVariables
  >(ContactShareholdingSummaryDocument, options);
}
export function useContactShareholdingSummarySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ContactShareholdingSummaryQuery,
    ContactShareholdingSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ContactShareholdingSummaryQuery,
    ContactShareholdingSummaryQueryVariables
  >(ContactShareholdingSummaryDocument, options);
}
export type ContactShareholdingSummaryQueryHookResult = ReturnType<
  typeof useContactShareholdingSummaryQuery
>;
export type ContactShareholdingSummaryLazyQueryHookResult = ReturnType<
  typeof useContactShareholdingSummaryLazyQuery
>;
export type ContactShareholdingSummarySuspenseQueryHookResult = ReturnType<
  typeof useContactShareholdingSummarySuspenseQuery
>;
export type ContactShareholdingSummaryQueryResult = Apollo.QueryResult<
  ContactShareholdingSummaryQuery,
  ContactShareholdingSummaryQueryVariables
>;
export const ContactsWithShareholdingsDocument = gql`
  query ContactsWithShareholdings(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $options: OptionsInput
  ) {
    contacts(
      after: $after
      before: $before
      first: $first
      last: $last
      options: $options
    ) {
      edges {
        node {
          id
          email
          firstName
          lastName
          shareholdings {
            id
            email
            accountName
            shareCountRank
          }
          investor {
            id
          }
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $options)
    }
  }
`;

/**
 * __useContactsWithShareholdingsQuery__
 *
 * To run a query within a React component, call `useContactsWithShareholdingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsWithShareholdingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsWithShareholdingsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useContactsWithShareholdingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ContactsWithShareholdingsQuery,
    ContactsWithShareholdingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ContactsWithShareholdingsQuery,
    ContactsWithShareholdingsQueryVariables
  >(ContactsWithShareholdingsDocument, options);
}
export function useContactsWithShareholdingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContactsWithShareholdingsQuery,
    ContactsWithShareholdingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ContactsWithShareholdingsQuery,
    ContactsWithShareholdingsQueryVariables
  >(ContactsWithShareholdingsDocument, options);
}
export function useContactsWithShareholdingsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ContactsWithShareholdingsQuery,
    ContactsWithShareholdingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ContactsWithShareholdingsQuery,
    ContactsWithShareholdingsQueryVariables
  >(ContactsWithShareholdingsDocument, options);
}
export type ContactsWithShareholdingsQueryHookResult = ReturnType<
  typeof useContactsWithShareholdingsQuery
>;
export type ContactsWithShareholdingsLazyQueryHookResult = ReturnType<
  typeof useContactsWithShareholdingsLazyQuery
>;
export type ContactsWithShareholdingsSuspenseQueryHookResult = ReturnType<
  typeof useContactsWithShareholdingsSuspenseQuery
>;
export type ContactsWithShareholdingsQueryResult = Apollo.QueryResult<
  ContactsWithShareholdingsQuery,
  ContactsWithShareholdingsQueryVariables
>;
export const ContactsDocument = gql`
  query Contacts(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $options: OptionsInput
  ) {
    contacts(
      after: $after
      before: $before
      first: $first
      last: $last
      options: $options
    ) {
      edges {
        node {
          id
          insertedAt
          importedAt
          insertedAt
          hnwStatus
          hnwIdentifiedAt
          email
          firstName
          lastName
          commsUnsubscribes {
            id
            scope
          }
          globalUnsubscribe {
            id
          }
          investor {
            id
            email
            firstName
            lastName
            username
            insertedAt
          }
          suppression {
            id
            insertedAt
            reason
            source
          }
          shareholderStatus
          shareholdings {
            id
            accountName
          }
          tags {
            id
            name
          }
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $options)
    }
  }
`;

/**
 * __useContactsQuery__
 *
 * To run a query within a React component, call `useContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useContactsQuery(
  baseOptions?: Apollo.QueryHookOptions<ContactsQuery, ContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContactsQuery, ContactsQueryVariables>(
    ContactsDocument,
    options
  );
}
export function useContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContactsQuery,
    ContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContactsQuery, ContactsQueryVariables>(
    ContactsDocument,
    options
  );
}
export function useContactsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ContactsQuery,
    ContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ContactsQuery, ContactsQueryVariables>(
    ContactsDocument,
    options
  );
}
export type ContactsQueryHookResult = ReturnType<typeof useContactsQuery>;
export type ContactsLazyQueryHookResult = ReturnType<
  typeof useContactsLazyQuery
>;
export type ContactsSuspenseQueryHookResult = ReturnType<
  typeof useContactsSuspenseQuery
>;
export type ContactsQueryResult = Apollo.QueryResult<
  ContactsQuery,
  ContactsQueryVariables
>;
export const CurrentCompanyDistributionSettingsDocument = gql`
  query CurrentCompanyDistributionSettings($flowType: FlowType!) {
    currentCompanyDistributionSettings(flowType: $flowType) {
      ...CurrentCompanyDistributionSettingsFields
    }
  }
  ${CurrentCompanyDistributionSettingsFieldsFragmentDoc}
`;

/**
 * __useCurrentCompanyDistributionSettingsQuery__
 *
 * To run a query within a React component, call `useCurrentCompanyDistributionSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCompanyDistributionSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCompanyDistributionSettingsQuery({
 *   variables: {
 *      flowType: // value for 'flowType'
 *   },
 * });
 */
export function useCurrentCompanyDistributionSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CurrentCompanyDistributionSettingsQuery,
    CurrentCompanyDistributionSettingsQueryVariables
  > &
    (
      | {
          variables: CurrentCompanyDistributionSettingsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentCompanyDistributionSettingsQuery,
    CurrentCompanyDistributionSettingsQueryVariables
  >(CurrentCompanyDistributionSettingsDocument, options);
}
export function useCurrentCompanyDistributionSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCompanyDistributionSettingsQuery,
    CurrentCompanyDistributionSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentCompanyDistributionSettingsQuery,
    CurrentCompanyDistributionSettingsQueryVariables
  >(CurrentCompanyDistributionSettingsDocument, options);
}
export function useCurrentCompanyDistributionSettingsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentCompanyDistributionSettingsQuery,
    CurrentCompanyDistributionSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentCompanyDistributionSettingsQuery,
    CurrentCompanyDistributionSettingsQueryVariables
  >(CurrentCompanyDistributionSettingsDocument, options);
}
export type CurrentCompanyDistributionSettingsQueryHookResult = ReturnType<
  typeof useCurrentCompanyDistributionSettingsQuery
>;
export type CurrentCompanyDistributionSettingsLazyQueryHookResult = ReturnType<
  typeof useCurrentCompanyDistributionSettingsLazyQuery
>;
export type CurrentCompanyDistributionSettingsSuspenseQueryHookResult =
  ReturnType<typeof useCurrentCompanyDistributionSettingsSuspenseQuery>;
export type CurrentCompanyDistributionSettingsQueryResult = Apollo.QueryResult<
  CurrentCompanyDistributionSettingsQuery,
  CurrentCompanyDistributionSettingsQueryVariables
>;
export const CurrentCompanyInvestorHubAnalysisDocument = gql`
  query CurrentCompanyInvestorHubAnalysis {
    currentCompanyInvestorHubAnalysis {
      currentMonthInvestorHubStats {
        signups
        title
        views
        visitors
      }
      previousMonthInvestorHubStats {
        signups
        title
        views
        visitors
      }
      overallInvestorHubStats {
        signups
        title
        views
        visitors
      }
    }
  }
`;

/**
 * __useCurrentCompanyInvestorHubAnalysisQuery__
 *
 * To run a query within a React component, call `useCurrentCompanyInvestorHubAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCompanyInvestorHubAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCompanyInvestorHubAnalysisQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentCompanyInvestorHubAnalysisQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentCompanyInvestorHubAnalysisQuery,
    CurrentCompanyInvestorHubAnalysisQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentCompanyInvestorHubAnalysisQuery,
    CurrentCompanyInvestorHubAnalysisQueryVariables
  >(CurrentCompanyInvestorHubAnalysisDocument, options);
}
export function useCurrentCompanyInvestorHubAnalysisLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCompanyInvestorHubAnalysisQuery,
    CurrentCompanyInvestorHubAnalysisQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentCompanyInvestorHubAnalysisQuery,
    CurrentCompanyInvestorHubAnalysisQueryVariables
  >(CurrentCompanyInvestorHubAnalysisDocument, options);
}
export function useCurrentCompanyInvestorHubAnalysisSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentCompanyInvestorHubAnalysisQuery,
    CurrentCompanyInvestorHubAnalysisQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentCompanyInvestorHubAnalysisQuery,
    CurrentCompanyInvestorHubAnalysisQueryVariables
  >(CurrentCompanyInvestorHubAnalysisDocument, options);
}
export type CurrentCompanyInvestorHubAnalysisQueryHookResult = ReturnType<
  typeof useCurrentCompanyInvestorHubAnalysisQuery
>;
export type CurrentCompanyInvestorHubAnalysisLazyQueryHookResult = ReturnType<
  typeof useCurrentCompanyInvestorHubAnalysisLazyQuery
>;
export type CurrentCompanyInvestorHubAnalysisSuspenseQueryHookResult =
  ReturnType<typeof useCurrentCompanyInvestorHubAnalysisSuspenseQuery>;
export type CurrentCompanyInvestorHubAnalysisQueryResult = Apollo.QueryResult<
  CurrentCompanyInvestorHubAnalysisQuery,
  CurrentCompanyInvestorHubAnalysisQueryVariables
>;
export const CurrentCompanyOverviewDocument = gql`
  query CurrentCompanyOverview($startDate: Date!, $endDate: Date!) {
    currentCompanyOverview(startDate: $startDate, endDate: $endDate) {
      date
      announcements {
        id
        header
      }
      currency
      shareholdingsCount
      close
      volume
    }
  }
`;

/**
 * __useCurrentCompanyOverviewQuery__
 *
 * To run a query within a React component, call `useCurrentCompanyOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCompanyOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCompanyOverviewQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCurrentCompanyOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    CurrentCompanyOverviewQuery,
    CurrentCompanyOverviewQueryVariables
  > &
    (
      | { variables: CurrentCompanyOverviewQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentCompanyOverviewQuery,
    CurrentCompanyOverviewQueryVariables
  >(CurrentCompanyOverviewDocument, options);
}
export function useCurrentCompanyOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCompanyOverviewQuery,
    CurrentCompanyOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentCompanyOverviewQuery,
    CurrentCompanyOverviewQueryVariables
  >(CurrentCompanyOverviewDocument, options);
}
export function useCurrentCompanyOverviewSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentCompanyOverviewQuery,
    CurrentCompanyOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentCompanyOverviewQuery,
    CurrentCompanyOverviewQueryVariables
  >(CurrentCompanyOverviewDocument, options);
}
export type CurrentCompanyOverviewQueryHookResult = ReturnType<
  typeof useCurrentCompanyOverviewQuery
>;
export type CurrentCompanyOverviewLazyQueryHookResult = ReturnType<
  typeof useCurrentCompanyOverviewLazyQuery
>;
export type CurrentCompanyOverviewSuspenseQueryHookResult = ReturnType<
  typeof useCurrentCompanyOverviewSuspenseQuery
>;
export type CurrentCompanyOverviewQueryResult = Apollo.QueryResult<
  CurrentCompanyOverviewQuery,
  CurrentCompanyOverviewQueryVariables
>;
export const CurrentCompanyProfileUserDocument = gql`
  query CurrentCompanyProfileUser {
    currentCompanyProfileUser {
      id
      enabledProductTour
      jobTitle
      profile {
        id
        addressLine1
        addressLine2
        announcementTypes {
          allValues
          list {
            subtypes {
              isMarketSensitive
              isRecommended
              subtype
              value
            }
            rectype
            value
          }
          recommendedValues
          rectypeValues
          recommendedValues
        }
        city
        colorPalette {
          primary {
            hsl
            shades {
              s10
              s20
              s30
              s40
              s50
              s60
              s70
              s80
              s90
            }
            tints {
              t10
              t20
              t30
              t40
              t50
              t60
              t70
              t80
              t90
            }
          }
        }
        country
        aiSummariesEnabled
        defaultPublicInteractionsEnabled
        dnsIntegrationPdfUrl
        email
        hidePrivateQuestionsFromCount
        informationConfirmedAt
        insertedAt
        investorCentreEnabled
        isDemo
        isPremium
        isTrial
        isUnlisted
        currency
        latestMarketCap
        logoUrl
        mobileNumber
        name
        primaryBrandColor
        postalCode
        registry
        sectors
        state
        hub
        socialConnection {
          id
          linkedinConnected
          linkedinSetupCompleted
          linkedinOrganisationName
          linkedinRefreshTokenExpiresAt
          twitterSetupCompleted
          twitterUsername
        }
        ticker {
          id
          listingKey
          marketKey
          marketListingKey
          refinitivIdentificationCode
        }
        totalShareholderOffers
        hasCompanyShareholderOfferPermission
        liveShareholderOfferId
        liveHubBuilderWebsite {
          id
          publishedAt
          updatedAt
          publishedByCompanyUser {
            id
            firstName
            lastName
            email
          }
          themeColourPrimary
          themeColourPrimaryText
          themeColourAccent
          themeColourAccentText
          themeFontTitle
          themeFontBody
        }
        investorHub {
          id
          email
          facebook
          instagram
          linkedin
          phone
          registry
          twitter
          website
          colourPrimary
          colourPrimaryText
          colourAccent
          colourAccentText
        }
        featuresEnabled
      }
      role
      simulatingAdminUserId
      status
      user {
        id
        insertedAt
        companyProfileUsers {
          id
          profile {
            id
            name
            logoUrl
            ticker {
              id
              listingKey
              marketListingKey
            }
          }
        }
        email
        informationConfirmedAt
        firstName
        lastName
        mobileNumber
        notificationPreferences {
          id
          channel
          isOn
          scope
        }
        passwordLastUpdatedAt
      }
    }
  }
`;

/**
 * __useCurrentCompanyProfileUserQuery__
 *
 * To run a query within a React component, call `useCurrentCompanyProfileUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCompanyProfileUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCompanyProfileUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentCompanyProfileUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentCompanyProfileUserQuery,
    CurrentCompanyProfileUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentCompanyProfileUserQuery,
    CurrentCompanyProfileUserQueryVariables
  >(CurrentCompanyProfileUserDocument, options);
}
export function useCurrentCompanyProfileUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCompanyProfileUserQuery,
    CurrentCompanyProfileUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentCompanyProfileUserQuery,
    CurrentCompanyProfileUserQueryVariables
  >(CurrentCompanyProfileUserDocument, options);
}
export function useCurrentCompanyProfileUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentCompanyProfileUserQuery,
    CurrentCompanyProfileUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentCompanyProfileUserQuery,
    CurrentCompanyProfileUserQueryVariables
  >(CurrentCompanyProfileUserDocument, options);
}
export type CurrentCompanyProfileUserQueryHookResult = ReturnType<
  typeof useCurrentCompanyProfileUserQuery
>;
export type CurrentCompanyProfileUserLazyQueryHookResult = ReturnType<
  typeof useCurrentCompanyProfileUserLazyQuery
>;
export type CurrentCompanyProfileUserSuspenseQueryHookResult = ReturnType<
  typeof useCurrentCompanyProfileUserSuspenseQuery
>;
export type CurrentCompanyProfileUserQueryResult = Apollo.QueryResult<
  CurrentCompanyProfileUserQuery,
  CurrentCompanyProfileUserQueryVariables
>;
export const CurrentCompanyRaiseStatsDocument = gql`
  query CurrentCompanyRaiseStats {
    currentCompanyStats {
      id
      raisingPotential {
        high
        low
        mean
      }
    }
  }
`;

/**
 * __useCurrentCompanyRaiseStatsQuery__
 *
 * To run a query within a React component, call `useCurrentCompanyRaiseStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCompanyRaiseStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCompanyRaiseStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentCompanyRaiseStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentCompanyRaiseStatsQuery,
    CurrentCompanyRaiseStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentCompanyRaiseStatsQuery,
    CurrentCompanyRaiseStatsQueryVariables
  >(CurrentCompanyRaiseStatsDocument, options);
}
export function useCurrentCompanyRaiseStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCompanyRaiseStatsQuery,
    CurrentCompanyRaiseStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentCompanyRaiseStatsQuery,
    CurrentCompanyRaiseStatsQueryVariables
  >(CurrentCompanyRaiseStatsDocument, options);
}
export function useCurrentCompanyRaiseStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentCompanyRaiseStatsQuery,
    CurrentCompanyRaiseStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentCompanyRaiseStatsQuery,
    CurrentCompanyRaiseStatsQueryVariables
  >(CurrentCompanyRaiseStatsDocument, options);
}
export type CurrentCompanyRaiseStatsQueryHookResult = ReturnType<
  typeof useCurrentCompanyRaiseStatsQuery
>;
export type CurrentCompanyRaiseStatsLazyQueryHookResult = ReturnType<
  typeof useCurrentCompanyRaiseStatsLazyQuery
>;
export type CurrentCompanyRaiseStatsSuspenseQueryHookResult = ReturnType<
  typeof useCurrentCompanyRaiseStatsSuspenseQuery
>;
export type CurrentCompanyRaiseStatsQueryResult = Apollo.QueryResult<
  CurrentCompanyRaiseStatsQuery,
  CurrentCompanyRaiseStatsQueryVariables
>;
export const CurrentCompanySppsAndPlacementsDocument = gql`
  query CurrentCompanySppsAndPlacements {
    currentCompany {
      id
      hasHadSpp
      hasHadPlacement
    }
  }
`;

/**
 * __useCurrentCompanySppsAndPlacementsQuery__
 *
 * To run a query within a React component, call `useCurrentCompanySppsAndPlacementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCompanySppsAndPlacementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCompanySppsAndPlacementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentCompanySppsAndPlacementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentCompanySppsAndPlacementsQuery,
    CurrentCompanySppsAndPlacementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentCompanySppsAndPlacementsQuery,
    CurrentCompanySppsAndPlacementsQueryVariables
  >(CurrentCompanySppsAndPlacementsDocument, options);
}
export function useCurrentCompanySppsAndPlacementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCompanySppsAndPlacementsQuery,
    CurrentCompanySppsAndPlacementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentCompanySppsAndPlacementsQuery,
    CurrentCompanySppsAndPlacementsQueryVariables
  >(CurrentCompanySppsAndPlacementsDocument, options);
}
export function useCurrentCompanySppsAndPlacementsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentCompanySppsAndPlacementsQuery,
    CurrentCompanySppsAndPlacementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentCompanySppsAndPlacementsQuery,
    CurrentCompanySppsAndPlacementsQueryVariables
  >(CurrentCompanySppsAndPlacementsDocument, options);
}
export type CurrentCompanySppsAndPlacementsQueryHookResult = ReturnType<
  typeof useCurrentCompanySppsAndPlacementsQuery
>;
export type CurrentCompanySppsAndPlacementsLazyQueryHookResult = ReturnType<
  typeof useCurrentCompanySppsAndPlacementsLazyQuery
>;
export type CurrentCompanySppsAndPlacementsSuspenseQueryHookResult = ReturnType<
  typeof useCurrentCompanySppsAndPlacementsSuspenseQuery
>;
export type CurrentCompanySppsAndPlacementsQueryResult = Apollo.QueryResult<
  CurrentCompanySppsAndPlacementsQuery,
  CurrentCompanySppsAndPlacementsQueryVariables
>;
export const CurrentCompanyStatsDocument = gql`
  query CurrentCompanyStats {
    currentCompanyStats {
      id
      averageHoldingTime
      companyProfileId
      growthRatioPastNinetyDays
      profitLoss {
        averageShareholderProfitLoss
        shareholdersInProfit
      }
      raisingPotential {
        averageUptake
        high
        low
        mean
        shareholderParticipation
        scenarioRange {
          allBad {
            averageUptake
            high
            low
            mean
            shareholderParticipation
          }
          allGood {
            averageUptake
            high
            low
            mean
            shareholderParticipation
          }
          rockyFinish {
            averageUptake
            high
            low
            mean
            shareholderParticipation
          }
        }
      }
      shareholderInsights {
        addresses
        eligibility
        email
        mobile
        reachability
        total
      }
    }
  }
`;

/**
 * __useCurrentCompanyStatsQuery__
 *
 * To run a query within a React component, call `useCurrentCompanyStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCompanyStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCompanyStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentCompanyStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentCompanyStatsQuery,
    CurrentCompanyStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentCompanyStatsQuery,
    CurrentCompanyStatsQueryVariables
  >(CurrentCompanyStatsDocument, options);
}
export function useCurrentCompanyStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCompanyStatsQuery,
    CurrentCompanyStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentCompanyStatsQuery,
    CurrentCompanyStatsQueryVariables
  >(CurrentCompanyStatsDocument, options);
}
export function useCurrentCompanyStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentCompanyStatsQuery,
    CurrentCompanyStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentCompanyStatsQuery,
    CurrentCompanyStatsQueryVariables
  >(CurrentCompanyStatsDocument, options);
}
export type CurrentCompanyStatsQueryHookResult = ReturnType<
  typeof useCurrentCompanyStatsQuery
>;
export type CurrentCompanyStatsLazyQueryHookResult = ReturnType<
  typeof useCurrentCompanyStatsLazyQuery
>;
export type CurrentCompanyStatsSuspenseQueryHookResult = ReturnType<
  typeof useCurrentCompanyStatsSuspenseQuery
>;
export type CurrentCompanyStatsQueryResult = Apollo.QueryResult<
  CurrentCompanyStatsQuery,
  CurrentCompanyStatsQueryVariables
>;
export const CurrentCompanyUserDocument = gql`
  query CurrentCompanyUser {
    currentCompanyUser {
      id
      insertedAt
      companyProfileUsers {
        id
        profile {
          id
          name
          logoUrl
          ticker {
            id
            listingKey
            marketListingKey
          }
        }
      }
      confirmedAt
      email
      firstName
      lastName
      mobileNumber
      notificationPreferences {
        id
        channel
        isOn
        scope
      }
      passwordLastUpdatedAt
    }
  }
`;

/**
 * __useCurrentCompanyUserQuery__
 *
 * To run a query within a React component, call `useCurrentCompanyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCompanyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCompanyUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentCompanyUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentCompanyUserQuery,
    CurrentCompanyUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentCompanyUserQuery,
    CurrentCompanyUserQueryVariables
  >(CurrentCompanyUserDocument, options);
}
export function useCurrentCompanyUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCompanyUserQuery,
    CurrentCompanyUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentCompanyUserQuery,
    CurrentCompanyUserQueryVariables
  >(CurrentCompanyUserDocument, options);
}
export function useCurrentCompanyUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentCompanyUserQuery,
    CurrentCompanyUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentCompanyUserQuery,
    CurrentCompanyUserQueryVariables
  >(CurrentCompanyUserDocument, options);
}
export type CurrentCompanyUserQueryHookResult = ReturnType<
  typeof useCurrentCompanyUserQuery
>;
export type CurrentCompanyUserLazyQueryHookResult = ReturnType<
  typeof useCurrentCompanyUserLazyQuery
>;
export type CurrentCompanyUserSuspenseQueryHookResult = ReturnType<
  typeof useCurrentCompanyUserSuspenseQuery
>;
export type CurrentCompanyUserQueryResult = Apollo.QueryResult<
  CurrentCompanyUserQuery,
  CurrentCompanyUserQueryVariables
>;
export const CurrentCorporatePagesDocument = gql`
  query CurrentCorporatePages {
    currentCorporatePages {
      id
      title
      type
      isLive
      insertedAt
      updatedAt
      lastEditedByUser {
        id
        firstName
        lastName
        email
      }
      sections {
        id
        heading
        description
        attachments {
          id
          orderId
          heading
          subheading
          description
          fileUrl
        }
      }
      slug
    }
  }
`;

/**
 * __useCurrentCorporatePagesQuery__
 *
 * To run a query within a React component, call `useCurrentCorporatePagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCorporatePagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCorporatePagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentCorporatePagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentCorporatePagesQuery,
    CurrentCorporatePagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentCorporatePagesQuery,
    CurrentCorporatePagesQueryVariables
  >(CurrentCorporatePagesDocument, options);
}
export function useCurrentCorporatePagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCorporatePagesQuery,
    CurrentCorporatePagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentCorporatePagesQuery,
    CurrentCorporatePagesQueryVariables
  >(CurrentCorporatePagesDocument, options);
}
export function useCurrentCorporatePagesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentCorporatePagesQuery,
    CurrentCorporatePagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentCorporatePagesQuery,
    CurrentCorporatePagesQueryVariables
  >(CurrentCorporatePagesDocument, options);
}
export type CurrentCorporatePagesQueryHookResult = ReturnType<
  typeof useCurrentCorporatePagesQuery
>;
export type CurrentCorporatePagesLazyQueryHookResult = ReturnType<
  typeof useCurrentCorporatePagesLazyQuery
>;
export type CurrentCorporatePagesSuspenseQueryHookResult = ReturnType<
  typeof useCurrentCorporatePagesSuspenseQuery
>;
export type CurrentCorporatePagesQueryResult = Apollo.QueryResult<
  CurrentCorporatePagesQuery,
  CurrentCorporatePagesQueryVariables
>;
export const CurrentGovernancePageDocument = gql`
  query CurrentGovernancePage {
    currentGovernancePage {
      id
      title
      type
      isLive
      insertedAt
      updatedAt
      lastEditedByUser {
        id
        firstName
        lastName
        email
      }
      sections {
        id
        heading
        description
        attachments {
          id
          orderId
          heading
          subheading
          description
          fileUrl
        }
      }
      slug
    }
  }
`;

/**
 * __useCurrentGovernancePageQuery__
 *
 * To run a query within a React component, call `useCurrentGovernancePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentGovernancePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentGovernancePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentGovernancePageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentGovernancePageQuery,
    CurrentGovernancePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentGovernancePageQuery,
    CurrentGovernancePageQueryVariables
  >(CurrentGovernancePageDocument, options);
}
export function useCurrentGovernancePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentGovernancePageQuery,
    CurrentGovernancePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentGovernancePageQuery,
    CurrentGovernancePageQueryVariables
  >(CurrentGovernancePageDocument, options);
}
export function useCurrentGovernancePageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentGovernancePageQuery,
    CurrentGovernancePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentGovernancePageQuery,
    CurrentGovernancePageQueryVariables
  >(CurrentGovernancePageDocument, options);
}
export type CurrentGovernancePageQueryHookResult = ReturnType<
  typeof useCurrentGovernancePageQuery
>;
export type CurrentGovernancePageLazyQueryHookResult = ReturnType<
  typeof useCurrentGovernancePageLazyQuery
>;
export type CurrentGovernancePageSuspenseQueryHookResult = ReturnType<
  typeof useCurrentGovernancePageSuspenseQuery
>;
export type CurrentGovernancePageQueryResult = Apollo.QueryResult<
  CurrentGovernancePageQuery,
  CurrentGovernancePageQueryVariables
>;
export const CurrentHoldingDistributionsDocument = gql`
  query CurrentHoldingDistributions {
    currentHoldingDistributions {
      date
      holdingDistributions {
        id
        lowerBound
        upperBound
        value
      }
      id
    }
  }
`;

/**
 * __useCurrentHoldingDistributionsQuery__
 *
 * To run a query within a React component, call `useCurrentHoldingDistributionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentHoldingDistributionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentHoldingDistributionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentHoldingDistributionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentHoldingDistributionsQuery,
    CurrentHoldingDistributionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentHoldingDistributionsQuery,
    CurrentHoldingDistributionsQueryVariables
  >(CurrentHoldingDistributionsDocument, options);
}
export function useCurrentHoldingDistributionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentHoldingDistributionsQuery,
    CurrentHoldingDistributionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentHoldingDistributionsQuery,
    CurrentHoldingDistributionsQueryVariables
  >(CurrentHoldingDistributionsDocument, options);
}
export function useCurrentHoldingDistributionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentHoldingDistributionsQuery,
    CurrentHoldingDistributionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentHoldingDistributionsQuery,
    CurrentHoldingDistributionsQueryVariables
  >(CurrentHoldingDistributionsDocument, options);
}
export type CurrentHoldingDistributionsQueryHookResult = ReturnType<
  typeof useCurrentHoldingDistributionsQuery
>;
export type CurrentHoldingDistributionsLazyQueryHookResult = ReturnType<
  typeof useCurrentHoldingDistributionsLazyQuery
>;
export type CurrentHoldingDistributionsSuspenseQueryHookResult = ReturnType<
  typeof useCurrentHoldingDistributionsSuspenseQuery
>;
export type CurrentHoldingDistributionsQueryResult = Apollo.QueryResult<
  CurrentHoldingDistributionsQuery,
  CurrentHoldingDistributionsQueryVariables
>;
export const CurrentHoldingInsightsDocument = gql`
  query CurrentHoldingInsights {
    currentHoldingInsights {
      id
      averageHoldingSize
      averageHoldingValue
      date
      holdingTimeseries {
        id
        averageHoldingTime
        date
        shareholdingsCount
      }
    }
  }
`;

/**
 * __useCurrentHoldingInsightsQuery__
 *
 * To run a query within a React component, call `useCurrentHoldingInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentHoldingInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentHoldingInsightsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentHoldingInsightsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentHoldingInsightsQuery,
    CurrentHoldingInsightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentHoldingInsightsQuery,
    CurrentHoldingInsightsQueryVariables
  >(CurrentHoldingInsightsDocument, options);
}
export function useCurrentHoldingInsightsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentHoldingInsightsQuery,
    CurrentHoldingInsightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentHoldingInsightsQuery,
    CurrentHoldingInsightsQueryVariables
  >(CurrentHoldingInsightsDocument, options);
}
export function useCurrentHoldingInsightsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentHoldingInsightsQuery,
    CurrentHoldingInsightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentHoldingInsightsQuery,
    CurrentHoldingInsightsQueryVariables
  >(CurrentHoldingInsightsDocument, options);
}
export type CurrentHoldingInsightsQueryHookResult = ReturnType<
  typeof useCurrentHoldingInsightsQuery
>;
export type CurrentHoldingInsightsLazyQueryHookResult = ReturnType<
  typeof useCurrentHoldingInsightsLazyQuery
>;
export type CurrentHoldingInsightsSuspenseQueryHookResult = ReturnType<
  typeof useCurrentHoldingInsightsSuspenseQuery
>;
export type CurrentHoldingInsightsQueryResult = Apollo.QueryResult<
  CurrentHoldingInsightsQuery,
  CurrentHoldingInsightsQueryVariables
>;
export const CurrentHoldingLengthsDocument = gql`
  query CurrentHoldingLengths {
    currentHoldingLengths {
      date
      holdingLengths {
        id
        label
        shareholdersCount
        totalShares
      }
      id
    }
  }
`;

/**
 * __useCurrentHoldingLengthsQuery__
 *
 * To run a query within a React component, call `useCurrentHoldingLengthsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentHoldingLengthsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentHoldingLengthsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentHoldingLengthsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentHoldingLengthsQuery,
    CurrentHoldingLengthsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentHoldingLengthsQuery,
    CurrentHoldingLengthsQueryVariables
  >(CurrentHoldingLengthsDocument, options);
}
export function useCurrentHoldingLengthsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentHoldingLengthsQuery,
    CurrentHoldingLengthsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentHoldingLengthsQuery,
    CurrentHoldingLengthsQueryVariables
  >(CurrentHoldingLengthsDocument, options);
}
export function useCurrentHoldingLengthsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentHoldingLengthsQuery,
    CurrentHoldingLengthsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentHoldingLengthsQuery,
    CurrentHoldingLengthsQueryVariables
  >(CurrentHoldingLengthsDocument, options);
}
export type CurrentHoldingLengthsQueryHookResult = ReturnType<
  typeof useCurrentHoldingLengthsQuery
>;
export type CurrentHoldingLengthsLazyQueryHookResult = ReturnType<
  typeof useCurrentHoldingLengthsLazyQuery
>;
export type CurrentHoldingLengthsSuspenseQueryHookResult = ReturnType<
  typeof useCurrentHoldingLengthsSuspenseQuery
>;
export type CurrentHoldingLengthsQueryResult = Apollo.QueryResult<
  CurrentHoldingLengthsQuery,
  CurrentHoldingLengthsQueryVariables
>;
export const CurrentHoldingSizesDocument = gql`
  query CurrentHoldingSizes {
    currentHoldingSizes {
      date
      holdingSizes {
        id
        label
        percentage
        value
      }
      id
    }
  }
`;

/**
 * __useCurrentHoldingSizesQuery__
 *
 * To run a query within a React component, call `useCurrentHoldingSizesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentHoldingSizesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentHoldingSizesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentHoldingSizesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentHoldingSizesQuery,
    CurrentHoldingSizesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentHoldingSizesQuery,
    CurrentHoldingSizesQueryVariables
  >(CurrentHoldingSizesDocument, options);
}
export function useCurrentHoldingSizesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentHoldingSizesQuery,
    CurrentHoldingSizesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentHoldingSizesQuery,
    CurrentHoldingSizesQueryVariables
  >(CurrentHoldingSizesDocument, options);
}
export function useCurrentHoldingSizesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentHoldingSizesQuery,
    CurrentHoldingSizesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentHoldingSizesQuery,
    CurrentHoldingSizesQueryVariables
  >(CurrentHoldingSizesDocument, options);
}
export type CurrentHoldingSizesQueryHookResult = ReturnType<
  typeof useCurrentHoldingSizesQuery
>;
export type CurrentHoldingSizesLazyQueryHookResult = ReturnType<
  typeof useCurrentHoldingSizesLazyQuery
>;
export type CurrentHoldingSizesSuspenseQueryHookResult = ReturnType<
  typeof useCurrentHoldingSizesSuspenseQuery
>;
export type CurrentHoldingSizesQueryResult = Apollo.QueryResult<
  CurrentHoldingSizesQuery,
  CurrentHoldingSizesQueryVariables
>;
export const CurrentShareholderContactabilitiesDocument = gql`
  query CurrentShareholderContactabilities {
    currentShareholderContactabilities {
      id
      date
      emailsCount
      phonesCount
      totalShareholders
    }
  }
`;

/**
 * __useCurrentShareholderContactabilitiesQuery__
 *
 * To run a query within a React component, call `useCurrentShareholderContactabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentShareholderContactabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentShareholderContactabilitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentShareholderContactabilitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentShareholderContactabilitiesQuery,
    CurrentShareholderContactabilitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentShareholderContactabilitiesQuery,
    CurrentShareholderContactabilitiesQueryVariables
  >(CurrentShareholderContactabilitiesDocument, options);
}
export function useCurrentShareholderContactabilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentShareholderContactabilitiesQuery,
    CurrentShareholderContactabilitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentShareholderContactabilitiesQuery,
    CurrentShareholderContactabilitiesQueryVariables
  >(CurrentShareholderContactabilitiesDocument, options);
}
export function useCurrentShareholderContactabilitiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentShareholderContactabilitiesQuery,
    CurrentShareholderContactabilitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentShareholderContactabilitiesQuery,
    CurrentShareholderContactabilitiesQueryVariables
  >(CurrentShareholderContactabilitiesDocument, options);
}
export type CurrentShareholderContactabilitiesQueryHookResult = ReturnType<
  typeof useCurrentShareholderContactabilitiesQuery
>;
export type CurrentShareholderContactabilitiesLazyQueryHookResult = ReturnType<
  typeof useCurrentShareholderContactabilitiesLazyQuery
>;
export type CurrentShareholderContactabilitiesSuspenseQueryHookResult =
  ReturnType<typeof useCurrentShareholderContactabilitiesSuspenseQuery>;
export type CurrentShareholderContactabilitiesQueryResult = Apollo.QueryResult<
  CurrentShareholderContactabilitiesQuery,
  CurrentShareholderContactabilitiesQueryVariables
>;
export const CurrentShareholderLocationsDocument = gql`
  query CurrentShareholderLocations {
    currentShareholderLocations {
      id
      date
      shareholderLocations {
        id
        location
        shareholdersCount
        totalShares
      }
    }
  }
`;

/**
 * __useCurrentShareholderLocationsQuery__
 *
 * To run a query within a React component, call `useCurrentShareholderLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentShareholderLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentShareholderLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentShareholderLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentShareholderLocationsQuery,
    CurrentShareholderLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentShareholderLocationsQuery,
    CurrentShareholderLocationsQueryVariables
  >(CurrentShareholderLocationsDocument, options);
}
export function useCurrentShareholderLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentShareholderLocationsQuery,
    CurrentShareholderLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentShareholderLocationsQuery,
    CurrentShareholderLocationsQueryVariables
  >(CurrentShareholderLocationsDocument, options);
}
export function useCurrentShareholderLocationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentShareholderLocationsQuery,
    CurrentShareholderLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentShareholderLocationsQuery,
    CurrentShareholderLocationsQueryVariables
  >(CurrentShareholderLocationsDocument, options);
}
export type CurrentShareholderLocationsQueryHookResult = ReturnType<
  typeof useCurrentShareholderLocationsQuery
>;
export type CurrentShareholderLocationsLazyQueryHookResult = ReturnType<
  typeof useCurrentShareholderLocationsLazyQuery
>;
export type CurrentShareholderLocationsSuspenseQueryHookResult = ReturnType<
  typeof useCurrentShareholderLocationsSuspenseQuery
>;
export type CurrentShareholderLocationsQueryResult = Apollo.QueryResult<
  CurrentShareholderLocationsQuery,
  CurrentShareholderLocationsQueryVariables
>;
export const CurrentShareholderProfitsDocument = gql`
  query CurrentShareholderProfits {
    currentShareholderProfits {
      id
      averageShareholdersProfit
      shareholdersInLoss
      shareholdersInProfit
      totalUnrealisedGain
      totalUnrealisedLoss
      shareholderProfits {
        id
        lowerBound
        shareholdersCount
        upperBound
      }
    }
  }
`;

/**
 * __useCurrentShareholderProfitsQuery__
 *
 * To run a query within a React component, call `useCurrentShareholderProfitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentShareholderProfitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentShareholderProfitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentShareholderProfitsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentShareholderProfitsQuery,
    CurrentShareholderProfitsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentShareholderProfitsQuery,
    CurrentShareholderProfitsQueryVariables
  >(CurrentShareholderProfitsDocument, options);
}
export function useCurrentShareholderProfitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentShareholderProfitsQuery,
    CurrentShareholderProfitsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentShareholderProfitsQuery,
    CurrentShareholderProfitsQueryVariables
  >(CurrentShareholderProfitsDocument, options);
}
export function useCurrentShareholderProfitsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentShareholderProfitsQuery,
    CurrentShareholderProfitsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentShareholderProfitsQuery,
    CurrentShareholderProfitsQueryVariables
  >(CurrentShareholderProfitsDocument, options);
}
export type CurrentShareholderProfitsQueryHookResult = ReturnType<
  typeof useCurrentShareholderProfitsQuery
>;
export type CurrentShareholderProfitsLazyQueryHookResult = ReturnType<
  typeof useCurrentShareholderProfitsLazyQuery
>;
export type CurrentShareholderProfitsSuspenseQueryHookResult = ReturnType<
  typeof useCurrentShareholderProfitsSuspenseQuery
>;
export type CurrentShareholderProfitsQueryResult = Apollo.QueryResult<
  CurrentShareholderProfitsQuery,
  CurrentShareholderProfitsQueryVariables
>;
export const CurrentWebsiteDocument = gql`
  query CurrentWebsite {
    currentWebsite {
      id
      publishedAt
      insertedAt
      updatedAt
      themeColourPrimary
      themeColourPrimaryText
      themeColourAccent
      themeColourAccentText
      themeFontTitle
      themeFontBody
      logoCloudinaryUrl
      logoSquareCloudinaryUrl
      previewSecret
      isFullWebsiteReplacement
      isStickySignupBannerEnabled
      isEmailPopupModalEnabled
      emailPopupModalCloudinaryUrl
      googleAnalyticsId
      title
      pages {
        id
        title
        slug
      }
      navMenuItems {
        id
        label
        url
        position
        openInNewTab
        page {
          id
          title
          slug
        }
        children {
          id
          label
          url
          position
          openInNewTab
          page {
            id
            title
            slug
          }
        }
      }
    }
  }
`;

/**
 * __useCurrentWebsiteQuery__
 *
 * To run a query within a React component, call `useCurrentWebsiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentWebsiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentWebsiteQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentWebsiteQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentWebsiteQuery,
    CurrentWebsiteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentWebsiteQuery, CurrentWebsiteQueryVariables>(
    CurrentWebsiteDocument,
    options
  );
}
export function useCurrentWebsiteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentWebsiteQuery,
    CurrentWebsiteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentWebsiteQuery, CurrentWebsiteQueryVariables>(
    CurrentWebsiteDocument,
    options
  );
}
export function useCurrentWebsiteSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentWebsiteQuery,
    CurrentWebsiteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentWebsiteQuery,
    CurrentWebsiteQueryVariables
  >(CurrentWebsiteDocument, options);
}
export type CurrentWebsiteQueryHookResult = ReturnType<
  typeof useCurrentWebsiteQuery
>;
export type CurrentWebsiteLazyQueryHookResult = ReturnType<
  typeof useCurrentWebsiteLazyQuery
>;
export type CurrentWebsiteSuspenseQueryHookResult = ReturnType<
  typeof useCurrentWebsiteSuspenseQuery
>;
export type CurrentWebsiteQueryResult = Apollo.QueryResult<
  CurrentWebsiteQuery,
  CurrentWebsiteQueryVariables
>;
export const CurrentWelcomePageDocument = gql`
  query CurrentWelcomePage {
    currentWelcomePage {
      id
      insertedAt
      updatedAt
      author
      linkedinProfileUrl
      publisher {
        id
        user {
          id
          email
          firstName
          lastName
        }
      }
      title
      welcomeMessage
      bannerUrl
      profilePictureUrl
      signatureUrl
    }
  }
`;

/**
 * __useCurrentWelcomePageQuery__
 *
 * To run a query within a React component, call `useCurrentWelcomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentWelcomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentWelcomePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentWelcomePageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentWelcomePageQuery,
    CurrentWelcomePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentWelcomePageQuery,
    CurrentWelcomePageQueryVariables
  >(CurrentWelcomePageDocument, options);
}
export function useCurrentWelcomePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentWelcomePageQuery,
    CurrentWelcomePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentWelcomePageQuery,
    CurrentWelcomePageQueryVariables
  >(CurrentWelcomePageDocument, options);
}
export function useCurrentWelcomePageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentWelcomePageQuery,
    CurrentWelcomePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentWelcomePageQuery,
    CurrentWelcomePageQueryVariables
  >(CurrentWelcomePageDocument, options);
}
export type CurrentWelcomePageQueryHookResult = ReturnType<
  typeof useCurrentWelcomePageQuery
>;
export type CurrentWelcomePageLazyQueryHookResult = ReturnType<
  typeof useCurrentWelcomePageLazyQuery
>;
export type CurrentWelcomePageSuspenseQueryHookResult = ReturnType<
  typeof useCurrentWelcomePageSuspenseQuery
>;
export type CurrentWelcomePageQueryResult = Apollo.QueryResult<
  CurrentWelcomePageQuery,
  CurrentWelcomePageQueryVariables
>;
export const CustomDomainInstructionDocument = gql`
  query CustomDomainInstruction {
    customDomain {
      id
      customDomain
      sesDkimConfigurations {
        configured
        name
        value
        type
      }
      sesMailFromConfigurations {
        configured
        name
        value
        priority
        type
      }
      sesDmarcConfigurations {
        configured
        name
        value
        type
      }
      vercelConfiguration {
        configured
        name
        value
        type
      }
      rootDomain
      isDmarcVerified
      isDkimVerified
      isVercelDomainVerified
      isMailfromVerified
      canSendEmails
    }
  }
`;

/**
 * __useCustomDomainInstructionQuery__
 *
 * To run a query within a React component, call `useCustomDomainInstructionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomDomainInstructionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomDomainInstructionQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomDomainInstructionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CustomDomainInstructionQuery,
    CustomDomainInstructionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CustomDomainInstructionQuery,
    CustomDomainInstructionQueryVariables
  >(CustomDomainInstructionDocument, options);
}
export function useCustomDomainInstructionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomDomainInstructionQuery,
    CustomDomainInstructionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CustomDomainInstructionQuery,
    CustomDomainInstructionQueryVariables
  >(CustomDomainInstructionDocument, options);
}
export function useCustomDomainInstructionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CustomDomainInstructionQuery,
    CustomDomainInstructionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CustomDomainInstructionQuery,
    CustomDomainInstructionQueryVariables
  >(CustomDomainInstructionDocument, options);
}
export type CustomDomainInstructionQueryHookResult = ReturnType<
  typeof useCustomDomainInstructionQuery
>;
export type CustomDomainInstructionLazyQueryHookResult = ReturnType<
  typeof useCustomDomainInstructionLazyQuery
>;
export type CustomDomainInstructionSuspenseQueryHookResult = ReturnType<
  typeof useCustomDomainInstructionSuspenseQuery
>;
export type CustomDomainInstructionQueryResult = Apollo.QueryResult<
  CustomDomainInstructionQuery,
  CustomDomainInstructionQueryVariables
>;
export const CustomDomainDocument = gql`
  query CustomDomain {
    customDomain {
      id
      customDomain
      rootDomain
      isDmarcVerified
      isDkimVerified
      isVercelDomainVerified
      isMailfromVerified
      canSendEmails
    }
  }
`;

/**
 * __useCustomDomainQuery__
 *
 * To run a query within a React component, call `useCustomDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomDomainQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomDomainQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CustomDomainQuery,
    CustomDomainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomDomainQuery, CustomDomainQueryVariables>(
    CustomDomainDocument,
    options
  );
}
export function useCustomDomainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomDomainQuery,
    CustomDomainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomDomainQuery, CustomDomainQueryVariables>(
    CustomDomainDocument,
    options
  );
}
export function useCustomDomainSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CustomDomainQuery,
    CustomDomainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CustomDomainQuery, CustomDomainQueryVariables>(
    CustomDomainDocument,
    options
  );
}
export type CustomDomainQueryHookResult = ReturnType<
  typeof useCustomDomainQuery
>;
export type CustomDomainLazyQueryHookResult = ReturnType<
  typeof useCustomDomainLazyQuery
>;
export type CustomDomainSuspenseQueryHookResult = ReturnType<
  typeof useCustomDomainSuspenseQuery
>;
export type CustomDomainQueryResult = Apollo.QueryResult<
  CustomDomainQuery,
  CustomDomainQueryVariables
>;
export const CustomEmailsDocument = gql`
  query CustomEmails {
    customEmails {
      marketingEmail {
        sendFromEmail
        sendFromName
        replyToEmail
      }
      transactionalEmail {
        sendFromEmail
        sendFromName
        replyToEmail
      }
      isTransactionalEmailSameAsMarketingEmail
      customCampaignTemplate {
        lastEditedProfileUser {
          id
          user {
            lastName
            firstName
          }
          profile {
            id
          }
        }
        emailHtml
        emailJson
        templateType
        updatedAt
      }
      automatedDistributionTemplate {
        lastEditedProfileUser {
          id
          user {
            lastName
            firstName
          }
          profile {
            id
          }
        }
        emailHtml
        emailJson
        templateType
        updatedAt
      }
      manualDistributionTemplate {
        lastEditedProfileUser {
          id
          user {
            lastName
            firstName
          }
          profile {
            id
          }
        }
        emailHtml
        emailJson
        templateType
        updatedAt
      }
      newShareholderWelcomeTemplate {
        lastEditedProfileUser {
          id
          user {
            lastName
            firstName
          }
          profile {
            id
          }
        }
        emailHtml
        emailJson
        templateType
        updatedAt
      }
    }
  }
`;

/**
 * __useCustomEmailsQuery__
 *
 * To run a query within a React component, call `useCustomEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomEmailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomEmailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CustomEmailsQuery,
    CustomEmailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomEmailsQuery, CustomEmailsQueryVariables>(
    CustomEmailsDocument,
    options
  );
}
export function useCustomEmailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomEmailsQuery,
    CustomEmailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomEmailsQuery, CustomEmailsQueryVariables>(
    CustomEmailsDocument,
    options
  );
}
export function useCustomEmailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CustomEmailsQuery,
    CustomEmailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CustomEmailsQuery, CustomEmailsQueryVariables>(
    CustomEmailsDocument,
    options
  );
}
export type CustomEmailsQueryHookResult = ReturnType<
  typeof useCustomEmailsQuery
>;
export type CustomEmailsLazyQueryHookResult = ReturnType<
  typeof useCustomEmailsLazyQuery
>;
export type CustomEmailsSuspenseQueryHookResult = ReturnType<
  typeof useCustomEmailsSuspenseQuery
>;
export type CustomEmailsQueryResult = Apollo.QueryResult<
  CustomEmailsQuery,
  CustomEmailsQueryVariables
>;
export const DistributionAnnouncementsDocument = gql`
  query DistributionAnnouncements(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $options: OptionsInput
  ) {
    mediaAnnouncements(
      after: $after
      before: $before
      first: $first
      last: $last
      options: $options
    ) {
      edges {
        node {
          id
          header
          media {
            id
            emailDistributionMethod
            email {
              id
              isDraft
              sentAt
              scheduledAt
            }
          }
          rectype
          subtypes
          postedAt
          marketSensitive
          distributedSocial {
            id
            linkedinPostId
            twitterPostId
            linkedinPostUrl
            twitterPostUrl
          }
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $options)
    }
  }
`;

/**
 * __useDistributionAnnouncementsQuery__
 *
 * To run a query within a React component, call `useDistributionAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributionAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributionAnnouncementsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDistributionAnnouncementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DistributionAnnouncementsQuery,
    DistributionAnnouncementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DistributionAnnouncementsQuery,
    DistributionAnnouncementsQueryVariables
  >(DistributionAnnouncementsDocument, options);
}
export function useDistributionAnnouncementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DistributionAnnouncementsQuery,
    DistributionAnnouncementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DistributionAnnouncementsQuery,
    DistributionAnnouncementsQueryVariables
  >(DistributionAnnouncementsDocument, options);
}
export function useDistributionAnnouncementsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DistributionAnnouncementsQuery,
    DistributionAnnouncementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DistributionAnnouncementsQuery,
    DistributionAnnouncementsQueryVariables
  >(DistributionAnnouncementsDocument, options);
}
export type DistributionAnnouncementsQueryHookResult = ReturnType<
  typeof useDistributionAnnouncementsQuery
>;
export type DistributionAnnouncementsLazyQueryHookResult = ReturnType<
  typeof useDistributionAnnouncementsLazyQuery
>;
export type DistributionAnnouncementsSuspenseQueryHookResult = ReturnType<
  typeof useDistributionAnnouncementsSuspenseQuery
>;
export type DistributionAnnouncementsQueryResult = Apollo.QueryResult<
  DistributionAnnouncementsQuery,
  DistributionAnnouncementsQueryVariables
>;
export const DistributionUpdatesDocument = gql`
  query DistributionUpdates(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $options: OptionsInput
  ) {
    mediaUpdates(
      after: $after
      before: $before
      first: $first
      last: $last
      options: $options
    ) {
      edges {
        node {
          id
          media {
            id
            emailDistributionMethod
            email {
              id
              isDraft
              scheduledAt
              sentAt
            }
          }
          postedAt
          title
          includedTypes
          distributedSocial {
            id
            linkedinPostId
            twitterPostId
            linkedinPostUrl
            twitterPostUrl
          }
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $options)
    }
  }
`;

/**
 * __useDistributionUpdatesQuery__
 *
 * To run a query within a React component, call `useDistributionUpdatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributionUpdatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributionUpdatesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDistributionUpdatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DistributionUpdatesQuery,
    DistributionUpdatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DistributionUpdatesQuery,
    DistributionUpdatesQueryVariables
  >(DistributionUpdatesDocument, options);
}
export function useDistributionUpdatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DistributionUpdatesQuery,
    DistributionUpdatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DistributionUpdatesQuery,
    DistributionUpdatesQueryVariables
  >(DistributionUpdatesDocument, options);
}
export function useDistributionUpdatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DistributionUpdatesQuery,
    DistributionUpdatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DistributionUpdatesQuery,
    DistributionUpdatesQueryVariables
  >(DistributionUpdatesDocument, options);
}
export type DistributionUpdatesQueryHookResult = ReturnType<
  typeof useDistributionUpdatesQuery
>;
export type DistributionUpdatesLazyQueryHookResult = ReturnType<
  typeof useDistributionUpdatesLazyQuery
>;
export type DistributionUpdatesSuspenseQueryHookResult = ReturnType<
  typeof useDistributionUpdatesSuspenseQuery
>;
export type DistributionUpdatesQueryResult = Apollo.QueryResult<
  DistributionUpdatesQuery,
  DistributionUpdatesQueryVariables
>;
export const EmailListDocument = gql`
  query EmailList(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $options: OptionsInput
  ) {
    emailList(
      after: $after
      before: $before
      first: $first
      last: $last
      options: $options
    ) {
      edges {
        node {
          id
          fromName
          fromEmail
          campaignName
          isDraft
          lastUpdatedUser {
            user {
              firstName
              lastName
            }
          }
          updatedAt
          scheduledAt
          sentAt
          media {
            emailDistributionMethod
            mediaAnnouncement {
              id
            }
            mediaUpdate {
              id
            }
          }
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $options)
    }
  }
`;

/**
 * __useEmailListQuery__
 *
 * To run a query within a React component, call `useEmailListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useEmailListQuery(
  baseOptions?: Apollo.QueryHookOptions<EmailListQuery, EmailListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmailListQuery, EmailListQueryVariables>(
    EmailListDocument,
    options
  );
}
export function useEmailListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmailListQuery,
    EmailListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EmailListQuery, EmailListQueryVariables>(
    EmailListDocument,
    options
  );
}
export function useEmailListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EmailListQuery,
    EmailListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EmailListQuery, EmailListQueryVariables>(
    EmailListDocument,
    options
  );
}
export type EmailListQueryHookResult = ReturnType<typeof useEmailListQuery>;
export type EmailListLazyQueryHookResult = ReturnType<
  typeof useEmailListLazyQuery
>;
export type EmailListSuspenseQueryHookResult = ReturnType<
  typeof useEmailListSuspenseQuery
>;
export type EmailListQueryResult = Apollo.QueryResult<
  EmailListQuery,
  EmailListQueryVariables
>;
export const EmailRecipientsAllDocument = gql`
  query EmailRecipientsAll($emailId: ID!) {
    email(id: $emailId) {
      id
      emailRecipients {
        id
        contact {
          id
        }
      }
    }
  }
`;

/**
 * __useEmailRecipientsAllQuery__
 *
 * To run a query within a React component, call `useEmailRecipientsAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailRecipientsAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailRecipientsAllQuery({
 *   variables: {
 *      emailId: // value for 'emailId'
 *   },
 * });
 */
export function useEmailRecipientsAllQuery(
  baseOptions: Apollo.QueryHookOptions<
    EmailRecipientsAllQuery,
    EmailRecipientsAllQueryVariables
  > &
    (
      | { variables: EmailRecipientsAllQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EmailRecipientsAllQuery,
    EmailRecipientsAllQueryVariables
  >(EmailRecipientsAllDocument, options);
}
export function useEmailRecipientsAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmailRecipientsAllQuery,
    EmailRecipientsAllQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmailRecipientsAllQuery,
    EmailRecipientsAllQueryVariables
  >(EmailRecipientsAllDocument, options);
}
export function useEmailRecipientsAllSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EmailRecipientsAllQuery,
    EmailRecipientsAllQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EmailRecipientsAllQuery,
    EmailRecipientsAllQueryVariables
  >(EmailRecipientsAllDocument, options);
}
export type EmailRecipientsAllQueryHookResult = ReturnType<
  typeof useEmailRecipientsAllQuery
>;
export type EmailRecipientsAllLazyQueryHookResult = ReturnType<
  typeof useEmailRecipientsAllLazyQuery
>;
export type EmailRecipientsAllSuspenseQueryHookResult = ReturnType<
  typeof useEmailRecipientsAllSuspenseQuery
>;
export type EmailRecipientsAllQueryResult = Apollo.QueryResult<
  EmailRecipientsAllQuery,
  EmailRecipientsAllQueryVariables
>;
export const EmailRecipientsDocument = gql`
  query EmailRecipients(
    $emailId: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $options: OptionsInput
  ) {
    emailRecipients(
      after: $after
      before: $before
      first: $first
      last: $last
      options: $options
      emailId: $emailId
    ) {
      edges {
        node {
          id
          contact {
            id
            email
            firstName
            lastName
            importedAt
            investor {
              id
              firstName
            }
            shareholdings {
              id
              accountName
            }
            commsUnsubscribes {
              id
              scope
            }
            globalUnsubscribe {
              id
            }
          }
          sentAt
          trackingEmail {
            events {
              eventType
            }
          }
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $options, emailId: $emailId)
    }
  }
`;

/**
 * __useEmailRecipientsQuery__
 *
 * To run a query within a React component, call `useEmailRecipientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailRecipientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailRecipientsQuery({
 *   variables: {
 *      emailId: // value for 'emailId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useEmailRecipientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    EmailRecipientsQuery,
    EmailRecipientsQueryVariables
  > &
    (
      | { variables: EmailRecipientsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmailRecipientsQuery, EmailRecipientsQueryVariables>(
    EmailRecipientsDocument,
    options
  );
}
export function useEmailRecipientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmailRecipientsQuery,
    EmailRecipientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmailRecipientsQuery,
    EmailRecipientsQueryVariables
  >(EmailRecipientsDocument, options);
}
export function useEmailRecipientsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EmailRecipientsQuery,
    EmailRecipientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EmailRecipientsQuery,
    EmailRecipientsQueryVariables
  >(EmailRecipientsDocument, options);
}
export type EmailRecipientsQueryHookResult = ReturnType<
  typeof useEmailRecipientsQuery
>;
export type EmailRecipientsLazyQueryHookResult = ReturnType<
  typeof useEmailRecipientsLazyQuery
>;
export type EmailRecipientsSuspenseQueryHookResult = ReturnType<
  typeof useEmailRecipientsSuspenseQuery
>;
export type EmailRecipientsQueryResult = Apollo.QueryResult<
  EmailRecipientsQuery,
  EmailRecipientsQueryVariables
>;
export const EmailReputationDataDocument = gql`
  query EmailReputationData {
    emailReputationData {
      reputation
      bounces
      sends
      clicks
      complaints
      opens
      unsubscribes
    }
  }
`;

/**
 * __useEmailReputationDataQuery__
 *
 * To run a query within a React component, call `useEmailReputationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailReputationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailReputationDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmailReputationDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EmailReputationDataQuery,
    EmailReputationDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EmailReputationDataQuery,
    EmailReputationDataQueryVariables
  >(EmailReputationDataDocument, options);
}
export function useEmailReputationDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmailReputationDataQuery,
    EmailReputationDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmailReputationDataQuery,
    EmailReputationDataQueryVariables
  >(EmailReputationDataDocument, options);
}
export function useEmailReputationDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EmailReputationDataQuery,
    EmailReputationDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EmailReputationDataQuery,
    EmailReputationDataQueryVariables
  >(EmailReputationDataDocument, options);
}
export type EmailReputationDataQueryHookResult = ReturnType<
  typeof useEmailReputationDataQuery
>;
export type EmailReputationDataLazyQueryHookResult = ReturnType<
  typeof useEmailReputationDataLazyQuery
>;
export type EmailReputationDataSuspenseQueryHookResult = ReturnType<
  typeof useEmailReputationDataSuspenseQuery
>;
export type EmailReputationDataQueryResult = Apollo.QueryResult<
  EmailReputationDataQuery,
  EmailReputationDataQueryVariables
>;
export const EmailReputationEmailStatsDocument = gql`
  query EmailReputationEmailStats($type: String!) {
    emailReputationEmailStats(type: $type) {
      identifier
      subject
      type
      method
      sends
      opens
      clicks
      bounces
      complaints
      unsubscribes
      insertedAt
      sentAt
    }
  }
`;

/**
 * __useEmailReputationEmailStatsQuery__
 *
 * To run a query within a React component, call `useEmailReputationEmailStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailReputationEmailStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailReputationEmailStatsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useEmailReputationEmailStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    EmailReputationEmailStatsQuery,
    EmailReputationEmailStatsQueryVariables
  > &
    (
      | { variables: EmailReputationEmailStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EmailReputationEmailStatsQuery,
    EmailReputationEmailStatsQueryVariables
  >(EmailReputationEmailStatsDocument, options);
}
export function useEmailReputationEmailStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmailReputationEmailStatsQuery,
    EmailReputationEmailStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmailReputationEmailStatsQuery,
    EmailReputationEmailStatsQueryVariables
  >(EmailReputationEmailStatsDocument, options);
}
export function useEmailReputationEmailStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EmailReputationEmailStatsQuery,
    EmailReputationEmailStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EmailReputationEmailStatsQuery,
    EmailReputationEmailStatsQueryVariables
  >(EmailReputationEmailStatsDocument, options);
}
export type EmailReputationEmailStatsQueryHookResult = ReturnType<
  typeof useEmailReputationEmailStatsQuery
>;
export type EmailReputationEmailStatsLazyQueryHookResult = ReturnType<
  typeof useEmailReputationEmailStatsLazyQuery
>;
export type EmailReputationEmailStatsSuspenseQueryHookResult = ReturnType<
  typeof useEmailReputationEmailStatsSuspenseQuery
>;
export type EmailReputationEmailStatsQueryResult = Apollo.QueryResult<
  EmailReputationEmailStatsQuery,
  EmailReputationEmailStatsQueryVariables
>;
export const EmailReputationOverTimeDocument = gql`
  query EmailReputationOverTime {
    emailReputationOverTime {
      reputation
      sends
      date
      emails {
        identifier
        subject
        type
      }
    }
  }
`;

/**
 * __useEmailReputationOverTimeQuery__
 *
 * To run a query within a React component, call `useEmailReputationOverTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailReputationOverTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailReputationOverTimeQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmailReputationOverTimeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EmailReputationOverTimeQuery,
    EmailReputationOverTimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EmailReputationOverTimeQuery,
    EmailReputationOverTimeQueryVariables
  >(EmailReputationOverTimeDocument, options);
}
export function useEmailReputationOverTimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmailReputationOverTimeQuery,
    EmailReputationOverTimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmailReputationOverTimeQuery,
    EmailReputationOverTimeQueryVariables
  >(EmailReputationOverTimeDocument, options);
}
export function useEmailReputationOverTimeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EmailReputationOverTimeQuery,
    EmailReputationOverTimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EmailReputationOverTimeQuery,
    EmailReputationOverTimeQueryVariables
  >(EmailReputationOverTimeDocument, options);
}
export type EmailReputationOverTimeQueryHookResult = ReturnType<
  typeof useEmailReputationOverTimeQuery
>;
export type EmailReputationOverTimeLazyQueryHookResult = ReturnType<
  typeof useEmailReputationOverTimeLazyQuery
>;
export type EmailReputationOverTimeSuspenseQueryHookResult = ReturnType<
  typeof useEmailReputationOverTimeSuspenseQuery
>;
export type EmailReputationOverTimeQueryResult = Apollo.QueryResult<
  EmailReputationOverTimeQuery,
  EmailReputationOverTimeQueryVariables
>;
export const EmailDocument = gql`
  query Email($id: ID!) {
    email(id: $id) {
      id
      fromName
      fromEmail
      campaignName
      isDraft
      scheduledAt
      sentAt
      lastUpdatedUser {
        id
        user {
          firstName
        }
      }
      fromName
      fromEmail
      subject
      emailHtml
      emailJson
      isWelcomeEmail
      media {
        id
        mediaAnnouncement {
          id
        }
        mediaUpdate {
          id
        }
      }
      sendToAllContacts
      sendToContacts {
        id
        email
        firstName
        lastName
      }
      sendToDynamicLists {
        id
        name
      }
      doNotSendToContacts {
        id
        email
        firstName
        lastName
      }
      doNotSendToDynamicLists {
        id
        name
      }
      totalClick: stats(type: CLICK)
      totalDelivery: stats(type: DELIVERY)
      totalOpen: stats(type: OPEN)
      totalUnsubscribed: stats(type: UNSUBSCRIBED)
      totalComplaints: stats(type: COMPLAINT)
      totalBounced: stats(type: BOUNCE)
      total: stats(type: TOTAL)
    }
  }
`;

/**
 * __useEmailQuery__
 *
 * To run a query within a React component, call `useEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmailQuery(
  baseOptions: Apollo.QueryHookOptions<EmailQuery, EmailQueryVariables> &
    ({ variables: EmailQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmailQuery, EmailQueryVariables>(
    EmailDocument,
    options
  );
}
export function useEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EmailQuery, EmailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EmailQuery, EmailQueryVariables>(
    EmailDocument,
    options
  );
}
export function useEmailSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<EmailQuery, EmailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EmailQuery, EmailQueryVariables>(
    EmailDocument,
    options
  );
}
export type EmailQueryHookResult = ReturnType<typeof useEmailQuery>;
export type EmailLazyQueryHookResult = ReturnType<typeof useEmailLazyQuery>;
export type EmailSuspenseQueryHookResult = ReturnType<
  typeof useEmailSuspenseQuery
>;
export type EmailQueryResult = Apollo.QueryResult<
  EmailQuery,
  EmailQueryVariables
>;
export const EngagementAnalyticsAnnouncementsDocument = gql`
  query EngagementAnalyticsAnnouncements(
    $startDate: NaiveDateTime!
    $endDate: NaiveDateTime!
  ) {
    announcementsReleasedCount(startDate: $startDate, endDate: $endDate)
    announcementsAnalyticsStats(startDate: $startDate, endDate: $endDate) {
      totalLikes
      totalLikesDifference
      totalQuestions
      totalQuestionsDifference
      totalSurveyResponses
      totalSurveyResponsesDifference
      totalViews
      totalViewsDifference
    }
    announcementEmailDistributionStatistics(
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      announcements {
        id
        header
        summary
        videoUrl
      }
      clicks
      sends
      ctr
      opens
      openRate
      date
    }
    topThreeViewedAnnouncements(startDate: $startDate, endDate: $endDate) {
      id
      summary
      videoUrl
      socialVideoUrl
      header
      totalViewCount
      totalViewCountFromTimePeriod(startDate: $startDate, endDate: $endDate)
      likes
      totalQuestionCount
      totalSurveyResponses
      distributedSocial {
        linkedinPostId
        linkedinPostedAt
        twitterPostId
        twitterPostedAt
      }
      email {
        sentAt
        subject
      }
    }
    announcementReachAndEngagement(startDate: $startDate, endDate: $endDate) {
      date
      announcements {
        id
        header
        summary
        videoUrl
      }
      totalViews
      totalLikes
      totalQuestions
      totalSurveyResponses
    }
  }
`;

/**
 * __useEngagementAnalyticsAnnouncementsQuery__
 *
 * To run a query within a React component, call `useEngagementAnalyticsAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEngagementAnalyticsAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEngagementAnalyticsAnnouncementsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useEngagementAnalyticsAnnouncementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    EngagementAnalyticsAnnouncementsQuery,
    EngagementAnalyticsAnnouncementsQueryVariables
  > &
    (
      | {
          variables: EngagementAnalyticsAnnouncementsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EngagementAnalyticsAnnouncementsQuery,
    EngagementAnalyticsAnnouncementsQueryVariables
  >(EngagementAnalyticsAnnouncementsDocument, options);
}
export function useEngagementAnalyticsAnnouncementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EngagementAnalyticsAnnouncementsQuery,
    EngagementAnalyticsAnnouncementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EngagementAnalyticsAnnouncementsQuery,
    EngagementAnalyticsAnnouncementsQueryVariables
  >(EngagementAnalyticsAnnouncementsDocument, options);
}
export function useEngagementAnalyticsAnnouncementsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EngagementAnalyticsAnnouncementsQuery,
    EngagementAnalyticsAnnouncementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EngagementAnalyticsAnnouncementsQuery,
    EngagementAnalyticsAnnouncementsQueryVariables
  >(EngagementAnalyticsAnnouncementsDocument, options);
}
export type EngagementAnalyticsAnnouncementsQueryHookResult = ReturnType<
  typeof useEngagementAnalyticsAnnouncementsQuery
>;
export type EngagementAnalyticsAnnouncementsLazyQueryHookResult = ReturnType<
  typeof useEngagementAnalyticsAnnouncementsLazyQuery
>;
export type EngagementAnalyticsAnnouncementsSuspenseQueryHookResult =
  ReturnType<typeof useEngagementAnalyticsAnnouncementsSuspenseQuery>;
export type EngagementAnalyticsAnnouncementsQueryResult = Apollo.QueryResult<
  EngagementAnalyticsAnnouncementsQuery,
  EngagementAnalyticsAnnouncementsQueryVariables
>;
export const EngagementAnalyticsInvestorHubDocument = gql`
  query EngagementAnalyticsInvestorHub(
    $startDate: NaiveDateTime!
    $endDate: NaiveDateTime!
  ) {
    investorHubAnalytics(startDate: $startDate, endDate: $endDate) {
      signupsDifference
      totalSignups
      totalUniqueVisitors
      totalViews
      totalViewsDifference
      uniqueVisitorsDifference
    }
    investorHubMostEngagedInvestors(startDate: $startDate, endDate: $endDate) {
      investorUser {
        contact {
          id
          firstName
          lastName
          shareholderStatus
          hnwStatus
          hnwIdentifiedAt
          shareholdings {
            hasParticipatedInPlacement
            hasParticipatedInSpp
            hnwBehaviour
            hnwIdentifiedAt
            id
            shareCount
            shareCountRank
          }
        }
        username
      }
      likes
      questions
      surveyResponses
    }
    investorHubSignupBreakdown(startDate: $startDate, endDate: $endDate) {
      existingShareholders
      leads
      pastShareholders
      nominatedShareholders
    }
    investorHubPagePerformance(startDate: $startDate, endDate: $endDate) {
      name
      unique
      total
    }
    investorHubEngagement(startDate: $startDate, endDate: $endDate) {
      close
      currency
      date
      signups
      totalViews
      totalUniqueVisitors
      announcements {
        announcementId
        header
      }
      updates {
        updateId
        title
      }
      campaigns {
        campaignId
        name
      }
    }
  }
`;

/**
 * __useEngagementAnalyticsInvestorHubQuery__
 *
 * To run a query within a React component, call `useEngagementAnalyticsInvestorHubQuery` and pass it any options that fit your needs.
 * When your component renders, `useEngagementAnalyticsInvestorHubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEngagementAnalyticsInvestorHubQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useEngagementAnalyticsInvestorHubQuery(
  baseOptions: Apollo.QueryHookOptions<
    EngagementAnalyticsInvestorHubQuery,
    EngagementAnalyticsInvestorHubQueryVariables
  > &
    (
      | {
          variables: EngagementAnalyticsInvestorHubQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EngagementAnalyticsInvestorHubQuery,
    EngagementAnalyticsInvestorHubQueryVariables
  >(EngagementAnalyticsInvestorHubDocument, options);
}
export function useEngagementAnalyticsInvestorHubLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EngagementAnalyticsInvestorHubQuery,
    EngagementAnalyticsInvestorHubQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EngagementAnalyticsInvestorHubQuery,
    EngagementAnalyticsInvestorHubQueryVariables
  >(EngagementAnalyticsInvestorHubDocument, options);
}
export function useEngagementAnalyticsInvestorHubSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EngagementAnalyticsInvestorHubQuery,
    EngagementAnalyticsInvestorHubQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EngagementAnalyticsInvestorHubQuery,
    EngagementAnalyticsInvestorHubQueryVariables
  >(EngagementAnalyticsInvestorHubDocument, options);
}
export type EngagementAnalyticsInvestorHubQueryHookResult = ReturnType<
  typeof useEngagementAnalyticsInvestorHubQuery
>;
export type EngagementAnalyticsInvestorHubLazyQueryHookResult = ReturnType<
  typeof useEngagementAnalyticsInvestorHubLazyQuery
>;
export type EngagementAnalyticsInvestorHubSuspenseQueryHookResult = ReturnType<
  typeof useEngagementAnalyticsInvestorHubSuspenseQuery
>;
export type EngagementAnalyticsInvestorHubQueryResult = Apollo.QueryResult<
  EngagementAnalyticsInvestorHubQuery,
  EngagementAnalyticsInvestorHubQueryVariables
>;
export const EngagementAnalyticsOverviewDocument = gql`
  query EngagementAnalyticsOverview(
    $startDate: NaiveDateTime!
    $endDate: NaiveDateTime!
  ) {
    engagementAnalyticsOverview(startDate: $startDate, endDate: $endDate) {
      totalLeads
      totalNominatedShareholders
      totalConvertedShareholders
      totalUniqueVisitors
      uniqueVisitorsDifference
      leadsDifference
      convertedShareholdersDifference
      audiencesBreakdown {
        date
        totalLeads
        totalUniqueVisitors
        totalConvertedShareholders
        totalNominatedShareholders
        conversionRateSma
        announcements {
          date
          header
          announcementId
          companyProfileId
        }
        campaigns {
          date
          campaignId
          name
        }
        updates {
          date
          updateId
          companyProfileId
          title
        }
      }
    }
  }
`;

/**
 * __useEngagementAnalyticsOverviewQuery__
 *
 * To run a query within a React component, call `useEngagementAnalyticsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useEngagementAnalyticsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEngagementAnalyticsOverviewQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useEngagementAnalyticsOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    EngagementAnalyticsOverviewQuery,
    EngagementAnalyticsOverviewQueryVariables
  > &
    (
      | { variables: EngagementAnalyticsOverviewQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EngagementAnalyticsOverviewQuery,
    EngagementAnalyticsOverviewQueryVariables
  >(EngagementAnalyticsOverviewDocument, options);
}
export function useEngagementAnalyticsOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EngagementAnalyticsOverviewQuery,
    EngagementAnalyticsOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EngagementAnalyticsOverviewQuery,
    EngagementAnalyticsOverviewQueryVariables
  >(EngagementAnalyticsOverviewDocument, options);
}
export function useEngagementAnalyticsOverviewSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EngagementAnalyticsOverviewQuery,
    EngagementAnalyticsOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EngagementAnalyticsOverviewQuery,
    EngagementAnalyticsOverviewQueryVariables
  >(EngagementAnalyticsOverviewDocument, options);
}
export type EngagementAnalyticsOverviewQueryHookResult = ReturnType<
  typeof useEngagementAnalyticsOverviewQuery
>;
export type EngagementAnalyticsOverviewLazyQueryHookResult = ReturnType<
  typeof useEngagementAnalyticsOverviewLazyQuery
>;
export type EngagementAnalyticsOverviewSuspenseQueryHookResult = ReturnType<
  typeof useEngagementAnalyticsOverviewSuspenseQuery
>;
export type EngagementAnalyticsOverviewQueryResult = Apollo.QueryResult<
  EngagementAnalyticsOverviewQuery,
  EngagementAnalyticsOverviewQueryVariables
>;
export const EngagementAnalyticsTotalUpdatesDocument = gql`
  query EngagementAnalyticsTotalUpdates {
    totalUpdates
  }
`;

/**
 * __useEngagementAnalyticsTotalUpdatesQuery__
 *
 * To run a query within a React component, call `useEngagementAnalyticsTotalUpdatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEngagementAnalyticsTotalUpdatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEngagementAnalyticsTotalUpdatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEngagementAnalyticsTotalUpdatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EngagementAnalyticsTotalUpdatesQuery,
    EngagementAnalyticsTotalUpdatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EngagementAnalyticsTotalUpdatesQuery,
    EngagementAnalyticsTotalUpdatesQueryVariables
  >(EngagementAnalyticsTotalUpdatesDocument, options);
}
export function useEngagementAnalyticsTotalUpdatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EngagementAnalyticsTotalUpdatesQuery,
    EngagementAnalyticsTotalUpdatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EngagementAnalyticsTotalUpdatesQuery,
    EngagementAnalyticsTotalUpdatesQueryVariables
  >(EngagementAnalyticsTotalUpdatesDocument, options);
}
export function useEngagementAnalyticsTotalUpdatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EngagementAnalyticsTotalUpdatesQuery,
    EngagementAnalyticsTotalUpdatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EngagementAnalyticsTotalUpdatesQuery,
    EngagementAnalyticsTotalUpdatesQueryVariables
  >(EngagementAnalyticsTotalUpdatesDocument, options);
}
export type EngagementAnalyticsTotalUpdatesQueryHookResult = ReturnType<
  typeof useEngagementAnalyticsTotalUpdatesQuery
>;
export type EngagementAnalyticsTotalUpdatesLazyQueryHookResult = ReturnType<
  typeof useEngagementAnalyticsTotalUpdatesLazyQuery
>;
export type EngagementAnalyticsTotalUpdatesSuspenseQueryHookResult = ReturnType<
  typeof useEngagementAnalyticsTotalUpdatesSuspenseQuery
>;
export type EngagementAnalyticsTotalUpdatesQueryResult = Apollo.QueryResult<
  EngagementAnalyticsTotalUpdatesQuery,
  EngagementAnalyticsTotalUpdatesQueryVariables
>;
export const EngagementAnalyticsUpdatesDocument = gql`
  query EngagementAnalyticsUpdates(
    $startDate: NaiveDateTime!
    $endDate: NaiveDateTime!
  ) {
    updatesReleasedCount(startDate: $startDate, endDate: $endDate)
    updatesAnalyticsStats(startDate: $startDate, endDate: $endDate) {
      totalLikes
      totalLikesDifference
      totalQuestions
      totalQuestionsDifference
      totalSurveyResponses
      totalSurveyResponsesDifference
      totalViews
      totalViewsDifference
    }
    topThreeViewedUpdates(startDate: $startDate, endDate: $endDate) {
      id
      title
      includedTypes
      likes
      totalViewCount
      totalQuestionCount
      totalSurveyResponses
      distributedSocial {
        linkedinPostId
        linkedinPostedAt
        twitterPostId
        twitterPostedAt
      }
      email {
        sentAt
        subject
      }
    }
    updatesReachAndEngagement(startDate: $startDate, endDate: $endDate) {
      date
      updates {
        id
        title
        includedTypes
      }
      totalViews
      totalLikes
      totalQuestions
      totalSurveyResponses
    }
    updatesEmailDistributionStatistics(
      startDate: $startDate
      endDate: $endDate
    ) {
      updates {
        id
        title
        includedTypes
      }
      opens
      clicks
      ctr
      openRate
      date
      sends
    }
  }
`;

/**
 * __useEngagementAnalyticsUpdatesQuery__
 *
 * To run a query within a React component, call `useEngagementAnalyticsUpdatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEngagementAnalyticsUpdatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEngagementAnalyticsUpdatesQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useEngagementAnalyticsUpdatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    EngagementAnalyticsUpdatesQuery,
    EngagementAnalyticsUpdatesQueryVariables
  > &
    (
      | { variables: EngagementAnalyticsUpdatesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EngagementAnalyticsUpdatesQuery,
    EngagementAnalyticsUpdatesQueryVariables
  >(EngagementAnalyticsUpdatesDocument, options);
}
export function useEngagementAnalyticsUpdatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EngagementAnalyticsUpdatesQuery,
    EngagementAnalyticsUpdatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EngagementAnalyticsUpdatesQuery,
    EngagementAnalyticsUpdatesQueryVariables
  >(EngagementAnalyticsUpdatesDocument, options);
}
export function useEngagementAnalyticsUpdatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EngagementAnalyticsUpdatesQuery,
    EngagementAnalyticsUpdatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EngagementAnalyticsUpdatesQuery,
    EngagementAnalyticsUpdatesQueryVariables
  >(EngagementAnalyticsUpdatesDocument, options);
}
export type EngagementAnalyticsUpdatesQueryHookResult = ReturnType<
  typeof useEngagementAnalyticsUpdatesQuery
>;
export type EngagementAnalyticsUpdatesLazyQueryHookResult = ReturnType<
  typeof useEngagementAnalyticsUpdatesLazyQuery
>;
export type EngagementAnalyticsUpdatesSuspenseQueryHookResult = ReturnType<
  typeof useEngagementAnalyticsUpdatesSuspenseQuery
>;
export type EngagementAnalyticsUpdatesQueryResult = Apollo.QueryResult<
  EngagementAnalyticsUpdatesQuery,
  EngagementAnalyticsUpdatesQueryVariables
>;
export const EstimateEmailRecipientsCountDocument = gql`
  query EstimateEmailRecipientsCount($emailId: ID!) {
    estimateEmailRecipientsCount(emailId: $emailId)
  }
`;

/**
 * __useEstimateEmailRecipientsCountQuery__
 *
 * To run a query within a React component, call `useEstimateEmailRecipientsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimateEmailRecipientsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimateEmailRecipientsCountQuery({
 *   variables: {
 *      emailId: // value for 'emailId'
 *   },
 * });
 */
export function useEstimateEmailRecipientsCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    EstimateEmailRecipientsCountQuery,
    EstimateEmailRecipientsCountQueryVariables
  > &
    (
      | {
          variables: EstimateEmailRecipientsCountQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EstimateEmailRecipientsCountQuery,
    EstimateEmailRecipientsCountQueryVariables
  >(EstimateEmailRecipientsCountDocument, options);
}
export function useEstimateEmailRecipientsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EstimateEmailRecipientsCountQuery,
    EstimateEmailRecipientsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EstimateEmailRecipientsCountQuery,
    EstimateEmailRecipientsCountQueryVariables
  >(EstimateEmailRecipientsCountDocument, options);
}
export function useEstimateEmailRecipientsCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EstimateEmailRecipientsCountQuery,
    EstimateEmailRecipientsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EstimateEmailRecipientsCountQuery,
    EstimateEmailRecipientsCountQueryVariables
  >(EstimateEmailRecipientsCountDocument, options);
}
export type EstimateEmailRecipientsCountQueryHookResult = ReturnType<
  typeof useEstimateEmailRecipientsCountQuery
>;
export type EstimateEmailRecipientsCountLazyQueryHookResult = ReturnType<
  typeof useEstimateEmailRecipientsCountLazyQuery
>;
export type EstimateEmailRecipientsCountSuspenseQueryHookResult = ReturnType<
  typeof useEstimateEmailRecipientsCountSuspenseQuery
>;
export type EstimateEmailRecipientsCountQueryResult = Apollo.QueryResult<
  EstimateEmailRecipientsCountQuery,
  EstimateEmailRecipientsCountQueryVariables
>;
export const GetColorPaletteDocument = gql`
  query GetColorPalette($hexCode: String!) {
    getColorPalette(hexCode: $hexCode) {
      primary {
        hsl
        shades {
          s10
          s20
          s30
          s40
          s50
          s60
          s70
          s80
          s90
        }
        tints {
          t10
          t20
          t30
          t40
          t50
          t60
          t70
          t80
          t90
        }
      }
    }
  }
`;

/**
 * __useGetColorPaletteQuery__
 *
 * To run a query within a React component, call `useGetColorPaletteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetColorPaletteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetColorPaletteQuery({
 *   variables: {
 *      hexCode: // value for 'hexCode'
 *   },
 * });
 */
export function useGetColorPaletteQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetColorPaletteQuery,
    GetColorPaletteQueryVariables
  > &
    (
      | { variables: GetColorPaletteQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetColorPaletteQuery, GetColorPaletteQueryVariables>(
    GetColorPaletteDocument,
    options
  );
}
export function useGetColorPaletteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetColorPaletteQuery,
    GetColorPaletteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetColorPaletteQuery,
    GetColorPaletteQueryVariables
  >(GetColorPaletteDocument, options);
}
export function useGetColorPaletteSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetColorPaletteQuery,
    GetColorPaletteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetColorPaletteQuery,
    GetColorPaletteQueryVariables
  >(GetColorPaletteDocument, options);
}
export type GetColorPaletteQueryHookResult = ReturnType<
  typeof useGetColorPaletteQuery
>;
export type GetColorPaletteLazyQueryHookResult = ReturnType<
  typeof useGetColorPaletteLazyQuery
>;
export type GetColorPaletteSuspenseQueryHookResult = ReturnType<
  typeof useGetColorPaletteSuspenseQuery
>;
export type GetColorPaletteQueryResult = Apollo.QueryResult<
  GetColorPaletteQuery,
  GetColorPaletteQueryVariables
>;
export const GrowthRatioTimeseriesDocument = gql`
  query GrowthRatioTimeseries($startDate: Date!, $endDate: Date!) {
    growthRatioTimeseries(startDate: $startDate, endDate: $endDate) {
      id
      date
      new
      returning
      churned
      growthRatio
    }
  }
`;

/**
 * __useGrowthRatioTimeseriesQuery__
 *
 * To run a query within a React component, call `useGrowthRatioTimeseriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrowthRatioTimeseriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGrowthRatioTimeseriesQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGrowthRatioTimeseriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GrowthRatioTimeseriesQuery,
    GrowthRatioTimeseriesQueryVariables
  > &
    (
      | { variables: GrowthRatioTimeseriesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GrowthRatioTimeseriesQuery,
    GrowthRatioTimeseriesQueryVariables
  >(GrowthRatioTimeseriesDocument, options);
}
export function useGrowthRatioTimeseriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GrowthRatioTimeseriesQuery,
    GrowthRatioTimeseriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GrowthRatioTimeseriesQuery,
    GrowthRatioTimeseriesQueryVariables
  >(GrowthRatioTimeseriesDocument, options);
}
export function useGrowthRatioTimeseriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GrowthRatioTimeseriesQuery,
    GrowthRatioTimeseriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GrowthRatioTimeseriesQuery,
    GrowthRatioTimeseriesQueryVariables
  >(GrowthRatioTimeseriesDocument, options);
}
export type GrowthRatioTimeseriesQueryHookResult = ReturnType<
  typeof useGrowthRatioTimeseriesQuery
>;
export type GrowthRatioTimeseriesLazyQueryHookResult = ReturnType<
  typeof useGrowthRatioTimeseriesLazyQuery
>;
export type GrowthRatioTimeseriesSuspenseQueryHookResult = ReturnType<
  typeof useGrowthRatioTimeseriesSuspenseQuery
>;
export type GrowthRatioTimeseriesQueryResult = Apollo.QueryResult<
  GrowthRatioTimeseriesQuery,
  GrowthRatioTimeseriesQueryVariables
>;
export const HubBuilderIndexPageHubStatsDocument = gql`
  query HubBuilderIndexPageHubStats {
    hubStats {
      signUpsLastThirtyDays
      uniqueVisitorsLastThirtyDays
      pendingQualifiedInvestors
    }
  }
`;

/**
 * __useHubBuilderIndexPageHubStatsQuery__
 *
 * To run a query within a React component, call `useHubBuilderIndexPageHubStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHubBuilderIndexPageHubStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHubBuilderIndexPageHubStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHubBuilderIndexPageHubStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HubBuilderIndexPageHubStatsQuery,
    HubBuilderIndexPageHubStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HubBuilderIndexPageHubStatsQuery,
    HubBuilderIndexPageHubStatsQueryVariables
  >(HubBuilderIndexPageHubStatsDocument, options);
}
export function useHubBuilderIndexPageHubStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HubBuilderIndexPageHubStatsQuery,
    HubBuilderIndexPageHubStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HubBuilderIndexPageHubStatsQuery,
    HubBuilderIndexPageHubStatsQueryVariables
  >(HubBuilderIndexPageHubStatsDocument, options);
}
export function useHubBuilderIndexPageHubStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    HubBuilderIndexPageHubStatsQuery,
    HubBuilderIndexPageHubStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HubBuilderIndexPageHubStatsQuery,
    HubBuilderIndexPageHubStatsQueryVariables
  >(HubBuilderIndexPageHubStatsDocument, options);
}
export type HubBuilderIndexPageHubStatsQueryHookResult = ReturnType<
  typeof useHubBuilderIndexPageHubStatsQuery
>;
export type HubBuilderIndexPageHubStatsLazyQueryHookResult = ReturnType<
  typeof useHubBuilderIndexPageHubStatsLazyQuery
>;
export type HubBuilderIndexPageHubStatsSuspenseQueryHookResult = ReturnType<
  typeof useHubBuilderIndexPageHubStatsSuspenseQuery
>;
export type HubBuilderIndexPageHubStatsQueryResult = Apollo.QueryResult<
  HubBuilderIndexPageHubStatsQuery,
  HubBuilderIndexPageHubStatsQueryVariables
>;
export const InteractiveMediaComparisonStatsDocument = gql`
  query InteractiveMediaComparisonStats {
    interactiveMediaStats {
      totalActiveQuestions
      totalQuestionsThisWeek
      totalQuestionsLastWeek
      totalQuestions
      totalAnnouncementsLastMonth
      totalAnnouncementsThisMonth
      totalAnnouncementsThisYear
      totalUpdatesLastMonth
      totalUpdatesThisMonth
      totalUpdatesThisYear
    }
  }
`;

/**
 * __useInteractiveMediaComparisonStatsQuery__
 *
 * To run a query within a React component, call `useInteractiveMediaComparisonStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInteractiveMediaComparisonStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInteractiveMediaComparisonStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInteractiveMediaComparisonStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InteractiveMediaComparisonStatsQuery,
    InteractiveMediaComparisonStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InteractiveMediaComparisonStatsQuery,
    InteractiveMediaComparisonStatsQueryVariables
  >(InteractiveMediaComparisonStatsDocument, options);
}
export function useInteractiveMediaComparisonStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InteractiveMediaComparisonStatsQuery,
    InteractiveMediaComparisonStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InteractiveMediaComparisonStatsQuery,
    InteractiveMediaComparisonStatsQueryVariables
  >(InteractiveMediaComparisonStatsDocument, options);
}
export function useInteractiveMediaComparisonStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    InteractiveMediaComparisonStatsQuery,
    InteractiveMediaComparisonStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InteractiveMediaComparisonStatsQuery,
    InteractiveMediaComparisonStatsQueryVariables
  >(InteractiveMediaComparisonStatsDocument, options);
}
export type InteractiveMediaComparisonStatsQueryHookResult = ReturnType<
  typeof useInteractiveMediaComparisonStatsQuery
>;
export type InteractiveMediaComparisonStatsLazyQueryHookResult = ReturnType<
  typeof useInteractiveMediaComparisonStatsLazyQuery
>;
export type InteractiveMediaComparisonStatsSuspenseQueryHookResult = ReturnType<
  typeof useInteractiveMediaComparisonStatsSuspenseQuery
>;
export type InteractiveMediaComparisonStatsQueryResult = Apollo.QueryResult<
  InteractiveMediaComparisonStatsQuery,
  InteractiveMediaComparisonStatsQueryVariables
>;
export const InteractiveMediaStatsDocument = gql`
  query InteractiveMediaStats {
    interactiveMediaStats {
      totalActiveQuestions
      totalLikesLastWeek
      totalLikesThisWeek
      totalLikes
      totalQuestionsThisWeek
      totalQuestionsLastWeek
      totalQuestions
      totalSurveyResponsesLastWeek
      totalSurveyResponsesThisWeek
      totalSurveyResponses
      totalAnnouncementsLastWeek
      totalAnnouncementsThisWeek
      totalAnnouncements
      totalUpdatesLastWeek
      totalUpdatesThisWeek
      totalUpdates
    }
  }
`;

/**
 * __useInteractiveMediaStatsQuery__
 *
 * To run a query within a React component, call `useInteractiveMediaStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInteractiveMediaStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInteractiveMediaStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInteractiveMediaStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InteractiveMediaStatsQuery,
    InteractiveMediaStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InteractiveMediaStatsQuery,
    InteractiveMediaStatsQueryVariables
  >(InteractiveMediaStatsDocument, options);
}
export function useInteractiveMediaStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InteractiveMediaStatsQuery,
    InteractiveMediaStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InteractiveMediaStatsQuery,
    InteractiveMediaStatsQueryVariables
  >(InteractiveMediaStatsDocument, options);
}
export function useInteractiveMediaStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    InteractiveMediaStatsQuery,
    InteractiveMediaStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InteractiveMediaStatsQuery,
    InteractiveMediaStatsQueryVariables
  >(InteractiveMediaStatsDocument, options);
}
export type InteractiveMediaStatsQueryHookResult = ReturnType<
  typeof useInteractiveMediaStatsQuery
>;
export type InteractiveMediaStatsLazyQueryHookResult = ReturnType<
  typeof useInteractiveMediaStatsLazyQuery
>;
export type InteractiveMediaStatsSuspenseQueryHookResult = ReturnType<
  typeof useInteractiveMediaStatsSuspenseQuery
>;
export type InteractiveMediaStatsQueryResult = Apollo.QueryResult<
  InteractiveMediaStatsQuery,
  InteractiveMediaStatsQueryVariables
>;
export const InvestorActivityStatsDocument = gql`
  query InvestorActivityStats($id: ID!) {
    investorActivityStats(id: $id) {
      id
      announcementsViewed
      likes
      questionsAsked
      surveyResponses
      updatesViewed
      followers
    }
  }
`;

/**
 * __useInvestorActivityStatsQuery__
 *
 * To run a query within a React component, call `useInvestorActivityStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorActivityStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorActivityStatsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvestorActivityStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    InvestorActivityStatsQuery,
    InvestorActivityStatsQueryVariables
  > &
    (
      | { variables: InvestorActivityStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InvestorActivityStatsQuery,
    InvestorActivityStatsQueryVariables
  >(InvestorActivityStatsDocument, options);
}
export function useInvestorActivityStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvestorActivityStatsQuery,
    InvestorActivityStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InvestorActivityStatsQuery,
    InvestorActivityStatsQueryVariables
  >(InvestorActivityStatsDocument, options);
}
export function useInvestorActivityStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    InvestorActivityStatsQuery,
    InvestorActivityStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InvestorActivityStatsQuery,
    InvestorActivityStatsQueryVariables
  >(InvestorActivityStatsDocument, options);
}
export type InvestorActivityStatsQueryHookResult = ReturnType<
  typeof useInvestorActivityStatsQuery
>;
export type InvestorActivityStatsLazyQueryHookResult = ReturnType<
  typeof useInvestorActivityStatsLazyQuery
>;
export type InvestorActivityStatsSuspenseQueryHookResult = ReturnType<
  typeof useInvestorActivityStatsSuspenseQuery
>;
export type InvestorActivityStatsQueryResult = Apollo.QueryResult<
  InvestorActivityStatsQuery,
  InvestorActivityStatsQueryVariables
>;
export const InvestorSearchContactsAndShareholdingsAutocompleteDocument = gql`
  query InvestorSearchContactsAndShareholdingsAutocomplete(
    $contactsAfter: String
    $contactsBefore: String
    $contactsFirst: Int
    $contactsLast: Int
    $contactsOptions: OptionsInput
    $shareholdingsAfter: String
    $shareholdingsBefore: String
    $shareholdingsFirst: Int
    $shareholdingsLast: Int
    $shareholdingsOptions: OptionsInput
  ) {
    contacts(
      after: $contactsAfter
      before: $contactsBefore
      first: $contactsFirst
      last: $contactsLast
      options: $contactsOptions
    ) {
      edges {
        node {
          id
          email
          firstName
          lastName
          shareholdings {
            id
            email
            accountName
            shareCountRank
          }
          investor {
            id
            username
          }
          tags {
            id
            name
          }
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $contactsOptions)
    }
    shareholdings(
      after: $shareholdingsAfter
      before: $shareholdingsBefore
      first: $shareholdingsFirst
      last: $shareholdingsLast
      options: $shareholdingsOptions
    ) {
      edges {
        node {
          accountName
          currentHoldingStartDate
          brokerNameShort
          email
          id
          initialPurchaseDate
          movementCount
          shareCount
          phoneNumber
          addressCity
          contact {
            id
            email
            firstName
            lastName
          }
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $shareholdingsOptions)
    }
  }
`;

/**
 * __useInvestorSearchContactsAndShareholdingsAutocompleteQuery__
 *
 * To run a query within a React component, call `useInvestorSearchContactsAndShareholdingsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorSearchContactsAndShareholdingsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorSearchContactsAndShareholdingsAutocompleteQuery({
 *   variables: {
 *      contactsAfter: // value for 'contactsAfter'
 *      contactsBefore: // value for 'contactsBefore'
 *      contactsFirst: // value for 'contactsFirst'
 *      contactsLast: // value for 'contactsLast'
 *      contactsOptions: // value for 'contactsOptions'
 *      shareholdingsAfter: // value for 'shareholdingsAfter'
 *      shareholdingsBefore: // value for 'shareholdingsBefore'
 *      shareholdingsFirst: // value for 'shareholdingsFirst'
 *      shareholdingsLast: // value for 'shareholdingsLast'
 *      shareholdingsOptions: // value for 'shareholdingsOptions'
 *   },
 * });
 */
export function useInvestorSearchContactsAndShareholdingsAutocompleteQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InvestorSearchContactsAndShareholdingsAutocompleteQuery,
    InvestorSearchContactsAndShareholdingsAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InvestorSearchContactsAndShareholdingsAutocompleteQuery,
    InvestorSearchContactsAndShareholdingsAutocompleteQueryVariables
  >(InvestorSearchContactsAndShareholdingsAutocompleteDocument, options);
}
export function useInvestorSearchContactsAndShareholdingsAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvestorSearchContactsAndShareholdingsAutocompleteQuery,
    InvestorSearchContactsAndShareholdingsAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InvestorSearchContactsAndShareholdingsAutocompleteQuery,
    InvestorSearchContactsAndShareholdingsAutocompleteQueryVariables
  >(InvestorSearchContactsAndShareholdingsAutocompleteDocument, options);
}
export function useInvestorSearchContactsAndShareholdingsAutocompleteSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    InvestorSearchContactsAndShareholdingsAutocompleteQuery,
    InvestorSearchContactsAndShareholdingsAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InvestorSearchContactsAndShareholdingsAutocompleteQuery,
    InvestorSearchContactsAndShareholdingsAutocompleteQueryVariables
  >(InvestorSearchContactsAndShareholdingsAutocompleteDocument, options);
}
export type InvestorSearchContactsAndShareholdingsAutocompleteQueryHookResult =
  ReturnType<typeof useInvestorSearchContactsAndShareholdingsAutocompleteQuery>;
export type InvestorSearchContactsAndShareholdingsAutocompleteLazyQueryHookResult =
  ReturnType<
    typeof useInvestorSearchContactsAndShareholdingsAutocompleteLazyQuery
  >;
export type InvestorSearchContactsAndShareholdingsAutocompleteSuspenseQueryHookResult =
  ReturnType<
    typeof useInvestorSearchContactsAndShareholdingsAutocompleteSuspenseQuery
  >;
export type InvestorSearchContactsAndShareholdingsAutocompleteQueryResult =
  Apollo.QueryResult<
    InvestorSearchContactsAndShareholdingsAutocompleteQuery,
    InvestorSearchContactsAndShareholdingsAutocompleteQueryVariables
  >;
export const InvestorSearchContactsAndShareholdingsDocument = gql`
  query InvestorSearchContactsAndShareholdings(
    $contactsAfter: String
    $contactsBefore: String
    $contactsFirst: Int
    $contactsLast: Int
    $contactsOptions: OptionsInput
    $shareholdingsAfter: String
    $shareholdingsBefore: String
    $shareholdingsFirst: Int
    $shareholdingsLast: Int
    $shareholdingsOptions: OptionsInput
  ) {
    contacts(
      after: $contactsAfter
      before: $contactsBefore
      first: $contactsFirst
      last: $contactsLast
      options: $contactsOptions
    ) {
      edges {
        node {
          id
          email
          firstName
          lastName
          hnwIdentifiedAt
          hnwStatus
          insertedAt
          shareholderStatus
          shareholdings {
            id
            accountName
          }
          investor {
            id
            username
            insertedAt
          }
          tags {
            id
            name
          }
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $contactsOptions)
    }
    shareholdings(
      after: $shareholdingsAfter
      before: $shareholdingsBefore
      first: $shareholdingsFirst
      last: $shareholdingsLast
      options: $shareholdingsOptions
    ) {
      edges {
        node {
          accountName
          currentHoldingStartDate
          brokerNameShort
          email
          id
          initialPurchaseDate
          movementCount
          shareCount
          phoneNumber
          addressCity
          shareCountRank
          hasParticipatedInSpp
          hasParticipatedInPlacement
          hnwIdentifiedAt
          hnwBehaviour
          contact {
            id
            email
            firstName
            lastName
          }
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $shareholdingsOptions)
    }
  }
`;

/**
 * __useInvestorSearchContactsAndShareholdingsQuery__
 *
 * To run a query within a React component, call `useInvestorSearchContactsAndShareholdingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorSearchContactsAndShareholdingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorSearchContactsAndShareholdingsQuery({
 *   variables: {
 *      contactsAfter: // value for 'contactsAfter'
 *      contactsBefore: // value for 'contactsBefore'
 *      contactsFirst: // value for 'contactsFirst'
 *      contactsLast: // value for 'contactsLast'
 *      contactsOptions: // value for 'contactsOptions'
 *      shareholdingsAfter: // value for 'shareholdingsAfter'
 *      shareholdingsBefore: // value for 'shareholdingsBefore'
 *      shareholdingsFirst: // value for 'shareholdingsFirst'
 *      shareholdingsLast: // value for 'shareholdingsLast'
 *      shareholdingsOptions: // value for 'shareholdingsOptions'
 *   },
 * });
 */
export function useInvestorSearchContactsAndShareholdingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InvestorSearchContactsAndShareholdingsQuery,
    InvestorSearchContactsAndShareholdingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InvestorSearchContactsAndShareholdingsQuery,
    InvestorSearchContactsAndShareholdingsQueryVariables
  >(InvestorSearchContactsAndShareholdingsDocument, options);
}
export function useInvestorSearchContactsAndShareholdingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvestorSearchContactsAndShareholdingsQuery,
    InvestorSearchContactsAndShareholdingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InvestorSearchContactsAndShareholdingsQuery,
    InvestorSearchContactsAndShareholdingsQueryVariables
  >(InvestorSearchContactsAndShareholdingsDocument, options);
}
export function useInvestorSearchContactsAndShareholdingsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    InvestorSearchContactsAndShareholdingsQuery,
    InvestorSearchContactsAndShareholdingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InvestorSearchContactsAndShareholdingsQuery,
    InvestorSearchContactsAndShareholdingsQueryVariables
  >(InvestorSearchContactsAndShareholdingsDocument, options);
}
export type InvestorSearchContactsAndShareholdingsQueryHookResult = ReturnType<
  typeof useInvestorSearchContactsAndShareholdingsQuery
>;
export type InvestorSearchContactsAndShareholdingsLazyQueryHookResult =
  ReturnType<typeof useInvestorSearchContactsAndShareholdingsLazyQuery>;
export type InvestorSearchContactsAndShareholdingsSuspenseQueryHookResult =
  ReturnType<typeof useInvestorSearchContactsAndShareholdingsSuspenseQuery>;
export type InvestorSearchContactsAndShareholdingsQueryResult =
  Apollo.QueryResult<
    InvestorSearchContactsAndShareholdingsQuery,
    InvestorSearchContactsAndShareholdingsQueryVariables
  >;
export const InvestorUsersDocument = gql`
  query InvestorUsers(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $options: OptionsInput
  ) {
    investorUsers(
      after: $after
      before: $before
      first: $first
      last: $last
      options: $options
    ) {
      edges {
        node {
          id
          confirmedAt
          email
          firstName
          isHoldingVerified
          lastName
          provider
          username
          certificate {
            id
            status
            isExpired
          }
          hnwIdentifiedAt
          hnwStatus
          notificationPreferences {
            id
            channel
            hasEoi
            isOn
            scope
          }
          shareholderInformation {
            id
            country
            partialHin
            postcode
          }
          contact {
            id
            email
          }
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $options)
    }
  }
`;

/**
 * __useInvestorUsersQuery__
 *
 * To run a query within a React component, call `useInvestorUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorUsersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useInvestorUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InvestorUsersQuery,
    InvestorUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorUsersQuery, InvestorUsersQueryVariables>(
    InvestorUsersDocument,
    options
  );
}
export function useInvestorUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvestorUsersQuery,
    InvestorUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorUsersQuery, InvestorUsersQueryVariables>(
    InvestorUsersDocument,
    options
  );
}
export function useInvestorUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    InvestorUsersQuery,
    InvestorUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    InvestorUsersQuery,
    InvestorUsersQueryVariables
  >(InvestorUsersDocument, options);
}
export type InvestorUsersQueryHookResult = ReturnType<
  typeof useInvestorUsersQuery
>;
export type InvestorUsersLazyQueryHookResult = ReturnType<
  typeof useInvestorUsersLazyQuery
>;
export type InvestorUsersSuspenseQueryHookResult = ReturnType<
  typeof useInvestorUsersSuspenseQuery
>;
export type InvestorUsersQueryResult = Apollo.QueryResult<
  InvestorUsersQuery,
  InvestorUsersQueryVariables
>;
export const IsFeatureOnboardedDocument = gql`
  query IsFeatureOnboarded($featureName: String!) {
    isFeatureOnboarded(featureName: $featureName)
  }
`;

/**
 * __useIsFeatureOnboardedQuery__
 *
 * To run a query within a React component, call `useIsFeatureOnboardedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsFeatureOnboardedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsFeatureOnboardedQuery({
 *   variables: {
 *      featureName: // value for 'featureName'
 *   },
 * });
 */
export function useIsFeatureOnboardedQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsFeatureOnboardedQuery,
    IsFeatureOnboardedQueryVariables
  > &
    (
      | { variables: IsFeatureOnboardedQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IsFeatureOnboardedQuery,
    IsFeatureOnboardedQueryVariables
  >(IsFeatureOnboardedDocument, options);
}
export function useIsFeatureOnboardedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsFeatureOnboardedQuery,
    IsFeatureOnboardedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IsFeatureOnboardedQuery,
    IsFeatureOnboardedQueryVariables
  >(IsFeatureOnboardedDocument, options);
}
export function useIsFeatureOnboardedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IsFeatureOnboardedQuery,
    IsFeatureOnboardedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    IsFeatureOnboardedQuery,
    IsFeatureOnboardedQueryVariables
  >(IsFeatureOnboardedDocument, options);
}
export type IsFeatureOnboardedQueryHookResult = ReturnType<
  typeof useIsFeatureOnboardedQuery
>;
export type IsFeatureOnboardedLazyQueryHookResult = ReturnType<
  typeof useIsFeatureOnboardedLazyQuery
>;
export type IsFeatureOnboardedSuspenseQueryHookResult = ReturnType<
  typeof useIsFeatureOnboardedSuspenseQuery
>;
export type IsFeatureOnboardedQueryResult = Apollo.QueryResult<
  IsFeatureOnboardedQuery,
  IsFeatureOnboardedQueryVariables
>;
export const KeyInsightsDocument = gql`
  query KeyInsights($endDate: Date!, $startDate: Date!) {
    keyInsights(endDate: $endDate, startDate: $startDate) {
      id
      churned
      downgraders
      new
      returning
      upgraders
    }
  }
`;

/**
 * __useKeyInsightsQuery__
 *
 * To run a query within a React component, call `useKeyInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeyInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeyInsightsQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useKeyInsightsQuery(
  baseOptions: Apollo.QueryHookOptions<
    KeyInsightsQuery,
    KeyInsightsQueryVariables
  > &
    (
      | { variables: KeyInsightsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KeyInsightsQuery, KeyInsightsQueryVariables>(
    KeyInsightsDocument,
    options
  );
}
export function useKeyInsightsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KeyInsightsQuery,
    KeyInsightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KeyInsightsQuery, KeyInsightsQueryVariables>(
    KeyInsightsDocument,
    options
  );
}
export function useKeyInsightsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    KeyInsightsQuery,
    KeyInsightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KeyInsightsQuery, KeyInsightsQueryVariables>(
    KeyInsightsDocument,
    options
  );
}
export type KeyInsightsQueryHookResult = ReturnType<typeof useKeyInsightsQuery>;
export type KeyInsightsLazyQueryHookResult = ReturnType<
  typeof useKeyInsightsLazyQuery
>;
export type KeyInsightsSuspenseQueryHookResult = ReturnType<
  typeof useKeyInsightsSuspenseQuery
>;
export type KeyInsightsQueryResult = Apollo.QueryResult<
  KeyInsightsQuery,
  KeyInsightsQueryVariables
>;
export const ContactsGraphDataDocument = gql`
  query contactsGraphData($startDate: Date!, $endDate: Date!) {
    getContactsGraphData(startDate: $startDate, endDate: $endDate) {
      date
      hubEmails
      totalEmails
    }
  }
`;

/**
 * __useContactsGraphDataQuery__
 *
 * To run a query within a React component, call `useContactsGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsGraphDataQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useContactsGraphDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContactsGraphDataQuery,
    ContactsGraphDataQueryVariables
  > &
    (
      | { variables: ContactsGraphDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ContactsGraphDataQuery,
    ContactsGraphDataQueryVariables
  >(ContactsGraphDataDocument, options);
}
export function useContactsGraphDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContactsGraphDataQuery,
    ContactsGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ContactsGraphDataQuery,
    ContactsGraphDataQueryVariables
  >(ContactsGraphDataDocument, options);
}
export function useContactsGraphDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ContactsGraphDataQuery,
    ContactsGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ContactsGraphDataQuery,
    ContactsGraphDataQueryVariables
  >(ContactsGraphDataDocument, options);
}
export type ContactsGraphDataQueryHookResult = ReturnType<
  typeof useContactsGraphDataQuery
>;
export type ContactsGraphDataLazyQueryHookResult = ReturnType<
  typeof useContactsGraphDataLazyQuery
>;
export type ContactsGraphDataSuspenseQueryHookResult = ReturnType<
  typeof useContactsGraphDataSuspenseQuery
>;
export type ContactsGraphDataQueryResult = Apollo.QueryResult<
  ContactsGraphDataQuery,
  ContactsGraphDataQueryVariables
>;
export const EngagementGraphDataDocument = gql`
  query engagementGraphData($startDate: Date!, $endDate: Date!) {
    getEngagementGraphData(startDate: $startDate, endDate: $endDate) {
      date
      uniqueVisitors
      engagedUniqueVisitors
      returningVisitors
    }
  }
`;

/**
 * __useEngagementGraphDataQuery__
 *
 * To run a query within a React component, call `useEngagementGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEngagementGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEngagementGraphDataQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useEngagementGraphDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    EngagementGraphDataQuery,
    EngagementGraphDataQueryVariables
  > &
    (
      | { variables: EngagementGraphDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EngagementGraphDataQuery,
    EngagementGraphDataQueryVariables
  >(EngagementGraphDataDocument, options);
}
export function useEngagementGraphDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EngagementGraphDataQuery,
    EngagementGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EngagementGraphDataQuery,
    EngagementGraphDataQueryVariables
  >(EngagementGraphDataDocument, options);
}
export function useEngagementGraphDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EngagementGraphDataQuery,
    EngagementGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EngagementGraphDataQuery,
    EngagementGraphDataQueryVariables
  >(EngagementGraphDataDocument, options);
}
export type EngagementGraphDataQueryHookResult = ReturnType<
  typeof useEngagementGraphDataQuery
>;
export type EngagementGraphDataLazyQueryHookResult = ReturnType<
  typeof useEngagementGraphDataLazyQuery
>;
export type EngagementGraphDataSuspenseQueryHookResult = ReturnType<
  typeof useEngagementGraphDataSuspenseQuery
>;
export type EngagementGraphDataQueryResult = Apollo.QueryResult<
  EngagementGraphDataQuery,
  EngagementGraphDataQueryVariables
>;
export const CampaignDistributionGraphDataDocument = gql`
  query campaignDistributionGraphData($lastNumberOfEmailCampaigns: Int!) {
    getCampaignDistributionGraphData(
      lastNumberOfEmailCampaigns: $lastNumberOfEmailCampaigns
    ) {
      date
      campaignName
      clickThroughRate
      openRate
      type
      totalSent
    }
  }
`;

/**
 * __useCampaignDistributionGraphDataQuery__
 *
 * To run a query within a React component, call `useCampaignDistributionGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignDistributionGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignDistributionGraphDataQuery({
 *   variables: {
 *      lastNumberOfEmailCampaigns: // value for 'lastNumberOfEmailCampaigns'
 *   },
 * });
 */
export function useCampaignDistributionGraphDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    CampaignDistributionGraphDataQuery,
    CampaignDistributionGraphDataQueryVariables
  > &
    (
      | {
          variables: CampaignDistributionGraphDataQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CampaignDistributionGraphDataQuery,
    CampaignDistributionGraphDataQueryVariables
  >(CampaignDistributionGraphDataDocument, options);
}
export function useCampaignDistributionGraphDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CampaignDistributionGraphDataQuery,
    CampaignDistributionGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CampaignDistributionGraphDataQuery,
    CampaignDistributionGraphDataQueryVariables
  >(CampaignDistributionGraphDataDocument, options);
}
export function useCampaignDistributionGraphDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CampaignDistributionGraphDataQuery,
    CampaignDistributionGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CampaignDistributionGraphDataQuery,
    CampaignDistributionGraphDataQueryVariables
  >(CampaignDistributionGraphDataDocument, options);
}
export type CampaignDistributionGraphDataQueryHookResult = ReturnType<
  typeof useCampaignDistributionGraphDataQuery
>;
export type CampaignDistributionGraphDataLazyQueryHookResult = ReturnType<
  typeof useCampaignDistributionGraphDataLazyQuery
>;
export type CampaignDistributionGraphDataSuspenseQueryHookResult = ReturnType<
  typeof useCampaignDistributionGraphDataSuspenseQuery
>;
export type CampaignDistributionGraphDataQueryResult = Apollo.QueryResult<
  CampaignDistributionGraphDataQuery,
  CampaignDistributionGraphDataQueryVariables
>;
export const SubmitFeedbackDocument = gql`
  query submitFeedback($message: String!) {
    submitFeedback(message: $message)
  }
`;

/**
 * __useSubmitFeedbackQuery__
 *
 * To run a query within a React component, call `useSubmitFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmitFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmitFeedbackQuery({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSubmitFeedbackQuery(
  baseOptions: Apollo.QueryHookOptions<
    SubmitFeedbackQuery,
    SubmitFeedbackQueryVariables
  > &
    (
      | { variables: SubmitFeedbackQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubmitFeedbackQuery, SubmitFeedbackQueryVariables>(
    SubmitFeedbackDocument,
    options
  );
}
export function useSubmitFeedbackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubmitFeedbackQuery,
    SubmitFeedbackQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubmitFeedbackQuery, SubmitFeedbackQueryVariables>(
    SubmitFeedbackDocument,
    options
  );
}
export function useSubmitFeedbackSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SubmitFeedbackQuery,
    SubmitFeedbackQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SubmitFeedbackQuery,
    SubmitFeedbackQueryVariables
  >(SubmitFeedbackDocument, options);
}
export type SubmitFeedbackQueryHookResult = ReturnType<
  typeof useSubmitFeedbackQuery
>;
export type SubmitFeedbackLazyQueryHookResult = ReturnType<
  typeof useSubmitFeedbackLazyQuery
>;
export type SubmitFeedbackSuspenseQueryHookResult = ReturnType<
  typeof useSubmitFeedbackSuspenseQuery
>;
export type SubmitFeedbackQueryResult = Apollo.QueryResult<
  SubmitFeedbackQuery,
  SubmitFeedbackQueryVariables
>;
export const LinkedinPostStatisticsDocument = gql`
  query LinkedinPostStatistics($postId: String!) {
    linkedinPostStatistics(postId: $postId) {
      id
      impressionCount
      commentCount
      shareCount
      likeCount
    }
  }
`;

/**
 * __useLinkedinPostStatisticsQuery__
 *
 * To run a query within a React component, call `useLinkedinPostStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkedinPostStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkedinPostStatisticsQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useLinkedinPostStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LinkedinPostStatisticsQuery,
    LinkedinPostStatisticsQueryVariables
  > &
    (
      | { variables: LinkedinPostStatisticsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LinkedinPostStatisticsQuery,
    LinkedinPostStatisticsQueryVariables
  >(LinkedinPostStatisticsDocument, options);
}
export function useLinkedinPostStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LinkedinPostStatisticsQuery,
    LinkedinPostStatisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LinkedinPostStatisticsQuery,
    LinkedinPostStatisticsQueryVariables
  >(LinkedinPostStatisticsDocument, options);
}
export function useLinkedinPostStatisticsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    LinkedinPostStatisticsQuery,
    LinkedinPostStatisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LinkedinPostStatisticsQuery,
    LinkedinPostStatisticsQueryVariables
  >(LinkedinPostStatisticsDocument, options);
}
export type LinkedinPostStatisticsQueryHookResult = ReturnType<
  typeof useLinkedinPostStatisticsQuery
>;
export type LinkedinPostStatisticsLazyQueryHookResult = ReturnType<
  typeof useLinkedinPostStatisticsLazyQuery
>;
export type LinkedinPostStatisticsSuspenseQueryHookResult = ReturnType<
  typeof useLinkedinPostStatisticsSuspenseQuery
>;
export type LinkedinPostStatisticsQueryResult = Apollo.QueryResult<
  LinkedinPostStatisticsQuery,
  LinkedinPostStatisticsQueryVariables
>;
export const AnnouncementEngagementDocument = gql`
  query AnnouncementEngagement($id: ID!, $dateRange: String!) {
    announcementEngagement(id: $id, dateRange: $dateRange) {
      date
      totalViews
      totalUniqueVisitors
    }
  }
`;

/**
 * __useAnnouncementEngagementQuery__
 *
 * To run a query within a React component, call `useAnnouncementEngagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementEngagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementEngagementQuery({
 *   variables: {
 *      id: // value for 'id'
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useAnnouncementEngagementQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnnouncementEngagementQuery,
    AnnouncementEngagementQueryVariables
  > &
    (
      | { variables: AnnouncementEngagementQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnnouncementEngagementQuery,
    AnnouncementEngagementQueryVariables
  >(AnnouncementEngagementDocument, options);
}
export function useAnnouncementEngagementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnnouncementEngagementQuery,
    AnnouncementEngagementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnnouncementEngagementQuery,
    AnnouncementEngagementQueryVariables
  >(AnnouncementEngagementDocument, options);
}
export function useAnnouncementEngagementSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AnnouncementEngagementQuery,
    AnnouncementEngagementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AnnouncementEngagementQuery,
    AnnouncementEngagementQueryVariables
  >(AnnouncementEngagementDocument, options);
}
export type AnnouncementEngagementQueryHookResult = ReturnType<
  typeof useAnnouncementEngagementQuery
>;
export type AnnouncementEngagementLazyQueryHookResult = ReturnType<
  typeof useAnnouncementEngagementLazyQuery
>;
export type AnnouncementEngagementSuspenseQueryHookResult = ReturnType<
  typeof useAnnouncementEngagementSuspenseQuery
>;
export type AnnouncementEngagementQueryResult = Apollo.QueryResult<
  AnnouncementEngagementQuery,
  AnnouncementEngagementQueryVariables
>;
export const MediaAnnouncementViewerStatsDocument = gql`
  query MediaAnnouncementViewerStats($mediaId: ID!) {
    mediaAnnouncementViewerStats(mediaId: $mediaId) {
      campaignViews
      linkedinViews
      preparedLinkViews
      twitterViews
      other
    }
  }
`;

/**
 * __useMediaAnnouncementViewerStatsQuery__
 *
 * To run a query within a React component, call `useMediaAnnouncementViewerStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaAnnouncementViewerStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaAnnouncementViewerStatsQuery({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useMediaAnnouncementViewerStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MediaAnnouncementViewerStatsQuery,
    MediaAnnouncementViewerStatsQueryVariables
  > &
    (
      | {
          variables: MediaAnnouncementViewerStatsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MediaAnnouncementViewerStatsQuery,
    MediaAnnouncementViewerStatsQueryVariables
  >(MediaAnnouncementViewerStatsDocument, options);
}
export function useMediaAnnouncementViewerStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaAnnouncementViewerStatsQuery,
    MediaAnnouncementViewerStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MediaAnnouncementViewerStatsQuery,
    MediaAnnouncementViewerStatsQueryVariables
  >(MediaAnnouncementViewerStatsDocument, options);
}
export function useMediaAnnouncementViewerStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaAnnouncementViewerStatsQuery,
    MediaAnnouncementViewerStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MediaAnnouncementViewerStatsQuery,
    MediaAnnouncementViewerStatsQueryVariables
  >(MediaAnnouncementViewerStatsDocument, options);
}
export type MediaAnnouncementViewerStatsQueryHookResult = ReturnType<
  typeof useMediaAnnouncementViewerStatsQuery
>;
export type MediaAnnouncementViewerStatsLazyQueryHookResult = ReturnType<
  typeof useMediaAnnouncementViewerStatsLazyQuery
>;
export type MediaAnnouncementViewerStatsSuspenseQueryHookResult = ReturnType<
  typeof useMediaAnnouncementViewerStatsSuspenseQuery
>;
export type MediaAnnouncementViewerStatsQueryResult = Apollo.QueryResult<
  MediaAnnouncementViewerStatsQuery,
  MediaAnnouncementViewerStatsQueryVariables
>;
export const MediaAnnouncementDocument = gql`
  query MediaAnnouncement($id: ID!) {
    mediaAnnouncement(id: $id) {
      id
      featuredOnHub
      header
      likes
      listingKey
      marketKey
      marketSensitive
      mediaId
      media {
        emailDistributionMethod
        email {
          id
          sentAt
          isDraft
          scheduledAt
          previewEmail
          previewEmailSubject
          subject
          totalClick: stats(type: CLICK)
          totalDelivery: stats(type: DELIVERY)
          totalOpen: stats(type: OPEN)
          totalUnsubscribed: stats(type: UNSUBSCRIBED)
          totalComplaints: stats(type: COMPLAINT)
          totalBounced: stats(type: BOUNCE)
          total: stats(type: TOTAL)
        }
      }
      distributedSocial {
        id
        linkedinPostId
        linkedinPostedAt
        linkedinPostUrl
        twitterPostId
        twitterPostUrl
        twitterPostedAt
      }
      postedAt
      preparedAnnouncement {
        id
        socialVideoUrl
        videoUrl
        commentContent
        commentUseCompanyAsUsername
        summary
      }
      rectype
      socialVideoUrl
      subtypes
      summary
      summaryAi
      totalQuestionCount
      totalSurveyResponses
      totalViewCount
      totalUniqueVisitors
      totalSignupsLastWeek
      url
      videoUrl
      germanTranslatedVideoUrl
      germanTranslatedUrl
      germanTranslatedHeader
      germanTranslatedSummary
    }
  }
`;

/**
 * __useMediaAnnouncementQuery__
 *
 * To run a query within a React component, call `useMediaAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaAnnouncementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMediaAnnouncementQuery(
  baseOptions: Apollo.QueryHookOptions<
    MediaAnnouncementQuery,
    MediaAnnouncementQueryVariables
  > &
    (
      | { variables: MediaAnnouncementQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MediaAnnouncementQuery,
    MediaAnnouncementQueryVariables
  >(MediaAnnouncementDocument, options);
}
export function useMediaAnnouncementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaAnnouncementQuery,
    MediaAnnouncementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MediaAnnouncementQuery,
    MediaAnnouncementQueryVariables
  >(MediaAnnouncementDocument, options);
}
export function useMediaAnnouncementSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaAnnouncementQuery,
    MediaAnnouncementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MediaAnnouncementQuery,
    MediaAnnouncementQueryVariables
  >(MediaAnnouncementDocument, options);
}
export type MediaAnnouncementQueryHookResult = ReturnType<
  typeof useMediaAnnouncementQuery
>;
export type MediaAnnouncementLazyQueryHookResult = ReturnType<
  typeof useMediaAnnouncementLazyQuery
>;
export type MediaAnnouncementSuspenseQueryHookResult = ReturnType<
  typeof useMediaAnnouncementSuspenseQuery
>;
export type MediaAnnouncementQueryResult = Apollo.QueryResult<
  MediaAnnouncementQuery,
  MediaAnnouncementQueryVariables
>;
export const MediaAnnouncementsDocument = gql`
  query MediaAnnouncements(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $options: OptionsInput
  ) {
    mediaAnnouncements(
      after: $after
      before: $before
      first: $first
      last: $last
      options: $options
    ) {
      edges {
        node {
          id
          featuredOnHub
          header
          likes
          listingKey
          marketKey
          mediaId
          postedAt
          socialVideoUrl
          summary
          totalActiveQuestionCount
          totalQuestionCount
          totalSurveyResponses
          totalViewCount
          videoUrl
          germanTranslatedVideoUrl
          germanTranslatedUrl
          germanTranslatedHeader
          germanTranslatedSummary
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $options)
    }
  }
`;

/**
 * __useMediaAnnouncementsQuery__
 *
 * To run a query within a React component, call `useMediaAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaAnnouncementsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useMediaAnnouncementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MediaAnnouncementsQuery,
    MediaAnnouncementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MediaAnnouncementsQuery,
    MediaAnnouncementsQueryVariables
  >(MediaAnnouncementsDocument, options);
}
export function useMediaAnnouncementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaAnnouncementsQuery,
    MediaAnnouncementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MediaAnnouncementsQuery,
    MediaAnnouncementsQueryVariables
  >(MediaAnnouncementsDocument, options);
}
export function useMediaAnnouncementsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaAnnouncementsQuery,
    MediaAnnouncementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MediaAnnouncementsQuery,
    MediaAnnouncementsQueryVariables
  >(MediaAnnouncementsDocument, options);
}
export type MediaAnnouncementsQueryHookResult = ReturnType<
  typeof useMediaAnnouncementsQuery
>;
export type MediaAnnouncementsLazyQueryHookResult = ReturnType<
  typeof useMediaAnnouncementsLazyQuery
>;
export type MediaAnnouncementsSuspenseQueryHookResult = ReturnType<
  typeof useMediaAnnouncementsSuspenseQuery
>;
export type MediaAnnouncementsQueryResult = Apollo.QueryResult<
  MediaAnnouncementsQuery,
  MediaAnnouncementsQueryVariables
>;
export const MediaCommentsCompanyAuthorDocument = gql`
  query MediaCommentsCompanyAuthor($mediaId: ID!) {
    mediaCommentsCompanyAuthor(mediaId: $mediaId) {
      id
      insertedAt
      updatedAt
      annotationMetadata {
        left
        pageIndex
        top
      }
      children {
        id
        insertedAt
        updatedAt
        annotationMetadata {
          left
          pageIndex
          top
        }
        companyAuthor {
          id
          email
          firstName
          lastName
        }
        content
        investorUser {
          id
          contact {
            id
            shareholderStatus
          }
          email
          firstName
          isHoldingVerified
          lastName
          username
        }
        likes
        private
        useCompanyAsUsername
      }
      companyAuthor {
        id
        email
        firstName
        lastName
      }
      content
      lastEditedByUser {
        id
        firstName
        lastName
      }
      likes
      useCompanyAsUsername
    }
  }
`;

/**
 * __useMediaCommentsCompanyAuthorQuery__
 *
 * To run a query within a React component, call `useMediaCommentsCompanyAuthorQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaCommentsCompanyAuthorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaCommentsCompanyAuthorQuery({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useMediaCommentsCompanyAuthorQuery(
  baseOptions: Apollo.QueryHookOptions<
    MediaCommentsCompanyAuthorQuery,
    MediaCommentsCompanyAuthorQueryVariables
  > &
    (
      | { variables: MediaCommentsCompanyAuthorQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MediaCommentsCompanyAuthorQuery,
    MediaCommentsCompanyAuthorQueryVariables
  >(MediaCommentsCompanyAuthorDocument, options);
}
export function useMediaCommentsCompanyAuthorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaCommentsCompanyAuthorQuery,
    MediaCommentsCompanyAuthorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MediaCommentsCompanyAuthorQuery,
    MediaCommentsCompanyAuthorQueryVariables
  >(MediaCommentsCompanyAuthorDocument, options);
}
export function useMediaCommentsCompanyAuthorSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaCommentsCompanyAuthorQuery,
    MediaCommentsCompanyAuthorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MediaCommentsCompanyAuthorQuery,
    MediaCommentsCompanyAuthorQueryVariables
  >(MediaCommentsCompanyAuthorDocument, options);
}
export type MediaCommentsCompanyAuthorQueryHookResult = ReturnType<
  typeof useMediaCommentsCompanyAuthorQuery
>;
export type MediaCommentsCompanyAuthorLazyQueryHookResult = ReturnType<
  typeof useMediaCommentsCompanyAuthorLazyQuery
>;
export type MediaCommentsCompanyAuthorSuspenseQueryHookResult = ReturnType<
  typeof useMediaCommentsCompanyAuthorSuspenseQuery
>;
export type MediaCommentsCompanyAuthorQueryResult = Apollo.QueryResult<
  MediaCommentsCompanyAuthorQuery,
  MediaCommentsCompanyAuthorQueryVariables
>;
export const MediaCommentsDocument = gql`
  query MediaComments($isAnnotation: Boolean, $mediaId: ID!) {
    mediaComments(isAnnotation: $isAnnotation, mediaId: $mediaId) {
      id
      insertedAt
      annotationMetadata {
        left
        pageIndex
        top
      }
      children {
        id
        insertedAt
        updatedAt
        annotationMetadata {
          left
          pageIndex
          top
        }
        companyAuthor {
          id
          email
          firstName
          lastName
        }
        content
        investorUser {
          id
          contact {
            id
            shareholderStatus
          }
          email
          firstName
          isHoldingVerified
          isSelfNominatedShareholder
          lastName
          username
        }
        likes
        private
        useCompanyAsUsername
      }
      companyAuthor {
        id
        email
        firstName
        lastName
      }
      content
      investorUser {
        id
        contact {
          id
        }
        email
        firstName
        isHoldingVerified
        isSelfNominatedShareholder
        lastName
        username
      }
      likes
      private
      useCompanyAsUsername
      userStarred {
        id
        starred
      }
      userRead {
        id
        read
      }
    }
  }
`;

/**
 * __useMediaCommentsQuery__
 *
 * To run a query within a React component, call `useMediaCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaCommentsQuery({
 *   variables: {
 *      isAnnotation: // value for 'isAnnotation'
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useMediaCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MediaCommentsQuery,
    MediaCommentsQueryVariables
  > &
    (
      | { variables: MediaCommentsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediaCommentsQuery, MediaCommentsQueryVariables>(
    MediaCommentsDocument,
    options
  );
}
export function useMediaCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaCommentsQuery,
    MediaCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediaCommentsQuery, MediaCommentsQueryVariables>(
    MediaCommentsDocument,
    options
  );
}
export function useMediaCommentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaCommentsQuery,
    MediaCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MediaCommentsQuery,
    MediaCommentsQueryVariables
  >(MediaCommentsDocument, options);
}
export type MediaCommentsQueryHookResult = ReturnType<
  typeof useMediaCommentsQuery
>;
export type MediaCommentsLazyQueryHookResult = ReturnType<
  typeof useMediaCommentsLazyQuery
>;
export type MediaCommentsSuspenseQueryHookResult = ReturnType<
  typeof useMediaCommentsSuspenseQuery
>;
export type MediaCommentsQueryResult = Apollo.QueryResult<
  MediaCommentsQuery,
  MediaCommentsQueryVariables
>;
export const MediaQuestionsListDocument = gql`
  query MediaQuestionsList(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $options: OptionsInput
  ) {
    mediaQuestionsList(
      after: $after
      before: $before
      first: $first
      last: $last
      options: $options
    ) {
      edges {
        node {
          id
          insertedAt
          updatedAt
          annotationMetadata {
            left
            pageIndex
            top
          }
          children {
            id
            insertedAt
            updatedAt
            lastEditedByUser {
              id
              firstName
              lastName
            }
            annotationMetadata {
              left
              pageIndex
              top
            }
            companyAuthor {
              id
              email
              firstName
              lastName
            }
            content
            investorUser {
              id
              contact {
                id
              }
              email
              firstName
              isHoldingVerified
              isSelfNominatedShareholder
              lastName
              username
            }
            likes
            private
            useCompanyAsUsername
          }
          commentSource
          commentSourceUrl
          companyAuthor {
            id
            email
            firstName
            lastName
          }
          content
          done
          lastEditedByUser {
            id
            firstName
            lastName
          }
          investorUser {
            id
            contact {
              id
              shareholderStatus
            }
            shareholdings {
              id
              shareCountRank
              shareCount
              hasParticipatedInPlacement
              hasParticipatedInSpp
              hnwBehaviour
              hnwIdentifiedAt
            }
            email
            firstName
            isHoldingVerified
            isSelfNominatedShareholder
            lastName
            username
          }
          media {
            id
            companyProfile {
              id
              name
              ticker {
                id
                listingKey
                marketKey
              }
            }
            mediaUpdate {
              id
              title
              slug
            }
            mediaAnnouncement {
              id
              header
            }
          }
          userStarred {
            id
            starred
          }
          userRead {
            id
            read
          }
          likes
          private
          useCompanyAsUsername
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $options)
      totalStarred(options: $options)
      totalDone(options: $options)
      totalActive(options: $options)
      totalAll(options: $options)
    }
  }
`;

/**
 * __useMediaQuestionsListQuery__
 *
 * To run a query within a React component, call `useMediaQuestionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaQuestionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaQuestionsListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useMediaQuestionsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MediaQuestionsListQuery,
    MediaQuestionsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MediaQuestionsListQuery,
    MediaQuestionsListQueryVariables
  >(MediaQuestionsListDocument, options);
}
export function useMediaQuestionsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaQuestionsListQuery,
    MediaQuestionsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MediaQuestionsListQuery,
    MediaQuestionsListQueryVariables
  >(MediaQuestionsListDocument, options);
}
export function useMediaQuestionsListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaQuestionsListQuery,
    MediaQuestionsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MediaQuestionsListQuery,
    MediaQuestionsListQueryVariables
  >(MediaQuestionsListDocument, options);
}
export type MediaQuestionsListQueryHookResult = ReturnType<
  typeof useMediaQuestionsListQuery
>;
export type MediaQuestionsListLazyQueryHookResult = ReturnType<
  typeof useMediaQuestionsListLazyQuery
>;
export type MediaQuestionsListSuspenseQueryHookResult = ReturnType<
  typeof useMediaQuestionsListSuspenseQuery
>;
export type MediaQuestionsListQueryResult = Apollo.QueryResult<
  MediaQuestionsListQuery,
  MediaQuestionsListQueryVariables
>;
export const MediaStatsDocument = gql`
  query MediaStats {
    mediaStats {
      comments
      reactions
      surveyResponses
      views
      visitors
    }
  }
`;

/**
 * __useMediaStatsQuery__
 *
 * To run a query within a React component, call `useMediaStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMediaStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MediaStatsQuery,
    MediaStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediaStatsQuery, MediaStatsQueryVariables>(
    MediaStatsDocument,
    options
  );
}
export function useMediaStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaStatsQuery,
    MediaStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediaStatsQuery, MediaStatsQueryVariables>(
    MediaStatsDocument,
    options
  );
}
export function useMediaStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaStatsQuery,
    MediaStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MediaStatsQuery, MediaStatsQueryVariables>(
    MediaStatsDocument,
    options
  );
}
export type MediaStatsQueryHookResult = ReturnType<typeof useMediaStatsQuery>;
export type MediaStatsLazyQueryHookResult = ReturnType<
  typeof useMediaStatsLazyQuery
>;
export type MediaStatsSuspenseQueryHookResult = ReturnType<
  typeof useMediaStatsSuspenseQuery
>;
export type MediaStatsQueryResult = Apollo.QueryResult<
  MediaStatsQuery,
  MediaStatsQueryVariables
>;
export const MediaSurveyAnswersDocument = gql`
  query MediaSurveyAnswers($mediaId: ID!) {
    mediaSurveyAnswers(mediaId: $mediaId) {
      id
      answer
      question
    }
  }
`;

/**
 * __useMediaSurveyAnswersQuery__
 *
 * To run a query within a React component, call `useMediaSurveyAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaSurveyAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaSurveyAnswersQuery({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useMediaSurveyAnswersQuery(
  baseOptions: Apollo.QueryHookOptions<
    MediaSurveyAnswersQuery,
    MediaSurveyAnswersQueryVariables
  > &
    (
      | { variables: MediaSurveyAnswersQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MediaSurveyAnswersQuery,
    MediaSurveyAnswersQueryVariables
  >(MediaSurveyAnswersDocument, options);
}
export function useMediaSurveyAnswersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaSurveyAnswersQuery,
    MediaSurveyAnswersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MediaSurveyAnswersQuery,
    MediaSurveyAnswersQueryVariables
  >(MediaSurveyAnswersDocument, options);
}
export function useMediaSurveyAnswersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaSurveyAnswersQuery,
    MediaSurveyAnswersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MediaSurveyAnswersQuery,
    MediaSurveyAnswersQueryVariables
  >(MediaSurveyAnswersDocument, options);
}
export type MediaSurveyAnswersQueryHookResult = ReturnType<
  typeof useMediaSurveyAnswersQuery
>;
export type MediaSurveyAnswersLazyQueryHookResult = ReturnType<
  typeof useMediaSurveyAnswersLazyQuery
>;
export type MediaSurveyAnswersSuspenseQueryHookResult = ReturnType<
  typeof useMediaSurveyAnswersSuspenseQuery
>;
export type MediaSurveyAnswersQueryResult = Apollo.QueryResult<
  MediaSurveyAnswersQuery,
  MediaSurveyAnswersQueryVariables
>;
export const MediaSurveyResponseStatsDocument = gql`
  query MediaSurveyResponseStats($mediaId: ID!) {
    mediaSurveyResponseStats(mediaId: $mediaId) {
      answers {
        answer
        responsePercentageAsFloat
      }
      question
      totalResponses
    }
  }
`;

/**
 * __useMediaSurveyResponseStatsQuery__
 *
 * To run a query within a React component, call `useMediaSurveyResponseStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaSurveyResponseStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaSurveyResponseStatsQuery({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useMediaSurveyResponseStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MediaSurveyResponseStatsQuery,
    MediaSurveyResponseStatsQueryVariables
  > &
    (
      | { variables: MediaSurveyResponseStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MediaSurveyResponseStatsQuery,
    MediaSurveyResponseStatsQueryVariables
  >(MediaSurveyResponseStatsDocument, options);
}
export function useMediaSurveyResponseStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaSurveyResponseStatsQuery,
    MediaSurveyResponseStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MediaSurveyResponseStatsQuery,
    MediaSurveyResponseStatsQueryVariables
  >(MediaSurveyResponseStatsDocument, options);
}
export function useMediaSurveyResponseStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaSurveyResponseStatsQuery,
    MediaSurveyResponseStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MediaSurveyResponseStatsQuery,
    MediaSurveyResponseStatsQueryVariables
  >(MediaSurveyResponseStatsDocument, options);
}
export type MediaSurveyResponseStatsQueryHookResult = ReturnType<
  typeof useMediaSurveyResponseStatsQuery
>;
export type MediaSurveyResponseStatsLazyQueryHookResult = ReturnType<
  typeof useMediaSurveyResponseStatsLazyQuery
>;
export type MediaSurveyResponseStatsSuspenseQueryHookResult = ReturnType<
  typeof useMediaSurveyResponseStatsSuspenseQuery
>;
export type MediaSurveyResponseStatsQueryResult = Apollo.QueryResult<
  MediaSurveyResponseStatsQuery,
  MediaSurveyResponseStatsQueryVariables
>;
export const MediaUpdateDocument = gql`
  query MediaUpdate($id: ID!) {
    mediaUpdate(id: $id) {
      id
      attachments {
        id
        thumbnailUrl
        title
        type
        url
        orderId
      }
      content {
        id
        content
        commentContent
        commentUseCompanyAsUsername
      }
      isDraft
      lastUpdatedBy {
        id
        firstName
        lastName
        email
      }
      likes
      mediaId
      media {
        emailDistributionMethod
        email {
          id
          sentAt
          isDraft
          scheduledAt
          previewEmail
          previewEmailSubject
          subject
          totalClick: stats(type: CLICK)
          totalDelivery: stats(type: DELIVERY)
          totalOpen: stats(type: OPEN)
          totalUnsubscribed: stats(type: UNSUBSCRIBED)
          totalComplaints: stats(type: COMPLAINT)
          totalBounced: stats(type: BOUNCE)
          total: stats(type: TOTAL)
        }
      }
      distributedSocial {
        id
        linkedinPostId
        linkedinPostedAt
        linkedinPostUrl
        twitterPostId
        twitterPostUrl
        twitterPostedAt
      }
      postedAt
      postedBy {
        id
        firstName
        lastName
        email
      }
      slug
      title
      totalActiveQuestionCount
      totalParentCompanyCommentCount
      totalQuestionCount
      totalSurveyResponses
      totalViewCount
      includedTypes
    }
  }
`;

/**
 * __useMediaUpdateQuery__
 *
 * To run a query within a React component, call `useMediaUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaUpdateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMediaUpdateQuery(
  baseOptions: Apollo.QueryHookOptions<
    MediaUpdateQuery,
    MediaUpdateQueryVariables
  > &
    (
      | { variables: MediaUpdateQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediaUpdateQuery, MediaUpdateQueryVariables>(
    MediaUpdateDocument,
    options
  );
}
export function useMediaUpdateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaUpdateQuery,
    MediaUpdateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediaUpdateQuery, MediaUpdateQueryVariables>(
    MediaUpdateDocument,
    options
  );
}
export function useMediaUpdateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaUpdateQuery,
    MediaUpdateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MediaUpdateQuery, MediaUpdateQueryVariables>(
    MediaUpdateDocument,
    options
  );
}
export type MediaUpdateQueryHookResult = ReturnType<typeof useMediaUpdateQuery>;
export type MediaUpdateLazyQueryHookResult = ReturnType<
  typeof useMediaUpdateLazyQuery
>;
export type MediaUpdateSuspenseQueryHookResult = ReturnType<
  typeof useMediaUpdateSuspenseQuery
>;
export type MediaUpdateQueryResult = Apollo.QueryResult<
  MediaUpdateQuery,
  MediaUpdateQueryVariables
>;
export const MediaUpdatesDocument = gql`
  query MediaUpdates(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $options: OptionsInput
  ) {
    mediaUpdates(
      after: $after
      before: $before
      first: $first
      last: $last
      options: $options
    ) {
      edges {
        node {
          id
          thumbnailAttachment {
            id
            thumbnailUrl
            type
            url
            orderId
          }
          attachments {
            id
            thumbnailUrl
            type
            url
            orderId
          }
          content {
            id
            content
            commentContent
            commentUseCompanyAsUsername
          }
          isDraft
          lastUpdatedBy {
            id
            firstName
            lastName
            email
          }
          likes
          mediaId
          postedAt
          postedBy {
            id
            firstName
            lastName
            email
          }
          slug
          title
          totalActiveQuestionCount
          totalParentCompanyCommentCount
          totalQuestionCount
          totalSurveyResponses
          totalViewCount
          includedTypes
          isPinned
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $options)
      pinnedMediaUpdateExists
    }
  }
`;

/**
 * __useMediaUpdatesQuery__
 *
 * To run a query within a React component, call `useMediaUpdatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaUpdatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaUpdatesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useMediaUpdatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MediaUpdatesQuery,
    MediaUpdatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediaUpdatesQuery, MediaUpdatesQueryVariables>(
    MediaUpdatesDocument,
    options
  );
}
export function useMediaUpdatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaUpdatesQuery,
    MediaUpdatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediaUpdatesQuery, MediaUpdatesQueryVariables>(
    MediaUpdatesDocument,
    options
  );
}
export function useMediaUpdatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaUpdatesQuery,
    MediaUpdatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MediaUpdatesQuery, MediaUpdatesQueryVariables>(
    MediaUpdatesDocument,
    options
  );
}
export type MediaUpdatesQueryHookResult = ReturnType<
  typeof useMediaUpdatesQuery
>;
export type MediaUpdatesLazyQueryHookResult = ReturnType<
  typeof useMediaUpdatesLazyQuery
>;
export type MediaUpdatesSuspenseQueryHookResult = ReturnType<
  typeof useMediaUpdatesSuspenseQuery
>;
export type MediaUpdatesQueryResult = Apollo.QueryResult<
  MediaUpdatesQuery,
  MediaUpdatesQueryVariables
>;
export const NavMenuItemsDocument = gql`
  query NavMenuItems {
    navMenuItems {
      id
      label
      url
      position
      openInNewTab
      parentId
      page {
        id
        slug
        title
      }
      children {
        id
        label
        url
        position
        openInNewTab
        parentId
        page {
          id
          slug
          title
        }
      }
    }
  }
`;

/**
 * __useNavMenuItemsQuery__
 *
 * To run a query within a React component, call `useNavMenuItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavMenuItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavMenuItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavMenuItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NavMenuItemsQuery,
    NavMenuItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NavMenuItemsQuery, NavMenuItemsQueryVariables>(
    NavMenuItemsDocument,
    options
  );
}
export function useNavMenuItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NavMenuItemsQuery,
    NavMenuItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NavMenuItemsQuery, NavMenuItemsQueryVariables>(
    NavMenuItemsDocument,
    options
  );
}
export function useNavMenuItemsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    NavMenuItemsQuery,
    NavMenuItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NavMenuItemsQuery, NavMenuItemsQueryVariables>(
    NavMenuItemsDocument,
    options
  );
}
export type NavMenuItemsQueryHookResult = ReturnType<
  typeof useNavMenuItemsQuery
>;
export type NavMenuItemsLazyQueryHookResult = ReturnType<
  typeof useNavMenuItemsLazyQuery
>;
export type NavMenuItemsSuspenseQueryHookResult = ReturnType<
  typeof useNavMenuItemsSuspenseQuery
>;
export type NavMenuItemsQueryResult = Apollo.QueryResult<
  NavMenuItemsQuery,
  NavMenuItemsQueryVariables
>;
export const NewCampaignAllContactsDocument = gql`
  query NewCampaignAllContacts($options: OptionsInput) {
    allContacts(options: $options) {
      id
      insertedAt
      email
      firstName
      lastName
      shareholdingsWithoutPreloads {
        id
        shareCount
      }
      tags {
        id
        name
      }
    }
  }
`;

/**
 * __useNewCampaignAllContactsQuery__
 *
 * To run a query within a React component, call `useNewCampaignAllContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewCampaignAllContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewCampaignAllContactsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useNewCampaignAllContactsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NewCampaignAllContactsQuery,
    NewCampaignAllContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NewCampaignAllContactsQuery,
    NewCampaignAllContactsQueryVariables
  >(NewCampaignAllContactsDocument, options);
}
export function useNewCampaignAllContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NewCampaignAllContactsQuery,
    NewCampaignAllContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NewCampaignAllContactsQuery,
    NewCampaignAllContactsQueryVariables
  >(NewCampaignAllContactsDocument, options);
}
export function useNewCampaignAllContactsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    NewCampaignAllContactsQuery,
    NewCampaignAllContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    NewCampaignAllContactsQuery,
    NewCampaignAllContactsQueryVariables
  >(NewCampaignAllContactsDocument, options);
}
export type NewCampaignAllContactsQueryHookResult = ReturnType<
  typeof useNewCampaignAllContactsQuery
>;
export type NewCampaignAllContactsLazyQueryHookResult = ReturnType<
  typeof useNewCampaignAllContactsLazyQuery
>;
export type NewCampaignAllContactsSuspenseQueryHookResult = ReturnType<
  typeof useNewCampaignAllContactsSuspenseQuery
>;
export type NewCampaignAllContactsQueryResult = Apollo.QueryResult<
  NewCampaignAllContactsQuery,
  NewCampaignAllContactsQueryVariables
>;
export const NonDraftNotLinkedPreparedAnnouncementsDocument = gql`
  query NonDraftNotLinkedPreparedAnnouncements($search: String!) {
    nonDraftNotLinkedPreparedAnnouncements(search: $search) {
      id
      title
      updatedAt
    }
  }
`;

/**
 * __useNonDraftNotLinkedPreparedAnnouncementsQuery__
 *
 * To run a query within a React component, call `useNonDraftNotLinkedPreparedAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonDraftNotLinkedPreparedAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonDraftNotLinkedPreparedAnnouncementsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useNonDraftNotLinkedPreparedAnnouncementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    NonDraftNotLinkedPreparedAnnouncementsQuery,
    NonDraftNotLinkedPreparedAnnouncementsQueryVariables
  > &
    (
      | {
          variables: NonDraftNotLinkedPreparedAnnouncementsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NonDraftNotLinkedPreparedAnnouncementsQuery,
    NonDraftNotLinkedPreparedAnnouncementsQueryVariables
  >(NonDraftNotLinkedPreparedAnnouncementsDocument, options);
}
export function useNonDraftNotLinkedPreparedAnnouncementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonDraftNotLinkedPreparedAnnouncementsQuery,
    NonDraftNotLinkedPreparedAnnouncementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NonDraftNotLinkedPreparedAnnouncementsQuery,
    NonDraftNotLinkedPreparedAnnouncementsQueryVariables
  >(NonDraftNotLinkedPreparedAnnouncementsDocument, options);
}
export function useNonDraftNotLinkedPreparedAnnouncementsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    NonDraftNotLinkedPreparedAnnouncementsQuery,
    NonDraftNotLinkedPreparedAnnouncementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    NonDraftNotLinkedPreparedAnnouncementsQuery,
    NonDraftNotLinkedPreparedAnnouncementsQueryVariables
  >(NonDraftNotLinkedPreparedAnnouncementsDocument, options);
}
export type NonDraftNotLinkedPreparedAnnouncementsQueryHookResult = ReturnType<
  typeof useNonDraftNotLinkedPreparedAnnouncementsQuery
>;
export type NonDraftNotLinkedPreparedAnnouncementsLazyQueryHookResult =
  ReturnType<typeof useNonDraftNotLinkedPreparedAnnouncementsLazyQuery>;
export type NonDraftNotLinkedPreparedAnnouncementsSuspenseQueryHookResult =
  ReturnType<typeof useNonDraftNotLinkedPreparedAnnouncementsSuspenseQuery>;
export type NonDraftNotLinkedPreparedAnnouncementsQueryResult =
  Apollo.QueryResult<
    NonDraftNotLinkedPreparedAnnouncementsQuery,
    NonDraftNotLinkedPreparedAnnouncementsQueryVariables
  >;
export const OldestMediaAnnouncementDocument = gql`
  query OldestMediaAnnouncement {
    oldestMediaAnnouncement {
      id
      header
      likes
      listingKey
      marketKey
      marketSensitive
      mediaId
      postedAt
      rectype
      subtypes
      summary
      totalQuestionCount
      totalSurveyResponses
      totalViewCount
      url
      videoUrl
    }
  }
`;

/**
 * __useOldestMediaAnnouncementQuery__
 *
 * To run a query within a React component, call `useOldestMediaAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useOldestMediaAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOldestMediaAnnouncementQuery({
 *   variables: {
 *   },
 * });
 */
export function useOldestMediaAnnouncementQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OldestMediaAnnouncementQuery,
    OldestMediaAnnouncementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OldestMediaAnnouncementQuery,
    OldestMediaAnnouncementQueryVariables
  >(OldestMediaAnnouncementDocument, options);
}
export function useOldestMediaAnnouncementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OldestMediaAnnouncementQuery,
    OldestMediaAnnouncementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OldestMediaAnnouncementQuery,
    OldestMediaAnnouncementQueryVariables
  >(OldestMediaAnnouncementDocument, options);
}
export function useOldestMediaAnnouncementSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OldestMediaAnnouncementQuery,
    OldestMediaAnnouncementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OldestMediaAnnouncementQuery,
    OldestMediaAnnouncementQueryVariables
  >(OldestMediaAnnouncementDocument, options);
}
export type OldestMediaAnnouncementQueryHookResult = ReturnType<
  typeof useOldestMediaAnnouncementQuery
>;
export type OldestMediaAnnouncementLazyQueryHookResult = ReturnType<
  typeof useOldestMediaAnnouncementLazyQuery
>;
export type OldestMediaAnnouncementSuspenseQueryHookResult = ReturnType<
  typeof useOldestMediaAnnouncementSuspenseQuery
>;
export type OldestMediaAnnouncementQueryResult = Apollo.QueryResult<
  OldestMediaAnnouncementQuery,
  OldestMediaAnnouncementQueryVariables
>;
export const PageDocument = gql`
  query Page($slug: String!) {
    page(slug: $slug) {
      id
      title
      slug
      publishedAt
      isDraft
      metaDescription
      socialImageCloudinaryPublicId
      blocks {
        id
        type
        position
        content
      }
      lastEditedByCompanyUser {
        id
        firstName
        lastName
      }
    }
  }
`;

/**
 * __usePageQuery__
 *
 * To run a query within a React component, call `usePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePageQuery(
  baseOptions: Apollo.QueryHookOptions<PageQuery, PageQueryVariables> &
    ({ variables: PageQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PageQuery, PageQueryVariables>(PageDocument, options);
}
export function usePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PageQuery, PageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PageQuery, PageQueryVariables>(
    PageDocument,
    options
  );
}
export function usePageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PageQuery, PageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PageQuery, PageQueryVariables>(
    PageDocument,
    options
  );
}
export type PageQueryHookResult = ReturnType<typeof usePageQuery>;
export type PageLazyQueryHookResult = ReturnType<typeof usePageLazyQuery>;
export type PageSuspenseQueryHookResult = ReturnType<
  typeof usePageSuspenseQuery
>;
export type PageQueryResult = Apollo.QueryResult<PageQuery, PageQueryVariables>;
export const PastPlacementParticipantsDocument = gql`
  query PastPlacementParticipants(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $options: OptionsInput
    $pastPlacementId: ID!
  ) {
    pastPlacementParticipants(
      after: $after
      before: $before
      first: $first
      last: $last
      options: $options
      pastPlacementId: $pastPlacementId
    ) {
      edges {
        node {
          id
          allocationShares
          invalidated
          oneMonthAfterSettlementShares
          oneWeekAfterSettlementShares
          settlementShares
          shareholding {
            id
            accountName
            shareCount
          }
          threeMonthsAfterSettlementShares
          trancheType
        }
      }
      hasInvalidatedParticipants(pastPlacementId: $pastPlacementId)
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $options, pastPlacementId: $pastPlacementId)
    }
  }
`;

/**
 * __usePastPlacementParticipantsQuery__
 *
 * To run a query within a React component, call `usePastPlacementParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePastPlacementParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePastPlacementParticipantsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      options: // value for 'options'
 *      pastPlacementId: // value for 'pastPlacementId'
 *   },
 * });
 */
export function usePastPlacementParticipantsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PastPlacementParticipantsQuery,
    PastPlacementParticipantsQueryVariables
  > &
    (
      | { variables: PastPlacementParticipantsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PastPlacementParticipantsQuery,
    PastPlacementParticipantsQueryVariables
  >(PastPlacementParticipantsDocument, options);
}
export function usePastPlacementParticipantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PastPlacementParticipantsQuery,
    PastPlacementParticipantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PastPlacementParticipantsQuery,
    PastPlacementParticipantsQueryVariables
  >(PastPlacementParticipantsDocument, options);
}
export function usePastPlacementParticipantsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PastPlacementParticipantsQuery,
    PastPlacementParticipantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PastPlacementParticipantsQuery,
    PastPlacementParticipantsQueryVariables
  >(PastPlacementParticipantsDocument, options);
}
export type PastPlacementParticipantsQueryHookResult = ReturnType<
  typeof usePastPlacementParticipantsQuery
>;
export type PastPlacementParticipantsLazyQueryHookResult = ReturnType<
  typeof usePastPlacementParticipantsLazyQuery
>;
export type PastPlacementParticipantsSuspenseQueryHookResult = ReturnType<
  typeof usePastPlacementParticipantsSuspenseQuery
>;
export type PastPlacementParticipantsQueryResult = Apollo.QueryResult<
  PastPlacementParticipantsQuery,
  PastPlacementParticipantsQueryVariables
>;
export const PastPlacementDocument = gql`
  query PastPlacement($id: ID!) {
    pastPlacement(id: $id) {
      id
      amountRaised
      amountRaisedTotal
      issuePrice
      movementSince
      offerAnnouncedAt
      outcomeAnnouncedAt
      settledAt
      sharesAllocated
      sharesIssued
      tradingHaltPrice
      tranche
      trancheTwoSettledAt
    }
  }
`;

/**
 * __usePastPlacementQuery__
 *
 * To run a query within a React component, call `usePastPlacementQuery` and pass it any options that fit your needs.
 * When your component renders, `usePastPlacementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePastPlacementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePastPlacementQuery(
  baseOptions: Apollo.QueryHookOptions<
    PastPlacementQuery,
    PastPlacementQueryVariables
  > &
    (
      | { variables: PastPlacementQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PastPlacementQuery, PastPlacementQueryVariables>(
    PastPlacementDocument,
    options
  );
}
export function usePastPlacementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PastPlacementQuery,
    PastPlacementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PastPlacementQuery, PastPlacementQueryVariables>(
    PastPlacementDocument,
    options
  );
}
export function usePastPlacementSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PastPlacementQuery,
    PastPlacementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PastPlacementQuery,
    PastPlacementQueryVariables
  >(PastPlacementDocument, options);
}
export type PastPlacementQueryHookResult = ReturnType<
  typeof usePastPlacementQuery
>;
export type PastPlacementLazyQueryHookResult = ReturnType<
  typeof usePastPlacementLazyQuery
>;
export type PastPlacementSuspenseQueryHookResult = ReturnType<
  typeof usePastPlacementSuspenseQuery
>;
export type PastPlacementQueryResult = Apollo.QueryResult<
  PastPlacementQuery,
  PastPlacementQueryVariables
>;
export const PastPlacementsDocument = gql`
  query PastPlacements {
    pastPlacements {
      id
      amountRaised
      amountRaisedTotal
      issuePrice
      movementSince
      offerAnnouncedAt
      outcomeAnnouncedAt
      settledAt
      sharesAllocated
      sharesIssued
      tradingHaltPrice
      tranche
      trancheTwoSettledAt
    }
  }
`;

/**
 * __usePastPlacementsQuery__
 *
 * To run a query within a React component, call `usePastPlacementsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePastPlacementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePastPlacementsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePastPlacementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PastPlacementsQuery,
    PastPlacementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PastPlacementsQuery, PastPlacementsQueryVariables>(
    PastPlacementsDocument,
    options
  );
}
export function usePastPlacementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PastPlacementsQuery,
    PastPlacementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PastPlacementsQuery, PastPlacementsQueryVariables>(
    PastPlacementsDocument,
    options
  );
}
export function usePastPlacementsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PastPlacementsQuery,
    PastPlacementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PastPlacementsQuery,
    PastPlacementsQueryVariables
  >(PastPlacementsDocument, options);
}
export type PastPlacementsQueryHookResult = ReturnType<
  typeof usePastPlacementsQuery
>;
export type PastPlacementsLazyQueryHookResult = ReturnType<
  typeof usePastPlacementsLazyQuery
>;
export type PastPlacementsSuspenseQueryHookResult = ReturnType<
  typeof usePastPlacementsSuspenseQuery
>;
export type PastPlacementsQueryResult = Apollo.QueryResult<
  PastPlacementsQuery,
  PastPlacementsQueryVariables
>;
export const PastPlacementAftermarketStatsDocument = gql`
  query PastPlacementAftermarketStats(
    $timeRange: PastPlacementAftermarketTimeRange!
    $pastPlacementId: ID!
  ) {
    pastPlacementAftermarketStats(
      timeRange: $timeRange
      pastPlacementId: $pastPlacementId
    ) {
      churnerCount
      downgraderCount
      upgraderCount
    }
  }
`;

/**
 * __usePastPlacementAftermarketStatsQuery__
 *
 * To run a query within a React component, call `usePastPlacementAftermarketStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePastPlacementAftermarketStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePastPlacementAftermarketStatsQuery({
 *   variables: {
 *      timeRange: // value for 'timeRange'
 *      pastPlacementId: // value for 'pastPlacementId'
 *   },
 * });
 */
export function usePastPlacementAftermarketStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PastPlacementAftermarketStatsQuery,
    PastPlacementAftermarketStatsQueryVariables
  > &
    (
      | {
          variables: PastPlacementAftermarketStatsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PastPlacementAftermarketStatsQuery,
    PastPlacementAftermarketStatsQueryVariables
  >(PastPlacementAftermarketStatsDocument, options);
}
export function usePastPlacementAftermarketStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PastPlacementAftermarketStatsQuery,
    PastPlacementAftermarketStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PastPlacementAftermarketStatsQuery,
    PastPlacementAftermarketStatsQueryVariables
  >(PastPlacementAftermarketStatsDocument, options);
}
export function usePastPlacementAftermarketStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PastPlacementAftermarketStatsQuery,
    PastPlacementAftermarketStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PastPlacementAftermarketStatsQuery,
    PastPlacementAftermarketStatsQueryVariables
  >(PastPlacementAftermarketStatsDocument, options);
}
export type PastPlacementAftermarketStatsQueryHookResult = ReturnType<
  typeof usePastPlacementAftermarketStatsQuery
>;
export type PastPlacementAftermarketStatsLazyQueryHookResult = ReturnType<
  typeof usePastPlacementAftermarketStatsLazyQuery
>;
export type PastPlacementAftermarketStatsSuspenseQueryHookResult = ReturnType<
  typeof usePastPlacementAftermarketStatsSuspenseQuery
>;
export type PastPlacementAftermarketStatsQueryResult = Apollo.QueryResult<
  PastPlacementAftermarketStatsQuery,
  PastPlacementAftermarketStatsQueryVariables
>;
export const PendingBulkImportsDocument = gql`
  query PendingBulkImports {
    pendingBulkImports {
      id
      insertedAt
      contactsCount
      uploaderProfileUser {
        id
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __usePendingBulkImportsQuery__
 *
 * To run a query within a React component, call `usePendingBulkImportsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingBulkImportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingBulkImportsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingBulkImportsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PendingBulkImportsQuery,
    PendingBulkImportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PendingBulkImportsQuery,
    PendingBulkImportsQueryVariables
  >(PendingBulkImportsDocument, options);
}
export function usePendingBulkImportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PendingBulkImportsQuery,
    PendingBulkImportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PendingBulkImportsQuery,
    PendingBulkImportsQueryVariables
  >(PendingBulkImportsDocument, options);
}
export function usePendingBulkImportsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PendingBulkImportsQuery,
    PendingBulkImportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PendingBulkImportsQuery,
    PendingBulkImportsQueryVariables
  >(PendingBulkImportsDocument, options);
}
export type PendingBulkImportsQueryHookResult = ReturnType<
  typeof usePendingBulkImportsQuery
>;
export type PendingBulkImportsLazyQueryHookResult = ReturnType<
  typeof usePendingBulkImportsLazyQuery
>;
export type PendingBulkImportsSuspenseQueryHookResult = ReturnType<
  typeof usePendingBulkImportsSuspenseQuery
>;
export type PendingBulkImportsQueryResult = Apollo.QueryResult<
  PendingBulkImportsQuery,
  PendingBulkImportsQueryVariables
>;
export const PendingReviewCertificatesDocument = gql`
  query PendingReviewCertificates {
    pendingReviewCertificates {
      id
      investorUser {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

/**
 * __usePendingReviewCertificatesQuery__
 *
 * To run a query within a React component, call `usePendingReviewCertificatesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingReviewCertificatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingReviewCertificatesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingReviewCertificatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PendingReviewCertificatesQuery,
    PendingReviewCertificatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PendingReviewCertificatesQuery,
    PendingReviewCertificatesQueryVariables
  >(PendingReviewCertificatesDocument, options);
}
export function usePendingReviewCertificatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PendingReviewCertificatesQuery,
    PendingReviewCertificatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PendingReviewCertificatesQuery,
    PendingReviewCertificatesQueryVariables
  >(PendingReviewCertificatesDocument, options);
}
export function usePendingReviewCertificatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PendingReviewCertificatesQuery,
    PendingReviewCertificatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PendingReviewCertificatesQuery,
    PendingReviewCertificatesQueryVariables
  >(PendingReviewCertificatesDocument, options);
}
export type PendingReviewCertificatesQueryHookResult = ReturnType<
  typeof usePendingReviewCertificatesQuery
>;
export type PendingReviewCertificatesLazyQueryHookResult = ReturnType<
  typeof usePendingReviewCertificatesLazyQuery
>;
export type PendingReviewCertificatesSuspenseQueryHookResult = ReturnType<
  typeof usePendingReviewCertificatesSuspenseQuery
>;
export type PendingReviewCertificatesQueryResult = Apollo.QueryResult<
  PendingReviewCertificatesQuery,
  PendingReviewCertificatesQueryVariables
>;
export const PlacementDemandReportDocument = gql`
  query PlacementDemandReport {
    placementDemandReport {
      insights {
        content
        lastPublishedAt
        topic
      }
      externalEstimate {
        id
        high
        lastPublishedAt
        lastRequestedAt
        low
        mid
      }
      investorHubEstimate {
        averageBidHigh
        averageBidLow
        averageBidMid
        eligibleInvestorCount
        high
        lastUpdatedAt
        low
        mid
      }
      registerEstimate {
        lastUpdatedDate
        potentialInvestorCount
        potentialInvestors {
          address
          email
          maskedHin
          name
          phoneNumber
        }
      }
      totalEstimate {
        changeOverPastMonthInPercentage
        high
        lastUpdatedAt
        low
        mid
      }
      registryDataStatus {
        latestReportDate
        state
      }
    }
  }
`;

/**
 * __usePlacementDemandReportQuery__
 *
 * To run a query within a React component, call `usePlacementDemandReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlacementDemandReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlacementDemandReportQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlacementDemandReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PlacementDemandReportQuery,
    PlacementDemandReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PlacementDemandReportQuery,
    PlacementDemandReportQueryVariables
  >(PlacementDemandReportDocument, options);
}
export function usePlacementDemandReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlacementDemandReportQuery,
    PlacementDemandReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PlacementDemandReportQuery,
    PlacementDemandReportQueryVariables
  >(PlacementDemandReportDocument, options);
}
export function usePlacementDemandReportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PlacementDemandReportQuery,
    PlacementDemandReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PlacementDemandReportQuery,
    PlacementDemandReportQueryVariables
  >(PlacementDemandReportDocument, options);
}
export type PlacementDemandReportQueryHookResult = ReturnType<
  typeof usePlacementDemandReportQuery
>;
export type PlacementDemandReportLazyQueryHookResult = ReturnType<
  typeof usePlacementDemandReportLazyQuery
>;
export type PlacementDemandReportSuspenseQueryHookResult = ReturnType<
  typeof usePlacementDemandReportSuspenseQuery
>;
export type PlacementDemandReportQueryResult = Apollo.QueryResult<
  PlacementDemandReportQuery,
  PlacementDemandReportQueryVariables
>;
export const PreparedAnnouncementDocument = gql`
  query PreparedAnnouncement($id: ID!) {
    preparedAnnouncement(id: $id) {
      id
      mediaAnnouncement {
        id
      }
      commentContent
      commentUseCompanyAsUsername
      hashId
      insertedAt
      isDraft
      mediaId
      socialVideoUrl
      summary
      title
      updatedAt
      videoUrl
      germanTranslatedVideoUrl
      germanTranslatedUrl
      germanTranslatedHeader
      germanTranslatedSummary
    }
  }
`;

/**
 * __usePreparedAnnouncementQuery__
 *
 * To run a query within a React component, call `usePreparedAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreparedAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreparedAnnouncementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePreparedAnnouncementQuery(
  baseOptions: Apollo.QueryHookOptions<
    PreparedAnnouncementQuery,
    PreparedAnnouncementQueryVariables
  > &
    (
      | { variables: PreparedAnnouncementQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PreparedAnnouncementQuery,
    PreparedAnnouncementQueryVariables
  >(PreparedAnnouncementDocument, options);
}
export function usePreparedAnnouncementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PreparedAnnouncementQuery,
    PreparedAnnouncementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PreparedAnnouncementQuery,
    PreparedAnnouncementQueryVariables
  >(PreparedAnnouncementDocument, options);
}
export function usePreparedAnnouncementSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PreparedAnnouncementQuery,
    PreparedAnnouncementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PreparedAnnouncementQuery,
    PreparedAnnouncementQueryVariables
  >(PreparedAnnouncementDocument, options);
}
export type PreparedAnnouncementQueryHookResult = ReturnType<
  typeof usePreparedAnnouncementQuery
>;
export type PreparedAnnouncementLazyQueryHookResult = ReturnType<
  typeof usePreparedAnnouncementLazyQuery
>;
export type PreparedAnnouncementSuspenseQueryHookResult = ReturnType<
  typeof usePreparedAnnouncementSuspenseQuery
>;
export type PreparedAnnouncementQueryResult = Apollo.QueryResult<
  PreparedAnnouncementQuery,
  PreparedAnnouncementQueryVariables
>;
export const RaisesSppHistoricalForCurrentCompanyDocument = gql`
  query RaisesSppHistoricalForCurrentCompany {
    raisesSppHistoricalForCurrentCompany {
      ticker
      sectorTicker
      amountRaised
      marketCap
      dateAnn
      belongsToCurrentCompany
    }
  }
`;

/**
 * __useRaisesSppHistoricalForCurrentCompanyQuery__
 *
 * To run a query within a React component, call `useRaisesSppHistoricalForCurrentCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useRaisesSppHistoricalForCurrentCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRaisesSppHistoricalForCurrentCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useRaisesSppHistoricalForCurrentCompanyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RaisesSppHistoricalForCurrentCompanyQuery,
    RaisesSppHistoricalForCurrentCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RaisesSppHistoricalForCurrentCompanyQuery,
    RaisesSppHistoricalForCurrentCompanyQueryVariables
  >(RaisesSppHistoricalForCurrentCompanyDocument, options);
}
export function useRaisesSppHistoricalForCurrentCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RaisesSppHistoricalForCurrentCompanyQuery,
    RaisesSppHistoricalForCurrentCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RaisesSppHistoricalForCurrentCompanyQuery,
    RaisesSppHistoricalForCurrentCompanyQueryVariables
  >(RaisesSppHistoricalForCurrentCompanyDocument, options);
}
export function useRaisesSppHistoricalForCurrentCompanySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RaisesSppHistoricalForCurrentCompanyQuery,
    RaisesSppHistoricalForCurrentCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RaisesSppHistoricalForCurrentCompanyQuery,
    RaisesSppHistoricalForCurrentCompanyQueryVariables
  >(RaisesSppHistoricalForCurrentCompanyDocument, options);
}
export type RaisesSppHistoricalForCurrentCompanyQueryHookResult = ReturnType<
  typeof useRaisesSppHistoricalForCurrentCompanyQuery
>;
export type RaisesSppHistoricalForCurrentCompanyLazyQueryHookResult =
  ReturnType<typeof useRaisesSppHistoricalForCurrentCompanyLazyQuery>;
export type RaisesSppHistoricalForCurrentCompanySuspenseQueryHookResult =
  ReturnType<typeof useRaisesSppHistoricalForCurrentCompanySuspenseQuery>;
export type RaisesSppHistoricalForCurrentCompanyQueryResult =
  Apollo.QueryResult<
    RaisesSppHistoricalForCurrentCompanyQuery,
    RaisesSppHistoricalForCurrentCompanyQueryVariables
  >;
export const RaiseSppHistoricalDocument = gql`
  query RaiseSppHistorical($sectorTicker: [String]!, $year: Int!) {
    raisesSppHistorical(sectorTicker: $sectorTicker, year: $year) {
      ticker
      sectorTicker
      amountRaised
      marketCap
      dateAnn
      belongsToCurrentCompany
    }
  }
`;

/**
 * __useRaiseSppHistoricalQuery__
 *
 * To run a query within a React component, call `useRaiseSppHistoricalQuery` and pass it any options that fit your needs.
 * When your component renders, `useRaiseSppHistoricalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRaiseSppHistoricalQuery({
 *   variables: {
 *      sectorTicker: // value for 'sectorTicker'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useRaiseSppHistoricalQuery(
  baseOptions: Apollo.QueryHookOptions<
    RaiseSppHistoricalQuery,
    RaiseSppHistoricalQueryVariables
  > &
    (
      | { variables: RaiseSppHistoricalQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RaiseSppHistoricalQuery,
    RaiseSppHistoricalQueryVariables
  >(RaiseSppHistoricalDocument, options);
}
export function useRaiseSppHistoricalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RaiseSppHistoricalQuery,
    RaiseSppHistoricalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RaiseSppHistoricalQuery,
    RaiseSppHistoricalQueryVariables
  >(RaiseSppHistoricalDocument, options);
}
export function useRaiseSppHistoricalSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RaiseSppHistoricalQuery,
    RaiseSppHistoricalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RaiseSppHistoricalQuery,
    RaiseSppHistoricalQueryVariables
  >(RaiseSppHistoricalDocument, options);
}
export type RaiseSppHistoricalQueryHookResult = ReturnType<
  typeof useRaiseSppHistoricalQuery
>;
export type RaiseSppHistoricalLazyQueryHookResult = ReturnType<
  typeof useRaiseSppHistoricalLazyQuery
>;
export type RaiseSppHistoricalSuspenseQueryHookResult = ReturnType<
  typeof useRaiseSppHistoricalSuspenseQuery
>;
export type RaiseSppHistoricalQueryResult = Apollo.QueryResult<
  RaiseSppHistoricalQuery,
  RaiseSppHistoricalQueryVariables
>;
export const RaisesSppReachabilityStatsDocument = gql`
  query RaisesSppReachabilityStats {
    raisesSppReachabilityStats {
      totalHubUsers
      engagedHubUsers
      totalPhones
      totalEmails
      openRate
      clickRate
    }
  }
`;

/**
 * __useRaisesSppReachabilityStatsQuery__
 *
 * To run a query within a React component, call `useRaisesSppReachabilityStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRaisesSppReachabilityStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRaisesSppReachabilityStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRaisesSppReachabilityStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RaisesSppReachabilityStatsQuery,
    RaisesSppReachabilityStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RaisesSppReachabilityStatsQuery,
    RaisesSppReachabilityStatsQueryVariables
  >(RaisesSppReachabilityStatsDocument, options);
}
export function useRaisesSppReachabilityStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RaisesSppReachabilityStatsQuery,
    RaisesSppReachabilityStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RaisesSppReachabilityStatsQuery,
    RaisesSppReachabilityStatsQueryVariables
  >(RaisesSppReachabilityStatsDocument, options);
}
export function useRaisesSppReachabilityStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RaisesSppReachabilityStatsQuery,
    RaisesSppReachabilityStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RaisesSppReachabilityStatsQuery,
    RaisesSppReachabilityStatsQueryVariables
  >(RaisesSppReachabilityStatsDocument, options);
}
export type RaisesSppReachabilityStatsQueryHookResult = ReturnType<
  typeof useRaisesSppReachabilityStatsQuery
>;
export type RaisesSppReachabilityStatsLazyQueryHookResult = ReturnType<
  typeof useRaisesSppReachabilityStatsLazyQuery
>;
export type RaisesSppReachabilityStatsSuspenseQueryHookResult = ReturnType<
  typeof useRaisesSppReachabilityStatsSuspenseQuery
>;
export type RaisesSppReachabilityStatsQueryResult = Apollo.QueryResult<
  RaisesSppReachabilityStatsQuery,
  RaisesSppReachabilityStatsQueryVariables
>;
export const RaisesSppShareholderStatsDocument = gql`
  query RaisesSppShareholderStats {
    raisesSppShareholderStats {
      totalShareholders
      averageHoldSize
      averageHoldLength
      totalHnws
      totalQual
      totalActive
      totalProfit
    }
  }
`;

/**
 * __useRaisesSppShareholderStatsQuery__
 *
 * To run a query within a React component, call `useRaisesSppShareholderStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRaisesSppShareholderStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRaisesSppShareholderStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRaisesSppShareholderStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RaisesSppShareholderStatsQuery,
    RaisesSppShareholderStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RaisesSppShareholderStatsQuery,
    RaisesSppShareholderStatsQueryVariables
  >(RaisesSppShareholderStatsDocument, options);
}
export function useRaisesSppShareholderStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RaisesSppShareholderStatsQuery,
    RaisesSppShareholderStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RaisesSppShareholderStatsQuery,
    RaisesSppShareholderStatsQueryVariables
  >(RaisesSppShareholderStatsDocument, options);
}
export function useRaisesSppShareholderStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RaisesSppShareholderStatsQuery,
    RaisesSppShareholderStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RaisesSppShareholderStatsQuery,
    RaisesSppShareholderStatsQueryVariables
  >(RaisesSppShareholderStatsDocument, options);
}
export type RaisesSppShareholderStatsQueryHookResult = ReturnType<
  typeof useRaisesSppShareholderStatsQuery
>;
export type RaisesSppShareholderStatsLazyQueryHookResult = ReturnType<
  typeof useRaisesSppShareholderStatsLazyQuery
>;
export type RaisesSppShareholderStatsSuspenseQueryHookResult = ReturnType<
  typeof useRaisesSppShareholderStatsSuspenseQuery
>;
export type RaisesSppShareholderStatsQueryResult = Apollo.QueryResult<
  RaisesSppShareholderStatsQuery,
  RaisesSppShareholderStatsQueryVariables
>;
export const RefinitivTokenDocument = gql`
  query RefinitivToken {
    token {
      value
    }
  }
`;

/**
 * __useRefinitivTokenQuery__
 *
 * To run a query within a React component, call `useRefinitivTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefinitivTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefinitivTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useRefinitivTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RefinitivTokenQuery,
    RefinitivTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RefinitivTokenQuery, RefinitivTokenQueryVariables>(
    RefinitivTokenDocument,
    options
  );
}
export function useRefinitivTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RefinitivTokenQuery,
    RefinitivTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RefinitivTokenQuery, RefinitivTokenQueryVariables>(
    RefinitivTokenDocument,
    options
  );
}
export function useRefinitivTokenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RefinitivTokenQuery,
    RefinitivTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RefinitivTokenQuery,
    RefinitivTokenQueryVariables
  >(RefinitivTokenDocument, options);
}
export type RefinitivTokenQueryHookResult = ReturnType<
  typeof useRefinitivTokenQuery
>;
export type RefinitivTokenLazyQueryHookResult = ReturnType<
  typeof useRefinitivTokenLazyQuery
>;
export type RefinitivTokenSuspenseQueryHookResult = ReturnType<
  typeof useRefinitivTokenSuspenseQuery
>;
export type RefinitivTokenQueryResult = Apollo.QueryResult<
  RefinitivTokenQuery,
  RefinitivTokenQueryVariables
>;
export const RegistryImportStatusDocument = gql`
  query RegistryImportStatus {
    registryImportStatus {
      id
      registry
      state
      lastSuccessfulRegisterImportAt
      latestReportDate
      earliestReportDate
    }
  }
`;

/**
 * __useRegistryImportStatusQuery__
 *
 * To run a query within a React component, call `useRegistryImportStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistryImportStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistryImportStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegistryImportStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegistryImportStatusQuery,
    RegistryImportStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegistryImportStatusQuery,
    RegistryImportStatusQueryVariables
  >(RegistryImportStatusDocument, options);
}
export function useRegistryImportStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegistryImportStatusQuery,
    RegistryImportStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegistryImportStatusQuery,
    RegistryImportStatusQueryVariables
  >(RegistryImportStatusDocument, options);
}
export function useRegistryImportStatusSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RegistryImportStatusQuery,
    RegistryImportStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RegistryImportStatusQuery,
    RegistryImportStatusQueryVariables
  >(RegistryImportStatusDocument, options);
}
export type RegistryImportStatusQueryHookResult = ReturnType<
  typeof useRegistryImportStatusQuery
>;
export type RegistryImportStatusLazyQueryHookResult = ReturnType<
  typeof useRegistryImportStatusLazyQuery
>;
export type RegistryImportStatusSuspenseQueryHookResult = ReturnType<
  typeof useRegistryImportStatusSuspenseQuery
>;
export type RegistryImportStatusQueryResult = Apollo.QueryResult<
  RegistryImportStatusQuery,
  RegistryImportStatusQueryVariables
>;
export const RegistryStatusDocument = gql`
  query RegistryStatus {
    registryStatus {
      expiryDate
      status
      username
    }
  }
`;

/**
 * __useRegistryStatusQuery__
 *
 * To run a query within a React component, call `useRegistryStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistryStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistryStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegistryStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegistryStatusQuery,
    RegistryStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegistryStatusQuery, RegistryStatusQueryVariables>(
    RegistryStatusDocument,
    options
  );
}
export function useRegistryStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegistryStatusQuery,
    RegistryStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegistryStatusQuery, RegistryStatusQueryVariables>(
    RegistryStatusDocument,
    options
  );
}
export function useRegistryStatusSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RegistryStatusQuery,
    RegistryStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RegistryStatusQuery,
    RegistryStatusQueryVariables
  >(RegistryStatusDocument, options);
}
export type RegistryStatusQueryHookResult = ReturnType<
  typeof useRegistryStatusQuery
>;
export type RegistryStatusLazyQueryHookResult = ReturnType<
  typeof useRegistryStatusLazyQuery
>;
export type RegistryStatusSuspenseQueryHookResult = ReturnType<
  typeof useRegistryStatusSuspenseQuery
>;
export type RegistryStatusQueryResult = Apollo.QueryResult<
  RegistryStatusQuery,
  RegistryStatusQueryVariables
>;
export const RegressRaiseSppHistoricalDocument = gql`
  query RegressRaiseSppHistorical($sectorTicker: [String]!, $year: Int!) {
    regressRaisesSppHistorical(sectorTicker: $sectorTicker, year: $year) {
      coefficients
      intercept
    }
  }
`;

/**
 * __useRegressRaiseSppHistoricalQuery__
 *
 * To run a query within a React component, call `useRegressRaiseSppHistoricalQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegressRaiseSppHistoricalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegressRaiseSppHistoricalQuery({
 *   variables: {
 *      sectorTicker: // value for 'sectorTicker'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useRegressRaiseSppHistoricalQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegressRaiseSppHistoricalQuery,
    RegressRaiseSppHistoricalQueryVariables
  > &
    (
      | { variables: RegressRaiseSppHistoricalQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegressRaiseSppHistoricalQuery,
    RegressRaiseSppHistoricalQueryVariables
  >(RegressRaiseSppHistoricalDocument, options);
}
export function useRegressRaiseSppHistoricalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegressRaiseSppHistoricalQuery,
    RegressRaiseSppHistoricalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegressRaiseSppHistoricalQuery,
    RegressRaiseSppHistoricalQueryVariables
  >(RegressRaiseSppHistoricalDocument, options);
}
export function useRegressRaiseSppHistoricalSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RegressRaiseSppHistoricalQuery,
    RegressRaiseSppHistoricalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RegressRaiseSppHistoricalQuery,
    RegressRaiseSppHistoricalQueryVariables
  >(RegressRaiseSppHistoricalDocument, options);
}
export type RegressRaiseSppHistoricalQueryHookResult = ReturnType<
  typeof useRegressRaiseSppHistoricalQuery
>;
export type RegressRaiseSppHistoricalLazyQueryHookResult = ReturnType<
  typeof useRegressRaiseSppHistoricalLazyQuery
>;
export type RegressRaiseSppHistoricalSuspenseQueryHookResult = ReturnType<
  typeof useRegressRaiseSppHistoricalSuspenseQuery
>;
export type RegressRaiseSppHistoricalQueryResult = Apollo.QueryResult<
  RegressRaiseSppHistoricalQuery,
  RegressRaiseSppHistoricalQueryVariables
>;
export const ShareMovementsDocument = gql`
  query ShareMovements($startDate: Date!, $endDate: Date!) {
    shareMovements(startDate: $startDate, endDate: $endDate) {
      id
      totalSold
      totalBought
      topBuyers {
        id
        accountName
        movement
        movementType
      }
      topSellers {
        id
        accountName
        movement
        movementType
      }
      topShareholders {
        id
        balance
        movement
        accountName
      }
    }
  }
`;

/**
 * __useShareMovementsQuery__
 *
 * To run a query within a React component, call `useShareMovementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShareMovementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShareMovementsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useShareMovementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShareMovementsQuery,
    ShareMovementsQueryVariables
  > &
    (
      | { variables: ShareMovementsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShareMovementsQuery, ShareMovementsQueryVariables>(
    ShareMovementsDocument,
    options
  );
}
export function useShareMovementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShareMovementsQuery,
    ShareMovementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShareMovementsQuery, ShareMovementsQueryVariables>(
    ShareMovementsDocument,
    options
  );
}
export function useShareMovementsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ShareMovementsQuery,
    ShareMovementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ShareMovementsQuery,
    ShareMovementsQueryVariables
  >(ShareMovementsDocument, options);
}
export type ShareMovementsQueryHookResult = ReturnType<
  typeof useShareMovementsQuery
>;
export type ShareMovementsLazyQueryHookResult = ReturnType<
  typeof useShareMovementsLazyQuery
>;
export type ShareMovementsSuspenseQueryHookResult = ReturnType<
  typeof useShareMovementsSuspenseQuery
>;
export type ShareMovementsQueryResult = Apollo.QueryResult<
  ShareMovementsQuery,
  ShareMovementsQueryVariables
>;
export const HnwIdentifiedNumbersDocument = gql`
  query HNWIdentifiedNumbers {
    hnwIdentifiedNumbers {
      id
      lastSevenDays
      sevenToFourteenDaysAgo
    }
  }
`;

/**
 * __useHnwIdentifiedNumbersQuery__
 *
 * To run a query within a React component, call `useHnwIdentifiedNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useHnwIdentifiedNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHnwIdentifiedNumbersQuery({
 *   variables: {
 *   },
 * });
 */
export function useHnwIdentifiedNumbersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HnwIdentifiedNumbersQuery,
    HnwIdentifiedNumbersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HnwIdentifiedNumbersQuery,
    HnwIdentifiedNumbersQueryVariables
  >(HnwIdentifiedNumbersDocument, options);
}
export function useHnwIdentifiedNumbersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HnwIdentifiedNumbersQuery,
    HnwIdentifiedNumbersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HnwIdentifiedNumbersQuery,
    HnwIdentifiedNumbersQueryVariables
  >(HnwIdentifiedNumbersDocument, options);
}
export function useHnwIdentifiedNumbersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    HnwIdentifiedNumbersQuery,
    HnwIdentifiedNumbersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HnwIdentifiedNumbersQuery,
    HnwIdentifiedNumbersQueryVariables
  >(HnwIdentifiedNumbersDocument, options);
}
export type HnwIdentifiedNumbersQueryHookResult = ReturnType<
  typeof useHnwIdentifiedNumbersQuery
>;
export type HnwIdentifiedNumbersLazyQueryHookResult = ReturnType<
  typeof useHnwIdentifiedNumbersLazyQuery
>;
export type HnwIdentifiedNumbersSuspenseQueryHookResult = ReturnType<
  typeof useHnwIdentifiedNumbersSuspenseQuery
>;
export type HnwIdentifiedNumbersQueryResult = Apollo.QueryResult<
  HnwIdentifiedNumbersQuery,
  HnwIdentifiedNumbersQueryVariables
>;
export const HnwInvestorsDocument = gql`
  query HNWInvestors(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchPhrase: String
    $hnwType: HnwQueryType
    $includePastInvestors: Boolean
    $order: OrderInput
  ) {
    hnwInvestors(
      after: $after
      before: $before
      first: $first
      last: $last
      searchPhrase: $searchPhrase
      hnwType: $hnwType
      includePastInvestors: $includePastInvestors
      order: $order
    ) {
      edges {
        node {
          id
          hnwIdentifiedAt
          hnwStatus
          hnwBehaviour
          email
          name
          accountNames
          shareCount
          type
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(
        searchPhrase: $searchPhrase
        hnwType: $hnwType
        includePastInvestors: $includePastInvestors
      )
      totals(
        searchPhrase: $searchPhrase
        includePastInvestors: $includePastInvestors
      ) {
        id
        all
        nominatedWithoutCert
        nominatedCertPending
        nominatedCertVerified
        identifiedViaBehaviour
      }
    }
  }
`;

/**
 * __useHnwInvestorsQuery__
 *
 * To run a query within a React component, call `useHnwInvestorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHnwInvestorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHnwInvestorsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      searchPhrase: // value for 'searchPhrase'
 *      hnwType: // value for 'hnwType'
 *      includePastInvestors: // value for 'includePastInvestors'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useHnwInvestorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HnwInvestorsQuery,
    HnwInvestorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HnwInvestorsQuery, HnwInvestorsQueryVariables>(
    HnwInvestorsDocument,
    options
  );
}
export function useHnwInvestorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HnwInvestorsQuery,
    HnwInvestorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HnwInvestorsQuery, HnwInvestorsQueryVariables>(
    HnwInvestorsDocument,
    options
  );
}
export function useHnwInvestorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    HnwInvestorsQuery,
    HnwInvestorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HnwInvestorsQuery, HnwInvestorsQueryVariables>(
    HnwInvestorsDocument,
    options
  );
}
export type HnwInvestorsQueryHookResult = ReturnType<
  typeof useHnwInvestorsQuery
>;
export type HnwInvestorsLazyQueryHookResult = ReturnType<
  typeof useHnwInvestorsLazyQuery
>;
export type HnwInvestorsSuspenseQueryHookResult = ReturnType<
  typeof useHnwInvestorsSuspenseQuery
>;
export type HnwInvestorsQueryResult = Apollo.QueryResult<
  HnwInvestorsQuery,
  HnwInvestorsQueryVariables
>;
export const ShareholderOfferDocument = gql`
  query ShareholderOffer($id: ID!) {
    shareholderOffer(id: $id) {
      id
      title
      type
      isLive
      publishedAt
      scheduledAt
      companyProfile {
        id
        registry
      }
      lastEditedByUser {
        email
        firstName
        lastName
      }
      privateViewers {
        id
        email
      }
      insertedAt
      updatedAt
      totalViewCount
      totalViewCountFromHubUsers
      totalUniqueVisitorsCount
      totalUniqueVisitorsCountFromHubUsers
      signUpsDuringOfferPeriod
      totalInvestorPresentationDownloadsCount
      totalOfferBookletDownloadsCount
      shareholderOfferEngagement {
        date
        totalViewCount
        totalUniqueVisitorsCount
      }
      companyShareholderOfferPage {
        id
        bannerUrl
        closingDate
        interactiveAnnouncementLink
        introductionHeading
        introductionMessage
        investorPresentationName
        investorPresentationUrl
        offerBookletName
        offerBookletUrl
        offerPrice
        raiseApplyInstructionMessage
        raiseDefinitionMessage
        raiseDefinitionTimelineDiagramUrl
        raiseReasonHeroMediaImageUrl
        raiseReasonHeroMediaVideoUrl
        raiseReasonHeroMediaSocialVideoUrl
        raiseReasonMessage
        raisingTarget
        usCitizenPageEnabled
        insertedAt
        updatedAt
        lastEditedByUser {
          email
          firstName
          lastName
        }
        faqs {
          id
          answer
          question
          orderId
          shareholderOfferPageId
        }
        instructions {
          id
          subheading
          message
          buttonUrl
          buttonText
          orderId
          shareholderOfferPageId
        }
        shareholderOffer {
          id
          type
          isLive
          publishedAt
          scheduledAt
        }
      }
    }
  }
`;

/**
 * __useShareholderOfferQuery__
 *
 * To run a query within a React component, call `useShareholderOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useShareholderOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShareholderOfferQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShareholderOfferQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShareholderOfferQuery,
    ShareholderOfferQueryVariables
  > &
    (
      | { variables: ShareholderOfferQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShareholderOfferQuery, ShareholderOfferQueryVariables>(
    ShareholderOfferDocument,
    options
  );
}
export function useShareholderOfferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShareholderOfferQuery,
    ShareholderOfferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ShareholderOfferQuery,
    ShareholderOfferQueryVariables
  >(ShareholderOfferDocument, options);
}
export function useShareholderOfferSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ShareholderOfferQuery,
    ShareholderOfferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ShareholderOfferQuery,
    ShareholderOfferQueryVariables
  >(ShareholderOfferDocument, options);
}
export type ShareholderOfferQueryHookResult = ReturnType<
  typeof useShareholderOfferQuery
>;
export type ShareholderOfferLazyQueryHookResult = ReturnType<
  typeof useShareholderOfferLazyQuery
>;
export type ShareholderOfferSuspenseQueryHookResult = ReturnType<
  typeof useShareholderOfferSuspenseQuery
>;
export type ShareholderOfferQueryResult = Apollo.QueryResult<
  ShareholderOfferQuery,
  ShareholderOfferQueryVariables
>;
export const ShareholderOffersDocument = gql`
  query ShareholderOffers(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $options: OptionsInput
  ) {
    shareholderOffers(
      after: $after
      before: $before
      first: $first
      last: $last
      options: $options
    ) {
      edges {
        node {
          id
          title
          type
          isLive
          publishedAt
          scheduledAt
          companyProfile {
            id
            registry
          }
          lastEditedByUser {
            email
            firstName
            lastName
          }
          insertedAt
          updatedAt
          companyShareholderOfferPage {
            id
            insertedAt
            updatedAt
            lastEditedByUser {
              email
              firstName
              lastName
            }
          }
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $options)
    }
  }
`;

/**
 * __useShareholderOffersQuery__
 *
 * To run a query within a React component, call `useShareholderOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useShareholderOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShareholderOffersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useShareholderOffersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ShareholderOffersQuery,
    ShareholderOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ShareholderOffersQuery,
    ShareholderOffersQueryVariables
  >(ShareholderOffersDocument, options);
}
export function useShareholderOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShareholderOffersQuery,
    ShareholderOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ShareholderOffersQuery,
    ShareholderOffersQueryVariables
  >(ShareholderOffersDocument, options);
}
export function useShareholderOffersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ShareholderOffersQuery,
    ShareholderOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ShareholderOffersQuery,
    ShareholderOffersQueryVariables
  >(ShareholderOffersDocument, options);
}
export type ShareholderOffersQueryHookResult = ReturnType<
  typeof useShareholderOffersQuery
>;
export type ShareholderOffersLazyQueryHookResult = ReturnType<
  typeof useShareholderOffersLazyQuery
>;
export type ShareholderOffersSuspenseQueryHookResult = ReturnType<
  typeof useShareholderOffersSuspenseQuery
>;
export type ShareholderOffersQueryResult = Apollo.QueryResult<
  ShareholderOffersQuery,
  ShareholderOffersQueryVariables
>;
export const ShareholderTradingActivityDocument = gql`
  query ShareholderTradingActivity(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: OrderInput
    $shareholderTradingActivityInput: ShareholderTradingActivityInput!
  ) {
    shareholderTradingActivity(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      shareholderTradingActivityInput: $shareholderTradingActivityInput
    ) {
      edges {
        node {
          id
          accountName
          daysTraded
          email
          holdings
          movement
          shareholdingId
        }
      }
      order {
        key
        value
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total
    }
  }
`;

/**
 * __useShareholderTradingActivityQuery__
 *
 * To run a query within a React component, call `useShareholderTradingActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useShareholderTradingActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShareholderTradingActivityQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      shareholderTradingActivityInput: // value for 'shareholderTradingActivityInput'
 *   },
 * });
 */
export function useShareholderTradingActivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShareholderTradingActivityQuery,
    ShareholderTradingActivityQueryVariables
  > &
    (
      | { variables: ShareholderTradingActivityQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ShareholderTradingActivityQuery,
    ShareholderTradingActivityQueryVariables
  >(ShareholderTradingActivityDocument, options);
}
export function useShareholderTradingActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShareholderTradingActivityQuery,
    ShareholderTradingActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ShareholderTradingActivityQuery,
    ShareholderTradingActivityQueryVariables
  >(ShareholderTradingActivityDocument, options);
}
export function useShareholderTradingActivitySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ShareholderTradingActivityQuery,
    ShareholderTradingActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ShareholderTradingActivityQuery,
    ShareholderTradingActivityQueryVariables
  >(ShareholderTradingActivityDocument, options);
}
export type ShareholderTradingActivityQueryHookResult = ReturnType<
  typeof useShareholderTradingActivityQuery
>;
export type ShareholderTradingActivityLazyQueryHookResult = ReturnType<
  typeof useShareholderTradingActivityLazyQuery
>;
export type ShareholderTradingActivitySuspenseQueryHookResult = ReturnType<
  typeof useShareholderTradingActivitySuspenseQuery
>;
export type ShareholderTradingActivityQueryResult = Apollo.QueryResult<
  ShareholderTradingActivityQuery,
  ShareholderTradingActivityQueryVariables
>;
export const ShareholdingSummaryDocument = gql`
  query ShareholdingSummary($id: ID!, $endDate: Date!, $startDate: Date!) {
    shareholdingSummary(id: $id, endDate: $endDate, startDate: $startDate) {
      id
      announcements {
        id
        header
        postedAt
      }
      dailyHoldings {
        id
        date
        balance
      }
      shareMovements {
        id
        closingBalance
        movement
        movementType
        openingBalance
        settledAt
      }
      timeseries {
        id
        close
        open
        high
        low
        date
        volume
      }
    }
  }
`;

/**
 * __useShareholdingSummaryQuery__
 *
 * To run a query within a React component, call `useShareholdingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useShareholdingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShareholdingSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useShareholdingSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShareholdingSummaryQuery,
    ShareholdingSummaryQueryVariables
  > &
    (
      | { variables: ShareholdingSummaryQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ShareholdingSummaryQuery,
    ShareholdingSummaryQueryVariables
  >(ShareholdingSummaryDocument, options);
}
export function useShareholdingSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShareholdingSummaryQuery,
    ShareholdingSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ShareholdingSummaryQuery,
    ShareholdingSummaryQueryVariables
  >(ShareholdingSummaryDocument, options);
}
export function useShareholdingSummarySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ShareholdingSummaryQuery,
    ShareholdingSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ShareholdingSummaryQuery,
    ShareholdingSummaryQueryVariables
  >(ShareholdingSummaryDocument, options);
}
export type ShareholdingSummaryQueryHookResult = ReturnType<
  typeof useShareholdingSummaryQuery
>;
export type ShareholdingSummaryLazyQueryHookResult = ReturnType<
  typeof useShareholdingSummaryLazyQuery
>;
export type ShareholdingSummarySuspenseQueryHookResult = ReturnType<
  typeof useShareholdingSummarySuspenseQuery
>;
export type ShareholdingSummaryQueryResult = Apollo.QueryResult<
  ShareholdingSummaryQuery,
  ShareholdingSummaryQueryVariables
>;
export const ShareholdingDocument = gql`
  query Shareholding($id: ID!) {
    shareholding(id: $id) {
      id
      accountName
      addressCity
      addressCountry
      addressLineOne
      addressLineTwo
      addressPostcode
      addressState
      brokerNameShort
      hnwIdentifiedAt
      hnwBehaviour
      holderIdMasked
      initialPurchaseDate
      currentHoldingStartDate
      email
      movementCount
      phoneNumber
      shareCount
      estimatedProfitLoss
      estimatedTotalPurchaseValue
      estimatedTotalSaleValue
      shareCountRank
      hasParticipatedInSpp
      hasParticipatedInPlacement
      contact {
        id
        email
        firstName
        lastName
        shareholdings {
          id
          accountName
        }
      }
      latestShareMovement {
        id
        closingBalance
        movement
        openingBalance
        settledAt
      }
    }
  }
`;

/**
 * __useShareholdingQuery__
 *
 * To run a query within a React component, call `useShareholdingQuery` and pass it any options that fit your needs.
 * When your component renders, `useShareholdingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShareholdingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShareholdingQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShareholdingQuery,
    ShareholdingQueryVariables
  > &
    (
      | { variables: ShareholdingQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShareholdingQuery, ShareholdingQueryVariables>(
    ShareholdingDocument,
    options
  );
}
export function useShareholdingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShareholdingQuery,
    ShareholdingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShareholdingQuery, ShareholdingQueryVariables>(
    ShareholdingDocument,
    options
  );
}
export function useShareholdingSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ShareholdingQuery,
    ShareholdingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ShareholdingQuery, ShareholdingQueryVariables>(
    ShareholdingDocument,
    options
  );
}
export type ShareholdingQueryHookResult = ReturnType<
  typeof useShareholdingQuery
>;
export type ShareholdingLazyQueryHookResult = ReturnType<
  typeof useShareholdingLazyQuery
>;
export type ShareholdingSuspenseQueryHookResult = ReturnType<
  typeof useShareholdingSuspenseQuery
>;
export type ShareholdingQueryResult = Apollo.QueryResult<
  ShareholdingQuery,
  ShareholdingQueryVariables
>;
export const ShareholdingsDocument = gql`
  query Shareholdings(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $options: OptionsInput
  ) {
    shareholdings(
      first: $first
      last: $last
      after: $after
      before: $before
      options: $options
    ) {
      total(options: $options)
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          accountName
          currentHoldingStartDate
          brokerNameShort
          email
          id
          hnwIdentifiedAt
          hnwBehaviour
          initialPurchaseDate
          movementCount
          shareCount
          phoneNumber
          addressCity
          shareCountRank
          hasParticipatedInSpp
          hasParticipatedInPlacement
          contact {
            id
            email
            firstName
            lastName
          }
        }
      }
    }
  }
`;

/**
 * __useShareholdingsQuery__
 *
 * To run a query within a React component, call `useShareholdingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShareholdingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShareholdingsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useShareholdingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ShareholdingsQuery,
    ShareholdingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShareholdingsQuery, ShareholdingsQueryVariables>(
    ShareholdingsDocument,
    options
  );
}
export function useShareholdingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShareholdingsQuery,
    ShareholdingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShareholdingsQuery, ShareholdingsQueryVariables>(
    ShareholdingsDocument,
    options
  );
}
export function useShareholdingsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ShareholdingsQuery,
    ShareholdingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ShareholdingsQuery,
    ShareholdingsQueryVariables
  >(ShareholdingsDocument, options);
}
export type ShareholdingsQueryHookResult = ReturnType<
  typeof useShareholdingsQuery
>;
export type ShareholdingsLazyQueryHookResult = ReturnType<
  typeof useShareholdingsLazyQuery
>;
export type ShareholdingsSuspenseQueryHookResult = ReturnType<
  typeof useShareholdingsSuspenseQuery
>;
export type ShareholdingsQueryResult = Apollo.QueryResult<
  ShareholdingsQuery,
  ShareholdingsQueryVariables
>;
export const LinkedinOrganisationsDocument = gql`
  query LinkedinOrganisations {
    linkedinOrganisations {
      id
      name
    }
  }
`;

/**
 * __useLinkedinOrganisationsQuery__
 *
 * To run a query within a React component, call `useLinkedinOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkedinOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkedinOrganisationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLinkedinOrganisationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LinkedinOrganisationsQuery,
    LinkedinOrganisationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LinkedinOrganisationsQuery,
    LinkedinOrganisationsQueryVariables
  >(LinkedinOrganisationsDocument, options);
}
export function useLinkedinOrganisationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LinkedinOrganisationsQuery,
    LinkedinOrganisationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LinkedinOrganisationsQuery,
    LinkedinOrganisationsQueryVariables
  >(LinkedinOrganisationsDocument, options);
}
export function useLinkedinOrganisationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    LinkedinOrganisationsQuery,
    LinkedinOrganisationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LinkedinOrganisationsQuery,
    LinkedinOrganisationsQueryVariables
  >(LinkedinOrganisationsDocument, options);
}
export type LinkedinOrganisationsQueryHookResult = ReturnType<
  typeof useLinkedinOrganisationsQuery
>;
export type LinkedinOrganisationsLazyQueryHookResult = ReturnType<
  typeof useLinkedinOrganisationsLazyQuery
>;
export type LinkedinOrganisationsSuspenseQueryHookResult = ReturnType<
  typeof useLinkedinOrganisationsSuspenseQuery
>;
export type LinkedinOrganisationsQueryResult = Apollo.QueryResult<
  LinkedinOrganisationsQuery,
  LinkedinOrganisationsQueryVariables
>;
export const SppEstimateDocument = gql`
  query sppEstimate {
    sppEstimate {
      medEstimate
      partRate
      avgPart
      priorPart
      updatedAt
    }
  }
`;

/**
 * __useSppEstimateQuery__
 *
 * To run a query within a React component, call `useSppEstimateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSppEstimateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSppEstimateQuery({
 *   variables: {
 *   },
 * });
 */
export function useSppEstimateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SppEstimateQuery,
    SppEstimateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SppEstimateQuery, SppEstimateQueryVariables>(
    SppEstimateDocument,
    options
  );
}
export function useSppEstimateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SppEstimateQuery,
    SppEstimateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SppEstimateQuery, SppEstimateQueryVariables>(
    SppEstimateDocument,
    options
  );
}
export function useSppEstimateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SppEstimateQuery,
    SppEstimateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SppEstimateQuery, SppEstimateQueryVariables>(
    SppEstimateDocument,
    options
  );
}
export type SppEstimateQueryHookResult = ReturnType<typeof useSppEstimateQuery>;
export type SppEstimateLazyQueryHookResult = ReturnType<
  typeof useSppEstimateLazyQuery
>;
export type SppEstimateSuspenseQueryHookResult = ReturnType<
  typeof useSppEstimateSuspenseQuery
>;
export type SppEstimateQueryResult = Apollo.QueryResult<
  SppEstimateQuery,
  SppEstimateQueryVariables
>;
export const SuggestedActionsMetadataDocument = gql`
  query SuggestedActionsMetadata {
    suggestedActionsMetadata {
      id
      activeQuestionCount
      aiSummariesEnabled
      isAutomatedDistributionSwitchedOn
      lastUpdatePostedDate
    }
  }
`;

/**
 * __useSuggestedActionsMetadataQuery__
 *
 * To run a query within a React component, call `useSuggestedActionsMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedActionsMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedActionsMetadataQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuggestedActionsMetadataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SuggestedActionsMetadataQuery,
    SuggestedActionsMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SuggestedActionsMetadataQuery,
    SuggestedActionsMetadataQueryVariables
  >(SuggestedActionsMetadataDocument, options);
}
export function useSuggestedActionsMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SuggestedActionsMetadataQuery,
    SuggestedActionsMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SuggestedActionsMetadataQuery,
    SuggestedActionsMetadataQueryVariables
  >(SuggestedActionsMetadataDocument, options);
}
export function useSuggestedActionsMetadataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SuggestedActionsMetadataQuery,
    SuggestedActionsMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SuggestedActionsMetadataQuery,
    SuggestedActionsMetadataQueryVariables
  >(SuggestedActionsMetadataDocument, options);
}
export type SuggestedActionsMetadataQueryHookResult = ReturnType<
  typeof useSuggestedActionsMetadataQuery
>;
export type SuggestedActionsMetadataLazyQueryHookResult = ReturnType<
  typeof useSuggestedActionsMetadataLazyQuery
>;
export type SuggestedActionsMetadataSuspenseQueryHookResult = ReturnType<
  typeof useSuggestedActionsMetadataSuspenseQuery
>;
export type SuggestedActionsMetadataQueryResult = Apollo.QueryResult<
  SuggestedActionsMetadataQuery,
  SuggestedActionsMetadataQueryVariables
>;
export const UpdateWebsiteDocument = gql`
  mutation UpdateWebsite(
    $themeColourPrimary: String
    $themeColourPrimaryText: String
    $themeColourAccent: String
    $themeColourAccentText: String
    $themeFontTitle: String
    $themeFontBody: String
    $logoCloudinaryUrl: String
    $logoSquareCloudinaryUrl: String
    $isFullWebsiteReplacement: Boolean
    $isStickySignupBannerEnabled: Boolean
    $isEmailPopupModalEnabled: Boolean
    $emailPopupModalCloudinaryUrl: String
    $googleAnalyticsId: String
    $title: String
  ) {
    updateWebsite(
      themeColourPrimary: $themeColourPrimary
      themeColourPrimaryText: $themeColourPrimaryText
      themeColourAccent: $themeColourAccent
      themeColourAccentText: $themeColourAccentText
      themeFontTitle: $themeFontTitle
      themeFontBody: $themeFontBody
      logoCloudinaryUrl: $logoCloudinaryUrl
      logoSquareCloudinaryUrl: $logoSquareCloudinaryUrl
      isStickySignupBannerEnabled: $isStickySignupBannerEnabled
      isFullWebsiteReplacement: $isFullWebsiteReplacement
      isEmailPopupModalEnabled: $isEmailPopupModalEnabled
      emailPopupModalCloudinaryUrl: $emailPopupModalCloudinaryUrl
      googleAnalyticsId: $googleAnalyticsId
      title: $title
    ) {
      id
    }
  }
`;
export type UpdateWebsiteMutationFn = Apollo.MutationFunction<
  UpdateWebsiteMutation,
  UpdateWebsiteMutationVariables
>;

/**
 * __useUpdateWebsiteMutation__
 *
 * To run a mutation, you first call `useUpdateWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebsiteMutation, { data, loading, error }] = useUpdateWebsiteMutation({
 *   variables: {
 *      themeColourPrimary: // value for 'themeColourPrimary'
 *      themeColourPrimaryText: // value for 'themeColourPrimaryText'
 *      themeColourAccent: // value for 'themeColourAccent'
 *      themeColourAccentText: // value for 'themeColourAccentText'
 *      themeFontTitle: // value for 'themeFontTitle'
 *      themeFontBody: // value for 'themeFontBody'
 *      logoCloudinaryUrl: // value for 'logoCloudinaryUrl'
 *      logoSquareCloudinaryUrl: // value for 'logoSquareCloudinaryUrl'
 *      isFullWebsiteReplacement: // value for 'isFullWebsiteReplacement'
 *      isStickySignupBannerEnabled: // value for 'isStickySignupBannerEnabled'
 *      isEmailPopupModalEnabled: // value for 'isEmailPopupModalEnabled'
 *      emailPopupModalCloudinaryUrl: // value for 'emailPopupModalCloudinaryUrl'
 *      googleAnalyticsId: // value for 'googleAnalyticsId'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateWebsiteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWebsiteMutation,
    UpdateWebsiteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWebsiteMutation,
    UpdateWebsiteMutationVariables
  >(UpdateWebsiteDocument, options);
}
export type UpdateWebsiteMutationHookResult = ReturnType<
  typeof useUpdateWebsiteMutation
>;
export type UpdateWebsiteMutationResult =
  Apollo.MutationResult<UpdateWebsiteMutation>;
export type UpdateWebsiteMutationOptions = Apollo.BaseMutationOptions<
  UpdateWebsiteMutation,
  UpdateWebsiteMutationVariables
>;
export const UpdatesEngagementByContentTypeDocument = gql`
  query UpdatesEngagementByContentType(
    $startDate: NaiveDateTime!
    $endDate: NaiveDateTime!
  ) {
    updatesEngagementByContentType(startDate: $startDate, endDate: $endDate) {
      totalEngagement
      totalViews
      includedMediaType
    }
  }
`;

/**
 * __useUpdatesEngagementByContentTypeQuery__
 *
 * To run a query within a React component, call `useUpdatesEngagementByContentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdatesEngagementByContentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatesEngagementByContentTypeQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useUpdatesEngagementByContentTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    UpdatesEngagementByContentTypeQuery,
    UpdatesEngagementByContentTypeQueryVariables
  > &
    (
      | {
          variables: UpdatesEngagementByContentTypeQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UpdatesEngagementByContentTypeQuery,
    UpdatesEngagementByContentTypeQueryVariables
  >(UpdatesEngagementByContentTypeDocument, options);
}
export function useUpdatesEngagementByContentTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpdatesEngagementByContentTypeQuery,
    UpdatesEngagementByContentTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UpdatesEngagementByContentTypeQuery,
    UpdatesEngagementByContentTypeQueryVariables
  >(UpdatesEngagementByContentTypeDocument, options);
}
export function useUpdatesEngagementByContentTypeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UpdatesEngagementByContentTypeQuery,
    UpdatesEngagementByContentTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UpdatesEngagementByContentTypeQuery,
    UpdatesEngagementByContentTypeQueryVariables
  >(UpdatesEngagementByContentTypeDocument, options);
}
export type UpdatesEngagementByContentTypeQueryHookResult = ReturnType<
  typeof useUpdatesEngagementByContentTypeQuery
>;
export type UpdatesEngagementByContentTypeLazyQueryHookResult = ReturnType<
  typeof useUpdatesEngagementByContentTypeLazyQuery
>;
export type UpdatesEngagementByContentTypeSuspenseQueryHookResult = ReturnType<
  typeof useUpdatesEngagementByContentTypeSuspenseQuery
>;
export type UpdatesEngagementByContentTypeQueryResult = Apollo.QueryResult<
  UpdatesEngagementByContentTypeQuery,
  UpdatesEngagementByContentTypeQueryVariables
>;
export const UtmLinkAnalyticsSignupsDocument = gql`
  query UtmLinkAnalyticsSignups(
    $utmLinkId: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $options: OptionsInput
  ) {
    utmLinkAnalyticsSignups(
      utmLinkId: $utmLinkId
      after: $after
      before: $before
      first: $first
      last: $last
      options: $options
    ) {
      edges {
        node {
          id
          firstName
          lastName
          email
          insertedAt
          hnwIdentifiedAt
          shareholderStatus
          hnwStatus
          hnwIdentifiedAt
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(utmLinkId: $utmLinkId, options: $options)
    }
  }
`;

/**
 * __useUtmLinkAnalyticsSignupsQuery__
 *
 * To run a query within a React component, call `useUtmLinkAnalyticsSignupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUtmLinkAnalyticsSignupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUtmLinkAnalyticsSignupsQuery({
 *   variables: {
 *      utmLinkId: // value for 'utmLinkId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUtmLinkAnalyticsSignupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UtmLinkAnalyticsSignupsQuery,
    UtmLinkAnalyticsSignupsQueryVariables
  > &
    (
      | { variables: UtmLinkAnalyticsSignupsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UtmLinkAnalyticsSignupsQuery,
    UtmLinkAnalyticsSignupsQueryVariables
  >(UtmLinkAnalyticsSignupsDocument, options);
}
export function useUtmLinkAnalyticsSignupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UtmLinkAnalyticsSignupsQuery,
    UtmLinkAnalyticsSignupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UtmLinkAnalyticsSignupsQuery,
    UtmLinkAnalyticsSignupsQueryVariables
  >(UtmLinkAnalyticsSignupsDocument, options);
}
export function useUtmLinkAnalyticsSignupsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UtmLinkAnalyticsSignupsQuery,
    UtmLinkAnalyticsSignupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UtmLinkAnalyticsSignupsQuery,
    UtmLinkAnalyticsSignupsQueryVariables
  >(UtmLinkAnalyticsSignupsDocument, options);
}
export type UtmLinkAnalyticsSignupsQueryHookResult = ReturnType<
  typeof useUtmLinkAnalyticsSignupsQuery
>;
export type UtmLinkAnalyticsSignupsLazyQueryHookResult = ReturnType<
  typeof useUtmLinkAnalyticsSignupsLazyQuery
>;
export type UtmLinkAnalyticsSignupsSuspenseQueryHookResult = ReturnType<
  typeof useUtmLinkAnalyticsSignupsSuspenseQuery
>;
export type UtmLinkAnalyticsSignupsQueryResult = Apollo.QueryResult<
  UtmLinkAnalyticsSignupsQuery,
  UtmLinkAnalyticsSignupsQueryVariables
>;
export const UtmLinkAnalyticsDocument = gql`
  query UtmLinkAnalytics(
    $id: ID!
    $startDate: NaiveDateTime!
    $endDate: NaiveDateTime!
  ) {
    utmLinkAnalytics(id: $id, startDate: $startDate, endDate: $endDate) {
      currPeriodAnalytics {
        id
        totalHits
        totalSignUps
        totalSignUpsList {
          date
          totalSignups
        }
        totalUniqueHits
        totalHitsList {
          date
          totalHits
        }
        totalUniqueHitsList {
          date
          uniqueHits
        }
      }
      prevPeriodAnalytics {
        totalHits
        totalSignUps
        totalSignUpsList {
          date
          totalSignups
        }
        totalUniqueHits
        totalHitsList {
          date
          totalHits
        }
        totalUniqueHitsList {
          date
          uniqueHits
        }
      }
    }
  }
`;

/**
 * __useUtmLinkAnalyticsQuery__
 *
 * To run a query within a React component, call `useUtmLinkAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUtmLinkAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUtmLinkAnalyticsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useUtmLinkAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UtmLinkAnalyticsQuery,
    UtmLinkAnalyticsQueryVariables
  > &
    (
      | { variables: UtmLinkAnalyticsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UtmLinkAnalyticsQuery, UtmLinkAnalyticsQueryVariables>(
    UtmLinkAnalyticsDocument,
    options
  );
}
export function useUtmLinkAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UtmLinkAnalyticsQuery,
    UtmLinkAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UtmLinkAnalyticsQuery,
    UtmLinkAnalyticsQueryVariables
  >(UtmLinkAnalyticsDocument, options);
}
export function useUtmLinkAnalyticsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UtmLinkAnalyticsQuery,
    UtmLinkAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UtmLinkAnalyticsQuery,
    UtmLinkAnalyticsQueryVariables
  >(UtmLinkAnalyticsDocument, options);
}
export type UtmLinkAnalyticsQueryHookResult = ReturnType<
  typeof useUtmLinkAnalyticsQuery
>;
export type UtmLinkAnalyticsLazyQueryHookResult = ReturnType<
  typeof useUtmLinkAnalyticsLazyQuery
>;
export type UtmLinkAnalyticsSuspenseQueryHookResult = ReturnType<
  typeof useUtmLinkAnalyticsSuspenseQuery
>;
export type UtmLinkAnalyticsQueryResult = Apollo.QueryResult<
  UtmLinkAnalyticsQuery,
  UtmLinkAnalyticsQueryVariables
>;
export const GetUtmLinkDocument = gql`
  query GetUtmLink($id: ID!) {
    getUtmLink(id: $id) {
      id
      hash
      destinationUrl
      utmCampaign
      utmMedium
      utmSource
      utmUrl
      insertedAt
      updatedAt
    }
  }
`;

/**
 * __useGetUtmLinkQuery__
 *
 * To run a query within a React component, call `useGetUtmLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUtmLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUtmLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUtmLinkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUtmLinkQuery,
    GetUtmLinkQueryVariables
  > &
    (
      | { variables: GetUtmLinkQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUtmLinkQuery, GetUtmLinkQueryVariables>(
    GetUtmLinkDocument,
    options
  );
}
export function useGetUtmLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUtmLinkQuery,
    GetUtmLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUtmLinkQuery, GetUtmLinkQueryVariables>(
    GetUtmLinkDocument,
    options
  );
}
export function useGetUtmLinkSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUtmLinkQuery,
    GetUtmLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUtmLinkQuery, GetUtmLinkQueryVariables>(
    GetUtmLinkDocument,
    options
  );
}
export type GetUtmLinkQueryHookResult = ReturnType<typeof useGetUtmLinkQuery>;
export type GetUtmLinkLazyQueryHookResult = ReturnType<
  typeof useGetUtmLinkLazyQuery
>;
export type GetUtmLinkSuspenseQueryHookResult = ReturnType<
  typeof useGetUtmLinkSuspenseQuery
>;
export type GetUtmLinkQueryResult = Apollo.QueryResult<
  GetUtmLinkQuery,
  GetUtmLinkQueryVariables
>;
export const UtmLinksDocument = gql`
  query UtmLinks(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $options: OptionsInput
  ) {
    utmLinks(
      after: $after
      before: $before
      first: $first
      last: $last
      options: $options
    ) {
      edges {
        node {
          id
          hash
          destinationUrl
          utmCampaign
          utmSource
          utmMedium
          utmUrl
          insertedAt
          updatedAt
        }
      }
      options {
        filters {
          key
          value
        }
        orders {
          key
          value
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total(options: $options)
    }
  }
`;

/**
 * __useUtmLinksQuery__
 *
 * To run a query within a React component, call `useUtmLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useUtmLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUtmLinksQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUtmLinksQuery(
  baseOptions?: Apollo.QueryHookOptions<UtmLinksQuery, UtmLinksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UtmLinksQuery, UtmLinksQueryVariables>(
    UtmLinksDocument,
    options
  );
}
export function useUtmLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UtmLinksQuery,
    UtmLinksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UtmLinksQuery, UtmLinksQueryVariables>(
    UtmLinksDocument,
    options
  );
}
export function useUtmLinksSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UtmLinksQuery,
    UtmLinksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UtmLinksQuery, UtmLinksQueryVariables>(
    UtmLinksDocument,
    options
  );
}
export type UtmLinksQueryHookResult = ReturnType<typeof useUtmLinksQuery>;
export type UtmLinksLazyQueryHookResult = ReturnType<
  typeof useUtmLinksLazyQuery
>;
export type UtmLinksSuspenseQueryHookResult = ReturnType<
  typeof useUtmLinksSuspenseQuery
>;
export type UtmLinksQueryResult = Apollo.QueryResult<
  UtmLinksQuery,
  UtmLinksQueryVariables
>;
export const CompanyProfileUserUpdatedDocument = gql`
  subscription CompanyProfileUserUpdated($id: ID!) {
    companyProfileUserUpdated(id: $id) {
      id
      status
    }
  }
`;

/**
 * __useCompanyProfileUserUpdatedSubscription__
 *
 * To run a query within a React component, call `useCompanyProfileUserUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCompanyProfileUserUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyProfileUserUpdatedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyProfileUserUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    CompanyProfileUserUpdatedSubscription,
    CompanyProfileUserUpdatedSubscriptionVariables
  > &
    (
      | {
          variables: CompanyProfileUserUpdatedSubscriptionVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CompanyProfileUserUpdatedSubscription,
    CompanyProfileUserUpdatedSubscriptionVariables
  >(CompanyProfileUserUpdatedDocument, options);
}
export type CompanyProfileUserUpdatedSubscriptionHookResult = ReturnType<
  typeof useCompanyProfileUserUpdatedSubscription
>;
export type CompanyProfileUserUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<CompanyProfileUserUpdatedSubscription>;
export const CurrentCompanyProfileUpdatedDocument = gql`
  subscription CurrentCompanyProfileUpdated {
    currentCompanyProfileUpdated {
      id
      addressLine1
      addressLine2
      city
      country
      email
      informationConfirmedAt
      insertedAt
      investorCentreEnabled
      logoUrl
      mobileNumber
      name
      postalCode
      state
    }
  }
`;

/**
 * __useCurrentCompanyProfileUpdatedSubscription__
 *
 * To run a query within a React component, call `useCurrentCompanyProfileUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCompanyProfileUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCompanyProfileUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCurrentCompanyProfileUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CurrentCompanyProfileUpdatedSubscription,
    CurrentCompanyProfileUpdatedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CurrentCompanyProfileUpdatedSubscription,
    CurrentCompanyProfileUpdatedSubscriptionVariables
  >(CurrentCompanyProfileUpdatedDocument, options);
}
export type CurrentCompanyProfileUpdatedSubscriptionHookResult = ReturnType<
  typeof useCurrentCompanyProfileUpdatedSubscription
>;
export type CurrentCompanyProfileUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<CurrentCompanyProfileUpdatedSubscription>;
